<template>
  <div class="outroot">
    <el-dialog
      :title="'上传' + headline"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      append-to-body
      custom-class="accentuate"
      @close="closeDialogFunc(false)"
    >
      <div class="commodityPopover">
        <el-upload
          class="upload-demo"
          :action="'/supplierapi/Material/addMaterial'"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :on-exceed="handleExceed"
          :file-list="fileList"
          accept="image/*"
          :on-success="uploadSuccess"
          :data="{ token }"
          :before-upload="beforeUpload"
          :show-file-list="false"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <!-- <div slot="tip" class="el-upload__tip">注意：只能上传图片格式</div> -->
        </el-upload>
        <el-table
          :header-cell-style="{
            background: '#F5F7FA',
            textAlign: 'center',
            color: '#333333',
          }"
          :row-class-name="tableRowClassName"
          :cell-style="{ textAlign: 'center' }"
          :data="tableData"
          highlight-current-row
          @current-change="rowClick"
          setCurrentRow
          border
          class="top10"
        >
          <el-table-column label="素材图片" :highlight-current-row="true">
            <template slot-scope="scope">
              <div>
                <div class="detalimg" v-if="scope.row.file_type == 0">
                  <img src="@/assets/images/folder.svg" />
                </div>
                <div class="detalimg" v-else-if="scope.row.file_type == 1">
                  <img :src="scope.row.key | imgbaseurl" />
                </div>
                <div class="detalimg" v-else-if="scope.row.file_type == 2">
                  <video
                    style="width: 32px; height: 32px"
                    id="tablemyVideo"
                    ref="video"
                    class="video-js vjs-default-skin viedomodel"
                    data-setup='{"controlBar": {"pictureInPictureToggle": false}}'
                  >
                    <source
                      :src="scope.row.key | imgbaseurl"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div
                  class="detalimg"
                  v-else-if="
                    scope.row.key.includes('.doc') ||
                    scope.row.key.includes('.docx')
                  "
                >
                  <img src="@/assets/material/word.svg" />
                </div>
                <div
                  class="detalimg"
                  v-else-if="
                    scope.row.key.includes('.xls') ||
                    scope.row.key.includes('.xlsx')
                  "
                >
                  <img src="@/assets/material/excel.svg" />
                </div>
                <div
                  class="detalimg"
                  v-else-if="scope.row.key.includes('.pdf')"
                >
                  <img src="@/assets/material/pdf.svg" />
                </div>
                <div
                  class="detalimg"
                  v-else-if="
                    scope.row.key.includes('.zip') ||
                    scope.row.key.includes('.rar')
                  "
                >
                  <img src="@/assets/material/zip.svg" />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="素材名称">
            <template slot-scope="{ row }">
              <div class="downloadBox">
                <span>{{ row.name }}</span>
                <span
                  class="download"
                  @click="
                    downloadFunc(row.key | imgbaseurl, row.file_type, row.name)
                  "
                  >点击下载</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          @next-click="handleCurrentChange"
          @prev-click="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="7"
          layout="total, prev, pager, next"
          :total="parseInt(total)"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialogFunc(false)">取 消</el-button>
        <el-button type="primary" @click="closeDialogFunc(true)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 7,
      total: 0,
      token: localStorage.getItem('token'),
      tableData: [],
      chooseRow: '',
      dialogVisible: true,
      fileSumSize: 0, //记录已上传文件总大小
    };
  },
  props: {
    flag: {
      //当同一个页面有多处上传时,区分的标识
      type: String,
      default: '',
    },
    url: {
      //自定义上传的url
      type: String,
      default: '',
    },
    headline: {
      //自定义上传的标题
      type: String,
      default: '素材',
    },
    file_type: {
      //可以指定查询文件的类型
      type: String,
      default: '',
    },
    uploadFileSumSize: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    beforeUpload(file) {
      let MB = file.size / 1024 / 1024;
      if (MB > 5) {
        this.$message({
          type: 'warning',
          message: '已超出单个上传最大限制,单个最大限制5MB!',
        });
      }
      // if (this.fileSumSize + file.size / 1024 / 1024 > this.uploadFileSumSize) {
      //   this.$message({
      //     type: 'warning',
      //     message: `已超出当前上传总大小,当前最大限制${uploadFileSumSize}M!`,
      //   });
      // }
      return MB <= 5;
    },
    //当页数发生改变时触发
    handleCurrentChange(currentPage) {
      this.getList(currentPage);
    },
    //操作后点击按钮触发回调
    closeDialogFunc(falseAndTrue) {
      this.dialogVisible = false;
      this.$emit('closeDialogEvent', falseAndTrue, this.flag, this.chooseRow);
    },
    //商品图片上传成功的回调
    uploadSuccess(file) {
      let temp = 'warning';
      if (file.errcode == 0) {
        this.fileSumSize += file.data.size;
        this.getList();
        this.getMaterialList();
        temp = 'success';
      }
      this.$message({ type: temp, message: res.msg });
    },
    getList(currentPage) {
      let that = this;
      xmJson(
        '/Material/getMaterialList',
        {
          page_size: that.pageSize,
          page_num: currentPage ? currentPage : that.currentPage,
          file_type: that.file_type ? that.file_type : '',
        },
        res => {
          that.total = res.data.count;
          that.tableData = res.data.list;
        },
        err => {
          console.error(err);
        },
        'get'
      );
    },
    rowClick(row) {
      this.chooseRow = row;
    },
    downloadFunc(url, type, name) {
      axios({
        url: url,
        method: 'get',
        responseType: 'blob',
      })
        .then(res => {
          const filestream = res.data; // 返回的文件流
          const blob = new Blob([filestream], {
            type: res.headers['content-type'],
          });
          const a = document.createElement('a');
          const href = window.URL.createObjectURL(blob); // 创建下载连接
          a.href = href;
          a.download = decodeURI(name);
          document.body.appendChild(a);
          // window.open(a.href)
          a.click();
          document.body.removeChild(a); // 下载完移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象
          console.log(123, a);
        })
        .catch(err => {
          console.error(err);
        });
    },
    getUrl(row) {
      if (row.file_type === '1') {
        return this.$options.filters.imgbaseurl(row.key);
      } else if (row.file_type === '0') {
        let typeUrl = [
          'static/fileTypeDefaultIcon/PDF.png',
          'static/fileTypeDefaultIcon/excel.png',
          'static/fileTypeDefaultIcon/compression.png',
          'static/fileTypeDefaultIcon/word.png',
          'static/fileTypeDefaultIcon/txt.png',
        ];
        let suffixBox = row.name.slice(row.name.lastIndexOf('.') + 1);
        let obj = [
          { suffix: ['pdf'], url: 0 },
          { suffix: ['xlsx', 'xls'], url: 1 },
          { suffix: ['rar', 'zip', '7z'], url: 2 },
          { suffix: ['doc', 'docx'], url: 3 },
          { suffix: ['txt'], url: 4 },
        ];
        let indexBox;
        obj.forEach((item, index) => {
          if (
            item.suffix.some(
              itemTwo => itemTwo.toLowerCase() === suffixBox.toLowerCase()
            )
          ) {
            return (indexBox = index);
          }
        });
        if (indexBox) {
          return typeUrl[obj[indexBox].url];
        }
        return 'static/fileTypeDefaultIcon/compression.png';
      }
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped>
.accentuate {
  z-index: 999;
}
.downloadBox {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.download {
  color: var(--xm-color);
  margin-left: 10px;
}

.top10 {
  margin-top: 10px;
}

.detalimg {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 0px auto;
}

.detalimg > img {
  width: 100%;
  height: 100%;
}
</style>

import { render, staticRenderFns } from "./XmVideo.vue?vue&type=template&id=494719f8&scoped=true"
import script from "./XmVideo.vue?vue&type=script&lang=js"
export * from "./XmVideo.vue?vue&type=script&lang=js"
import style0 from "./XmVideo.vue?vue&type=style&index=0&id=494719f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "494719f8",
  null
  
)

export default component.exports
/**
 * 后端接口划分模块，request.js 的工具函数
 * 1. 保存各模块对应的 api url
 * 2. 获取模块对应的 url
 *
 * @author hukeyi
 */

/**
 * 按功能模块划分的后端新项目，统一转发到 8003（VUE_APP_API_HOST）；
 * 旧项目 supplier-api 接口仍在 8004
 */
const keyURLMap = {
  user: '/userapi', // 用户中心
  goods: '/goodsapi', // 商品中心
  notify: '/notifyapi', // 通知中心
  order: '/orderapi', // 订单中心
  ware: '/warehouseapi', // 仓储中心
  fund: '/fundapi', // 资金中心
  // #todo 其他模块在下面加
};

// 获取 baseURL
export function getBaseURL(key) {
  return keyURLMap[key]
    ? process.env.VUE_APP_API_HOST + keyURLMap[key]
    : process.env.VUE_APP_API;
}

<template>
  <div class="country-selector" :style="{ width: width }">
    <el-select
      clearable
      @change="reInput"
      :value="completeAddress"
      :popper-append-to-body="false"
      popper-class="el-select-popper"
      @focus="showDropdown = true"
      ref="selector"
    >
      <template slot="empty">
        <el-tabs v-show="showDropdown" v-model="tabName" type="border-card">
          <!-- <el-tab-pane
            label="大洲"
            name="continents"
            v-if="Category == 'international'"
          >
            <div class="continents-list-wrapper">
              <div class="continents-groups">
                <ul>
                  <li
                    v-for="group in continents_list"
                    :key="group.id"
                    :class="{
                      active: addressData.continents_id == group.id,
                    }"
                    @click="selectOption(1, group)"
                  >
                    {{ group.cname }}
                  </li>
                </ul>
              </div>
            </div>
          </el-tab-pane> -->
          <el-tab-pane
            label="国家"
            name="country"
            :disabled="country_list && country_list != [] ? true : false"
            v-if="isShow(0)"
          >
            <el-input
              placeholder="搜索国家"
              v-model="searchTarget"
              @input="searchCountry"
            >
              <img
                class="search-icon"
                width="16px"
                height="16px"
                slot="prefix"
                :src="searchIcon"
              />
            </el-input>
            <div class="country-list-wrapper">
              <div
                class="country-groups"
                v-for="group in country_list"
                :key="group.initial"
              >
                <div>
                  <span
                    :class="{
                      active:
                        initialActive.toUpperCase() ==
                        group.initial.toUpperCase(),
                    }"
                    >{{ group.initial }}</span
                  >
                  <hr class="country-group-divider" />
                </div>
                <ul>
                  <li
                    v-for="item in group.list"
                    :key="item.id"
                    :class="{
                      active: addressData.country_id == item.id,
                    }"
                    @click="selectOption(0, item)"
                  >
                    {{ item.cname }}
                  </li>
                </ul>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            name="save"
            :disabled="save_list && save_list != [] ? false : true"
            label="省（州）"
            v-if="isShow(1)"
          >
            <ul class="no-search-input">
              <li
                v-for="item in save_list"
                :key="item.id"
                :class="{ active: addressData.save_id == item.id }"
                @click="selectOption(2, item)"
              >
                {{ item.cname }}
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane
            name="city"
            :disabled="city_list && city_list != [] ? false : true"
            label="市"
            v-if="isShow(2)"
          >
            <ul class="no-search-input">
              <li
                v-for="item in city_list"
                :key="item.id"
                :class="{ active: addressData.city_id == item.id }"
                @click="selectOption(3, item)"
              >
                {{ item.cname }}
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs></template
      ></el-select
    >
  </div>
</template>
<script>
export default {
  props: [
    'country_id',
    'country',
    'width',
    'continents',
    'continents_id',
    'Category',
  ],
  //   props: ['areaData'],

  data() {
    return {
      showDropdown: false,
      tabName: '',
      initialActive: '', // 搜索出来的符合条件的首字母
      searchTarget: '', // 用户搜索框的输入
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标

      continents_list: [], //洲列表
      country_list: [], //国列表
      save_list: [], //省列表
      city_list: [], //市列表

      /**
       * 需要传出的数据：
       */
      addressData: {
        continents: '',
        continents_id: '',
        country: '',
        country_id: '',
        continents_list: [],
        country_list: [],
        save_list: [], //省列表
        city_list: [],
        save: '',
        save_id: '',
        city: '',
        city_id: '',
      },
    };
  },
  watch: {
    Category(n, o) {
      if (n == 'china') {
        this.getProvinceList();
        this.changeTab('save');
      }
      if (n == 'international') {
        this.getCountryList();
        this.changeTab('country');
      }
    },
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      switch (this.Category) {
        case 'international':
          if (this.addressData.country && this.addressData.country != '') {
            const addr = `${this.addressData.country}`;
            return addr;
          }
          break;
        case 'china':
          const addr = `${this.addressData.save}${
            this.addressData.city && this.addressData.city != '' ? '/' : ''
          }${this.addressData.city}`;
          return addr;
        default:
          break;
      }
    },
  },
  methods: {
    reInput() {
      if (this.completeAddress && this.completeAddress != '') {
        this.resetData();
        this.closeDropdown();
        this.initialize();
      }
    },
    //0-国 1-省 2-市
    isShow(n) {
      switch (n) {
        case 0:
          switch (this.Category) {
            case 'international':
              return true;
            case 'china':
              return false;
            default:
              break;
          }
        case 1:
          switch (this.Category) {
            case 'international':
              return false;
            case 'china':
              return true;
            default:
              break;
          }
        case 2:
          switch (this.Category) {
            case 'international':
              return false;
            case 'china':
              return true;
            default:
              break;
          }
        default:
          break;
      }
    },
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 0：国家菜单；1：大洲；2:省;3:市
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      if (index == 1) {
        // 选择大洲
        this.getCountryList(item.id);
        this.getCountryList1(item.id);
        this.changeTab('country');
        this.addressData.continents = item.cname;
        this.addressData.continents_id = item.id;
      }
      if (index == 0) {
        // 选择国家
        this.addressData.country = item.cname;
        this.addressData.country_id = item.id;
        // 可以传出数据了
        if (this.Category == 'international') {
          this.closeDropdown();
          this.searchTarget = '';
        }
        if (this.Category == 'china') {
          if (item.has_state == '1') this.getProvinceList();
          else if (item.has_state == '0') this.getCityList();
        }
      }
      if (index == 2) {
        // 选择省份
        this.addressData.save = item.cname;
        this.addressData.save_id = item.id;
        // 清空状态
        this.addressData.city = '';
        this.addressData.city_id = '';

        this.getCityList();
      }
      if (index == 3) {
        this.addressData.city = item.cname;
        this.addressData.city_id = item.id;

        // 可以传出数据了
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.$emit('update', this.addressData);
      this.showDropdown = false;
      this.$refs.selector.blur(); // 选择器的尖角符号向下
    },
    // 搜索国家，高亮用户输入数据后的首字母
    searchCountry() {
      const api = 'Supplier.getCountries';
      const options = {
        is_initial_group: 1,
        name: this.searchTarget,
      };
      switch (this.Category) {
        case 'international':
          options.id = this.addressData.continents_id;
          break;
        case 'china':
          options.id = '1';
          options.type_rote = 'china';
        default:
          break;
      }
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            if (res.data && res.data.length > 0) {
              that.country_list = res.data;
              that.initialActive = res.data[0].initial;
            }
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 获取大洲列表
    getcontinentsList() {
      const api = '/supplier/getContinents';
      const options = {};
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            that.continents_list = res.data;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 获取国家列表
    getCountryList(id) {
      const api = 'Supplier.getCountries';
      const options = {
        is_initial_group: '1',
      };
      switch (this.Category) {
        case 'international':
          options.id = id;
          // options.type_rote = 'international';
          break;
        case 'china':
          options.id = '1';
          options.type_rote = 'china';
        default:
          break;
      }
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            that.country_list = res.data;
            res.data.forEach(item => {
              that.addressData.country_list.push(...item.list);
            });
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    getCountryList1(id) {
      const api = 'Supplier.getCountries';
      const options = {
        is_initial_group: '2',
      };
      switch (this.Category) {
        case 'international':
          options.id = id;
          // options.type_rote = 'international';
          break;
        case 'china':
          options.id = '1';
          options.type_rote = 'china';
        default:
          break;
      }
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            that.addressData.country_list = res.data;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    getProvinceList() {
      const api = 'supplier.getStates';
      const options = {};
      switch (this.Category) {
        case 'international':
          options.id = this.addressData.country_id;
          break;
        case 'china':
          options.id = '44';
        default:
          break;
      }
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            that.save_list = res.data;
            that.addressData.save_list = res.data;
            that.changeTab('save');
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    getCityList() {
      const api = 'supplier.getCity';
      // 上级id：没有省份的国家，其城市的上级id就是国家id；否则为省份id
      const upperType =
        this.addressData.save_id && this.addressData.save_id != '0'
          ? ''
          : 'country';
      const upperId =
        upperType != 'country'
          ? this.addressData.save_id
          : this.addressData.country_id;
      const options = {
        id: upperId,
        type: upperType,
      };
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            that.city_list = res.data;
            that.addressData.city_list = res.data;
            if (that.city_list.length > 0) {
              that.changeTab('city');
            } else {
              that.closeDropdown();
            }
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        continents: '',
        continents_id: '',
        country: '',
        country_id: '',
        continents_list: [],
        // country_list: [],
        save_list: [], //省列表
        city_list: [],
        save: '',
        save_id: '',
        city: '',
        city_id: '',
      };
      this.initialActive = '';
      this.searchTarget = '';
      this.tabName = '';
      // this.country_list = [];
      this.continents_list = [];
      this.save_list = [];
      this.city_list = [];
    },
    // 数据回显
    echo() {
      this.addressData.country = this.country || '';
      this.addressData.country_id = this.country_id || '';
      this.addressData.continents = this.continents || '';
      this.addressData.continents_id = this.continents_id || '';
    },
    reInfo() {
      this.addressData = {
        continents: '',
        continents_id: '',
        country: '',
        country_id: '',
        continents_list: [],
        country_list: [],
        save_list: [], //省列表
        city_list: [],
        save: '',
        save_id: '',
        city: '',
        city_id: '',
      };
      switch (this.Category) {
        case 'international':
          this.changeTab('country');
          break;
        case 'china':
          this.city_list = [];
          this.changeTab('save');
        default:
          break;
      }
    },
    initialize() {
      switch (this.Category) {
        case 'international':
          this.getCountryList();
          this.changeTab('country');
          break;
        case 'china':
          this.getProvinceList();
          this.changeTab('save');
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.resetData();
  },
};
</script>
<style lang="scss" scoped>
$tab-width: 100px;
$select-height: 40px;
$highlight-color: #ff7802;

.country-selector {
  position: relative;
  // width: $tab-width * 3;
  width: 200px;
  height: $select-height;

  * {
    border: 0px;
  }
  ul {
    padding-left: 1em; // 选项名称距离左边的距离
  }

  li {
    list-style-type: none;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  li.active,
  span.active {
    color: $highlight-color;
  }
  li:hover {
    color: $highlight-color;
  }
  ::v-deep .el-input.el-input--suffix {
    width: 100%;
  }

  // 解决focus输入框边框变蓝问题
  ::v-deep .el-select .el-input.is-focus .el-input__inner,
  ::v-deep .el-select .el-input__inner:focus {
    border-color: $highlight-color;
  }
  // 隐藏el原本的下拉菜单
  ::v-deep .el-select-dropdown {
    visibility: hidden;
  }
  ::v-deep .popper__arrow {
    visibility: hidden;
  }

  .no-search-input {
    max-height: 314px;
    overflow: auto;
    padding-bottom: 16px;
  }
  ::v-deep .el-input {
    // width: 280px;
    height: 40px;
    margin-bottom: 10px; // 搜索框离下面的选项文字的距离
  }

  ::v-deep .el-input__prefix {
    display: inline-block;
  }

  ::v-deep .search-icon {
    display: inline-block;
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-tabs .el-input__inner {
    width: 280px;
    margin-left: -5px;
    border-radius: 0px 0px 0px 0px;
    background-color: #f7f7f7;

    font-size: 12px;
    color: #808080;
  }
  // 处理tabs位置问题
  ::v-deep .el-tabs {
    visibility: visible;
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 2; // 必须比原本的下拉菜单大

    width: $tab-width * 3;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    * {
      font-size: 14px;
      color: #666;
    }
  }
  // // 固定下拉菜单整体高度为 350px
  // ::v-deep .el-tabs .el-tabs--top .el-tabs--border-card {
  //   height: 350px;
  // }
  ::v-deep .el-tabs__header .is-top {
    background-color: #f2f2f2;
  }

  ::v-deep .el-tabs--border-card > .el-tabs__header {
    border: 0px;
  }
  ::v-deep .el-tabs--border-card > .el-tabs__content {
    padding-bottom: 10px;
  }
  // 解决高亮标签页两侧有1px缝隙
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-left-color: transparent;
    border-right-color: transparent;
  }
  // 调整标签页宽度，宽度相同且文本居中
  ::v-deep .el-tabs__item {
    width: $tab-width;
    text-align: center;
  }
  // 解决“城市”标签页头右侧有1px缝隙
  ::v-deep .el-tabs__item:nth-last-of-type(1) {
    width: $tab-width + 1;
  }
  // 隐藏标签页前后的滑动提示箭头
  ::v-deep .el-tabs__nav-prev,
  .el-tabs__nav-next {
    display: none;
  }
  ::v-deep
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item
    + .el-tabs__item {
    margin-left: 0px;
  }

  ::v-deep .el-tabs__content {
    padding-right: 0px; // 解决滚动条不贴右边问题
    padding-top: 10px;
    // padding: 0px;
    height: 310px;
  }
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #808080;
  }
  // 解决点击和悬浮标签页，标签页文字蓝色高亮问题
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: #4d4d4d;
  }
  ::v-deep .el-tabs__item.is-top {
    background-color: #f2f2f2;
    color: #808080;
  }
  ::v-deep .el-tabs__item.is-active {
    background-color: #ffffff;
    color: #4d4d4d;
  }

  .country-list-wrapper {
    height: 240px;
    overflow: auto;
  }
  .country-groups {
    & > span {
      color: #666;
      padding-right: 5px;
      margin-top: 5px;
    }
    & > span.active {
      color: $highlight-color;
    }
    // 国家按拼音分组，分组的水平分割线
    .country-group-divider {
      border: 1px solid #e6e6e6;
      display: inline-block;
      width: $tab-width * 3 - 50px;

      margin-bottom: 0.33em;
    }
  }
}
::-webkit-scrollbar-track {
  background-color: #ffffff !important;
}
</style>

<template>
  <div class="contact-someone" :style="{ alignItems: align }">
    <span class="pointer" @click="handleClick" :style="{ color: labelColor }">{{
      label
    }}</span>
    <img
      v-if="showIcon"
      id="contact-icon"
      @click="handleClick"
      width="16px"
      class="pointer margin-left"
      src="@/assets/images/chaticon.svg"
    />
  </div>
</template>

<script>
import { CS_ROUTE_NAME } from '@/utils/chat_tools.js';
export default {
  name: 'XmContact',
  props: {
    label: {
      type: String,
      default: '联系客服',
    },
    labelColor: {
      type: String,
      default: '#666666',
    },
    // 联系人的账号id
    // 默认为客服的id
    contactId: {
      type: String,
      default: CS_ROUTE_NAME,
    },
    showIcon: {
      // 是否显示聊天icon
      type: Boolean,
      default: true,
    },
    align: {
      // flex对齐方式
      type: String,
      default: 'center',
    },
  },
  methods: {
    // 点击“联系客服/分销商/供应商”，跳转相应聊天页面
    handleClick() {
      let routeData = this.$router.resolve({
        path: '/chat/' + this.contactId,
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-someone {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  * {
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;

    margin: 0;
    padding: 0;
  }
  img#contact-icon {
    display: inline-block;
  }

  span {
    display: inline-block;
  }

  .pointer {
    cursor: pointer;
  }

  .margin-left {
    margin-left: 4px;
  }
}
</style>

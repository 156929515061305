/**
 * 数据处理的工具函数
 * @author hukeyi
 * @date 2023-09-08
 */

/**
 * 是否是非空值
 * #todo 为兼容旧项目代码保留，之后要么删掉要么重写
 * @author hukeyi
 * @date 2023-09-07
 * @param { * } val 判断的目标
 * @returns { Boolean } true：不是空值；false：空值
 */
function hasValue(val) {
  return (
    val !== undefined &&
    val !== null &&
    val !== '' &&
    val !== 'undefined' &&
    val !== 'null'
  );
}

/**
 * 获取函数传入的第一个非空形参
 * #todo 为兼容旧项目代码保留，之后要么删掉要么重写
 * @author hukeyi
 * @date 2023-09-07
 * @returns { * } 第一个非空形参。如果都空，则返回函数最后一个参数
 */
function getFirstAvailableValue() {
  let params = [...arguments];
  for (let i = 0; i < params.length; i++) {
    if (hasValue(params[i])) {
      return params[i];
    }
  }
  return params[params.length - 1];
}

export { hasValue, getFirstAvailableValue };

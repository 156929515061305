import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import chat from './chat'; // 聊天
import goods from './goods'; // 商品
import category from './category'; // 类目数据
import pageFilter from './page_filter_criteria'; //页面条件筛选的记录

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    chat,
    goods,
    category,
    pageFilter,
  },
  state: {
    time: '',
    euId: sessionStorage.getItem('euId') || '', //欧盟详情id
    shopIndex: false,
  },
  mutations: {
    setEuId: (state, euId) => {
      state.euId = euId;
      sessionStorage.setItem('euId', euId);
    },
    setIndexShow(state, data) {
      state.shopIndex = data;
    },
  },
  actions: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['chat', 'pageFilter'],
    }),
    createPersistedState({
      storage: window.localStorage,
      paths: ['category', 'goods'],
    }),
  ],
});
export default store;

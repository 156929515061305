/**
 * 用户模块
 * - 注册
 * - 登录
 * - 账号及认证
 */

const Login = () => import('@/pages/login/loginHome.vue');
const LoginLocal = () => import('@/pages/login/local/login.vue');
const Register = () => import('@/pages/login/registerHome.vue');
const Redirect = () => import('@/pages/login/redirect.vue');

const userRoutes = [
  {
    path: '/redirect',
    name: 'redirect',
    meta: {
      title: '页面跳转中...',
      path: '/redirect',
      needsToken: false,
    },
    component: Redirect,
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '供应商登录',
      path: '/login',
      needsToken: false,
    },
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '供应商注册',
      path: '/register',
      needsToken: false,
    },
    component: Register,
  },
  // 账号及认证 - 账号设置
  {
    path: '/accountSetting',
    name: 'accountSetting',
    meta: {
      title: '账户设置',
      navIndex: 'account',
      path: '/accountSetting',
      needsToken: true,
    },
    component: () => import('@/pages/user/user-center/accountSetting'),
  },
  {
    path: '/account/verify/information/:type?',
    name: 'VerifyInfo',
    meta: {
      title: '实名信息',
      navIndex: 'account',
      path: '/account/verify/information',
      needsToken: true,
    },
    component: () =>
      import('@/pages/user/user-center/accountSetting/verify-info'),
  },
  {
    path: '/systemAccount',
    name: 'systemAccount',
    meta: {
      title: '账号管理',
      navIndex: 'account',
      path: '/systemAccount',
      needsToken: true,
    },
    component: () =>
      import('@/pages/user/user-center/accountSetting/systemAccount'),
  },
  {
    path: '/editphone',
    name: 'editPhone',
    meta: {
      title: '修改手机号',
      navIndex: 'account',
      path: '/editphone',
      needsToken: true,
    },
    component: () =>
      import('@/pages/user/user-center/accountSetting/editPhone'),
  },
  {
    path: '/editLoginPw',
    name: 'editLoginPw',
    meta: {
      title: '修改登录密码',
      navIndex: 'account',
      path: '/editLoginPw',
      needsToken: true,
    },
    component: () =>
      import('@/pages/user/user-center/accountSetting/editLoginPw'),
  },
  {
    path: '/setpaypwd',
    name: 'SetPayPwd',
    meta: {
      title: '设置支付密码',
      navIndex: 'account',
      path: '/accountSetting',
      needsToken: true,
    },
    component: () =>
      import(
        '@/pages/user/user-center/accountSetting/set-pay-pwd/SetPayPassword'
      ),
  },
  {
    path: '/authDetail',
    name: 'authDetail',
    meta: {
      title: '权限说明',
      navIndex: 'account',
      path: '/authDetail',
      needsToken: true,
    },
    component: () => import('@/pages/user/user-center/authDetail'),
  },
  // 账号及认证 - 其他
  {
    path: '/operLog',
    name: 'operLog',
    meta: {
      title: '操作日志',
      navIndex: 'account',
      path: '/operLog',
      needsToken: true,
    },
    component: () => import('@/pages/user/user-center/operLog/index'),
  },
  {
    path: '/shopnotice',
    name: 'ShopNotice',
    meta: {
      title: '店铺公告',
      navIndex: 'account',
      path: '/shopnotice',
      needsToken: true,
    },
    component: () => import('@/pages/user/user-center/shop-notice/index.vue'),
  },
  {
    path: '/assetManagement',
    name: 'assetManagement',
    meta: {
      title: '素材管理',
      navIndex: 'account',
      path: '/assetManagement',
      needsToken: true,
    },
    component: () => import('@/pages/user/user-center/assetManagement/index'),
  },
  {
    path: '/attribute',
    name: 'attribute',
    meta: {
      title: '商品属性配置',
      navIndex: 'account',
      path: '/attribute',
      needsToken: true,
    },
    component: () => import('@/pages/user/user-center/property/attribute'),
  },
  {
    path: '/configure',
    name: 'configure',
    meta: {
      title: '修改资料',
      navIndex: 'account',
      path: '/configure',
      needsToken: true,
    },
    component: () =>
      import('@/pages/user/user-center/accountSetting/configure'),
  },
  {
    path: '/eprManageList',
    name: 'eprManageList',
    meta: { title: 'EPR管理', navIndex: 'account', needsToken: true },
    component: () => import('@/pages/user/user-center/epr/eprManage'),
    children: [
      {
        path: '',
        name: 'eprManageList',
        meta: { title: 'EPR管理', navIndex: 'account', needsToken: true },
        component: () => import('@/pages/user/user-center/epr/eprManageList'),
      },
      {
        path: 'info/:id',
        name: 'eprManageDetail',
        meta: { title: '查看详情', navIndex: 'account', needsToken: true },
        component: () => import('@/pages/user/user-center/epr/eprManageDetail'),
      },
    ],
  },
  {
    path: '/euPersonLiableList',
    name: 'euPersonLiableList',
    meta: { title: '欧盟责任人', navIndex: 'account', needsToken: true },
    component: () =>
      import('@/pages/user/user-center/euagent/euPersonLiableList'),
    children: [
      {
        path: 'info',
        name: 'euPersonInfo',
        meta: {
          title: '欧盟责任人详情',
          navIndex: 'account',
          needsToken: true,
        },
        component: () =>
          import('@/pages/user/user-center/euagent/components/euPersonInfo'),
      },
    ],
  },
  {
    path: '/euPersonLiableAdd',
    name: 'euPersonLiableAdd',
    meta: {
      title: '欧盟责任人添加',
      leftNavIndex: '/euPersonLiableList',
      navIndex: 'account',
      needsToken: true,
    },
    component: () =>
      import('@/pages/user/user-center/euagent/euPersonLiableAdd'),
  },
  {
    path: '/addressList',
    name: 'addressList',
    meta: { title: '地址管理', navIndex: 'account', needsToken: true },
    component: () => import('@/pages/user/user-center/address/addressList'),
  },
  {
    path: '/addressAdd',
    name: 'addressAdd',
    meta: {
      title: '地址添加',
      leftNavIndex: '/addressList',
      navIndex: 'account',
      needsToken: true,
    },
    component: () => import('@/pages/user/user-center/address/addressAdd'),
  },
  {
    path: '/brandName',
    name: 'brandName',
    meta: {
      title: '品牌商标',
      leftNavIndex: '/brandName',
      navIndex: 'account',
      needsToken: true,
    },
    component: () => import('@/pages/user/user-center/brandName/brandName'),
  },
  {
    path: '/checkOut',
    name: 'checkOutd',
    meta: {
      title: '退店',
      leftNavIndex: '/checkOut',
      navIndex: 'account',
      needsToken: true,
    },
    component: () => import('@/pages/user/user-center/checkOut/checkOut'),
  },
];

// if (process.env.NODE_ENV === 'development') {
// 仅当开发环境，才注册本地测试登录路由
// 暂时打开
const localLoginRoute = {
  path: '/templogin',
  name: 'loginLocal',
  meta: {
    title: '细目供应商登录',
    path: '/templogin',
    needsToken: false,
  },
  component: LoginLocal,
};
userRoutes.unshift(localLoginRoute);
// }

export default userRoutes;

import { generateRecallSysMsg, filterNimMsg } from '@/utils/chat_tools.js';
import store from '@/store/index';
const NIM = window?.NIM;

function isLocalEnv() {
  return (
    process.env.VUE_APP_ENV === 'local' ||
    process.env.VUE_APP_ENV === 'dev-188' ||
    process.env.VUE_APP_ENV === 'check-191' ||
    process.env.VUE_APP_ENV === 'check-193' ||
    process.env.VUE_APP_ENV === 'release-190'
  );
}

export class NimChat {
  constructor(myAccount, token) {
    this.nim = null;

    this.scene = 'p2p';
    if (!myAccount || !token) {
      // 没开通账号的客服拒绝进入聊天界面
      this.isValid = false;
      return;
    }
    this.isValid = true;
    this.token = token;
    this.account = myAccount.toLowerCase();
  }
  // 初始化NIM实例对象
  init(options = {}) {
    if (!this.token || !this.account) {
      this.isValid = false;
      return false;
    }
    let nimOptions = Object.assign(
      {
        debug: false, // 关闭 debug
        appKey: process.env.VUE_APP_NIM_APP_KEY, // 自己申请的appKey
        account: this.account?.toLowerCase(),
        token: this.token,
        db: true, // 开启数据库
        quickReconnect: true, // 开启快速重连。根据浏览器的 online, offline 事件嗅探网络状态，并在网络恢复时快速重连
        reconnectionAttempts: 10, // 重连尝试最大次数
        syncSessionUnread: true, // 将未读数同步到服务器
        syncStickTopSessions: true, // 同步云端置顶会话
        onconnect: this.onConnect,
        onwillreconnect: this.onWillReconnect,
        ondisconnect: this.onDisconnect,
        onsyncdone: this.onSyncDone,
        onerror: this.onError,
        onsessions: this.onReceivingSessionList,
        onupdatesessions: this.onUpdateSessions,
        onmsg: this.onReceivingMessage,
        onsysmsg: this.onSystemMsg,
      },
      options
    );
    this.nim = NIM?.getInstance(nimOptions);
    return true;
  }
  destroy() {
    return new Promise((resolve, reject) => {
      if (!this.nim) return resolve();
      this.nim?.logout();
      this.nim?.destroy({
        done: function (err) {
          if (err) {
            console.error('销毁 nim 实例失败', err);
            reject(err);
          } else {
            if (isLocalEnv()) {
              console.log('销毁 nim 实例');
            }
            resolve();
          }
        },
      });
    });
  }
  /**
   * 事件监听器
   */
  onConnect(res) {
    if (isLocalEnv()) {
      console.log('连接成功', res);
    }
  }
  onSyncDone() {
    if (isLocalEnv()) {
      console.log('初始化同步成功');
    }
  }
  onWillReconnect(res) {
    if (isLocalEnv()) {
      console.log('正在重连', res);
    }
  }
  onDisconnect(error) {
    if (isLocalEnv()) {
      console.log('连接断开', error);
    }
  }
  onError(error) {
    if (isLocalEnv()) {
      console.log('系统错误', error);
    }
  }
  onReceivingMessage(msg) {
    if (isLocalEnv()) {
      console.log('收到聊天消息', msg);
    }
    if (msg?.from && msg.from !== this.account) {
      store.commit('addUnread');
    }
  }
  onReceivingSessionList(res) {
    if (isLocalEnv()) {
      console.log('收到会话列表', res);
    }
    let totalUnread = 0;
    for (let session of res) {
      totalUnread += session?.unread || 0;
    }
    store.commit('updateUnread', totalUnread);
  }
  onUpdateSessions(res) {
    if (isLocalEnv()) {
      console.log('会话列表更新', res);
    }
  }
  onSystemMsg(res) {
    if (res.type === 'deleteMsg') {
      console.log('有人撤回了一条消息', res);
    }
  }
  /**
   * 发送聊天信息
   */
  async sendMessage(msg) {
    return new Promise(async (resolve, reject) => {
      try {
        let res;
        switch (msg.type) {
          case 'text':
            res = await this.sendTextMessage(msg);
            break;

          case 'image':
            res = await this.sendLinkMessage(msg);
            break;

          case 'link':
            res = await this.sendLinkMessage(msg);
            break;

          case 'quote':
            res = await this.sendQuoteMessage(msg);
            break;
          // #testme-recall 撤回消息
          case 'recall':
            res = await this.sendRecallTipMessage(msg);
            break;

          default:
            res = await this.sendTextMessage(msg);
            break;
        }
        return resolve(res);
      } catch (err) {
        console.error('[发送消息失败]', err);
        msg.time = Date.now();
        msg.content = JSON.stringify(msg.content);
        return reject(msg);
      }
    });
  }
  sendTextMessage(msg) {
    return new Promise((resolve, reject) => {
      this.nim.sendText({
        scene: 'p2p',
        to: msg.to.toLowerCase(),
        text: msg.content,
        done: function (err, msg) {
          if (err) {
            console.error(err);
            return reject(msg);
          } else {
            if (isLocalEnv()) {
              console.log('[发送文本成功]', msg);
            }
            return resolve(msg);
          }
        },
      });
    });
  }
  sendImgMessage(msg, domId) {
    return new Promise((resolve, reject) => {
      this.nim.sendFile({
        scene: 'p2p',
        to: msg.to.toLowerCase(),
        type: 'image',
        fileInput: domId,
        done: function (err, msg) {
          if (err) {
            console.error(err);
            return reject(msg);
          } else {
            if (isLocalEnv()) {
              console.log('[发送图片成功]', msg);
            }
            return resolve(msg);
          }
        },
      });
      return res;
    });
  }
  sendLinkMessage(msg) {
    return new Promise((resolve, reject) => {
      this.nim.sendCustomMsg({
        scene: 'p2p',
        to: msg.to.toLowerCase(),
        //接收方通过onMsg接收消息
        //然后如果msg.type === 'custom'，接收方通过读取msg.content，然后调用业务代码
        content: JSON.stringify({
          type: msg.type,
          content: msg.content,
          price: msg.price,
          dataType: msg.dataType,
        }),
        done: function (err, msg) {
          if (err) {
            console.error(err);
            return reject(msg);
          } else {
            if (isLocalEnv()) {
              console.log('[发送链接成功]', msg);
            }
            return resolve(msg);
          }
        },
      });
    });
  }
  // 发送引用消息
  sendQuoteMessage(msg) {
    return new Promise((resolve, reject) => {
      this.nim.sendCustomMsg({
        scene: 'p2p',
        to: msg.to.toLowerCase(),
        content: JSON.stringify({
          type: msg.type,
          quoteMsg: filterNimMsg(msg.quoteMsg),
          content: msg.content,
        }),
        done: function (err, msg) {
          if (err) {
            console.error(err);
            return reject(msg);
          } else {
            if (isLocalEnv()) {
              console.log('[发送引用消息成功]', msg);
            }
            return resolve(msg);
          }
        },
      });
    });
  }
  // 发送撤回提示消息
  sendRecallTipMessage(msg) {
    return new Promise((resolve, reject) => {
      // 特殊处理会话 id，避免对端利用 sessionId 找不到会话历史记录
      // #memo 为什么要 filterNimMsg？因为 nim 对 custom 的长度有限制，
      // custom 的 json 过长会导致发送消息失败
      const myOriginalMsg = msg.originalMsg;
      const sendOriginalMsg = filterNimMsg(msg.originalMsg, false);
      sendOriginalMsg.sessionId = `${this.scene}-${this.account}`;

      this.nim.sendTipMsg({
        scene: 'p2p',
        to: msg.to.toLowerCase(),
        tip: '撤回了一条消息',
        custom: JSON.stringify({
          type: 'recall',
          originalMsg: sendOriginalMsg,
        }),
        isUnreadable: false, // 撤回提示消息不计入未读数
        done: function (err, msg) {
          if (err) {
            console.error(err);
            return reject(err);
          } else {
            if (isLocalEnv()) {
              console.log('[发送撤回消息成功]', msg);
            }
            // 这里要把 sessionId 给还原，否则本端找不到会话历史记录
            msg.custom = JSON.stringify({
              type: 'recall',
              originalMsg: myOriginalMsg,
            });
            return resolve(msg);
          }
        },
      });
    });
  }
  // 撤销聊天信息
  recallMessage(msg) {
    let that = this;
    return new Promise((resolve, reject) => {
      this.nim.recallMsg({
        msg: msg,
        done: function (err, data) {
          if (err) {
            console.error('撤回失败', data);
            return reject(err);
          } else {
            const hintMsg = generateRecallSysMsg(data?.msg, that.account);
            return resolve(hintMsg);
          }
        },
      });
    });
  }
  // 发送消息已读回执
  sendMsgReadReceipt(msg) {
    return new Promise((resolve, reject) => {
      const params = {
        msg: msg,
        done: function (err, data) {
          if (err) {
            console.error('[NimChat: 发送消息已读回执失败]', err);
            reject(err);
          } else {
            if (isLocalEnv()) {
              console.log('[NimChat: 发送消息已读回执成功]', data);
            }
            resolve(data);
          }
        },
      };

      this.nim.sendMsgReceipt(params);
    });
  }
  // 获取最近的 N 个会话列表信息
  getLatestNLocalSessions(total = 10, lastSessionId = '') {
    const limit = 10;
    const pageSize = total > limit ? limit : total;
    let that = this;
    return new Promise((resolve, reject) => {
      const params = {
        limit: pageSize,
        reverse: true,
        done: async function (err, data) {
          if (err) {
            reject(err);
          } else {
            const retSessions = data.sessions;
            lastSessionId =
              retSessions.length > 0
                ? retSessions[retSessions.length - 1].id
                : undefined;

            if (retSessions.length < pageSize) {
              // 已经查询完毕
              resolve({
                sessions: retSessions,
                lastSessionId,
              });
            } else if (total > pageSize) {
              // 返回 limit 个会话对象。继续递归查询
              const nextPageResult = await that.getLatestNLocalSessions(
                total - pageSize,
                lastSessionId
              );
              resolve({
                sessions: retSessions.concat(nextPageResult.sessions),
                lastSessionId: nextPageResult.lastSessionId,
              });
            } else {
              // 查询结束
              resolve({
                sessions: retSessions,
                lastSessionId,
              });
            }
          }
        },
      };
      if (lastSessionId) {
        params.lastSessionId = lastSessionId;
      }
      this.nim.getLocalSessions(params);
    });
  }
  // 更新本地会话信息
  updateLocalSession(id) {
    return new Promise((resolve, reject) => {
      const params = {
        id: id,
        done: function (err, data) {
          if (err) {
            console.error('[NimChat: 更新本地数据库会话 failed]', err);
            reject(err);
          } else {
            if (isLocalEnv()) {
              console.log('[NimChat: 更新本地数据库会话 success]', data);
            }
            resolve(data);
          }
        },
      };

      this.nim.updateLocalSession(params);
    });
  }
  // 获取云端聊天历史记录
  getCloudHistoryMsgsById(to, lastMsg, limit = 30) {
    to = to.toLowerCase();
    const scene = this.scene;
    return new Promise((resolve, reject) => {
      // 获取历史消息
      const sessionId = `${scene}-${to}`;
      // const lastMsg = msgArr[msgArr.length - 1];
      const params = {
        // 返回的结果按时间降序排列
        asc: true,
        scene: scene,
        to: to,
        beginTime: 0,
        limit: limit,
        endTime: lastMsg?.time || 0,
        // 从endTime开始往前查找
        reverse: false,
        done: function (err, data) {
          //结束调用，设置fetching = false
          if (err) {
            console.error('[getCloudHistoryMsgsById]', err);
            return reject(err);
          } else if (data?.msgs?.length > 0) {
            return resolve(data.msgs);
          } else {
            if (isLocalEnv()) {
              console.log('[getCloudHistoryMsgsById 已拉取会话所有消息]');
              console.log(`[当前会话: ${sessionId} 的历史消息为空]`);
            }
            return resolve([]);
          }
        },
      };

      if (lastMsg) {
        // 传入的最后一条消息的id
        params.lastMsgId = lastMsg.idServer;
      }

      this.nim.getHistoryMsgs(params);
    });
  }
  // 获取本地数据库中的聊天历史记录
  getLocalHistoryMsgsById(to, lastMsg, limit = 30) {
    const scene = this.scene;
    return new Promise((resolve, reject) => {
      to = to.toLowerCase();
      const sessionId = `${scene}-${to}`;

      const params = {
        // 返回的结果按时间降序排列
        desc: false,
        limit: limit,
        sessionId: sessionId,
        // start: 0,
        end: lastMsg ? lastMsg.time : 0,
        done: function (err, data) {
          //结束调用，设置fetching = false
          if (err) {
            console.error('[getLocalHistoryMsgsById]', err);
            return reject(err);
          } else if (data?.msgs?.length > 0) {
            if (isLocalEnv()) {
              console.log(
                '[getLocalHistoryMsgsById 已拉取会话所有本地数据库消息]'
              );
            }
            return resolve(data.msgs);
          } else {
            if (isLocalEnv()) {
              console.log(
                '[getLocalHistoryMsgsById 已拉取会话所有本地数据库消息]'
              );
              console.log(`[当前会话: ${sessionId} 的本地数据库历史消息为空]`);
            }
            return resolve([]);
          }
        },
      };

      if (lastMsg) {
        // 传入的最后一条消息的id
        params.lastMsgId = lastMsg.idServer;
      }

      this.nim.getLocalMsgs(params);
    });
  }
  // 更新本地数据库的聊天列表
  updateLocalMsgs(msgs) {
    if (!msgs || msgs?.length === 0) return;
    return new Promise((resolve, reject) => {
      const params = {
        msgs: msgs,
        done: function (err) {
          if (err) {
            console.error('[NimChat: 更新本地数据库聊天消息列表 failed]', err);
            reject(err);
          } else {
            if (isLocalEnv()) {
              console.log('[NimChat: 更新本地数据库聊天消息列表 success]');
            }
            resolve();
          }
        },
      };

      this.nim.saveMsgsToLocal(params);
    });
  }
  // 根据 id 获取用户信息
  getUserInfoById(id) {
    if (!id) return;
    id = id.toLowerCase();
    return new Promise((resolve, reject) => {
      const params = {
        account: id,
        sync: true, // 从服务器获取用户信息
        done: function (err, data) {
          if (err) {
            console.error('[getUserInfoById]', err);
            return reject(err);
          } else {
            return resolve(data);
          }
        },
      };

      this.nim.getUser(params);
    });
  }
  // 获取一堆用户的昵称和头像信息
  getUsersInfoByIds(ids) {
    if (!ids || ids.length == 0) {
      return;
    }
    ids = ids.map(x => x.toLowerCase());
    return new Promise((resolve, reject) => {
      const params = {
        accounts: ids,
        sync: true, // 从服务器获取用户信息
        done: function (err, data) {
          if (err) {
            console.error('[getUsersInfoByIds]', err);
            return reject(err);
          } else {
            return resolve(data);
          }
        },
      };

      this.nim.getUsers(params);
    });
  }
  // 向本地数据库会话列表中插入新会话
  insertLocalSession(to) {
    if (!to) return;
    to = to.toLowerCase();
    return new Promise((resolve, reject) => {
      const scene = this.scene;
      const params = {
        scene: scene,
        to: to,
        done: function (err, data) {
          if (err) {
            console.error('[NimChat insertLocalSession failed]', err);
            resolve(err);
          } else if (data) {
            if (isLocalEnv()) {
              console.log('[NimChat insertLocalSession success]', data);
            }
            resolve(data);
          } else {
            resolve();
          }
        },
      };

      this.nim.insertLocalSession(params);
    });
  }
  // 设置当前会话
  setCurrSession(to) {
    to = to.toLowerCase();
    return new Promise((resolve, reject) => {
      const sessionId = `${this.scene}-${to}`;

      this.nim.setCurrSession(sessionId);
      resolve();
    });
  }
  // 取消设置当前会话
  resetCurrSession(to) {
    to = to.toLowerCase();
    return new Promise((resolve, reject) => {
      const sessionId = `${this.scene}-${to}`;

      this.nim.resetCurrSession(sessionId);
      resolve();
    });
  }
  // 增加置顶会话
  setStickTopSession(to) {
    const sessionId = `${this.scene}-${to.toLowerCase()}`;
    return new Promise((resolve, reject) => {
      const params = {
        id: sessionId,
        done: function (err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        },
      };

      this.nim.addStickTopSession(params);
    });
  }
}

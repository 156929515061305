import request from '@/utils/request.js';

// 短信发送
export function sendSmsCode(data) {
  return request({
    url: '/Sms/sendSmsCode',
    method: 'get',
    params: data,
  });
}

// 手机号验证
export function supplierCheckPhone(data) {
  return request({
    url: '/supplier/supplierCheckInfo',
    method: 'get',
    params: data,
  });
}

// 获取账户基本信息
export function getInfoIndex(data) {
  return request({
    apiModule: 'user',
    url: '/supplierInfo/supplierInformation',
    method: 'post',
    data: data,
  });
}

// 设置支付密码
export function setPayPassword(data) {
  return request({
    url: '/paymentManage/setPassword',
    method: 'post',
    data: data,
  });
}

// 判断有没有支付密码
export function isSetPassword(data) {
  return request({
    url: '/paymentManage/isSetPassword',
    method: 'get',
    params: data,
  });
}

/**
 * 账号及认证 编辑基本资料相关
 */
// 查看个人基本资料
export function getApplyInfo(data) {
  return request({
    apiModule: 'user',
    url: '/supplierInfo/supplierBasicInfo',
    method: 'post',
    data: data,
  });
}

// 编辑个人基本资料
export function putApplyInfo(data) {
  return request({
    url: '/supplier/putApplyInfo',
    method: 'post',
    data: data,
  });
}

/**
 * 账号及认证 实名认证相关
 */
// 判断供应商法人是否已经注册过公司
export function judgeRegisterInfo(data) {
  return request({
    url: '/supplier/registerInfo',
    method: 'get',
    params: data,
  });
}

// 编辑实名认证资料
export function editApplyInfo(data) {
  return request({
    url: '/supplier/editApplyInfo',
    method: 'post',
    data: data,
  });
}

// 实名认证审核记录
export function applyRecord(data) {
  return request({
    apiModule: 'user',
    url: '/supplierInfo/supplierExamineRecord',
    method: 'post',
    data: data,
  });
}

/**
 * 账号及认证 修改绑定手机号相关
 */
// 修改手机号
export function changePhone(data) {
  return request({
    apiModule: 'user',
    url: '/supplierInfo/changeSupplierPhone',
    method: 'post',
    data: data,
  });
}

/**
 * 账号及认证 修改登录密码相关
 */
// step1: 修改登录密码验证手机号
export function editPwdCheckPhone(data) {
  return request({
    apiModule: 'user',
    url: '/supplierInfo/supplierCheckSmsCode',
    method: 'post',
    data: data,
  });
}

// step2: 修改登录密码
export function rechargePassword(data) {
  return request({
    apiModule: 'user',
    url: '/supplierInfo/setSupplierNewPassword',
    method: 'post',
    data: data,
  });
}

// 获取店铺违禁词
export function getShopNameForbiddenWords(data) {
  return request({
    url: '/supplier/getShopNameForbiddenWords',
    method: 'post',
    data: data,
  });
}

<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>商品</div>
        <span @click="goShop">查看全部</span>
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>已上架(当前)</div>
          <span :class="[{ 'have-num': detail.onsale != 0 }]">{{
            detail.onsale
          }}</span>
        </div>
        <div class="label-list">
          <div>
            审核中
            <el-popover
              placement="bottom-start"
              width="168"
              trigger="hover"
              content="当前处于审核中的商品数量"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.ischeck != 0 }]">{{
            detail.ischeck
          }}</span>
        </div>
        <div class="label-list">
          <div>
            被收藏数(当前)
            <el-popover
              placement="bottom-start"
              width="144"
              trigger="hover"
              content="被分销商收藏的商品数"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.fav != 0 }]">{{
            detail.fav
          }}</span>
        </div>
        <div class="label-list">
          <div>有销量商品数(近30天)</div>
          <span :class="[{ 'have-num': detail.sale != 0 }]">{{
            detail.sale
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultTo } from 'lodash';

export default {
  name: 'GoodsDetails',
  props: {
    // 商品信息数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // detail: {},
    };
  },
  methods: {
    goShop() {
      this.$router.push('/goodGroup');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

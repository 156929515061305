<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>仓库数据</div>
        <span @click="goUrl">查看全部</span>
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>主仓数量</div>
          <span :class="[{ 'have-num': detail.masterWarehouse != 0 }]">{{
            detail.masterWarehouse
          }}</span>
        </div>
        <div class="label-list">
          <div>合作仓数量</div>
          <span :class="[{ 'have-num': detail.cooperation != 0 }]">{{
            detail.cooperation
          }}</span>
        </div>
        <div class="label-list">
          <div>平台仓数量</div>
          <span :class="[{ 'have-num': detail.platform != 0 }]">{{
            detail.platform
          }}</span>
        </div>
        <div class="label-list">
          <div>待审核平台仓</div>
          <span :class="[{ 'have-num': detail.waitingForReview != 0 }]">{{
            detail.waitingForReview
          }}</span>
        </div>
        <!-- <div class="label-list">
          <div>自建仓数</div>
          <span :class="[{ 'have-num': detail.my != 0 }]">{{ detail.my }}</span>
        </div> -->
        <!-- <div class="label-list">
          <div>
            待审核
            <el-popover
              placement="bottom-start"
              width="156"
              trigger="hover"
              content="等待平台审核的仓库数量"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.wait != 0 }]">{{
            detail.wait
          }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defaultTo } from 'lodash';

export default {
  name: 'WarehouseData',
  props: {
    // 仓库数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // detail: {},
    };
  },
  methods: {
    goUrl() {
      this.$router.push('/goodsStoreHouse');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

<template>
  <!-- 带input的下拉选择器组件 -->
  <div class="xm-select-option">
    <div v-if="isSelect">
      <el-select
        v-model="selectValue"
        :placeholder="placeholder"
        :popper-append-to-body="false"
        @change="onChange"
        @clear="clearVal"
        :style="{ width: width + 'px' }"
        class="select-box"
      >
        <el-option
          v-for="(item, index) in optionData"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="el-date-picker-box" :style="{ width: inputWidth + 'px' }">
      <el-date-picker
        v-model="inputTime"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :value-format="isDefaultTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
        :default-time="isDefaultTime ? ['00:00:00', '23:59:59'] : false"
        :class="isSelect ? 'topsearch_picker' : ''"
        :style="{ width: inputWidth + 'px' }"
        @change="onSearch($event)"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: 'XmSelectDatePicker',
  data() {
    return {
      selectValue: this.defaultVal, // 选项的val
      // inputTime: [], // 时间范围搜索
    };
  },
  props: {
    value: {},
    width: {
      // 选择器的宽度
      type: Number,
      default: 112,
    },
    inputWidth: {
      // input的宽度
      type: Number,
      default: 157,
    },
    optionData: {
      // 选项数据
      type: Array,
      default: () => [],
    },
    defaultVal: {
      // 下拉框默认展示的选项值
      type: String,
      default: '',
    },
    // placeholder: {
    //   // 下拉选择的placeholder
    //   type: String,
    //   default: '请选择',
    // },
    // 是否需要默认加上 00:00:00', '23:59:59’
    isDefaultTime: {
      type: Boolean,
      default: true,
    },
    inputPlaceholder: {
      // input框的placehoder
      type: String,
      default: '关键词',
    },
    // 控制是否要下拉框
    isSelect: {
      type: Boolean,
      default: true,
    },
    // 控制需要什么时间范围
    // 1今天及今天之前 2今天及今天以后 3今天之前 4今天之后 不传不做限制
    timeOption: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    // 组件v-model绑定的时间数组
    inputTime: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    pickerOptions() {
      return {
        disabledDate: time => {
          let nowDate = parseInt(dayjs().format('YYYYMMDD')),
            thenDate = parseInt(dayjs(time).format('YYYYMMDD'));
          switch (this.timeOption) {
            case 1:
              return thenDate > nowDate;
            case 2:
              return thenDate < nowDate;
            case 3:
              return thenDate >= nowDate;
            case 4:
              return thenDate <= nowDate;
            default:
              break;
          }
        },
      };
    },
    // 带下拉选择框的 默认展示选项第一个
    placeholder() {
      if (this.isSelect) {
        return this.optionData && this.optionData.length > 0
          ? this.optionData[0].label
          : '';
      }
    },
  },
  // mounted() {
  //   if (this.defaultVal && this.defaultVal != '') {
  //     this.val = this.defaultVal;
  //   }
  // },
  watch: {
    defaultVal(newVal) {
      this.selectValue = newVal;
    },
  },
  methods: {
    // 传值出去的方法
    onSearch() {
      if (this.isSelect) {
        if (this.selectValue == '') {
          this.selectValue = this.optionData[0].value;
        }
      }
      this.$emit('change', this.selectValue);
    },
    // 切换下拉框值的方法
    onChange() {
      this.inputTime = [];
      // 触发修改字段名的事件
      this.$emit('change-prop', this.selectValue);
    },
    // 点击清空按钮的方法
    clearVal() {
      this.inputTime = [];
      this.selectValue = '';
      this.$emit('change', this.selectValue);
    },
    // 父组件点击重置方法
    // TODO 后续优化的点之一
    reVal(selectValue = '') {
      this.selectValue = selectValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-select-option {
  display: flex;

  .topsearch_picker {
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }
  .select-box ::v-deep .el-input__inner {
    border-radius: 4px 0 0 4px;
  }
  ::v-deep .is-active {
    border-left: 1px solid #ff7802;
  }
  ::v-deep .el-select-dropdown__item {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }

  ::v-deep .el-select-dropdown__item.selected {
    font-weight: 400 !important;
  }
}
</style>

<template>
  <div class="select-area">
    <el-cascader ref="SelectArea"  v-model="cascaderValue" :props="cascaderProp" :size="size" :filterable="true" @change="onChange"></el-cascader>
  </div>
</template>

<script>
import { get } from 'lodash'

import { size } from "./scripts/props";
const API = [
  "supplier.getCountries", // 国家 api 1
  "supplier.getStates", // 省份 api 2
  "supplier.getCity", // 城市 api 3
];

  export default {
    naem: "SelectArea",
    props: {
      size,
      value: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        cascaderProp: {
          lazy: true,
          label: "cname",
          value: "id",
          lazyLoad(node, resolve) {
            let { level, value: id } = node;

            if(level >= API.length) {
              resolve([]);
              return;
            }
            console.log(node)
            const api = API[level] + "";

            xmJson(api, { id }, (res) => {
              if (res.errcode == 0) {
                console.log('地区', res)
                const list = get(res, "data", []);

                list.forEach(item => {
                  item.leaf = level >= 2;
                })

                resolve(list);
              } else {
                resolve([]);
              }
            });
          },
        },
      }
    },
    computed: {
      cascaderValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    },
    methods: {
      onChange(val) {
        const node = this.$refs.SelectArea.getCheckedNodes()[0];

        const labels = [
          node.parent.parent.label,
          node.parent.label,
          node.label
        ];


        this.$emit('change', {
          value: val,
          labels
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>

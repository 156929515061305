<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>
          待处理数据<i class="update-time">(更新于{{ detail.updateTime }})</i>
        </div>
        <!-- <span @click="goUrl">查看全部</span> -->
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>待开发票金额</div>
          <span :class="[{ 'have-num': detail.invoice != 0.0 }]"
            >{{ currencySign }}{{ detail.invoice }}</span
          >
        </div>
        <div class="label-list">
          <div>待开发票订单</div>
          <span :class="[{ 'have-num': detail.invoiceOrder != 0 }]">{{
            detail.invoiceOrder
          }}</span>
        </div>
        <div class="label-list">
          <div>投诉待申诉</div>
          <span :class="[{ 'have-num': detail.complain != 0 }]">{{
            detail.complain
          }}</span>
        </div>
        <div class="label-list">
          <div>违规待申诉</div>
          <span :class="[{ 'have-num': detail.violation != 0 }]">{{
            detail.violation
          }}</span>
        </div>
        <!-- <div class="label-list">
          <div>违规商品数</div>
          <span :class="[{'have-num': detail.violation != 0 }]">{{ detail.violation }}</span>
        </div>
        <div class="label-list">
          <div>授权分销商数</div>
          <span :class="[{'have-num': detail.agent != 0 }]">{{ detail.agent }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { defaultTo } from 'lodash';
export default {
  name: 'AlertData',
  props: {
    // 当前页面币种, 1 人民币 2 美元
    pageCurrency: {
      type: String,
      default: '1',
    },
    // 待处理数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      update_time: '',
      // detail: {},
    };
  },
  computed: {
    // 货币符号
    currencySign() {
      switch (this.pageCurrency) {
        case '1':
          return '￥';
        case '2':
          return '$';
      }
    },
  },
  mounted() {
    this.update_time = dayjs().format('YYYY-MM-DD');
  },
  methods: {
    goUrl() {},
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

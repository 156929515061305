/**
 * 仓储模块
 * - 仓库管理
 * - 库存管理
 * - 补货
 * - 出入库
 */

const warehouseRoutes = [
  {
    path: '/wareHouse',
    name: 'wareHouse',
    meta: {
      title: '仓库信息',
      navIndex: 'ware',
      path: '/wareHouse',
      needsToken: true,
    },
    component: () => import('@/pages/warehouse/wareHouse'),
  },
  {
    path: '/wareDetail',
    name: 'wareDetail',
    meta: {
      title: '仓库详情',
      leftNavIndex: '/wareHouse',
      navIndex: 'ware',
      path: '/wareDetail',
      needsToken: true,
    },
    component: () => import('@/pages/warehouse/wareDetail'),
  },
  // 仓库列表
  {
    path: '/goodsStoreHouse',
    name: 'goodsStoreHouse',
    meta: {
      title: '商品仓',
      navIndex: 'ware',
      path: '/goodsStoreHouse',
      needsToken: true,
    },
    component: () => import('@/pages/warehouse/goodsStoreHouse'),
    children: [
      {
        path: 'addSupplier',
        name: 'addSupplier',
        meta: {
          title: '添加自建仓',
          navIndex: 'ware',
          path: 'addSupplier',
          needsToken: true,
        },
        component: () => import('@/pages/warehouse/component/addSupplierStore'),
      },
      {
        path: 'editSupplier/:storeId',
        name: 'editSupplier',
        meta: {
          title: '编辑自建仓',
          navIndex: 'ware',
          path: 'editSupplier',
          needsToken: true,
        },
        component: () => import('@/pages/warehouse/component/addSupplierStore'),
      },
      {
        path: 'addPlatform',
        name: 'addPlatform',
        meta: {
          title: '添加平台仓',
          navIndex: 'ware',
          path: 'addPlatform',
          needsToken: true,
        },
        component: () => import('@/pages/warehouse/component/addPlatformStore'),
      },
      {
        path: 'editPlatform/:storeId',
        name: 'editPlatform',
        meta: {
          title: '编辑平台仓',
          navIndex: 'ware',
          path: 'editPlatform',
          needsToken: true,
        },
        component: () => import('@/pages/warehouse/component/addPlatformStore'),
      },
      {
        path: 'storeInfo/:storeId',
        name: 'storeInfo',
        meta: {
          title: '查看详情',
          navIndex: 'ware',
          path: 'storeInfo',
          needsToken: true,
        },
        component: () => import('@/pages/warehouse/component/storeInfo'),
      },
    ],
  },
  {
    path: '/wareGoodsManagement',
    name: 'buildStoreHouse',
    meta: {
      title: '仓库商品列表',
      navIndex: 'ware',
      path: '/wareGoodsManagement',
      needsToken: true,
    },
    component: () =>
      import(
        '@/pages/warehouse/store-commodity/ware-goods/wareGoodsManagement.vue'
      ),
  },
  {
    path: '/platformWarehouseGoods/:war_id?',
    name: 'platformWarehouseGoods',
    meta: {
      title: '平台仓库',
      navIndex: 'ware',
      path: '/platformWarehouseGoods',
      needsToken: true,
    },
    component: () =>
      import('@/pages/warehouse/store-commodity/platformWarehouseGoods'),
  },
  {
    path: '/alonewarehouse/:war_id?',
    name: 'warehouseGoods',
    meta: {
      title: '仓库商品管理',
      navIndex: 'ware',
      path: '/warehouseGoods',
      needsToken: true,
    },
    component: () =>
      import(
        '@/pages/warehouse/store-commodity/single-warehouse-goods/AloneWareHouse.vue'
      ),
  },
  {
    path: '/platformGoods',
    name: 'platformGoods',
    meta: {
      title: '仓库商品管理',
      navIndex: 'ware',
      path: '/platformGoods',
      needsToken: true,
    },
    component: () => import('@/pages/warehouse/store-commodity/platformGoods'),
  },
  // 出入库记录
  {
    path: '/inoutrecord/:item_id?',
    name: 'inoutrecord',
    meta: {
      title: '出入库记录',
      navIndex: 'ware',
      path: '/inoutrecord',
      needsToken: true,
    },
    component: () => import('@/pages/warehouse/inoutrecord'),
  },
  // 补货
  {
    path: '/freedomStoreReplenish',
    name: 'freedomStoreReplenish',
    meta: {
      title: '自建仓补货',
      navIndex: 'ware',
      path: '/freedomStoreReplenish',
      needsToken: true,
    },
    component: () =>
      import('@/pages/warehouse/replenish/freedomStoreReplenish'),
  },
  {
    path: '/platformStoreReplenish',
    name: 'platformStoreReplenish',
    meta: {
      title: '平台仓补货',
      navIndex: 'ware',
      path: '/platformStoreReplenish',
      needsToken: true,
    },
    component: () =>
      import('@/pages/warehouse/replenish/platformStoreReplenish'),
  },
  {
    path: '/applyReplenish/:sskuid?/:type?',
    name: 'applyReplenish',
    meta: {
      title: '补货-预约仓位',
      leftNavIndex: '/platformStoreReplenish',
      navIndex: 'ware',
      path: '/applyReplenish',
      needsToken: true,
    },
    component: () => import('@/pages/warehouse/replenish/applyReplenish'),
  },
];

export default warehouseRoutes;

<template>
  <div class="more-select">
    <el-popover
      placement="bottom-start"
      trigger="hover"
      :disabled="selectData.length <= 2"
      :open-delay="300"
    >
      <el-select
        slot="reference"
        v-model="selectData"
        placeholder="请选择"
        multiple
        :popper-append-to-body="false"
        @change="getSelect"
      >
        <div>
          <div class="optionmodel">
            <!-- <el-option style="display: none"></el-option> -->

            <el-checkbox-group v-model="selectData">
              <div v-for="item in optionsData" :key="item.value">
                <el-option :label="item.label" :value="item.value">
                  <el-checkbox
                    :label="item.label"
                    @change="itemoption(item.label)"
                  >
                    {{ item.label }}
                  </el-checkbox>
                </el-option>
              </div>
            </el-checkbox-group>
          </div>
          <div style="overflow: hidden; width: 100%">
            <div class="bottommodel">
              <div class="bottomlabel">已选择:</div>
              <div
                class="bottomvalue"
                v-for="(item, index) in selectData"
                :key="item"
              >
                {{ item }}
                <span class="hand" @click="cancelitwm(index)">x</span>
              </div>
            </div>
          </div>
        </div>
      </el-select>
      <div class="tags" v-show="selectData.length != 0">
        <span class="tags-item" v-for="(item, index) in selectData" :key="item">
          <span>{{ item }}</span>
          <span
            ><i
              class="el-tag__close el-icon-close"
              @click="cancelitwm(index)"
            ></i
          ></span>
        </span>
      </div>
    </el-popover>

    <!-- <div class="ellipsis" v-show="selectData.length > 2">...</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标
      selectData: [], //
    };
  },
  props: {
    optionsData: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},

  methods: {
    cancelitwm(index) {
      this.selectData.splice(index, 1);
    },

    //点击复选框也能选中
    itemoption(item) {
      let i = this.selectData.indexOf(item);
      if (i === -1) {
        this.selectData.push(item);
      } else {
        this.selectData.splice(i, 1);
      }
      this.$emit('change', this.selectData);
    },
    getSelect() {
      this.$emit('change', this.selectData);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select-dropdown__item.selected {
  background: #ffffff !important;
}
::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.more-select {
  position: relative;

  .ellipsis {
    position: absolute;
    top: 12px;
    right: 35px;
    color: #808080;
  }
}
.toptad {
  width: 100%;
  height: 36px;
  background: #e6e6e6;
}
.tags {
  display: flex;

  .tags-item {
    flex-shrink: 0;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 2px 8px;
    background: #f4f4f5;
    margin-right: 10px;
    display: flex;
    align-items: center;
    i {
      background: #c0c4cc;
      border-radius: 50%;
      text-align: center;
      position: relative;
      cursor: pointer;
      font-size: 12px;
      vertical-align: middle;
      top: -1px;
      right: -5px;
    }
  }
}

.more-select ::v-deep .el-select-dropdown__list {
  padding: 0;
}

.more-select ::v-deep .el-popper[x-placement^='bottom'] .popper__arrow {
  display: none;
}

.counttext {
  width: 100px;
  height: 36px;
  background: #ffffff;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #4d4d4d;
  line-height: 36px;
  text-align: center;
}

.more-select ::v-deep.el-select {
  width: 300px;
}

.more-select ::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.more-select ::v-deep .el-scrollbar {
  height: fit-content;
}

.searchinput {
  box-sizing: border-box;
  padding: 10px;
}

.searchinput ::v-deep.el-input--prefix .el-input__inner {
  border-radius: 0px;
  background: #e6e6e6;
  border-color: #cccccc;
}

.searchinput ::v-deep .el-input__prefix {
  top: 12px;
  left: 13px;
}

.optionmodel {
  height: 263px;
  overflow: auto;
}

.allmodel {
  padding-left: 20px;
  margin-top: -10px;
  margin-bottom: -10px;
}

.bottommodel {
  box-sizing: border-box;
  padding: 10px 0px 10px 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid #e6e6e6;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80px;
  margin-right: -10px;
  min-height: 50px;
}

.bottommodel > div {
  height: 24px;
}

.bottomlabel {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #4d4d4d;
  margin-right: 10px;
  line-height: 24px;
}

.bottomvalue {
  background: #fff1e5;
  margin-right: 10px;
  padding: 0px 5px;
  line-height: 24px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ff7802;
  margin-bottom: 5px;
}

.hand {
  cursor: pointer;
}

.more-select ::v-deep .el-select-dropdown__wrap {
  max-height: 1000px;
}

.more-select
  ::v-deep
  .el-select-dropdown.is-multiple
  .el-select-dropdown__item {
  text-align: left;
}
</style>

import BusOverview from './BusOverview';
import CardList from './CardList';
import RealTimeOverview from './RealTimeOverview';
import TopList from './TopList';

// 图表模块
import ReturnRate from './echarts/ReturnRate.vue';
import StatisticalTime from './echarts/StatisticalTime.vue';
import Coupon from './echarts/Coupon.vue';


export default {
  BusOverview,
  CardList,
  RealTimeOverview,
  StatisticalTime,
  ReturnRate,
  StatisticalTime,
  TopList,
  Coupon
};
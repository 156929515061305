<template>
  <div class="outerdiv">
    <div class="cover-mask">
      <div
        v-if="type == 2"
        class="preview-dialog-close-header"
        @click="closeimg"
      >
        <img src="@/assets/images/cross.svg" class="dialog-close-icon" />
      </div>
      <div :class="['preview-dialog-container', { 'wide-dialog': type == 2 }]">
        <header v-if="type == 1">
          <span>预览</span>
          <i class="iconfont dialog-close-icon" @click="closeimg">&#xe625;</i>
        </header>
        <main id="file-container">
          <iframe v-if="type == 1" class="child" frameborder="0" :src="showurl">
          </iframe>
          <iframe
            v-if="type == 2"
            class="child"
            frameborder="0"
            :src="officePrefix + showurl"
          >
          </iframe>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
// import Video from 'video.js';
// import 'video.js/dist/video-js.css';
export default {
  props: {
    showurl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      type: '1',
      officePrefix: 'https://view.officeapps.live.com/op/view.aspx?src=',
    };
  },
  mounted() {
    let length = this.showurl.split('.').length;
    let currenttype = this.showurl.split('.')[length - 1];
    if (currenttype == 'pdf') {
      this.type = '1';
    } else {
      this.type = '2';
    }
  },
  methods: {
    closeimg() {
      this.$emit('closepredox');
    },
  },
};
</script>
<style lang="scss" scoped>
.outerdiv {
  // 遮罩层
  .cover-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.25);

    // 关闭按钮
    .preview-dialog-close-header {
      position: fixed;
      top: 50px;
      right: 50px;
      cursor: pointer;
      z-index: 3001;

      text-align: right;

      .dialog-close-icon {
        width: 32px;
        height: 32px;
      }
    }

    .preview-dialog-container {
      width: 800px;
      max-width: 90vw;
      height: 900px;
      max-height: 90vh;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: #fff;

      header {
        height: 56px;
        padding: 20px;

        span {
          font-size: 16px;
          color: #111;
        }

        .dialog-close-icon {
          float: right;
          font-size: 24px;
          color: #9d9d9d;
          cursor: pointer;
        }
      }

      main {
        width: 100%;
        height: calc(100% - 56px);

        // 左右下留白
        border-bottom: 20px solid #fff;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
    .preview-dialog-container.wide-dialog {
      width: 1440px;
      height: 920px;

      main {
        height: 100%;
        border: none;

        padding: 0px;
      }
    }
  }
}
</style>

/**
 * 用于兼容旧 supplier 项目的代码们
 * @author hukeyi
 * @date 2023-09-12
 */

import request from '@/utils/request';
import { getXimuToken } from './service';
import {
  ObjectValue,
  hasValue,
  getFirstAvailableValue,
  deepCopy,
} from './xm-jstools';

function xmJson(
  url,
  data,
  success,
  error = err => {
    console.error(err);
  },
  method = 'get',
  closeLoading = false
) {
  if (url.indexOf('.') >= 0) {
    url = url.replace('.', '/');
  }
  const options = {
    url,
    method,
  };
  let propName = method === 'get' ? 'params' : 'data';
  // 处理后端的项目模块
  if (data.API_MODULE) {
    options.apiModule = data.API_MODULE;
    delete data.API_MODULE;
  }
  options[propName] = data;
  if (closeLoading) {
    options.controller = {};
    options.controller.closeLoading = closeLoading;
  }
  request(options).then(success).catch(error);
}

function xmUpload(
  url,
  data,
  success,
  error,
  method = 'post',
  closeLoading = false
) {
  if (url.indexOf('.') >= 0) {
    url = url.replace('.', '/');
  }
  let token = getXimuToken();
  let formData = new FormData();
  formData.append('token', token);
  formData.append('file', data.file);
  const options = {
    url,
    method,
    data: formData,
  };
  if (closeLoading) {
    options.controller = {};
    options.controller.closeLoading = closeLoading;
  }
  request(options).then(success).catch(error);
}

export function installOldTool(Vue) {
  if (!Vue) {
    window.xmJson = xmJson;
    window.xmUpload = xmUpload;
    window.ObjectValue = ObjectValue;
    window.hasValue = hasValue;
    window.getFirstAvailableValue = getFirstAvailableValue;
    window.deepCopy = deepCopy;
    window.projectName = '';
    return;
  }
  Vue.prototype.$old = {
    xmJson,
    xmUpload,
    ObjectValue,
    hasValue,
    getFirstAvailableValue,
  };
}

<template>
  <div class="xm-success-dialog">
    <!-- 遮罩层 -->
    <div class="mask" :style="{ opacity: opacity, 'z-index': zIndex }"></div>
    <!-- 弹窗主体 -->
    <div class="box" :style="{ width: width, 'z-index': Number(zIndex) + 1 }">
      <div class="box-header">
        <div class="box-header-title">
          <!-- 标题插槽 有多个标题时可以用 -->
          {{ title }}
        </div>
        <i @click="handleClickBtn" class="box-close-icon el-icon-close"></i>
      </div>
      <div class="box-main">
        <slot></slot>
      </div>
      <footer class="box-footer">
        <button @click="handleClickBtn" class="footer-btn">
          <p>
            <span v-if="timeCount > 0">({{ timeCount }}s)</span>{{ btnText }}
          </p>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
// 带倒计时的成功提示弹窗
export default {
  name: 'XmSuccessDialog',
  props: {
    title: {
      type: String,
      default: '提示',
    },
    btnText: {
      type: String,
      default: '关闭',
    },
    timeCount: {
      type: Number,
      default: 3,
    },
    width: {
      // 弹窗宽度
      type: String,
      default: '567px',
    },
    opacity: {
      // 遮罩层的透明度
      type: String,
      default: '0.25',
    },
    zIndex: {
      // 遮罩层的z轴坐标
      type: String,
      default: '2000',
    },
  },
  watch: {
    // 3秒后自动关闭
    timeCount: function(val) {
      if (val <= 0) {
        this.handleCountdownEnd();
      }
    },
  },
  methods: {
    // 倒计时
    countdown() {
      if (this.timeCount < 0) return;

      const timer = setInterval(() => {
        if (this.timeCount >= 0) {
          --this.timeCount;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    // 点击弹窗按钮
    handleClickBtn() {
      this.$emit('click-btn');
    },
    // 倒计时结束的事件
    handleCountdownEnd() {
      this.$emit('countdown-end');
    },
  },
  mounted() {
    this.countdown(); // 开启倒计时
  },
};
</script>

<style lang="scss" scoped>
.xm-success-dialog {
  * {
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }
  /* 遮罩层 */
  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #111;
    opacity: 0.4;
    z-index: 1000;
  }
  /* 弹窗 */
  .box {
    height: auto;
    font-size: 14px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    background-color: #fff;
    display: grid;
    grid-template-rows: 60px 1fr;

    // 弹窗头部标题行
    .box-header {
      height: 60px;
      background-color: #f2f2f2;

      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: space-around;
      align-items: center;

      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #111111;

      padding-left: 20px;
      padding-right: 20px;

      .box-close-icon {
        justify-self: end;
        color: #333333;
        font-size: 24px;
        cursor: pointer;
      }
    }
    // 弹窗主体，插槽
    .box-main {
      align-self: center;
      padding: 20px;
      width: 100%;
      height: 100%;
      color: #333333;
    }
  }
  .box-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-bottom: 30px;

    position: relative;

    button {
      width: 82px;
      height: 40px;
      background-color: #ff7802;
      color: #fff;
      cursor: pointer;

      p,
      span {
        display: inline-block;
        height: 24px;
        line-height: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        color: #ffffff;
      }
    }
  }
}
</style>

<template>
  <div class="bus-overview">
    <div class="card-left">
      <div class="card-left-title">
        <div class="title-font">经营总览</div>

        <div class="currency-chose">
          <div
            class="currency-type"
            v-for="item in currencyList"
            :key="item.value"
            :class="{ 'chose-type': pageCurrency == item.value }"
            @click="changePageCurrency(item.value)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="card-left-content">
        <div class="content-score">
          <div class="content-score-level">
            等级
            <span class="level-number">{{ detail.level || '--' }}</span>
            <!-- <span class="level-number">L000</span>距升级还差￥000000销售额 -->

            <!-- <el-popover
              placement="bottom-start"
              width="302"
              trigger="hover"
              popper-class="platform-level-popover"
            >
              <div class="tip-content">
                <div>L0：自然月累计商品销售额X≤50000元</div>
                <div>L1：自然月累计商品销售额200000≥X>50000元</div>
                <div>L2：自然月累计商品销售额400000≥X>200000元</div>
                <div>L3：自然月累计商品销售额700000≥X>400000元</div>
                <div>L4：自然月累计商品销售额1000000≥X>700000元</div>
                <div>L5：自然月累计商品销售额X>1000000元</div>
                <div>
                  操作费、物流费、仓储费、派送费、偏远费、推款不
                  计算在商品销售额内
                </div>
              </div>
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover> -->
          </div>

          <div class="content-score-num">
            积分
            <el-popover
              placement="bottom-start"
              width="288"
              trigger="hover"
              popper-class="platform-level-popover"
            >
              <div class="platform-tip-content">
                <div v-html="detail.integral_text"></div>
              </div>
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
            <span>{{ detail.integral || '--' }}</span>
          </div>
        </div>

        <div class="content-overall">
          <div class="overall-title">综合得分</div>
          <div class="mine-score">
            {{ detail.comprehensive || '--' }}
            <span :class="[{ increase: symbolsNum }]">
              {{ detail.comprehensiveRadio || '--' }}%
            </span>
          </div>

          <div class="progress-bar">
            <!-- 进度条 -->
            <el-progress type="line" :percentage="percentage">
              <!-- {{scope.row.progress}} -->
            </el-progress>

            <!-- 同行同层级平均 -->
            <!-- <div class="same-average" :style="{ left: sameAverage + 'px' }">
              <div class="average-title">同行同层级平均</div>
            </div> -->

            <!-- 同行同层级优秀 -->
            <!-- <div class="same-excellent" :style="{ left: sameExcellent + 'px' }">
              <div class="excellent-title">同行同层级优秀</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="card-right" ref="radarMap"></div>
  </div>
</template>

<script>
// import BusOverviewEchart from './echarts/BusOverviewEchart';
// import * as echarts from 'echarts';

export default {
  name: 'BusOverview',
  // components: { BusOverviewEchart },
  props: {
    // 当前页面展示的币种, 1 人民币 2 美元
    pageCurrency: {
      type: String,
      default: '1',
    },
    // 用户积分等级信息
    detail: {
      type: Object,
      default: () => {},
    },
    // 雷达图信息
    raderPicMsg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currencyList: [
        {
          name: '人民币',
          value: '1',
        },
        {
          name: '美元',
          value: '2',
        },
      ],
      // detail: {}, // 用户积分等级信息
      raderNum: [], // 雷达图数据信息
      // raderPic: {}, //雷达图信息
      percentage: 85,
      sameAverage: 240,
      sameExcellent: 390,
      symbolsNum: false, // 综合得分是增加还是减少 false减少，true增加
    };
  },
  watch: {
    // 处理积分数据
    detail(val) {
      this.percentage = Number(Number(val?.comprehensive) / 182) * 100;

      if (val?.comprehensiveRadio?.substring(0, 1) != '-') {
        val.comprehensiveRadio = '+' + val?.comprehensiveRadio;
        this.symbolsNum = true;
      } else {
        this.symbolsNum = false;
      }
    },
    // 处理雷达图数据
    raderPicMsg(val) {
      if (val?.hexagon) {
        this.raderNum = [
          val?.hexagon?.goods?.value,
          val?.hexagon?.service?.value,
          val?.hexagon?.grow?.value,
          // val?.hexagon.stock.value,
          // val?.hexagon.quality.value,
          val?.hexagon?.logistics?.value,
        ];
        this.radarMap();
      }
    },
  },
  mounted() {},
  methods: {
    // 改变页面选择的币种
    changePageCurrency(type) {
      // this.pageCurrency = type;
      this.$emit('change-currency', type);
    },
    // 渲染雷达图
    radarMap() {
      // 避免报错：There is a chart instance already initialized on the dom
      const el = this.$refs.radarMap;
      let myChart = echarts.getInstanceByDom(el);
      if (myChart == null) {
        myChart = echarts.init(el);
      }

      var option = {
        color: '#FF7802',
        tooltip: {
          show: false,
          triggerOn: 'mousemove',
          backgroundColor: 'rgba(255, 255, 255, .9)',
          formatter: function () {
            return '';
          },
          // trigger: 'item', //鼠标移动到节点上会显示数据，这里没有使用formatter自定义显示内容
        },
        radar: [
          {
            // 指定雷达图中的多个变量（维度）
            indicator: [
              { name: '商品评分', max: '100.00' },
              { name: '服务评分', max: '100.00' },
              { name: '成长评分', max: '100.00' },
              // { name: '库存评分', max: '20.00' },
              // { name: '质量评分', max: '40.00' },
              { name: '物流评分', max: '100.00' },
            ],
            center: ['50%', '50%'],
            radius: 50, // 圆的半径
            // startAngle: 120, // 坐标系起始角度(第一个指示器轴的角度)
            splitNumber: 4, // 圆的环数
            axisNameGap: 4, // 指示器名称和指示器轴的距离
            shape: 'polygon',
            axisName: {
              color: '#999',
              fontSize: 12,
            },
            splitArea: {
              areaStyle: {
                color: '#FFF',
              },
            },
            // 图内坐标轴线相关设置
            axisLine: {
              lineStyle: {
                color: '#FFF', // 轴线颜色
              },
            },
            // 圆环坐标轴线相关设置
            splitLine: {
              lineStyle: {
                color: '#CCC',
                type: 'dashed',
              },
            },
          },
        ],
        series: [
          {
            name: '雷达图',
            type: 'radar',
            symbol: 'circle',
            symbolSize: 6, // 圆点大小
            // 折线拐点标志的样式
            itemStyle: {
              // 普通状态时的样式
              width: 1,
              color: '#FFF',
              borderColor: '#FF7802',
            },
            // 高亮时的样式
            emphasis: {
              lineStyle: {
                width: 4,
              },
            },
            // 雷达图数据是多维度的
            data: [
              {
                // value项数组是具体的数据，每个值跟 radar.indicator 一一对应
                value: this.raderNum,
                // 单个拐点文本的样式设置
                label: {
                  show: false, // 单个拐点文本的样式设置
                  position: 'top', // 标签的位置
                  distance: 5, // 距离图形元素的距离
                  color: 'rgba(255,0,0,1)', // 文字的颜色
                  fontSize: 12, // 文字的字体大小
                },
                // 单项区域填充样式
                areaStyle: {
                  color: 'rgba(255,120,2,0.3)',
                },
              },
            ],
          },
        ],
      };

      myChart.on('mousemove', params => {
        let num = params.event.topTarget.__dimIdx;
        if (num === undefined) {
          option.tooltip.show = false;
          option.tooltip.formatter = function () {
            return '';
          };
        } else {
          option.tooltip.show = true;
          option.tooltip.formatter = function (params) {
            // console.log(option.radar[0].indicator[num], 'xuanfu');
            return `<div class="mine-type">${option.radar[0].indicator[num].name}</div>
                <div class="mine-score">我的分数：${params.data.value[num]}</div>
                <div class="mine-score">最大分数：${option.radar[0].indicator[num].max}</div>
              `;
          };
        }
        myChart.setOption(option);
      });

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
// @import './styles/minx.scss';
.bus-overview {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 20px;
  width: 752px;
  height: 185px;
  background-color: #fff;

  .card-left {
    width: 490px;

    .card-left-title {
      display: flex;

      .title-font {
        margin-bottom: 20px;
        height: 21px;
        font-size: 16px;
        font-weight: 400;
        color: #111;
      }

      .currency-chose {
        display: flex;
        margin-left: 20px;
        padding: 2px;
        width: 108px;
        height: 24px;
        font-size: 12px;
        color: #808080;
        border-radius: 12px;
        background-color: #e6e6e6;

        .currency-type {
          width: 52px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 10px;
          cursor: pointer;
        }

        .chose-type {
          color: #fff;
          background-color: #ff7802;
        }
      }
    }

    .card-left-content {
      .content-score {
        display: flex;
        height: 21px;
        line-height: 21px;
        color: #808080;
        font-size: 12px;

        .content-score-level {
          display: flex;
          align-items: baseline;

          .level-number {
            margin-left: 10px;
            margin-right: 4px;
            color: #ff7802;
            font-size: 16px;
          }
        }

        .content-score-num {
          display: flex;
          margin-left: 55px;

          i {
            margin-right: 10px;
          }

          span {
            color: #333;
            font-size: 16px;
          }
        }

        i {
          margin-left: 2px;
          font-size: 16px;
          color: #3d8df5;
          cursor: pointer;
        }
      }

      .content-overall {
        .overall-title {
          margin: 20px 0 10px;
          height: 16px;
          font-size: 12px;
          color: #808080;
        }
        .mine-score {
          margin-bottom: 10px;
          height: 21px;
          font-size: 16px;
          color: #333;

          &::before {
            content: none;
          }

          span {
            margin-left: 4px;
            font-size: 12px;
            color: #07c160;
          }

          .increase {
            color: #d22316 !important;
          }
        }
        .progress-bar {
          position: relative;
          color: #999;
          font-size: 12px;
          // width: 400px;

          .same-average {
            position: absolute;
            bottom: 0;
            padding-right: 4px;
            border-right: 1px dashed #07c160;
            height: 40px;
          }

          .same-excellent {
            position: absolute;
            bottom: 0;
            padding-left: 4px;
            height: 40px;
            border-left: 1px dashed #ff7701;
          }
        }
      }
    }
  }

  .card-right {
    width: 242px;
    height: 145px;
  }
}

// 分数进度条
::v-deep .el-progress {
  width: 100%;
  height: 6px;

  .el-progress__text {
    display: none;
    color: #fff;
    font-size: 12px !important;
  }

  .el-progress-bar__outer {
    height: 6px !important;
    background-color: transparent;
  }

  /* 渐变进度条 */
  .el-progress-bar__inner {
    background-color: unset;
    background-image: linear-gradient(270deg, #ff7700 0%, #ffd6b2 100%);
  }

  .el-progress-bar {
    padding-right: 0 !important;
    display: block;
    width: 400px;
    margin-right: 0 !important;
    box-sizing: border-box;
  }
}

// 平均及优秀分数位置样式
::v-deep .mine-type,
::v-deep .mine-score {
  font-size: 12px;
  height: 16px;
  line-height: 14px;
  color: #808080;
}

::v-deep .mine-score {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

::v-deep .mine-score::before {
  content: '';
  display: block;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ff7802;
}
</style>

<style>
/* 提示框样式 */
.platform-level-popover {
  margin-left: -7px;
  margin-top: 5px !important;
  min-width: 100px !important;
  font-size: 12px !important;
  color: #999 !important;
  line-height: 16px !important;
  border: none !important;
}

.platform-level-popover .popper__arrow {
  border-bottom-color: #fff !important;
}

.platform-tip-content {
  white-space: pre-wrap;
}
</style>

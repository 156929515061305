<template>
  <div>
    <div class="boxContainer">
      <div class="uploadImg" v-if="noButton">
        <div
          v-for="(file, index) in fileArray"
          :key="file"
          :class="{
            marginRight0: maxNumber == fileArray.length,
            noInfo: maxNumber,
          }"
          :style="{ '--imgRight': imgMarginRight }"
        >
          <img
            :src="getDocDefaultImg(file)"
            v-if="isFileType(true, file)"
            @click="!maxNumber ? showImgFunc(file, true) : ''"
          />
          <video
            class="pointer video-class"
            v-else-if="isFileType(false, file)"
            :key="index"
            :src="file | imgbaseurl"
            muted
            nofullscreen
            @click="!maxNumber ? showImgFunc(file, false) : ''"
          ></video>
          <img
            v-else
            class="pic-contain"
            :src="file | imgbaseurl"
            alt=""
            @click="!maxNumber ? showImgFunc(file, false) : ''"
          />
          <div v-if="maxNumber" class="action-icon">
            <img
              src="@/assets/images/previewsvg.svg"
              @click="showImgFunc(file, false, true)"
              v-if="isPreview"
            />
            <i class="el-icon-delete" @click="delImage(index)" v-if="isdel"></i>

            <div class="mask"></div>
          </div>
        </div>
      </div>
      <div :class="uploadDisabled ? 'notAllow' : ''">
        <el-upload
          :disabled="uploadDisabled"
          class="upload-demo"
          action="/supplierapi/Material/addMaterial"
          :show-file-list="false"
          :on-success="uploadSuccess"
          :data="needData"
          :before-upload="beforeUpload"
          :on-error="uploadError"
          :on-exceed="exceedFunc"
          :on-progress="progressFunc"
          :accept="getAcceptData"
        >
          <div class="uploadContainer" v-if="noButton">
            <div v-if="currentStatus === ''">
              <div
                class="uploadBox"
                v-if="fileArray.length < maxNumber"
                @click="TanChuangflag = true"
                slot="trigger"
              >
                <i class="iconfont">&#xe609;</i>
                <span>{{ content }}</span>
              </div>
            </div>
            <div v-else-if="currentStatus === 'conduct'">
              <div class="uploadBox conduct">
                <span>{{ conduct }}</span>
              </div>
            </div>
            <div v-else-if="currentStatus === 'failure'">
              <div class="uploadBox failure">
                <span>{{ failure }}</span>
                <span @click="reload">失败</span>
              </div>
            </div>
            <!-- <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb
          </div> -->
          </div>
          <div v-else>
            <slot name="triggerMode"></slot>
          </div>
        </el-upload>
      </div>
    </div>
    <previewimg
      :showurl="imgUrl"
      @closepreimg="previewCloseFunc"
      v-if="showPreviewimg"
    ></previewimg>
    <previewdoc
      :showurl="imgUrl"
      @closepredox="previewCloseFunc"
      v-if="showPreviewdoc"
    ></previewdoc>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      //上传框中提示内容
      type: String,
      default: '上传图片',
    },
    maxNumber: {
      //最大上传数量(传0标识详情专用,不能删除)
      type: [Number, String],
      default: 3,
    },
    file_type: {
      //支持哪些文件类型
      type: Array,
      default: () => [],
    },
    propFileArray: {
      //回显专用
      type: [Array, String],
      default: () => [],
    },
    flagType: {
      //当同一个页面多处使用时区分标识
      type: String,
      default: '',
    },
    singleMaxSize: {
      //单次(图片/文档)最大上传大小
      type: Number,
      default: 5,
    },
    singleVideoMaxSize: {
      //单次视频最大上传大小
      type: Number,
      default: 200,
    },
    isPreview: {
      //是否展示预览图标
      type: Boolean,
      default: false,
    },
    isdel: {
      //是否展示删除图标
      type: Boolean,
      default: true,
    },
    noButton: {
      //是否是点击按钮触发
      type: Boolean,
      default: true,
    },
    isSingle: {
      //是否单张上传(传string或array都行)
      type: Boolean,
      default: false,
    },
    //是否禁止上传
    isDisabled: {
      type: Boolean,
      default: false,
    },
    //多种图片之间的右外边距
    imgMarginRight: {
      type: String,
      default: '6px',
    },
    //后端限制文件大小，不传默认5M
    limitType: {
      type: String,
      default: '',
    },
    // 图片水印
    watermark: {
      type: String,
      default: '',
    },
  },
  watch: {
    //回显
    propFileArray: {
      handler() {
        //多文件回显
        if (!this.isSingle) {
          this.fileArray = JSON.parse(JSON.stringify(this.propFileArray));
          this.fileArray.forEach((item, index) => {
            this.imgCheckType(item);
          });
        } else {
          //单文件回显
          this.fileArray = [];
          if (this.propFileArray) {
            this.fileArray.push(this.propFileArray);
            this.imgCheckType(this.propFileArray);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      showPreviewdoc: false, //控制文档弹窗
      // isDisabled: false, //控制能否禁用上传
      uploadDisabled: false, // 控制能否禁用上传，作为 isDisabled 的中间变量
      failure: '上传失败', //失败提示词
      conduct: '上传中', //上传中提示词
      token: localStorage.getItem('token'), //获取token
      TanChuangflag: false, //控制上传阀门
      fileArray: [], //存储所有数据
      imgUrl: '', //存储大图url
      showPreviewimg: false, //控制图片弹窗
      currentImgType: '', //记录当前图片状态
      fileNameArray: [], //记录上传素材的名字
      sourceFileArray: [], //存储文件数据
      watermarkArray: [], // 存储水印数据
      currentStatus: '', //记录当前上传状态
      fileImgMap: {
        //各种文件类型所展示的封面图
        pdf: require('@/assets/material/pdf.svg'),
        xlsx: require('@/assets/material/excel.svg'),
        xls: require('@/assets/material/excel.svg'),
        doc: require('@/assets/material/word.svg'),
        docx: require('@/assets/material/word.svg'),
        zip: require('@/assets/material/zip.svg'),
        rar: require('@/assets/material/zip.svg'),
        '7z': require('@/assets/material/zip.svg'),
      },
    };
  },
  methods: {
    //判断文件类型
    isFileType(isDoc, file) {
      if (isDoc) {
        return this.sourceFileArray.some(
          item => item.index === file && item.type != 'video'
        );
      } else {
        return this.sourceFileArray.some(
          item => item.index === file && item.type == 'video'
        );
      }
    },
    //获取文档url默认封面
    getDocDefaultImg(file) {
      return this.fileImgMap[
        this.sourceFileArray[
          this.sourceFileArray.reduce((box, item, indexTwo) => {
            item.index === file && item.type != 'video'
              ? box.push(indexTwo)
              : '';
            return box;
          }, [])[0]
        ].type
      ];
    },
    //失败点击指定区域才能触发上传
    reload() {
      this.currentStatus = '';
      setTimeout(() => {
        this.uploadDisabled = false;
      }, 0);
    },
    //上传失败回调
    uploadError() {
      this.uploadDisabled = true;
      this.currentStatus = 'failure';
    },
    //上传中回调
    progressFunc() {
      this.uploadDisabled = true;
      this.currentStatus = 'conduct';
    },
    //上传上限提示
    exceedFunc() {
      this.$message.warning('上传已上限!');
    },
    //上传前效验图片格式
    beforeUpload(file) {
      console.log();
      let MB = file.size / 1024 / 1024;
      if (file.type.indexOf('image/') != -1 && MB > this.singleMaxSize) {
        this.$message.warning(
          `已超出单个上传最大限制,单个最大限制${this.singleMaxSize}MB!`
        );
        return false;
      } else if (
        file.type.indexOf('video/') != -1 &&
        MB > this.singleVideoMaxSize
      ) {
        this.$message.warning(
          `已超出单个上传最大限制,单个最大限制${this.singleVideoMaxSize}MB!`
        );
        return false;
      }
      let arr = file.name.split('.') || [];
      let fileSuffix = arr.length ? arr[arr.length - 1] : '';
      if (fileSuffix) {
        let temp = this.file_type.some(
          item => item.toLowerCase() == fileSuffix.toLowerCase()
        );
        if (!temp) {
          this.$message({
            type: 'warning',
            message: '此处不支持该类型文件上传!',
          });
          return false;
        }
      } else {
        this.$message({ type: 'warning', message: '上传异常!' });
        return false;
      }
      if (this.fileArray.length + 1 > this.maxNumber) {
        this.$message({
          type: 'warning',
          message: '已超出最大上传个数!',
        });
        return false;
      }
      return true;
    },
    //详情页点击查看大图
    showImgFunc(file, noImg, flag) {
      if (flag) {
        let temp = this.matchType(file);
        if (temp) {
          if (temp == 'image' || temp == 'video') {
            noImg = false;
          } else {
            noImg = true;
          }
        } else {
          this.$message({ type: 'warning', message: '文件/图片格式异常!' });
          return;
        }
      }
      this.imgUrl = this.$options.filters.imgbaseurl(file);
      if (noImg) {
        this.showPreviewdoc = true;
      } else {
        this.showPreviewimg = true;
      }
    },
    //关闭预览弹窗
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    //删除图片
    delImage(index) {
      let flag = -1;
      this.sourceFileArray.filter((item, indexTwo) => {
        if (item.index === index) {
          flag = indexTwo;
        }
      });
      flag != -1 ? this.sourceFileArray.splice(flag, 1) : '';
      this.fileArray.splice(index, 1);
      this.fileNameArray.splice(index, 1);
      this.watermarkArray.splice(index, 1);
      this.$emit(
        'statuChangeEvent',
        JSON.parse(JSON.stringify(this.fileArray)),
        this.flagType,
        this.fileNameArray.slice(),
        JSON.parse(JSON.stringify(this.watermarkArray))
      );
    },
    //上传成功回调
    uploadSuccess(res) {
      this.uploadDisabled = false;
      this.currentStatus = '';
      if (res.errcode == 0) {
        this.imgCheckType(res.data.link, false);
        this.fileArray.push(res.data.link);
        this.fileNameArray.push(res.data.name);
        this.watermarkArray.push(res.data.watermark_key);
        this.$emit(
          'statuChangeEvent',
          JSON.parse(JSON.stringify(this.fileArray)),
          this.flagType,
          this.fileNameArray.slice(),
          JSON.parse(JSON.stringify(this.watermarkArray))
        );
      } else {
        this.$message({ type: 'warning', message: res.msg });
      }
    },
    //判断具体是什么文件
    imgCheckType(file) {
      //判断文件类型
      let flag = file.split('.')[1];
      if (flag == 'pdf') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'doc' || flag == 'docx') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'xls' || flag == 'xlsx') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'zip' || flag == 'rar' || flag == '7z') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (this.matchType(file) == 'video') {
        this.sourceFileArray.push({
          index: file,
          type: 'video',
        });
      }
    },
  },
  computed: {
    //限制上传文件类型
    getAcceptData() {
      let data = this.file_type?.map(item => '.' + item);
      return data?.toString();
    },
    // 传给后端需要的data参数
    needData() {
      if (this.watermark != '') {
        return {
          token: this.token,
          limit_type: this.limitType,
          watermark: this.watermark,
        };
      } else {
        return { token: this.token, limit_type: this.limitType };
      }
    },
  },
  mounted() {
    this.uploadDisabled = this.isDisabled;
  },
};
</script>

<style scoped>
.marginRight0 {
  margin-right: 0;
}
.hide {
  position: absolute;
  z-index: -100;
}
/* 上传失败样式 */
.failure {
  border-color: #d22316 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uploadContainer .failure > span {
  color: #d22316 !important;
  font-size: 14px !important;
  line-height: 22px;
}
/* 上传中样式 */
.conduct {
  background: rgba(17, 17, 17, 0.5);
  text-align: center;
  line-height: 100px;
}
.uploadContainer .conduct > span {
  color: white !important;
  font-size: 14px !important;
}
.boxContainer {
  display: flex;
}
.pic-contain {
  object-fit: contain;
}
.action-icon img,
.action-icon .el-icon-delete {
  cursor: pointer;
}

.notAllow ::v-deep .el-upload {
  cursor: not-allowed;
}
.upload-demo {
  height: 100px;
}
.uploadContainer {
  position: relative;
  display: flex;
}
/* 上传盒子样式 */
.uploadBox {
  width: 100px;
  height: 100px;
  border: 1px solid #cccccc;
  /* border-radius: 4px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #808080;
}
.uploadBox > i {
  font-size: 16px;
  margin-top: 27px;
  margin-bottom: 11px;
  line-height: 16px;
}
.uploadBox > span {
  font-size: 12px !important;
}
/* 设置附件图片样式 */
.uploadImg {
  display: flex;
}
.uploadImg > div {
  width: 100px;
  height: 100px;
  /* margin-right: 6px; */
  margin-right: var(--imgRight);
  /* border-radius: 4px; */
  position: relative;
  border: 1px solid #cccccc;
}
.uploadImg > div > .video-class {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 4px; */
}
.uploadImg > div > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadImg > div > img {
  width: 100%;
  height: 100%;
  /* border-radius: 4px; */
}
.uploadImg > div > div > i,
.uploadImg > div > div > img {
  color: white;
  font-size: 24px;
  display: none;
  z-index: 2;
}
.uploadImg > div > div > i {
  margin-left: 10px;
}
.uploadImg > .noInfo:hover {
  border: 0;
}
.uploadImg > div:hover i,
.uploadImg > div:hover img,
.uploadImg > div:hover .mask {
  display: block;
}
.uploadImg > div > div > .mask {
  background: rgba(17, 17, 17, 0.5);
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  top: 0;
  /* border-radius: 4px; */
  display: none;
}
</style>

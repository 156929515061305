/**
 * 消息模块
 * - 通知
 * - 公告
 */

const noticeRoutes = [
  {
    path: '/bulletinlist',
    name: 'BulletinList',
    meta: {
      title: '公告列表',
      navIndex: 'notice',
      path: '/bulletinlist',
      needsToken: true,
    },
    component: () => import('@/pages/notice/bulletin/BulletinList'),
  },
  {
    path: '/bulletindetail/:id',
    name: 'BulletinDetail',
    meta: {
      title: '公告详情',
      navIndex: 'notice',
      path: '/bulletindetail',
      needsToken: true,
    },
    component: () => import('@/pages/notice/bulletin/BulletinDetail'),
  },
  {
    path: '/notice/setting',
    name: 'NoticeSetting',
    meta: {
      title: '通知设置',
      navIndex: 'notice',
      path: '/notice/setting',
      needsToken: true,
    },
    component: () => import('@/pages/notice/setting/index'),
  },
  {
    path: '/notice/shop',
    name: 'ShopManagementList',
    meta: {
      title: '店铺经营',
      navIndex: 'notice',
      path: '/notice/shop',
      needsToken: true,
    },
    component: () =>
      import('@/pages/notice/shop-management/ShopManagementList'),
  },
  {
    path: '/notice/shop/detail/:id',
    name: 'ShopInformDetail',
    meta: {
      title: '店铺经营查看详情',
      navIndex: 'notice',
      path: '/notice/shop',
      needsToken: true,
    },
    component: () => import('@/pages/notice/shop-management/ShopInformDetail'),
  },
  {
    path: '/notice/trade',
    name: 'TradeInformList',
    meta: {
      title: '交易通知',
      navIndex: 'notice',
      path: '/notice/trade',
      needsToken: true,
    },
    component: () => import('@/pages/notice/trade/TradeInformList'),
  },
  {
    path: '/notice/trade/detail/:id',
    name: 'TradeInformDetail',
    meta: {
      title: '交易通知查看详情',
      navIndex: 'notice',
      path: '/notice/trade',
      needsToken: true,
    },
    component: () => import('@/pages/notice/trade/TradeInformDetail'),
  },
  {
    path: '/notice/system',
    name: 'SystemInformList',
    meta: {
      title: '系统通知',
      navIndex: 'notice',
      path: '/notice/system',
      needsToken: true,
    },
    component: () => import('@/pages/notice/system/SystemInformList'),
  },
  {
    path: '/notice/system/detail/:id',
    name: 'SystemInformDetail',
    meta: {
      title: '系统通知查看详情',
      navIndex: 'notice',
      path: '/notice/system',
      needsToken: true,
    },
    component: () => import('@/pages/notice/system/SystemInformDetail'),
  },
];

export default noticeRoutes;

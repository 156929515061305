<template>
  <div
    :class="['xm-step-icon', statusClass]"
    :style="{
      width: diameter,
      height: diameter,
      lineHeight: diameter,
    }"
  >
    <span v-if="status != 'finish'">{{ num }}</span>
    <img
      :width="diameter"
      :height="diameter"
      v-else
      src="@/assets/images/stepfirst.png"
    />
  </div>
</template>

<script>
export default {
  name: 'XmStepIcon',
  props: {
    num: {
      // 圆形的内容
      type: [String, Number],
      default: '1',
    },
    diameter: {
      // 圆形直径
      type: String,
      default: '24px',
    },
    // 当前步骤状态
    // finish, process, wait
    status: {
      type: String,
      default: 'process',
    },
  },
  computed: {
    statusClass() {
      let className = '';
      switch (this.status) {
        case 'finish':
          className = 'is-finish';
          break;
        case 'process':
          className = 'is-process';
          break;
        case 'wait':
          className = 'is-wait';
          break;

        default:
          break;
      }
      return className;
    },
  },
};
</script>

<style lang="scss" scoped>
$highlight-color: #ff7802;

.xm-step-icon {
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: $highlight-color;

  span {
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
  }

  &.is-finish {
    // opacity: 0.5;
    background-color: #ffd6b2;
  }

  &.is-process {
    background-color: $highlight-color;
  }

  &.is-wait {
    background-color: #999;
  }
}
</style>

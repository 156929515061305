<!-- 下拉复选 -->
<template>
  <div class="multiple-choice">
    <el-select
      slot="reference"
      clearable
      v-model="selectData"
      placeholder="请选择"
      @change="multipleChange"
      multiple
      popper-class="xm-multiple-choice"
    >
      <div class="option-model">
        <el-option style="display: none" value=""></el-option>

        <el-checkbox
          v-model="checkAll"
          @change="changeAllSelect"
          id="checkbox-style"
          :class="checkAll ? 'check-all-selected-style' : ''"
        >
          <div>全部</div>
        </el-checkbox>
        <el-checkbox-group v-model="selectData">
          <div v-for="item in searchOption()" :key="item.value">
            <el-option
              :label="item[optionLabelKey]"
              :value="item[optionValKey]"
            >
              <el-checkbox
                :label="item[optionValKey]"
                @change="checkOption(item)"
              >
                {{ item[optionLabelKey] }}
              </el-checkbox>
            </el-option>
          </div>
        </el-checkbox-group>
      </div>
    </el-select>
    <div class="tags" v-show="selectData.length !== 0 && selectShow">
      <div class="arrow"></div>
      <div class="tags-item" v-for="(item, index) in selectData" :key="index">
        <span>{{ getLabel(item) }}</span>
        <span
          ><i class="el-tag__close el-icon-close" @click="deselect(index)"></i
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
// 使用例子：src\pages\goods\group\component\BatchEditorSpecInfo.vue
export default {
  data() {
    return {
      selectData: [], //被选中的列表数据
      selectShow: true,
      checkAll: false, //控制全选
    };
  },
  props: {
    // 可以选的下拉列表
    // name 和 id  可以变，由optionLabelKey 和 optionValKey 控制
    optionsData: {
      type: Array,
      default: () => [
        // { name: '测试1', id: '1' },
        // { name: '测试2', id: '2' },
        // { name: '测试3', id: '3' },
        // { name: '测试4', id: '4' },
        // { name: '测试5', id: '5' },
        // { name: '测试6', id: '6' },
      ],
    },
    // 采用哪个字段为label值
    // 默认为 name
    optionLabelKey: {
      type: String,
      default: 'name',
    },
    // 采用哪个字段为value值
    // 默认为 id
    optionValKey: {
      type: String,
      default: 'id',
    },
    // 选中后需要存储对应的key
    selectKey: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleChangeAllSelect() {
      this.checkAll = !this.checkAll;
      this.changeAllSelect(this.checkAll);
    },
    // 点击全选
    changeAllSelect(checkbox, $event) {
      this.checkAll = checkbox;

      if (this.checkAll) {
        // 全选
        this.optionsData?.forEach(item => {
          let idx = this.selectData?.findIndex(
            item2 => item2 == item?.[this.optionValKey]
          );
          if (idx === -1) {
            this.selectData.push(item?.[this.optionValKey]);
          }
        });
      } else {
        // 取消全选
        this.selectData = [];
      }
      this.multipleChange();
      // 阻止冒泡事件
      console.log($event, '$event');

      $event?.stopPropagation();
    },
    // 获取自定义下拉选项
    searchOption() {
      return this.optionsData;
    },
    multipleChange() {
      if (this.selectData?.length === this.optionsData?.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      this.$emit('multiple-change', this.getObj(), this.selectKey);
    },
    // 点击下拉选项中复选框
    checkOption(checkInfo) {
      let idx = this.selectData?.indexOf(checkInfo[this.optionValKey]);
      // 如果idx 为-1 则被选列表 selectData 中没有这个值，则加入
      // 反之，删除idx 位置中的值
      idx === -1
        ? this.selectData.push(checkInfo[this.optionValKey])
        : this.selectData.splice(idx, 1);

      if (this.selectData?.length === this.optionsData?.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      this.$emit('multiple-change', this.getObj(), this.selectKey);
    },
    //获取完整的被选中列表对象
    getObj() {
      let arr = this.optionsData.reduce((box, item) => {
        // 筛选出被选中的 optionValKey ，加入box中
        if (this.selectData.some(value => value == item[this.optionValKey])) {
          box?.push(item);
        }

        return box;
      }, []);
      return arr;
    },
    // 悬浮弹框的
    //根据 optionValKey 值获取对应 optionLabelKey 值
    getLabel(value) {
      let infoVal =
        this.optionsData?.filter(
          item => item[this.optionValKey] === value
        )?.[0] || {};
      if (infoVal?.[this.optionLabelKey]) {
        return infoVal?.[this.optionLabelKey];
      }
      return '';
    },
    // 悬浮弹框点击删除图标
    deselect(index) {
      this.selectData.splice(index, 1);
      if (this.selectData?.length === this.optionsData?.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      this.$emit('del-tags', this.getObj(), this.selectKey);
    },
    // 数据重置
    reData() {
      this.selectData = [];
      this.$emit('multiple-change', this.getObj(), this.selectKey);
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-choice {
  width: 178px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  ::v-deep .el-input__inner {
    width: 178px;
  }
}
.multiple-choice:hover .tags {
  visibility: visible;
  opacity: 1;
}

.tabs-box {
  width: fit-content;
  // margin-top: 10px;
  background: #ffffff;
  height: 40px;
  padding: 8px 10px;
}
.tags {
  font-size: 12px;
  font-weight: 400;
  width: fit-content;
  margin-top: 10px;
  background: #ffffff;
  padding: 8px 10px 0 10px;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s ease-in;
  position: absolute;
  z-index: 1000;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  .arrow {
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    // background: black;
    position: absolute;
    top: -12px;
    left: 16px;
  }

  .tags-item {
    display: inline-block;
    padding: 2px 10px 0 10px;
    background: #f4f4f5;
    margin-right: 10px;
    margin-bottom: 10px;
    color: #909399;
    i {
      text-align: center;
      position: relative;
      cursor: pointer;
      font-size: 12px;
      vertical-align: middle;
      top: -1px;
      right: -5px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }
}
.multiple-choice ::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
</style>
<style lang="scss">
// 特殊的样式
.xm-multiple-choice {
  // 图标样式
  .el-input__prefix {
    line-height: 40px;
    margin-top: 4px;
  }

  // 下拉选项内容
  .el-select-dropdown__item {
    text-align: left !important;
  }
  #checkbox-style {
    padding: 10px 20px;
    width: -webkit-fill-available;
    cursor: pointer;
    &:hover {
      background-color: #fff2e6 !important;
    }
  }
  .check-all-selected-style {
    background-color: #fff2e6 !important;
  }
}
</style>

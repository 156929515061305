<template>
  <div class="protocol-box">
    <!-- 遮罩层 -->
    <div class="mask"></div>
    <!-- 注册协议 -->
    <div
      class="box-container"
      :style="{ height: hasFooter ? '600px' : '526px' }"
    >
      <header class="box-title">
        <span>{{ title }}</span>
        <img @click="handleClickClose" :src="closeIcon" alt="X" />
      </header>
      <main class="box-main">
        <section class="box-content">
          <slot></slot>
        </section>
      </main>
      <footer class="box-footer" v-if="hasFooter">
        <button
          @click="handleClickBtn"
          class="footer-btn"
          :disabled="seconds >= 0"
        >
          <span v-if="seconds >= 0">（{{ seconds }}S）</span>我已阅读并同意
        </button>
        <span id="time-str">{{ currentTime }}</span>
      </footer>
    </div>
  </div>
</template>

<script>
import { showCurrentTime } from '@/utils/timeFormat.js';

export default {
  props: {
    title: {
      type: String,
      default: '细目协议默认标题',
    },
    hasFooter: {
      // 弹窗底部是否有同意按钮
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTime: '',
      seconds: 3,
      closeIcon: require('@/assets/images/close_icon.png'),
    };
  },
  methods: {
    // 倒计时
    countdown() {
      if (this.seconds < 0) return;

      const timer = setInterval(() => {
        if (this.seconds >= 0) {
          --this.seconds;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    handleClickBtn() {
      this.$emit('agree');
    },
    handleClickClose() {
      this.$emit('close');
    },
  },
  created() {
    this.currentTime = showCurrentTime(); // 获取当前时间
  },
  mounted() {
    if (this.hasFooter) this.countdown(); // 开启倒计时
  },
};
</script>

<style lang="scss" scoped>
.protocol-box {
  * {
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
  }
  /* 遮罩层 */
  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #111;
    opacity: 0.4;
    z-index: 88;
  }

  /* 注册协议、隐私政策 */
  .box-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 860px;
    height: 600px;
    z-index: 99;
    background-color: #fff;
    border-radius: 4px;

    padding-bottom: 16px;

    .box-title,
    .box-footer {
      padding: 0 24px;
      height: 56px;
    }

    .box-title {
      display: flex;
      justify-content: space-between;
      height: 56px;
      line-height: 56px;
      span {
        font-size: 16px;
        color: #111;
      }
      img {
        margin: 18px 4px 0 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .box-main {
      height: 470px;
      border-top: 1px solid #f0f0f0;
      padding: 25px 22px 23px 22px;

      .box-content {
        height: 100%;
        background-color: #f0f0f0;
        overflow: auto;
      }
    }

    .box-footer {
      display: flex;
      align-items: center;
      height: 56px;

      position: relative;

      button {
        width: 130px;
        height: 40px;

        background-color: #ff7802;
        color: #fff;

        border-radius: 4px 4px 4px 4px;
        cursor: pointer;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        &:disabled {
          width: 179px;
          background: rgba(255, 120, 2, 0.5);
        }
      }

      #time-str {
        position: absolute;
        right: 26px;
      }
    }
  }
}
</style>

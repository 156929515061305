<template>
  <div class="statistical_time panel_public_class">
    <div class="statistical_time_title">
      <span class="panel_public_title">优惠券数据111</span>
      <!-- <span class="statistical_time_title_time">{{ currentDate }}</span> -->
      <el-date-picker
        class="statistical_time_select_time"
        v-model="searchTimer"
        type="daterange"
        size="small"
        start-placeholder="开始日期"
        end-placeholder="截止日期"
      ></el-date-picker>
      <div class="statistical_time_title_btn" @click="getData">
        <span>搜索</span>
      </div>
      <!-- <div
                class="statistical_time_title_btn statistical_time_title_btn_reset"
                @click="resetTimeFun"
            >
                <span>重置</span>
            </div> -->
    </div>
    <div class="statistical_time_echarts_box">
      <div
        ref="CouponEchart"
        id="coupon_echarts"
        style="width: 100%; height: 400px"
      ></div>
    </div>
  </div>
</template>

<script>
import { defaultTo } from 'lodash';
import dayjs from 'dayjs';
// import * as echarts from 'echarts';
import CouponController from './controller/CouponController';

export default {
  name: 'Coupon',
  data() {
    return {
      searchTimer: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    initEchart(data) {
      this.$nextTick(() =>
        console.log(document.querySelector('#coupon_echarts').offsetWidth)
      );
      if (!data.length) return;
      const el = this.$refs.CouponEchart;
      const chart = echarts.init(el, null);

      const labels = data.map(item => item.times);

      const receive = data.map(item => item.receive);
      const use = data.map(item => item.use);
      const deduction = data.map(item => item.deduction);
      const userAll = data.map(item => item.userAll);

      const values = [receive, use, deduction, userAll];

      CouponController.setLabels(labels);
      CouponController.setData(values);

      const options = CouponController.getOptions();
      chart.setOption(options);
    },
    getData() {
      const api = 'platformReport.getcoupon';

      const options = {
        start:
          this.searchTimer[0] &&
          dayjs(this.searchTimer[0]).format('YYYY-MM-DD'),
        end:
          this.searchTimer[1] &&
          dayjs(this.searchTimer[1]).format('YYYY-MM-DD'),
        MUTE_WARNING: 1,
      };

      xmJson(
        api,
        options,
        res => {
          // console.log(res);
          if (res.errcode == 0) {
            const data = defaultTo(res.data, []);
            this.initEchart(data);
          }
        },
        err => {
          console.error(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.toutal_money {
  flex: auto;
  font-size: 12px;
  color: #666;
  text-align: right;
}

.panel_public_class {
  background: #ffffff;
  border-radius: 8px;
}
.panel_public_title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.statistical_time {
  width: 100%;
  padding: 17px 20px 20px 20px;
}
.statistical_time_title {
  width: 100%;
  display: flex;
  align-items: center;
}
.statistical_time_title_time {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  padding: 0 20px;
}
.statistical_time_title_btn {
  width: 66px;
  height: 28px;
  background: #3271ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.statistical_time_select_time {
  width: 230px;
  margin-right: 20px;
}
.statistical_time_type_box {
  width: 100%;
  display: flex;
  margin-top: 26px;
}
.statistical_time_type {
  width: 260px;
  height: 130px;
  padding: 30px;
  cursor: pointer;
}
.statistical_time_type_num {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  margin-bottom: 10px;
}
.statistical_time_type_title {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  min-height: 19px;
}
.statistical_time_type_title img {
  width: 12px;
  height: 12px;
  margin-left: 8px;
  flex-shrink: 0;
}
.statistical_time_echarts_box {
  width: 100%;
  padding-top: 10px;
}
.statistical_time_type {
  // background: url("./images/statistical_time/statistical_time_type.png") no-repeat center;
  background-size: cover;
  color: #333333;
}
.statistical_time_type_active {
  // background: url("./images/statistical_time/statistical_time_type_active.png") no-repeat center;
  background-size: cover;
  color: #3271ff;
  box-shadow: none;
  border: 1px solid #3271ff;
}
.statistical_time_title_btn_reset {
  width: 66px;
  height: 28px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cccccc;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.statistical_time_type {
  height: 150px;
  padding: 30px;
  cursor: pointer;
  width: 19%;
  // border: 1px solid #333333;
  margin-right: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
}
.compare_last_week {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.compare_last_week > span:first-child {
  font-size: 12px;
  margin-bottom: 10px;
  color: #666666;
}
.compare_last_week > span:last-child {
  font-size: 12px;
  color: red;
}
#coupon_echarts > div:nth-child(1) {
  width: 500px !important;
  background-color: palegreen;
}
#coupon_echarts > div:nth-child(1) > canvas {
  width: 500px !important;
}
</style>

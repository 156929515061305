<template>
  <div class="xm-video">
    <div class="video-top-bar" v-if="showTopBar">
      <i @click="handleClickClose" class="box-close-icon el-icon-close"></i>
    </div>
    <video
      :id="'my-xm-video-' + id"
      ref="myXmVideo"
      class="video-js vjs-default-skin xm-video-container"
      controls
      :poster="getVideoPoster(src)"
    >
      <source :src="src" type="video/mp4" />
    </video>
  </div>
</template>

<script>
// import 'video.js/dist/video-js.css';
export default {
  name: 'XmVideo',
  props: {
    // 视频链接
    src: {
      type: String,
      default: '',
    },
    // 播放器宽度
    width: {
      type: String,
      default: '100%',
    },
    // 播放器高度
    height: {
      type: String,
      default: '500px',
    },
    // 是否自动播放
    autoplay: {
      type: Boolean,
      default: false,
    },
    // 当页面有多个 videojs 实例时，其 id 应当互不相同
    id: {
      type: String,
      default: 'default',
    },
    // 是否展示顶部的 banner 栏（包含关闭按钮）
    showTopBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      player: null, // 播放器实例
    };
  },
  watch: {
    src() {
      this.initPlayer();
    },
  },
  methods: {
    handleClickClose() {
      this.$emit('close');
    },
    // 初始化播放器组件实例
    initPlayer() {
      this.player = videojs('my-xm-video-' + this.id, {
        controls: true,
        preload: 'auto',
        width: this.width,
        height: this.height,
        autoplay: this.autoplay,
        controlBar: {
          pictureInPictureToggle: false, // 播放器控制栏是否有画中画按钮
        },
        language: 'zh-cn',
        languages: {
          // 播放器中文标签
          'zh-cn': {
            Play: '播放',
            Pause: '暂停',
            'Current Time': '当前时间',
            Duration: '时长',
            'Remaining Time': '剩余时间',
            Fullscreen: '全屏',
            Mute: '静音',
            Unmute: '取消静音',
            'Play Video': '播放视频',
          },
        },
      });
    },
  },
  mounted() {
    if (!this.player) {
      this.initPlayer();
    }
  },
  beforeDestroy() {
    this.player?.dispose();
  },
};
</script>

<style lang="scss" scoped>
.xm-video {
  border-radius: 4px;
  cursor: default;

  ::v-deep .vjs-paused .vjs-big-play-button,
  .vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
  }

  ::v-deep .vjs-tech {
    cursor: default;
  }

  .video-top-bar {
    display: none;
    cursor: default;

    width: 100%;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;

    position: absolute;
    top: 0;
    z-index: 100;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    background: rgba(0, 0, 0, 0.75);

    .box-close-icon {
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }

  &:hover {
    .video-top-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .xm-video-container {
    width: 100%;
    border-radius: 4px;
    // z-index: 2;

    // 视频开头的播放图标
    ::v-deep .vjs-big-play-button {
      height: 83px;
      width: 83px;
      line-height: 83px;
      font-size: 30px;

      -webkit-border-radius: 83px;
      -moz-border-radius: 83px;
      border-radius: 83px;
      border-width: 1px;

      background-color: #737373;

      // 垂直水平居中
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      // z-index: 3;

      /* 中间的播放箭头 */
      .vjs-icon-placeholder {
        font-size: 50px;
      }
    }

    /* 加载圆圈 */
    ::v-deep .vjs-loading-spinner {
      width: 2em;
      height: 2em;

      margin-top: -1em;
      margin-left: -1.5em;

      border-radius: 1em;
      font-size: 2.5em;
    }

    ::v-deep .vjs-play-control.vjs-ended .vjs-icon-placeholder:before,
    .vjs-icon-replay:before {
      content: '\f101';
    }

    ::v-deep .vjs-play-progress,
    ::v-deep .vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
      background: #ff7802;
    }

    ::v-deep .vjs-control-bar {
      background-color: transparent;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
</style>

<template>
  <div class="top-list">
    <!-- 商品分类搜索 -->
    <div class="search-top">
      <div class="search-top-label">商品分类</div>
      <radio-select-category
        placeholder="选择分类"
        class="search-category"
        @change="getList"
        v-model="category"
      ></radio-select-category>
      <CurrencyChose
        :pageCurrency="pageCurrency"
        @change-currency="changeCurrency"
      ></CurrencyChose>
    </div>

    <!-- 展示数据 -->
    <div class="search-content" :key="scorllKey">
      <!-- 分销商采购额TOP榜 -->
      <div class="list-box">
        <div class="box-title">
          <div>分销商采购额TOP榜</div>
          <img src="@/assets/images/fire_icon.svg" alt="" />
        </div>
        <div class="box-content">
          <div class="table-title">
            <div class="list-title">分销商ID</div>
            <div class="list-title">采购额({{ currencySign }})</div>
          </div>
          <div
            class="scroll"
            v-if="tradeRank.length != 0"
            :style="{ height: tradeRankHeight }"
          >
            <vue-seamless-scroll
              :data="tradeRank"
              :class-option="optiontTradeRank"
            >
              <ul>
                <li
                  class="list-detail"
                  v-for="(item, index) in tradeRank"
                  :key="index"
                >
                  <div class="detail-id">
                    <div
                      class="rank"
                      :class="
                        index + 1 == 1
                          ? 'top-one'
                          : index + 1 == 2
                          ? 'top-two'
                          : index + 1 == 3
                          ? 'top-three'
                          : ''
                      "
                    >
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div class="width129" :title="item.user_id">
                      {{ item.user_id }}
                    </div>
                  </div>
                  <div class="detail-num">{{ item.total_money }}</div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
          <div class="no-data" v-else>暂无数据</div>
        </div>
      </div>

      <!-- 供应商销量TOP榜 -->
      <div class="list-box">
        <div class="box-title">
          供应商销量TOP榜
          <img src="@/assets/images/fire_icon.svg" alt="" />
        </div>
        <div class="box-content">
          <div class="table-title">
            <div class="list-title">供应商店铺名</div>
            <div class="list-title">销量</div>
          </div>
          <div
            class="scroll"
            v-if="agentSalesVolume.length != 0"
            :style="{ height: agentSalesVolumeHeight }"
          >
            <vue-seamless-scroll
              :data="agentSalesVolume"
              :class-option="optionAgentSalesVolume"
            >
              <ul>
                <li
                  class="list-detail"
                  v-for="(item, index) in agentSalesVolume"
                  :key="index"
                >
                  <div class="detail-id">
                    <div
                      class="rank"
                      :class="
                        index + 1 == 1
                          ? 'top-one'
                          : index + 1 == 2
                          ? 'top-two'
                          : index + 1 == 3
                          ? 'top-three'
                          : ''
                      "
                    >
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div class="width129" :title="item.supplier_id">
                      {{ item.supplier_id }}
                    </div>
                  </div>
                  <div class="detail-num">{{ item.num }}</div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
          <div class="no-data" v-else>暂无数据</div>
        </div>
      </div>

      <!-- 供应商销售额TOP榜 -->
      <div class="list-box">
        <div class="box-title">
          供应商销售额TOP榜
          <img src="@/assets/images/fire_icon.svg" alt="" />
        </div>
        <div class="box-content">
          <div class="table-title">
            <div class="list-title">供应商店铺名</div>
            <div class="list-title">销售额({{ currencySign }})</div>
          </div>
          <div
            class="scroll"
            v-if="agentSalesMoney.length != 0"
            :style="{ height: agentSalesMoneyHeight }"
          >
            <vue-seamless-scroll
              :data="agentSalesMoney"
              :class-option="optionAgentSalesMoney"
            >
              <ul>
                <li
                  class="list-detail"
                  v-for="(item, index) in agentSalesMoney"
                  :key="index"
                >
                  <div class="detail-id">
                    <div
                      class="rank"
                      :class="
                        index + 1 == 1
                          ? 'top-one'
                          : index + 1 == 2
                          ? 'top-two'
                          : index + 1 == 3
                          ? 'top-three'
                          : ''
                      "
                    >
                      <span>{{ index + 1 }}</span>
                    </div>
                    <div class="width129" :title="item.supplier_id">
                      {{ item.supplier_id }}
                    </div>
                  </div>
                  <div class="detail-num">{{ item.num }}</div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
          <div class="no-data" v-else>暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import vueSeamlessScroll from 'vue-seamless-scroll';
import CurrencyChose from './CurrencyChose.vue';

export default {
  name: 'TopList',
  components: {
    vueSeamlessScroll,
    CurrencyChose,
  },
  props: {
    // 当前页面币种, 1 人民币 2 美元
    pageCurrency: {
      type: String,
      default: '1',
    },
    // top榜数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      boxCurrencyType: '', // 当前板块的币种
      tradeRank: [], // 分销商采购额TOP榜
      agentSalesVolume: [], // 供应商销量TOP榜
      agentSalesMoney: [], // 供应商销售额TOP榜
      category: [], // 搜索类目
      tradeRankHeight: '240px', // 分销商采购额TOP榜高度
      agentSalesVolumeHeight: '240px', // 供应商销量TOP榜高度
      agentSalesMoneyHeight: '240px', // 供应商销售额TOP榜高度
      scorllKey: 0, // 重新渲染组件
    };
  },
  computed: {
    optiontTradeRank() {
      return {
        step: this.tradeRank.length < 5 ? 0 : 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.tradeRank.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    optionAgentSalesVolume() {
      return {
        step: this.agentSalesVolume.length < 5 ? 0 : 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.agentSalesVolume.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    optionAgentSalesMoney() {
      return {
        step: this.agentSalesMoney.length < 5 ? 0 : 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.agentSalesMoney.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    // 货币符号
    currencySign() {
      switch (this.pageCurrency) {
        case '1':
          return '￥';
        case '2':
          return '$';
      }
    },
  },
  watch: {
    detail: {
      immediate: true,
      deep: true,
      handler(val) {
        this.scorllKey++;
        this.tradeRank = JSON.parse(JSON.stringify(val.tradeRank));
        this.agentSalesVolume = JSON.parse(
          JSON.stringify(val.agentSalesVolume)
        );
        this.agentSalesMoney = JSON.parse(JSON.stringify(val.agentSalesMoney));

        this.tradeRankHeight =
          this.tradeRank.length > 5
            ? '240px'
            : 48 * this.tradeRank.length + 'px';
        this.agentSalesVolumeHeight =
          this.agentSalesVolume.length > 5
            ? '240px'
            : 48 * this.agentSalesVolume.length + 'px';
        this.agentSalesMoneyHeight =
          this.agentSalesMoney.length > 5
            ? '240px'
            : 48 * this.agentSalesMoney.length + 'px';
      },
    },
  },
  methods: {
    // 搜索不同类目的排行榜
    getList() {
      this.$emit('change-category', this.category);
    },
    // 切换币种
    changeCurrency(type) {
      this.$emit('change-currency', type);
    },
  },
};
</script>

<style lang="scss" scoped>
.width129 {
  max-width: 129px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.top-list {
  padding: 20px;
  background-color: #fff;
  .search-top {
    display: flex;
    align-items: center;

    .search-top-label {
      margin-right: 10px;
    }

    ::v-deep .search-category {
      margin-right: 20px;

      .el-input__inner {
        padding-left: 12px;
        width: 300px;
        color: #808080;
        border: 1px solid #ccc;
        border-radius: 0;
      }
    }
  }

  .search-content {
    display: flex;
    justify-content: space-between;

    .list-box {
      .box-title {
        display: flex;
        align-items: center;
        margin: 20px 0 10px;
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        color: #111;

        img {
          margin-left: 4px;
          width: 16px;
          height: 16px;
        }
      }

      .box-content {
        width: 370px;
        height: 288px;

        .scroll {
          overflow: hidden;
        }

        div:nth-child(2) {
          // height: 240px;
          // overflow: hidden;
        }
        .table-title {
          display: flex;
          height: 48px;
          line-height: 48px;
          color: #333;
          font-size: 14px;
          background-color: #fff1e5;

          .list-title {
            width: 185px;
            text-align: center;
          }
        }

        .no-data {
          display: flex;
          justify-content: center;
          margin-top: 80px;
          color: #808080;
          font-size: 14px;
        }

        .list-detail {
          display: flex;
          color: #808080;
          font-size: 12px;
          // height: 240px;

          > div {
            width: 185px;
            height: 48px;
            line-height: 48px;
            text-align: center;
          }

          .detail-id {
            display: flex;
            align-items: center;
            padding-left: 20px;

            .rank {
              margin-right: 10px;
              padding: 0 2px;
              width: 18px;
              height: 18px;
              line-height: 18px;
            }

            .top-one,
            .top-two,
            .top-three {
              color: #fff;
              border-radius: 2px;
            }

            .top-one {
              background-image: url('~@/assets/images/top1.svg');
              span {
                display: none;
              }
            }

            .top-two {
              background-image: url('~@/assets/images/top2.svg');
              span {
                display: none;
              }
            }

            .top-three {
              background-image: url('~@/assets/images/top3.svg');
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  ::v-deep .el-cascader .el-input .el-input__inner:focus,
  ::v-deep .el-cascader .el-input.is-focus .el-input__inner {
    border-color: #ff7802;
  }
}
</style>

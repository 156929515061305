<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>订单</div>
        <span @click="goUrl">查看全部</span>
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>今日新订单</div>
          <span :class="[{ 'have-num': detail.todayOrder != 0 }]">{{
            detail.todayOrder
          }}</span>
        </div>
        <div class="label-list">
          <div>
            申请取消
            <el-popover
              placement="bottom-start"
              width="156"
              trigger="hover"
              content="今日申请取消的订单总数"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.cancelOrder != 0 }]">{{
            detail.cancelOrder
          }}</span>
        </div>
        <div class="label-list">
          <div>好评率</div>
          <span :class="[{ 'have-num': detail.wellRadio != 0.0 }]"
            >{{ detail.wellRadio }}%</span
          >
        </div>
        <div class="label-list">
          <div>待发货</div>
          <span :class="[{ 'have-num': detail.waitingToBeSent != 0 }]">{{
            detail.waitingToBeSent
          }}</span>
        </div>
        <!-- <div class="label-list">
          <div>
            评价率
            <el-popover
              placement="bottom-start"
              width="210"
              trigger="hover"
              content="已评价订单总数/已完成的订单总数"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.evaRadio != 0.0 }]"
            >{{ detail.evaRadio }}%</span
          >
        </div> -->
        <div class="label-list">
          <div>待评价(近30天)</div>
          <span :class="[{ 'have-num': detail.evaOrder != 0 }]">{{
            detail.evaOrder
          }}</span>
        </div>
        <div class="label-list">
          <div>售后待处理</div>
          <span :class="[{ 'have-num': detail.afterOrder != 0 }]">{{
            detail.afterOrder
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultTo } from 'lodash';

export default {
  name: 'OrderDetails',
  props: {
    // 订单数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // detail: {},
    };
  },
  methods: {
    goUrl() {
      this.$router.push('/orderList');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

import SelectCategory from './SelectCategory.vue';
import CategorySearch from './CategorySearch.vue';
import RadioSelectCategory from './RadioSelectCategory.vue';
import SelectArea from './SelectArea.vue';
import UnSelectArea from './UnSelectArea.vue';



export default {
  SelectCategory,
  SelectArea,
  UnSelectArea,
  RadioSelectCategory,
  CategorySearch
};

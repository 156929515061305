<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>纠纷</div>
        <span @click="goUrl">查看全部</span>
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>等待处理</div>
          <span :class="[{ 'have-num': detail.wait != 0 }]">{{
            detail.wait
          }}</span>
        </div>
        <div class="label-list">
          <div>等待供应商退款</div>
          <span :class="[{ 'have-num': detail.going != 0 }]">{{
            detail.going
          }}</span>
        </div>
        <!-- <div class="label-list">
          <div>纠纷中</div>
          <span :class="[{'have-num': detail.going != 0 }]">{{ detail.going }}</span>
        </div> -->
        <div class="label-list">
          <div>等待分销商退货</div>
          <span :class="[{ 'have-num': detail.complete != 0 }]">{{
            detail.complete
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultTo } from 'lodash';
export default {
  name: 'DisputeDetails',
  props: {
    // 纠纷数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // detail: {},
    };
  },
  methods: {
    goUrl() {
      this.$router.push('/afterSalesList');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

<template>
  <div class="addressinsure">
    <el-select
      ref="selectinput"
      v-model="countvalue"
      placeholder="请选择"
      multiple
      :popper-append-to-body="false"
      :style="{ width: selectWidth }"
      @change="changeValue"
    >
      <div>
        <div class="topbar">
          <div>大洲</div>
          <div>国家</div>
          <div>已选择</div>
        </div>
        <div class="optionmodel">
          <el-option value="" style="display: none"></el-option>
          <div class="optionContent">
            <div>
              <!-- 大洲 -->
              <div
                v-for="item in continents_list"
                :key="item.id"
                class="contente-left height-style margintop"
              >
                <el-checkbox
                  v-model="item.checkbox"
                  @change="continentChange(item)"
                ></el-checkbox>
                <div
                  class="con-right"
                  @click="getCountryList(item.id, item)"
                  :class="continentId == item.id ? 'activeColor' : ''"
                >
                  <div>
                    {{ item.cname }}
                  </div>
                  <div
                    class="iconfont left-incon-active"
                    v-if="continentId == item.id"
                  >
                    &#xe639;
                  </div>
                  <div class="iconfont left-incon" v-else>&#xe632;</div>
                </div>
              </div>
            </div>
            <!-- 国家 -->
            <div class="count-content">
              <div
                v-for="item in country_list"
                :key="item.id"
                class="contente-left height-style margintop"
                v-show="item.checkbox == false"
              >
                <el-checkbox
                  v-model="item.checkbox"
                  @change="countryChange(item)"
                ></el-checkbox>
                <div
                  class="con-right"
                  :title="isCheckOverflow(item.cname, 124) ? item.cname : ''"
                >
                  <div>
                    {{ item.cname }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 已选择 -->
            <div class="selectModel">
              <div v-for="continent in selectArea" :key="continent.id" class="">
                <div class="contente-left height-style margintop">
                  <div class="con-right">
                    <div :class="continent.isShow == true ? 'icon-active' : ''">
                      {{ continent.cname }}
                    </div>
                    <div
                      class="iconfont left-incon-active"
                      v-if="continent.isShow == true"
                      @mousedown="selectStyle(continent)"
                    >
                      &#xe639;
                    </div>
                    <div
                      class="iconfont left-incon"
                      @mousedown="selectStyle(continent)"
                      v-else
                    >
                      &#xe632;
                    </div>
                  </div>
                </div>
                <div class="contente-left">
                  <ul v-show="continent.isShow">
                    <li v-for="item in continent.info" :key="item.id">
                      <div
                        class="con-right shadow-style"
                        @mouseenter="isOpenStyle(item)"
                        @mouseleave="isCloseStyle(item)"
                      >
                        <div
                          class="curse-style"
                          :title="
                            isCheckOverflow(item.cname, 124) ? item.cname : ''
                          "
                        >
                          {{ item.cname ? item.cname : item.name }}
                        </div>
                        <div v-show="item.id == isCurrentid">
                          <i
                            class="box-close-icon el-icon-close"
                            @click="deleteCountry(continent, item)"
                          ></i>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="opera-model">
          <div @click="confirmSelect">确定</div>
          <div @click="cancelSelect">取消</div>
        </div>
      </div>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'XmAddressMultiSelect',
  data() {
    return {
      continents_list: [], // 大洲列表
      country_list: [], //当前展示的国家列表
      continentId: '', //保存当前洲悬浮id
      countvalue: [], // 展示的数据
      currentContinent: [], //展开当前国家对应的洲
      isCurrentid: '', //已选择栏保存当前悬浮id
      selectArea: [], //已选择数据
      countIds: [], //已选择国家ID
    };
  },
  mounted() {
    this.selectArea = JSON.parse(JSON.stringify(this.initSelectArea));
    this.countIds = JSON.parse(JSON.stringify(this.initCountIds));
    this.getcontinentsList();
  },
  props: {
    //已选择国家ID
    initCountIds: {
      type: Array,
      default: [],
    },
    // 是否隐藏“全球”选项
    hideAll: {
      type: Boolean,
      default: false,
    },
    initSelectArea: {
      //已选择
      type: Array,
      default: [],
    },
    selectWidth: {
      // 选择器的宽度
      type: String,
      default: '300px',
    },
    // 过滤掉中国，仅在发布商品使用(值为2过滤掉中国)
    filterType: {
      type: String,
      default: '',
    },
  },
  methods: {
    //展开后样式
    selectStyle(item) {
      item.isShow = !item.isShow;
      //如果当前展开其他关闭
      if (item.isShow) {
        this.selectArea.forEach(continent => {
          if (continent.id != item.id) {
            continent.isShow = false;
          }
        });
      }
    },
    //不显示关闭图标
    isCloseStyle(item) {
      this.isCurrentid = '';
    },
    //显示关闭图标
    isOpenStyle(item) {
      this.isCurrentid = item.id;
    },
    // 获取大洲列表
    getcontinentsList() {
      const api = '/supplier/getContinents';
      const options = {};
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            res.data.forEach(item => {
              item.checkbox = false;
            });
            this.continents_list = res.data;
          }
          //回显有值
          if (this.countIds.length) {
            this.countIds = [];
            this.countvalue = [];
            this.selectArea.forEach(continent => {
              //如果洲的国家全选点亮全选
              if (continent.count == continent.info.length) {
                this.continents_list.forEach(item => {
                  if (item.id == continent.id) {
                    item.checkbox = true;
                  }
                });
              }
              //countvalue countIds存储已经选的国家名字和id
              for (let i = 0; i < continent.info.length; i++) {
                this.countIds.push(continent.info[i].id);
                this.countvalue.push(continent.info[i].cname);
              }
            });
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    //洲复选框发生变化
    continentChange(item) {
      //当前展开国家是否在当前点过的复选框上
      if (this.currentContinent.id == item.id) {
        this.country_list.forEach(country => {
          country.checkbox = item.checkbox;
        });
      }
      if (item.checkbox) {
        //获取该州所对应国家
        const api = 'Supplier.getCountries';
        const options = {
          id: item.id,
          is_initial_group: 0,
        };
        xmJson(
          api,
          options,
          res => {
            if (res.errcode == 0) {
              // 过滤掉中国
              let country_list = [];
              if (this.filterType == 3) {
                country_list = res.data.filter(item => {
                  return item.id != 44;
                });
              } else {
                country_list = res.data;
              }

              //获取洲在this.selectArea位置
              let continentindex = this.selectArea.findIndex(
                continent => continent.id == item.id
              );
              let infoArr = [];
              for (let i = 0; i < country_list.length; i++) {
                let addCountryObj = {
                  id: country_list[i].id,
                  cname: country_list[i].cname,
                };
                //如果洲存在，添加没被添加国家
                if (continentindex > -1) {
                  let indexId = this.countIds.findIndex(
                    id => id == country_list[i].id
                  );
                  if (indexId == -1) {
                    this.selectArea[continentindex].info.push(addCountryObj);
                    this.countIds.push(country_list[i].id);
                    this.countvalue.push(country_list[i].cname);
                  }
                }
                //如果洲不存在直接在两个表添加
                else if (continentindex == -1) {
                  infoArr.push(addCountryObj);
                  this.countIds.push(country_list[i].id);
                  this.countvalue.push(country_list[i].cname);
                  //最后一个国家id添加完，对象添加到this.selectArea中
                  if (i == country_list.length - 1) {
                    let addContinentObj = {
                      id: item.id,
                      cname: item.cname,
                      info: infoArr,
                      isShow: false,
                      count: country_list.length,
                    };
                    this.selectArea.push(addContinentObj);
                  }
                }
              }
            }
          },
          err => {
            console.error(err);
          }
        );
      } else {
        //获取洲在this.selectArea位置
        let continentindex = this.selectArea.findIndex(
          continent => continent.id == item.id
        );
        if (continentindex > -1) {
          for (
            let i = 0;
            i < this.selectArea[continentindex].info.length;
            i++
          ) {
            let indexId = this.countIds.findIndex(
              id => id == this.selectArea[continentindex].info[i].id
            );
            if (indexId > -1) {
              //在this.countIds删除
              this.countIds.splice(indexId, 1);
              this.countvalue.splice(indexId, 1);
            }
          }
          //在this.selectArea删除
          this.selectArea.splice(continentindex, 1);
        }
      }
    },
    //国家复选框发生变化
    countryChange(item) {
      if (item.checkbox) {
        this.countIds.push(item.id);
        this.countvalue.push(item.cname);
        let addCountryObj = {
          id: item.id,
          cname: item.cname,
        };
        //如果洲存在直接插入
        for (var i = 0; i < this.selectArea.length; i++) {
          if (this.selectArea[i].id == item.continent_id) {
            this.selectArea[i].info.push(addCountryObj);
            break;
          }
        }
        //如果洲不存在新建洲再插入
        if (i == this.selectArea.length) {
          let addContinentObj = {
            id: this.currentContinent.id,
            cname: this.currentContinent.cname,
            info: [addCountryObj],
            isShow: false,
            count: this.country_list.length,
          };
          this.selectArea.push(addContinentObj);
        }
        //国家全选点亮州
        if (this.selectArea[i].info.length == this.selectArea[i].count) {
          //点亮洲
          this.continents_list.forEach(continent => {
            if (continent.id == this.currentContinent.id) {
              continent.checkbox = true;
            }
          });
        }
      } else {
        //分别删除id
        let indexId = this.countIds.findIndex(id => id == item.id);
        if (indexId > -1) {
          this.countIds.splice(indexId, 1);
          this.countvalue.splice(indexId, 1);
        }
        for (let i = 0; i < this.selectArea.length; i++) {
          if (this.selectArea[i].id == item.continent_id) {
            let index = this.selectArea[i].info.findIndex(
              country => country.id == item.id
            );
            this.selectArea[i].info.splice(index, 1);
            //如果该州选择的国全部删除那么该州也删除
            if (this.selectArea[i].info.length == 0) {
              this.selectArea.splice(i, 1);
            }
          }
        }
        //不点亮洲
        this.continents_list.forEach(continent => {
          if (continent.id == this.currentContinent.id) {
            continent.checkbox = false;
          }
        });
      }
    },
    //获取洲对应国家信息
    getCountryList(id, currentContinent) {
      this.currentContinent = currentContinent;
      this.continentId = id;
      const api = 'Supplier.getCountries';
      const options = {
        id,
        is_initial_group: 0,
      };
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            res.data.forEach(item => {
              item.checkbox = false;
              //查看国家是否已选 已选checkbox为true
              this.countIds.forEach(id => {
                if (id == item.id) {
                  item.checkbox = true;
                }
              });
            });
          }
          if (this.filterType == 3) {
            this.country_list = res.data.filter(item => {
              return item.id != 44;
            });
          } else {
            this.country_list = res.data;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    //已选择中删除
    deleteCountry(currentContinent, currentCountry) {
      //分别删除id
      let indexId = this.countIds.findIndex(id => id == currentCountry.id);
      if (indexId > -1) {
        this.countIds.splice(indexId, 1);
        this.countvalue.splice(indexId, 1);
      }
      for (let i = 0; i < this.selectArea.length; i++) {
        if (this.selectArea[i].id == currentContinent.id) {
          let index = this.selectArea[i].info.findIndex(
            country => country.id == currentCountry.id
          );
          this.selectArea[i].info.splice(index, 1);
          //如果该州选择的国全部删除那么该州也删除
          if (this.selectArea[i].info.length == 0) {
            this.selectArea.splice(i, 1);
          }
        }
      }
      //不点亮洲
      this.continents_list.forEach(continent => {
        if (continent.id == currentContinent.id) {
          continent.checkbox = false;
        }
      });
      //国家列表不点亮
      if (currentContinent.id == this.currentContinent.id) {
        this.country_list.forEach(country => {
          if (country.id == currentCountry.id) {
            country.checkbox = false;
          }
        });
      }
    },
    //显示框中删除
    changeValue() {
      //this.countvalue
      //现在要获取当前洲id
      let currentContinentId;
      let CurrentCountryId;
      for (let i = 0; i < this.selectArea.length; i++) {
        for (let j = 0; j < this.selectArea[i].info.length; j++) {
          var indexValue = this.countvalue.findIndex(
            cname => this.selectArea[i].info[j].cname == cname
          );
          if (indexValue == -1) {
            currentContinentId = this.selectArea[i].id;
            CurrentCountryId = this.selectArea[i].info[j].id;
            this.selectArea[i].info.splice(j, 1);
            if (this.selectArea[i].info.length == 0) {
              this.selectArea.splice(i, 1);
            }
            let indexId = this.countIds.findIndex(id => id == CurrentCountryId);
            if (indexId > -1) {
              this.countIds.splice(indexId, 1);
            }
            break;
          }
        }
      }
      //不点亮洲
      this.continents_list.forEach(continent => {
        if (continent.id == currentContinentId) {
          continent.checkbox = false;
        }
      });
      //国家列表不点亮
      if (currentContinentId == this.currentContinent.id) {
        this.country_list.forEach(country => {
          if (country.id == CurrentCountryId) {
            country.checkbox = false;
          }
        });
      }
    },
    //确认当前选择
    confirmSelect() {
      this.$refs.selectinput.blur();
      this.continentId = [];
      this.country_list = [];
      this.$emit(
        'getAddressID',
        this.countIds,
        this.countvalue,
        this.selectArea
      );
    },
    //取消当前选择
    cancelSelect() {
      this.$refs.selectinput.blur();
      this.countvalue = [];
      this.continentId = [];
      this.country_list = [];
      this.selectArea = JSON.parse(JSON.stringify(this.initSelectArea));
      this.countIds = JSON.parse(JSON.stringify(this.initCountIds));
      if (this.countIds.length) {
        this.getcontinentsList();
      } else {
        this.continents_list.forEach(item => {
          item.checkbox = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addressinsure {
  .topbar {
    width: 522px;
    height: 36px;
    background: #f2f2f2;
    justify-content: flex-start;
    display: flex;

    & > div {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #4d4d4d;
      padding: 0px 20px;
      line-height: 36px;
      width: 174px;
    }
  }

  .optionmodel {
    width: 100%;
    height: 275px;
    overflow: hidden;

    .optionContent {
      display: flex;
      justify-content: flex-start;

      > div {
        width: 174px;
        box-sizing: border-box;
        padding-left: 20px;
      }

      .margintop {
        margin: 5px 0px;
      }
      .height-style {
        height: 22px;
        line-height: 22px;
      }
      .contente-left {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        // margin-top: 10px;
        color: #666666;

        .shadow-style:hover {
          background-color: rgba(192, 189, 185, 0.1);
        }

        .shadow-style {
          height: 24px;
          align-items: center;
        }

        .con-right {
          display: flex;
          justify-content: space-between;
          width: 130px;
          & > div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 124px;
          }
          .left-incon {
            scale: 0.7;
            color: #666666;
            cursor: pointer;
          }

          .left-incon-active {
            color: #ff7802;
            cursor: pointer;
          }

          .icon-active {
            color: #ff7802 !important;
          }

          .icon-rotate-active {
            color: #ff7802 !important;
            transform: rotate(90deg) !important;
            cursor: pointer;
          }

          .curse-style {
            cursor: pointer;
          }
          .icon-size {
            width: 1px;
            height: 3px;
          }

          .box-close-icon {
            width: 7px;
            height: 7px;
            margin-right: 10px;
            color: #666666;
            cursor: pointer;
          }
        }
      }
    }

    .count-content {
      max-height: 275px;
      height: fit-content;
      overflow: auto;

      // &::-webkit-scrollbar {
      //   display: none;
      // }
      & > div {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }
    }

    .selectModel {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      max-height: 275px;
      height: fit-content;
      overflow: auto;
      // &::-webkit-scrollbar {
      //   display: none;
      // }

      .selectItem {
        width: 174px;
        height: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        display: flex;
        justify-content: flex-start;
        line-height: 22px;

        .icon-x {
          display: none;
          line-height: 20px;
          cursor: pointer;
        }

        & > :first-child {
          width: 135px;
        }

        &:hover {
          background: #f7f7f7;

          .icon-x {
            display: block;
          }
        }
      }
    }
  }

  .flex-left {
    display: flex;
    justify-content: flex-start;
  }

  .activeColor {
    color: #ff7802;
  }

  .opera-model {
    width: 100%;
    height: 70px;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: center;
    padding-top: 10px;

    & > div {
      width: 60px;
      height: 40px;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      cursor: pointer;
    }

    & > :first-child {
      background: #ff7802;
      color: #ffffff;
    }

    & > :last-child {
      margin-left: 20px;
      border: 1px solid #cccccc;
      color: #606266;
    }
  }

  ::v-deep .el-select-dropdown__list {
    padding: 0px;
    border-radius: 0px;
  }

  ::v-deep .el-popper[x-placement^='bottom'] .popper__arrow::after {
    border-bottom-color: #f2f2f2;
  }

  ::v-deep .el-select-dropdown__wrap {
    max-height: 1000px;
  }

  ::v-deep .el-select-dropdown {
    border-radius: 0px;
  }

  ::v-deep .el-collapse-item__header {
    height: 38px;
    border-bottom: none;
  }

  ::v-deep .el-collapse-item__header.is-active {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ff7802;
  }

  ::v-deep .el-collapse-item__content {
    padding-bottom: 0px;
  }

  ::v-deep .el-collapse-item__wrap {
    border-bottom: none;
  }

  ::v-deep .el-select__tags {
    height: 30px;
    overflow: hidden;
  }
}
</style>

<template>
  <ximu-dialog
    :title="title"
    class="xm-confirm-dialog"
    @close-dialog="close"
    :width="width"
    :opacity="opacity"
    :zIndex="zIndex"
  >
    <div class="dialog-content">
      <slot>默认内容</slot>
    </div>
    <div class="dialog-btn-group">
      <button
        @click="confirm"
        class="dialog-btn confirm-btn"
        :style="{ width: btnWidth }"
      >
        {{ confirmText }}
      </button>
      <button
        @click="close"
        class="dialog-btn cancel-btn"
        :style="{ width: btnWidth }"
      >
        取消
      </button>
    </div>
  </ximu-dialog>
</template>

<script>
import XimuDialog from './XimuDialog.vue';
export default {
  props: {
    title: {
      type: String,
    },
    width: {
      type: String,
      default: '480px',
    },
    // 按钮宽度
    btnWidth: {
      type: String,
      default: '68px',
    },
    opacity: {
      type: String,
      default: '0.25',
    },
    zIndex: {
      type: String,
      default: '9999',
    },
    confirmText: {
      type: String,
      default: '确认',
    },
  },
  components: { XimuDialog },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('close-dialog'); // 父组件绑定的事件
    },
    confirm() {
      this.$emit('confirm'); // 父组件绑定的事件
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-confirm-dialog {
  position: relative;
  width: 400px;

  &.dialog-main {
    display: grid;
    grid-template-rows: auto 70px;
  }

  .dialog-content {
    height: auto;
    // padding: 20px 20px 30px 20px;
  }
  .dialog-btn-group {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 70px;

    padding: 20px 20px 30px 20px;

    .dialog-btn {
      width: 68px;
      height: 40px;
      // border-radius: 4px 4px 4px 4px;

      font-size: 14px;

      cursor: pointer;
    }
    .dialog-btn.confirm-btn {
      background-color: #ff7802;
      color: #ffffff;
      margin-right: 20px;
    }
    .dialog-btn.cancel-btn {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      color: #808080;
    }
  }
}
</style>

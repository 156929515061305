<template>
  <div class="xm-preview-img-video">
    <div class="mask">
      <div class="dialog-close-header" @click="handleClose">
        <img src="@/assets/images/cross.svg" class="dialog-close-icon" />
      </div>
      <div class="preview-image-content">
        <div
          class="preview-video-container"
          v-if="currMedia && currMedia.type === 'video'"
        >
          <xm-video
            ref="myCarouselVideoPlayer"
            :src="currMedia && currMedia.url | imgbaseurl"
            :id="currIndex"
          ></xm-video>
        </div>
        <div
          v-else
          id="preview-image-container"
          class="preview-image-container"
        >
          <img
            :src="currMedia && currMedia.url | imgbaseurl"
            class="preview-image-item"
          />
        </div>
        <div class="operations-group" v-if="mediaData && mediaData.length > 1">
          <div
            class="operation-change-img operation-prev"
            @click="handleMoveImg('prev')"
          >
            <i class="iconfont img-prev-btn">&#xe60b;</i>
          </div>
          <div
            class="operation-change-img operation-next"
            @click="handleMoveImg('next')"
          >
            <i class="iconfont img-next-btn">&#xe60b;</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XmPreviewImgVideo',
  props: {
    mediaData: {
      type: Array,
      default: () => [],
    },
    imgIndex: {
      type: [Number, String],
      default: -1,
    },
  },
  data() {
    return {
      currMedia: {},
      currIndex: 0,
    };
  },
  methods: {
    handleClickPlayBtn() {
      this.$refs.myCarouselVideoPlayer?.handleClickPlayBtn();
    },
    handleClose() {
      this.$emit('close');
    },
    /**
     * 根据imgs数组索引更新当前展示的轮播图大图
     * @param index 将要展示的图片imgs[index]
     */
    handleChangeImg(index) {
      this.currIndex = index;
      if (index < 0) return;
      this.currMedia = this.mediaData?.[index];
    },

    handleMoveImg(dir) {
      let limit = this.mediaData?.length;
      let currIndex = this.currIndex;
      // 计算移动后的下一张图片的 imgs 索引
      let nextIndex =
        dir == 'prev'
          ? (currIndex - 1 + limit) % limit
          : (currIndex + 1) % limit;

      this.handleChangeImg(nextIndex);
    },
  },

  mounted() {
    const index = this.imgIndex;
    this.handleChangeImg(index);
  },
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 2025;

  /* 选中文字时避免出现蓝色背景 */
  * {
    -moz-user-select: none; /* 火狐 */
    -webkit-user-select: none; /* webkit浏览器 */
    -ms-user-select: none; /* IE10 */
    user-select: none;
  }

  .dialog-close-header {
    position: fixed;
    top: 50px;
    right: 50px;
    cursor: pointer;
    z-index: 2026;

    text-align: right;

    .dialog-close-icon {
      width: 32px;
      height: 32px;
    }
  }
  .preview-image-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2025;
    max-width: 90vw;
    max-height: 90vh;
  }

  .preview-video-container {
    // width: fit-content;
    max-width: 70vw;
    max-height: 80vh;
    margin: 0 auto;

    ::v-deep .xm-video {
      z-index: 2027;
    }
  }

  .preview-image-container {
    max-width: 90vw;
    max-height: 80vh;

    display: flex;
    justify-items: center;

    .preview-image-item {
      max-width: 90vw;
      max-height: 80vh;
      object-fit: contain;
      margin: auto;
    }
  }

  .operations-group {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2026;

    display: flex;
    justify-content: space-between;
    .operation-change-img {
      display: inline-block;
      width: 48px;
      height: 96px;
      line-height: 96px;
      text-align: center;
      background: #808080;
      border-radius: 0px;

      cursor: pointer;

      i {
        display: inline-block;
        font-size: 48px;
        color: #fff;
      }

      .img-prev-btn {
        transform: rotate(180deg);
      }
      .img-next-btn {
        position: relative;
        right: 0;
      }
    }
  }
}
</style>

import { render, staticRenderFns } from "./RadioSelectCategory.vue?vue&type=template&id=042b9270&scoped=true"
import script from "./RadioSelectCategory.vue?vue&type=script&lang=js"
export * from "./RadioSelectCategory.vue?vue&type=script&lang=js"
import style0 from "./RadioSelectCategory.vue?vue&type=style&index=0&id=042b9270&prod&lang=scss&scoped=true"
import style1 from "./RadioSelectCategory.vue?vue&type=style&index=1&id=042b9270&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "042b9270",
  null
  
)

export default component.exports
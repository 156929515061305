<template>
  <div
    class="ximu-pic-exchange"
    :style="[{ gap: spacing, width: dragBoxWidth }]"
  >
    <template v-for="(item, index) in picList">
      <div
        class="content"
        :key="index"
        :style="[{ width: picWidth, height: picHeight }]"
        draggable="true"
        @dragstart="handleDragStart($event, item)"
        @dragenter="handleDragEnter($event, item)"
        @dragover.prevent="getThrottle($event, item)"
        @drop="handleDrop($event, item)"
        @dragend="handleDragEnd($event, item)"
        :class="[{ color: addBorder == index }]"
        v-if="item.isImg"
      >
        <div class="pic-del" @click="delPic(item)">
          <div class="iconfont">&#xe68e;</div>
        </div>
        <div class="pic-show">
          <img :src="item.img | imgbaseurl" alt="" draggable="false" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'XimuPicExchange',
  props: {
    // 整个拖拽盒子的宽度(非必须)
    dragBoxWidth: {
      type: String,
      default: '100%',
    },
    // 每个包裹图片盒子的宽度(非必须)
    picWidth: {
      type: String,
      default: '70px',
    },
    // 每个包裹图片盒子的高度(非必须)
    picHeight: {
      type: String,
      default: '70px',
    },
    // 每个图片盒子的间距(非必须)
    spacing: {
      type: String,
      default: '20px',
    },
    // 传入的图片数组(必须)
    picArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      picList: [],
      dragging: null,
      trottle: 0,
      addBorder: -1,
    };
  },
  watch: {
    picArray: {
      immediate: true,
      deep: true,
      handler: function(val) {
        this.picList = JSON.parse(JSON.stringify(val));
      },
    },
  },
  mounted() {
    this.getThrottle = this.throttle(this.handleDragover, 500);
  },
  methods: {
    /**
     * dragstart：开始拖元素触发，作用于拖拽元素
     * dragenter：元素拖进可drop元素（绑定drop事件的元素）时触发，作用于目标元素(当被鼠标拖动的对象进入其容器范围内时触发)
     * dragover：当元素拖动到drop元素上时触发，作用于目标元素(当某被拖动的对象在另一对象容器范围内拖动时触发)
     *           必须给拖放区元素添加dragover.prevent，才能使dragDrop事件正确执行
     * drop：当元素放下到drop元素触发，作用于目标元素(当放置被拖元素时触发)
     * dragleave ：当元素离开drop元素时触发，作用于目标元素
     * drag：每次元素被拖动时会触发，作用于目标元素
     * dragend：放开拖动元素时触发，作用于目标元素(完成元素拖动后触发)
     *
     * 完成一次拖放的事件过程是： dragstart –> dragenter –> dragover –> drop –> dragend
     */
    // dragstart
    handleDragStart(e, picMsg) {
      this.dragging = picMsg;
    },
    // dragenter
    handleDragEnter(e, picMsg) {
      /**
       * DataTransfer.effectAllowed属性设置本次拖拉中允许的效果。它可能取下面的值:
       * copy：复制被拖拉的节点
       * move：移动被拖拉的节点
       * link：创建指向被拖拉节点的链接
       * copyLink：允许copy或link
       * copyMove：允许copy或move
       * linkMove：允许link或move
       * all：允许所有效果
       * none：无法放下被拖拉的节点
       * uninitialized：默认值，等同于all
       */
      this.addBorder = this.picList.findIndex(item => item === picMsg);
      e.preventDefault();
      e.dataTransfer.effectAllowed = 'move';
    },
    // dragover 拖拉到当前节点上方时，在当前节点上持续触发（相隔几百毫秒）
    handleDragover(e) {
      // 首先把div变成可以放置的元素，即重写dragenter/dragover
      // 在dragenter中针对放置目标来设置
      e.dataTransfer.dropEffect = 'move';
    },
    // drop 当放置被拖元素时触发
    handleDrop(e, item) {
      e.dataTransfer.dropEffect = 'move';
      if (item === this.dragging) {
        return;
      }
      let newItems = [...this.picList]; // 复制原来的图片数组
      let from = newItems.indexOf(this.dragging); // 获取被拖动的元素的索引
      let to = newItems.indexOf(item); // 获取目标元素的索引
      // 交换
      newItems[from] = item;
      newItems[to] = this.dragging;
      this.picList = newItems; // 赋值交换位置后的数组
    },
    // dragend
    handleDragEnd(e) {
      this.dragging = null;
      if (document.getElementsByClassName('color')[0] != undefined) {
        document.getElementsByClassName('color')[0].className = 'content';
      }
      this.$emit('change-pic', this.picList);
    },
    // 删除图片
    delPic(picMsg) {
      this.picList.splice(
        this.picList.findIndex(item => item === picMsg),
        1
      );
      this.$emit('del-pic', this.picList);
    },
  },
};
</script>

<style lang="scss" scoped>
.ximu-pic-exchange {
  display: flex;
  flex-wrap: wrap;

  .content {
    position: relative;
    border: 1px dashed #ccc;
    box-sizing: border-box;
    cursor: all-scroll;
    z-index: 100;

    .pic-del {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: 11;
      cursor: pointer;

      .iconfont {
        color: #fff;
        font-size: 16px;

        &:hover {
          color: #d22316;
        }
      }
    }

    .pic-show {
      width: 100%;
      height: 100%;
      // pointer-events: none;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .color {
    border: 1px dashed #d22316;
  }
}
</style>

<template>
  <div class="address-selector">
    <el-select
      clearable
      :value="completeAddress"
      popper-class="xm-area-select-popper"
      @focus="showDropdown = true"
      ref="selector"
      @clear="clearAddressFunc"
      :placeholder="placeholderKey"
      :disabled="disabled"
    >
      <template slot="empty">
        <el-tabs v-show="showDropdown" v-model="tabName" type="border-card">
          <el-tab-pane
            :label="langType == 'en' ? 'country' : '国家'"
            name="country"
          >
            <el-input
              :placeholder="langType == 'en' ? 'search country' : '搜索国家'"
              v-model="searchTarget"
              @input="searchCountry"
            >
              <img
                class="search-icon"
                width="16px"
                height="16px"
                slot="prefix"
                :src="searchIcon"
              />
            </el-input>
            <div class="country-list-wrapper">
              <div
                class="country-groups"
                v-for="group in country_list"
                :key="group.initial"
              >
                <div>
                  <span
                    :class="{
                      active:
                        initialActive.toUpperCase() ==
                        group.initial.toUpperCase(),
                    }"
                    >{{ group.initial }}</span
                  >
                  <hr class="country-group-divider" />
                </div>
                <ul>
                  <li
                    v-for="item in group.list"
                    :key="item.id"
                    :class="{
                      active: addressData.country_id == item.id,
                    }"
                    @click="selectOption(0, item)"
                  >
                    {{ item.cname ? item.cname : item.name }}
                  </li>
                </ul>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :name="stateEnName"
            :disabled="
              !addressData.country_id || addressData.country_id.length == 0
            "
            :label="stateName"
          >
            <ul class="no-search-input">
              <li
                v-for="item in province_city_list"
                :key="item.id"
                :class="{
                  active:
                    addressData.province_id == item.id ||
                    addressData.city_id == item.id,
                }"
                @click="selectOption(has_state, item)"
              >
                {{ item.cname ? item.cname : item.name }}
              </li>
            </ul>
          </el-tab-pane>
          <!-- <el-tab-pane
            name="city"
            :disabled="
              !addressData.country_id ||
                addressData.country_id.length == 0 ||
                !addressData.province_id ||
                addressData.province_id.length == 0
            "
            :label="langType == 'en' ? 'city' : '市'"

          >
            <ul class="no-search-input">
              <li
                v-for="item in city_list"
                :key="item.id"
                :class="{ active: addressData.city_id == item.id }"
                @click="selectOption(has_state, item)"
              >
                {{ item.cname ? item.cname : item.name }}
              </li>
            </ul>
          </el-tab-pane> -->
        </el-tabs></template
      ></el-select
    >
  </div>
</template>
<script>
// import {
//   getCountries,
//   getStates,
//   getCity,
// } from '@/utils/api/address/addressApi.js';
export default {
  name: 'SnAreaSelect',
  // inject: [
  //   'country',
  //   'country_id',

  //   'province',
  //   'province_id',
  //   'city',
  //   'city_id',
  // ],
  //isEnglish标识需要返回英文名称
  props: [
    'areaData',
    'continentId',
    'langType',
    'placeholderKey',
    'isEnglish',
    'en_areaData',
    'multipleFlag',
    'disabled',
    'isglobal',
  ],

  data() {
    return {
      showDropdown: false,
      tabName: '',
      initialActive: '', // 搜索出来的符合条件的首字母
      searchTarget: '', // 用户搜索框的输入
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标

      has_state: 1, //1为省 2为市
      stateName: '省（州）', //中文地区名字
      stateEnName: 'province', //英文地区名字
      country_list: [],
      province_list: [],
      city_list: [],
      province_city_list: [], //省或者市列表
      /**
       * 需要传出的数据：
       */
      addressData: {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
        Info: {},
        code: '',
      },
      en_addressData: {
        en_country: '',
        en_province: '',
        en_city: '',
      },
    };
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      const addr = `${this.addressData.country}${
        this.addressData.province && this.addressData.province.length > 0
          ? '/'
          : ''
      }${this.addressData.province}${
        this.addressData.city && this.addressData.city.length > 0 ? '/' : ''
      }${this.addressData.city}`;
      return addr;
    },
  },
  methods: {
    //点击x清空选择地址
    clearAddressFunc() {
      Object.keys(this.addressData).forEach(item => {
        this.addressData[item] = '';
      });
      if (this.isEnglish) {
        Object.keys(this.en_addressData).forEach(item => {
          this.en_addressData[item] = '';
        });
      }
      this.changeTab('country');
      this.stateName = '省（州）';
      this.$emit(
        'update',
        this.addressData,
        this.en_addressData,
        this.multipleFlag
      );
    },
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 0：国家菜单；1：省份菜单；2：城市菜单
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      if (index == 0) {
        this.province_list = [];
        this.city_list = [];
        this.province_city_list = [];
        // 选择国家
        this.addressData.country = item.cname ? item.cname : item.name;
        this.addressData.country_id = item.id;
        item.type = 'country';
        this.addressData.Info = item;
        this.addressData.code = item.code;
        // 清空状态
        this.addressData.province = '';
        this.addressData.province_id = '';
        this.addressData.city = '';
        this.addressData.city_id = '';
        if (this.isEnglish) {
          this.en_addressData.en_country = item.name;
          this.en_addressData.en_province = this.en_addressData.en_city = '';
        }
        if (item.has_state == '1') {
          this.getProvinceList();
          this.has_state = 1;
          this.stateEnName = 'province';
          if (this.langType == 'en') {
            this.stateName = 'province';
          } else {
            this.stateName = '省（州）';
          }
        } else if (item.has_state == '0') {
          this.getCityList();
          this.has_state = 2;
          this.stateEnName = 'city';
          if (this.langType == 'en') {
            this.stateName = 'city';
          } else {
            this.stateName = '市';
          }
        }
      } else if (index == 1) {
        this.city_list = [];
        this.province_city_list = [];
        // 选择省份
        this.addressData.province = item.cname ? item.cname : item.name;
        this.addressData.province_id = item.id;
        // 清空状态
        this.addressData.city = '';
        this.addressData.city_id = '';
        if (this.isEnglish) {
          this.en_addressData.en_province = item.name;
          this.en_addressData.en_city = '';
        }
        item.type = 'province';
        this.addressData.Info = item;
        this.closeDropdown();
        // this.getCityList();
      } else if (index == 2) {
        this.addressData.city = item.cname ? item.cname : item.name;
        this.addressData.city_id = item.id;
        if (this.isEnglish) {
          this.en_addressData.en_city = item.name;
        }
        // 可以传出数据了
        item.type = 'city';
        this.addressData.Info = item;
        this.closeDropdown();
      }
      //返回true每个维度都可以回显
      if (this.isglobal == true) {
        this.$emit(
          'update',
          this.addressData,
          this.en_addressData,
          this.multipleFlag
        );
      }
    },
    closeDropdown() {
      this.$emit(
        'update',
        this.addressData,
        this.en_addressData,
        this.multipleFlag
      );
      this.showDropdown = false;
      this.$refs.selector && this.$refs.selector.blur(); // 选择器的尖角符号向下
    },
    // 搜索国家，高亮用户输入数据后的首字母
    async searchCountry() {
      let that = this;
      const options = {
        is_initial_group: 1,
        name: this.searchTarget,
        id: this.continentId ? this.continentId : '',
      };
      const api = '/Supplier/getCountries';
      xmJson(
        api,
        options,
        res => {
          if (res.errcode === 0) {
            that.country_list = res.data;
            if (that.country_list) {
              that.initialActive = res.data[0].initial;
            }
            return;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    async getCountryList() {
      let that = this;
      const options = {
        is_initial_group: 1,
        id: this.continentId ? this.continentId : '',
      };
      const api = '/Supplier/getCountries';
      xmJson(
        api,
        options,
        res => {
          if (res.errcode === 0) {
            that.country_list = res.data;
            if (that.country_list) {
              that.initialActive = res.data[0].initial;
            }
            return;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    getProvinceList() {
      let that = this;
      return new Promise(async (resolve, reject) => {
        const options = {
          id: this.addressData.country_id,
        };
        const api = '/supplier/getStates';
        try {
          xmJson(api, options, res => {
            if (res.errcode === 0) {
              that.province_list = res.data;
              that.province_city_list = res.data;
              that.changeTab('province');
              resolve(res.data);
              return;
            }
          });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    getCityList() {
      return new Promise(async (resolve, reject) => {
        const upperType =
          this.addressData.province_id && this.addressData.province_id != '0'
            ? ''
            : 'country';
        const upperId =
          upperType != 'country'
            ? this.addressData.province_id
            : this.addressData.country_id;
        const options = {
          id: upperId,
          type: upperType,
        };
        let that = this;
        const api = '/supplier/getCity';
        try {
          xmJson(api, options, res => {
            if (res.errcode === 0) {
              that.city_list = res.data;
              that.province_city_list = res.data;
              if (that.city_list.length > 0) {
                that.changeTab('city');
                resolve(res.data);
              } else {
                that.closeDropdown();
              }
              return;
            }
          });
        } catch (error) {
          console.error(err);
          reject(err);
        }
      });
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
      };
      this.en_addressData = this.$options.data().en_addressData;
      this.initialActive = '';
      this.searchTarget = '';
      this.tabName = '';
      // this.country_list = [];
      this.province_list = [];
      this.city_list = [];
      this.province_city_list = [];
      this.changeTab('country');
    },
  },
  beforeDestroy() {
    this.resetData();
  },
  async mounted() {
    this.getCountryList();
    this.changeTab('country');
    if (this.country_id) {
      // 使用 provide-inject 传参
      this.addressData.country = this.country || '';
      this.addressData.country_id = this.country_id || '';
      this.addressData.province = this.province || '';
      this.addressData.province_id = this.province_id || '';
      this.addressData.city = this.city || '';
      this.addressData.city_id = this.city_id || '';
    } else {
      // 若父组件传入的地址数据，是在其组件渲染后（比如，在父组件的mounted函数中向后端请求数据）
      // 才获取的，那么就应当使用props传参，参数名为 `areaData`
      this.addressData.country = this.areaData
        ? this.areaData.country || ''
        : '';
      this.addressData.country_id = this.areaData
        ? this.areaData.country_id
        : '';
      this.addressData.province = this.areaData
        ? this.areaData.province || ''
        : '';
      this.addressData.province_id = this.areaData
        ? this.areaData.province_id
        : '';
      this.addressData.city = this.areaData ? this.areaData.city || '' : '';
      this.addressData.city_id = this.areaData ? this.areaData.city_id : '';
      if (this.isEnglish) {
        //英文(地区)回显
        this.en_addressData.en_country = this.en_areaData
          ? this.en_areaData.en_country || ''
          : '';
        this.en_addressData.en_province = this.en_areaData
          ? this.en_areaData.en_province || ''
          : '';
        this.en_addressData.en_city = this.en_areaData
          ? this.en_areaData.en_city || ''
          : '';
      }
    }
    if (this.addressData.province_id && this.addressData.province_id != '0') {
      // 有省份的国家，才获取省份
      const res = await this.getProvinceList();
    }
    if (this.addressData.city_id && this.addressData.city_id != '0') {
      const res = await this.getCityList();
    }
  },
};
</script>
<style lang="scss">
$tab-width: 100px;
$select-height: 40px;
$highlight-color: #ff7802;
.xm-area-select-popper {
  position: relative;
  // width: $tab-width * 3;
  width: 200px;

  * {
    border: 0px;
  }
  .el-select {
    height: 40px;
  }
  ul {
    padding-left: 1em; // 选项名称距离左边的距离
  }

  li {
    list-style-type: none;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  li.active,
  span.active {
    color: $highlight-color;
  }
  li:hover {
    color: $highlight-color;
  }
  .el-input.el-input--suffix {
    width: 200px;
  }

  // 解决focus输入框边框变蓝问题
  .el-select .el-input.is-focus .el-input__inner,
  .el-select .el-input__inner:focus {
    border-color: $highlight-color;
  }
  // 隐藏el原本的下拉菜单
  .el-select-dropdown {
    visibility: hidden;
  }
  .popper__arrow {
    visibility: hidden;
  }

  .no-search-input {
    height: 290px;
    overflow: auto;
    padding-left: 5px;

    &::-webkit-scrollbar-track {
      background: none;
    }
  }
  .el-input {
    // width: 280px;
    height: 40px;
    margin-bottom: 10px; // 搜索框离下面的选项文字的距离
  }

  .el-input__prefix {
    display: inline-block;
  }

  .search-icon {
    display: inline-block;
    height: 40px;
    line-height: 40px;
  }

  .el-tabs .el-input__inner {
    width: 280px;
    margin-left: -5px;
    border-radius: 0px 0px 0px 0px;
    background-color: #f7f7f7;

    font-size: 12px;
    color: #808080;
    border: 1px solid #ccc;
  }
  // 处理tabs位置问题
  .el-tabs {
    visibility: visible;
    position: absolute;
    top: -5px;
    left: -6px;
    z-index: 2; // 必须比原本的下拉菜单大

    width: $tab-width * 3;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    * {
      font-size: 14px;
      color: #666;
    }
  }

  .el-tabs--border-card > .el-tabs__header {
    border: 0px;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding-bottom: 10px;
  }
  // 解决高亮标签页两侧有1px缝隙
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-left-color: transparent;
    border-right-color: transparent;
  }
  // 调整标签页宽度，宽度相同且文本居中
  .el-tabs__item {
    width: $tab-width;
    text-align: center;
  }
  // 解决“城市”标签页头右侧有1px缝隙
  .el-tabs__item:nth-last-of-type(1) {
    width: $tab-width + 1;
  }
  // 隐藏标签页前后的滑动提示箭头
  .el-tabs__nav-prev,
  .el-tabs__nav-next {
    display: none;
  }

  .el-tabs--border-card > .el-tabs__header .el-tabs__item + .el-tabs__item {
    margin-left: 0px;
  }

  .el-tabs__content {
    padding-right: 2px; // 解决滚动条不贴右边问题（有 2px 缝隙）
    padding-top: 10px;
    height: 310px;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #808080;
  }
  // 解决点击和悬浮标签页，标签页文字蓝色高亮问题
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: #4d4d4d;
  }
  .el-tabs__item.is-top {
    background-color: #f2f2f2;
    color: #808080;
  }
  .el-tabs__item.is-active {
    background-color: #ffffff;
    color: #4d4d4d;
  }

  .country-list-wrapper {
    height: 240px;
    overflow: auto;

    &::-webkit-scrollbar-track {
      background: none;
    }
  }
  .country-groups {
    & > span {
      color: #666;
      padding-right: 5px;
      margin-top: 5px;
    }
    & > span.active {
      color: $highlight-color;
    }
    // 国家按拼音分组，分组的水平分割线
    .country-group-divider {
      border: 1px solid #e6e6e6;
      display: inline-block;
      width: $tab-width * 3 - 50px;

      margin-bottom: 0.33em;
    }
  }
}
</style>

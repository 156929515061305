// import * as echarts from 'echarts';

const options = {
  xAxis: {
    type: 'category',
    data: [],
    axisTick: {
      alignWithLabel: true,
    },
    // boundaryGap: ['54%', '54%'],
    axisLabel: {
      show: true,
      margin: 20,
      color: '#808080',
      fontSize: 12,
      formatter: function (value) {
        return value.split(' ').join('\n');
      },
    },
  },
  yAxis: {
    type: 'value',
    name: '金额',
    nameTextStyle: {
      padding: [0, 0, 5, -55],
      fontSize: 14,
    },
    splitNumber: 4,
    interval: 0,
    min: 0,
    max: 0,
    axisLabel: {
      show: true,
      margin: 10,
      color: '#808080',
      fontSize: 12,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed',
      },
    },
  },
  grid: {
    top: '35',
    left: '62',
    right: '30',
    bottom: '30',
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: 'line',
      smooth: true,
      showSymbol: false,
      emphasis: {
        color: '#ff7802',
      },
      itemStyle: {
        color: '#ff7802', //改变折线颜色
        borderColor: '#fff',
        borderWidth: 1,
      },
      /* areaStyle: {
        opacity: 0.3,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(255, 120, 2, 1)'
          },
          {
            offset: 1,
            color: 'rgba(255, 120, 2, 0)'
          }
        ])
      }, */
    },
  ],
  tooltip: {
    trigger: 'axis',
    className: 'tooltip',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    axisPointer: {
      type: 'line',
      snap: true,
      label: {
        color: '#ff7802',
        backgroundColor: '#ff7802',
      },
      lineStyle: {
        type: 'solid',
        color: '#d9d9d9',
      },
    },
    formatter: function (data) {
      var font = document
        .getElementsByClassName('box-list-active')[1]
        .childNodes[0].innerText.split(' ')[0];
      var hoverDate = data[0].axisValueLabel.split(' ').join('');
      return `
        <div class="hoverDate">${hoverDate}</div>
        <div class="detailNum">
          ${font}：
          <span>${data[0].value}%</span>
        </div>`;
    },
  },
};

export default {
  setYAxisName(name) {
    options.yAxis.name = name;
  },
  setYAxisMax(max) {
    options.yAxis.max = max;
  },
  setYAxisInterval(interval) {
    options.yAxis.interval = interval;
  },
  setYAxisPadding(padding) {
    options.yAxis.nameTextStyle.padding = padding;
  },
  setLabel(labels) {
    options.xAxis.data = labels;
  },
  setGridBottom(bottom) {
    options.grid.bottom = bottom;
  },
  setXAxisMargin(margin) {
    options.xAxis.axisLabel.margin = margin;
  },
  setData(data) {
    options.series[0].data = data;
  },
  getOptions() {
    return options;
  },
};

<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>流量数据<i class="update-time">(近30天)</i></div>
        <!-- <span @click="goUrl">查看全部</span> -->
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>
            曝光数
            <el-popover
              placement="bottom-start"
              width="255"
              trigger="hover"
              popper-class="platform-level-popover"
            >
              <div class="tip-content">
                <div>
                  统计范围内（此处统计范围为30天）供应商所有商品在商品首页、列表页、搜索结果页显示过的次数综合
                </div>
              </div>
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.exposure != 0 }]">{{
            detail.exposure
          }}</span>
        </div>
        <div class="label-list">
          <div>
            访客数
            <el-popover
              placement="bottom-start"
              width="255"
              trigger="hover"
              popper-class="platform-level-popover"
            >
              <div class="tip-content">
                <div>
                  统计范围内（此处统计范围为30天）访问商品详情页的去重人数，一个人在统计时间范围内访问多次只记为一个。
                </div>
              </div>
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.visitor != 0 }]">{{
            detail.visitor
          }}</span>
        </div>
        <div class="label-list">
          <div>点击量</div>
          <span :class="[{ 'have-num': detail.click != 0 }]">{{
            detail.click
          }}</span>
        </div>
        <div class="label-list">
          <div>点击率</div>
          <span :class="[{ 'have-num': detail.clickRadio != 0.0 }]"
            >{{ detail.clickRadio }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultTo } from 'lodash';
export default {
  name: 'FlowData',
  data() {
    return {
      detail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const api = 'PlatformReport.getFlowNumber';
      xmJson(
        api,
        { MUTE_WARNING: 1 },
        res => {
          if (res.errcode == 0) {
            for (const key in res.data) {
              res.data[key] = defaultTo(res.data[key], 0);
            }
            this.detail = res.data;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    goUrl() {},
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

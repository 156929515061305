<template>
  <!-- 实时概况 -->
  <div class="real-time-overview">
    <div class="real-time-overview-title">
      <div class="title-left">
        <span class="title-left-font">实时概况</span>
        <span class="title-left-time">{{ currentTime }}</span>
      </div>
      <!-- <div class="title-right">
        <span>查看更多</span>
      </div> -->
    </div>
    <div class="real-time-overview-content">
      <div
        class="content-item"
        :class="item.className"
        v-for="(item, index) in realTimeList"
        :key="index"
      >
        <div class="content-item-title">{{ item.title }}</div>
        <div class="content-item-num">{{ item.num }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { defaultTo } from 'lodash';

export default {
  name: 'RealTimeOverview',
  props: {
    // 当前页面的展示币种, 1 人民币 2 美元
    pageCurrency: {
      type: String,
      default: '1',
    },
    // 实时概况数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentTime: '',
    };
  },
  computed: {
    // 货币符号
    currencySign() {
      switch (this.pageCurrency) {
        case '1':
          return '￥';
        case '2':
          return '$';
      }
    },
    // 展示数据
    realTimeList() {
      let realTime = [
        // {
        //   title: '曝光数',
        //   num: 0,
        // },
        // {
        //   title: '访客数',
        //   num: 0,
        // },
        {
          title: '支付订单数',
          num: 0,
        },
        {
          title: '支付分销商数',
          num: 0,
        },
        {
          title: `${'支付金额(' + this.currencySign + ')'}`,
          num: 0,
        },
      ];
      const keyMapping = [
        // 'exposure', // 曝光数
        // 'visitor', // 访客数
        'pay', // 支付订单数
        'user', // 支付分销数
        'money', // 支付金额
      ];
      keyMapping.forEach((key, index) => {
        const item = realTime[index];
        const value = defaultTo(this.detail[key], 0);
        this.$set(item, 'num', value);

        if (index == 4 && value != 0) {
          this.$set(item, 'num', Number(value).toFixed(2));
        }
      });
      return realTime;
    },
  },
  mounted() {
    this.currentTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.real-time-overview {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;

  .real-time-overview-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 21px;
    line-height: 21px;

    .title-left {
      .title-left-font {
        color: #111;
        font-size: 16px;
        line-height: 19px;
      }

      .title-left-time {
        color: #999;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .title-right {
      span {
        height: 16px;
        line-height: 14px;
        color: #3d8df5;
        font-size: 12px;
        cursor: pointer;
      }

      span::after {
        content: '\e637';
        margin-left: 4px;
        font-family: iconfont !important;
        font-size: 14px;
      }
    }
  }

  .real-time-overview-content {
    display: flex;
    justify-content: space-between;

    .content-item {
      padding: 20px;
      width: 377px;
      border: 1px solid #e6e6e6;

      .content-item-title {
        margin-bottom: 20px;
        height: 18px;
        line-height: 16px;
        font-size: 14px;
        color: #4d4d4d;
      }

      .content-item-num {
        height: 21px;
        line-height: 19px;
        font-size: 16px;
        color: #333;
        font-weight: bold;
      }
    }
  }
}
</style>

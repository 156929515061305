<template>
  <div class="category-search" v-click-outside="closePanel">
    <div class="category-search-inp" :style="{ width: width }">
      <el-input
        v-model="categoryInput"
        placeholder="类目"
        @focus="openPanel"
        @input="openPanleAll"
        @change="changeSelect"
        ><i slot="suffix" class="searchicon">
          <img src="@/assets/images/magnifier.svg" />
        </i>
      </el-input>
    </div>
    <!-- 三级面板 -->
    <div class="category-panel" v-show="showPanel">
      <!-- 一级类目 -->
      <div class="level-category-panel">
        <div class="category-box">
          <el-input v-model="inputLevel1" placeholder="请输入关键字">
            <img
              class="search-icon"
              width="16px"
              height="16px"
              slot="prefix"
              src="@/assets/images/magnifier.svg"
            />
          </el-input>
          <ul class="category-panel-item">
            <li
              class="category-item"
              v-for="(cateFirst, index) in searchListLevel1"
              :key="cateFirst.supplier_class_id + index"
              @click="selectFirstCate(cateFirst)"
              :class="{
                active: firstSelectCateId === cateFirst.supplier_class_id,
              }"
            >
              <span v-html="highlight(cateFirst.name, inputLevel1)"></span>
              <span><i class="el-icon-arrow-right"></i></span>
            </li>
          </ul>
        </div>
        <!-- 二级类目 -->
        <div class="category-box" v-show="firstSelectCateId">
          <el-input v-model="inputLevel2" placeholder="请输入关键字">
            <img
              class="search-icon"
              width="16px"
              height="16px"
              slot="prefix"
              src="@/assets/images/magnifier.svg"
            />
          </el-input>
          <ul class="category-panel-item">
            <li
              class="category-item"
              v-for="(cateSecond, idx) in searchListLevel2"
              :key="cateSecond.supplier_class_id + idx"
              @click="selectSecondCate(cateSecond)"
              :class="{
                active: secondSelectCateId === cateSecond.supplier_class_id,
              }"
            >
              <span v-html="highlight(cateSecond.name, inputLevel2)"></span>
              <span><i class="el-icon-arrow-right"></i></span>
            </li>
          </ul>
        </div>
        <!-- 三级类目 -->
        <div class="category-box" v-show="secondSelectCateId">
          <el-input v-model="inputLevel3" placeholder="请输入关键字">
            <img
              class="search-icon"
              width="16px"
              height="16px"
              slot="prefix"
              src="@/assets/images/magnifier.svg"
            />
          </el-input>
          <ul class="category-panel-item" id="list">
            <li
              class="category-item"
              v-for="(cateThird, index2) in searchListLevel3"
              :key="cateThird.supplier_class_id + index2"
              @click="selectThirdCate(cateThird)"
              :id="{
                selecteditem: thirdSelectCateId === cateThird.supplier_class_id,
              }"
              :class="{
                active: thirdSelectCateId === cateThird.supplier_class_id,
              }"
            >
              <span v-html="highlight(cateThird.name, inputLevel3)"></span>
              <!-- <span><i class="el-icon-arrow-right"></i></span> -->
            </li>
          </ul>
        </div>
      </div>

      <div class="current-select">
        <span>您当前的选择： </span>
        <span class="current-text" @click="clearLevelSecond">{{
          firstSelectCateName ? firstSelectCateName : ''
        }}</span>
        <span class="current-text" @click="clearLevelThird">{{
          secondSelectCateName ? '>' + secondSelectCateName : ''
        }}</span>
        <span class="current-text">{{
          thirdSelectCateName ? '>' + thirdSelectCateName : ''
        }}</span>
      </div>
      <div class="category-btn-box">
        <el-button type="primary" :disabled="isCongirm" @click="clickCongirm"
          >确定</el-button
        ><el-button @click="closePanel">取消</el-button
        ><el-button @click="clearSelectd">清空</el-button>
      </div>
    </div>

    <!-- 总体搜索 -->
    <div class="cate-all-panel" v-show="showPanelAll">
      <ul>
        <li
          :title="cate.content"
          class="all-panel-item"
          v-for="(cate, idx) in searchCateAll"
          :key="idx"
          @click="selectCateAll(cate)"
        >
          <span v-html="highlight(cate.content, categoryInput)"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategorySearch',
  model: {
    // prop: 'selectId',
    event: 'selectCate',
  },
  props: {
    // 所选类目的id数组
    // selectId: {
    //   type: Array,
    //   default: () => [],
    // },
    width: {
      type: String,
      default: '360px',
    },
    isEcho: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categoryInput: '', // 整体搜索
      categoryDataAll: [], // 拼接好的所有类目
      // categoryDataLevel1: [], // 一级类目数据
      categoryDataLevel2: [], // 二级类目数据
      categoryDataLevel3: [], // 三级类目数据

      showPanel: false, // 展示三级类目面板
      showPanelAll: false, // 展示所有类目的面板
      firstSelectCateId: '', // 一级类目被选中的id
      secondSelectCateId: '', // 二级类目被选中的id
      thirdSelectCateId: '', // 三级类目被选中的id
      firstSelectCateName: '', // 被选中的类目name
      secondSelectCateName: '', // 被选中的类目name
      thirdSelectCateName: '', // 被选中的类目name
      isCongirm: true, // 未选第三级禁止点击确认
      inputLevel1: '',
      inputLevel2: '',
      inputLevel3: '',

      selectedIdData: [], // 选中的id数组
      // selectedCateData: [
      //   { levelId: '', levelName: '' },
      //   { levelId: '', levelName: '' },
      //   { levelId: '', levelName: '' },
      // ], // 选择的类目的数据
      isCosmetics: '', // 是否化妆品类目，-1不是 1是
      isMustCertification: '', // ccc认证是否必填
    };
  },
  created() {
    this.getCategoryAll();
  },
  watch: {
    // 创建回显
    categoryDataLevel1() {
      // if (this.isEcho) {
      // this.getSecondCategory(this.selectedId[0]);
      // this.getThirdCategory(this.selectedId[1]);
      this.isCongirm = !this.selectedId[2];
      this.updateCateData(this.selectedId);
      // }
    },
    // 编辑回显
    selectedId(cateIdArr) {
      // this.getSecondCategory(this.selectedId[0]);
      // this.getThirdCategory(this.selectedId[1]);
      this.updateCateData(cateIdArr);
    },
  },
  computed: {
    searchListLevel1() {
      return (
        this.categoryDataLevel1?.filter(item =>
          item.name.includes(this.inputLevel1)
        ) || []
      );
    },
    searchListLevel2() {
      return (
        this.categoryDataLevel2?.filter(item =>
          item.name.includes(this.inputLevel2)
        ) || []
      );
    },
    searchListLevel3() {
      return (
        this.categoryDataLevel3?.filter(item =>
          item.name.includes(this.inputLevel3)
        ) || []
      );
    },
    searchCateAll() {
      if (this.categoryInput) {
        return (
          this.categoryDataAll?.filter(item =>
            item.content.includes(this.categoryInput)
          ) || []
        );
      } else {
        return [];
      }
    },
    selectedId() {
      return this.$store.state.goods.selectedCateId;
    },
    // 类目数据
    categoryDataLevel1() {
      return this.$store.state.category.categoryData;
    },
  },
  methods: {
    handleClickOutside() {
      this.showPanel = false;
      this.showPanelAll = false;
    },
    updateCateData(selectedItem) {
      let cateName = [];

      let cateLevel2 = [];
      let cateLevel3 = [];

      this.categoryDataLevel1.forEach(item => {
        if (selectedItem[0] == item.supplier_class_id) {
          cateLevel2 = item._child;
          this.categoryDataLevel2 = item._child;
          this.firstSelectCateName = cateName[0] = item.name;
        }
      });

      cateLevel2.forEach(item => {
        if (selectedItem[1] == item.supplier_class_id) {
          cateLevel3 = item._child;
          this.categoryDataLevel3 = item._child;
          this.secondSelectCateName = cateName[1] = item.name;
        }
      });

      cateLevel3.forEach(item => {
        if (selectedItem[2] == item.supplier_class_id) {
          this.thirdSelectCateName = cateName[2] = item.name;
          this.isCosmetics = item.is_cosmetics;
          this.isMustCertification = item.ccc_is_must;
        }
      });

      this.firstSelectCateId = selectedItem[0];
      this.secondSelectCateId = selectedItem[1];
      this.thirdSelectCateId = selectedItem[2];

      this.isCongirm = !this.thirdSelectCateId;

      cateName = cateName.filter(Boolean);
      this.categoryInput = cateName.join(' > ');
    },
    changeSelect() {
      this.$emit('selectCate', this.selectedId);
    },
    // 搜索
    highlight(val, str) {
      if (str) {
        let reg = new RegExp(str, 'gi');
        return val.replace(
          reg,
          '<span style="color: #ff7802">' + str + '</span>'
        );
      } else {
        return val;
      }
    },
    // highlight(val, keyword) {
    //   if (val.indexOf(keyword) !== -1 && keyword !== '') {
    //     return val.replace(
    //       keyword,
    //       '<span style="color: #ff7802">' + keyword + '</span>'
    //     );
    //   } else {
    //     return val;
    //   }
    // },
    // 点击一级类目
    selectFirstCate(cate) {
      this.firstSelectCateId = cate.supplier_class_id;
      this.firstSelectCateName = cate.name;
      this.getSecondCategory(cate.supplier_class_id);
      this.inputLevel2 = '';

      this.clearLevelSecond();
    },
    // 点击二级类目
    selectSecondCate(cate) {
      this.secondSelectCateId = cate.supplier_class_id;
      this.secondSelectCateName = cate.name;
      this.getThirdCategory(cate.supplier_class_id);
      this.inputLevel3 = '';

      this.clearLevelThird();
    },
    // 点击三级类目
    selectThirdCate(cate) {
      this.thirdSelectCateId = cate.supplier_class_id;
      this.thirdSelectCateName = cate.name;
      this.isCongirm = !this.thirdSelectCateId;
      this.isCosmetics = cate.is_cosmetics;
      this.isMustCertification = cate.ccc_is_must;
    },
    // 点击确定
    clickCongirm() {
      let selectedNameData = [
        this.firstSelectCateName,
        this.secondSelectCateName,
        this.thirdSelectCateName,
      ].filter(Boolean);
      this.categoryInput = selectedNameData.join(' > ');

      this.selectedIdData[0] = this.firstSelectCateId;
      this.selectedIdData[1] = this.secondSelectCateId;
      this.selectedIdData[2] = this.thirdSelectCateId;

      // console.log('所选id数组=>', this.selectedIdData);

      this.$store.commit('setSelectedCateId', this.selectedIdData);
      this.$store.commit('setCosmetics', this.isCosmetics); // 点击确定，保存是否是特殊化妆品类目
      this.$store.commit('setCertification', this.isMustCertification); // 点击确定，保存ccc认证是否必填
      this.$emit('updataCate', this.selectedId);
      this.closePanel();
      this.changeSelect();
    },
    // 获取二级类目
    getSecondCategory(id) {
      this.categoryDataLevel1.forEach(item => {
        if (item.supplier_class_id == id) {
          this.categoryDataLevel2 = item._child;
        }
      });
    },
    // 获取三级类目
    getThirdCategory(id) {
      this.categoryDataLevel2.forEach(item => {
        if (item.supplier_class_id === id) {
          this.categoryDataLevel3 = item._child;
        }
      });
    },
    // 搜索选择
    selectCateAll(cate) {
      this.categoryInput = cate?.content;
      this.searchCateAll.length = 0; // 关闭总搜索面板
      this.showPanel = false;
      this.showPanelAll = false;
      this.getSecondCategory(cate?.firstId);
      this.getThirdCategory(cate?.secondId);

      this.firstSelectCateId = cate?.firstId;
      this.secondSelectCateId = cate?.secondId;
      this.thirdSelectCateId = cate?.thirdId;

      this.isCongirm = !this.thirdSelectCateId;

      this.firstSelectCateName = cate?.firstName;
      this.secondSelectCateName = cate?.secondName;
      this.thirdSelectCateName = cate?.thirdName;

      this.selectedIdData[0] = cate?.firstId;
      this.selectedIdData[1] = cate?.secondId;
      this.selectedIdData[2] = cate?.thirdId;
      this.isCosmetics = cate?.is_cosmetics;
      this.isMustCertification = cate?.ccc_is_must;
      this.$store.commit('setSelectedCateId', this.selectedIdData);
      this.$store.commit('setCosmetics', this.isCosmetics); // 搜索点击确定，保存是否是特殊化妆品类目
      this.$store.commit('setCertification', this.isMustCertification); // 点击确定，保存ccc认证是否必填
      this.$emit('updataCate', this.selectedIdData);
      this.changeSelect();

      // console.log('总id数组=>', this.selectedIdData);
    },

    clearLevelSecond() {
      this.secondSelectCateId = '';
      this.secondSelectCateName = '';

      this.clearLevelThird();
    },
    clearLevelThird() {
      this.thirdSelectCateId = '';
      this.thirdSelectCateName = '';
      this.isCongirm = !this.thirdSelectCateId;
    },

    // 清空
    clearSelectd() {
      this.firstSelectCateId = '';
      this.firstSelectCateName = '';

      this.categoryInput = '';
      this.inputLevel1 = ''; // 清空一级搜索框

      this.clearLevelSecond();
      this.selectedIdData = [];
      this.isCosmetics = '';
      this.isMustCertification = '';
      this.$store.commit('setSelectedCateId', this.selectedIdData);
      this.$store.commit('setCosmetics', this.isCosmetics); // 点击确定，保存是否是特殊化妆品类目
      this.$store.commit('setCertification', this.isMustCertification); // 点击确定，保存ccc认证是否必填
      this.$emit('updataCate', this.selectedIdData);
      this.changeSelect();
    },
    // 点击input展开三级面板
    openPanel() {
      this.updateCateData(this.selectedId);
      this.showPanel = true;
    },
    // 点击总搜索input
    openPanleAll() {
      if (this.categoryInput != '' && this.searchCateAll?.length > 0) {
        this.showPanelAll = true;
      } else {
        this.showPanelAll = false;
      }
    },
    // 点击取消
    closePanel() {
      this.updateCateData(this.selectedId);
      this.clearLevelSecond();
      this.firstSelectCateId = '';
      this.firstSelectCateName = '';
      this.showPanel = false;
      this.showPanelAll = false;
      // this.searchCateAll.length = 0; // 关闭总搜索面板
    },
    // 拼接所有类目
    getCategoryAll() {
      let data = this.categoryDataLevel1;
      // let categoryDataAll = [];
      if (data?.length > 0) {
        for (let cate1 = 0; cate1 < data.length; cate1++) {
          let level1 = data[cate1];

          // console.log(`level1-${cate1}=>`, level1.name);
          if (level1?._child?.length > 0) {
            for (let cate2 = 0; cate2 < level1._child.length; cate2++) {
              let level2 = level1._child[cate2];

              // console.log(`level2-${cate2}=>`, level2.name);
              if (level2?._child?.length > 0) {
                for (let cate3 = 0; cate3 < level2._child.length; cate3++) {
                  let label = {};

                  let level3 = level2?._child[cate3];
                  label.firstId = level1?.supplier_class_id;
                  label.secondId = level2?.supplier_class_id;
                  label.thirdId = level3?.supplier_class_id;
                  label.firstName = level1?.name;
                  label.secondName = level2?.name;
                  label.thirdName = level3?.name;
                  label.is_cosmetics = level3?.is_cosmetics;
                  label.ccc_is_must = level3?.ccc_is_must;

                  label.content =
                    level1.name + ' > ' + level2.name + ' > ' + level3.name;
                  // console.log(`level3-${cate3}=>`, level3.name);

                  this.categoryDataAll.push(label);
                }
              }
            }
          }
        }
      }

      // console.log('所有类目==>', this.categoryDataAll);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item.is-error .category-panel {
  ::v-deep .el-input__inner {
    border-color: #d9d9d9 !important;
  }
}
.el-form-item.is-error .category-panel {
  ::v-deep .el-input__inner:focus {
    border-color: #ff7802 !important;
  }
}

.category-search-inp {
  // width: 100%;

  ::v-deep .el-input__suffix-inner {
    display: inline-block;
    width: 23px;
    vertical-align: middle;
  }
}
.category-panel {
  z-index: 100;
  position: absolute;
  width: 938px;
  height: 468px;
  background: #fff;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  margin-top: 7px;
  padding: 20px 20px 30px;

  .level-category-panel {
    display: flex;

    .category-box:nth-child(2) {
      border-left: 0;
    }
    .category-box:nth-child(3) {
      border-left: 0;
    }
  }

  .current-select {
    height: 40px;
    background: #f7f7f7;
    margin-top: 10px;
    padding-left: 20px;
    color: #808080;

    .current-text {
      color: #ff7802;
      cursor: pointer;
    }
  }

  .category-btn-box {
    width: fit-content;
    height: 40px;
    margin: 20px auto;
    ::v-deep .el-button {
      padding: 12px 16px;
    }
    ::v-deep .el-button--default {
      margin-left: 20px;
    }
  }
}
.level-category-panel .category-box ::v-deep .el-input__prefix {
  display: inline-block;
  top: 2px;
}

.category-box {
  background: #fff;
  width: 300px;
  height: 308px;
  padding: 10px 0px 10px 8px;
  border: 1px solid #cccccc;

  .category-panel-item {
    height: 234px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
    box-sizing: border-box;
  }
  .category-item {
    width: 280px;
    height: 34px;
    line-height: 34px;
    // background-color: lightblue;
    padding-left: 10px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    color: #808080;
  }
  .category-item:hover {
    background-color: #fff2e6;
  }
  .category-item.active {
    color: #ff7802;
    background-color: #fff2e6;
  }
}

.category-box ::v-deep .el-input__inner {
  width: 280px !important;
  border-radius: 0 !important;
  background-color: #f7f7f7 !important;
}

.cate-all-panel {
  margin-top: 10px;
  margin-left: 4px;
  width: 400px;
  height: 340px;
  z-index: 101;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);

  .all-panel-item {
    padding: 0 15px;
    line-height: 34px;
    height: 34px;
    color: #606266;
    // overflow-y: auto;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .all-panel-item:hover {
    background-color: #fff2e6;
  }
}
</style>

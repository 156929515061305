<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>仓库</div>
        <!-- <span @click="goUrl">查看全部</span> -->
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>待发货</div>
          <span :class="[{ 'have-num': detail.coll != 0 }]">{{
            detail.coll
          }}</span>
        </div>
        <div class="label-list">
          <div>
            待确认收货
            <el-popover
              placement="bottom-start"
              width="144"
              trigger="hover"
              content="待分销商收货的订单数"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.send != 0 }]">{{
            detail.send
          }}</span>
        </div>
        <div class="label-list">
          <div>违规记录</div>
          <span :class="[{ 'have-num': detail.violation != 0 }]">{{
            detail.violation
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultTo } from 'lodash';
export default {
  name: 'WarehouseDetails',
  data() {
    return {
      detail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const api = 'PlatformReport.getWarehouseNumber';
      xmJson(
        api,
        { MUTE_WARNING: 1 },
        res => {
          if (res.errcode == 0) {
            for (const key in res.data) {
              res.data[key] = defaultTo(res.data[key], 0);
            }
            this.detail = res.data;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    goUrl() {
      this.$router.push('/shippedList');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

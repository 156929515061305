// 权限控制器
import permission_table from '@/assets/json/permission_table.json';


// 创建权限表数据
function create() {
  return deepCopy(permission_table);;
}

export default {
  create
};
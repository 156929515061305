<template>
  <!-- 页脚 -->
  <div class="index-bottom" :style="{ backgroundColor: backgroundColor }">
    <div class="wrap" :style="{ width: bottomWrapWidth }">
      <!-- 网站服务 -->
      <!-- <div class="service" v-if="hasService">
        <div class="logo">
          <div class="logo-img">
            <img :src="logoImg" style="width: 100%" />
          </div>
        </div>

        <div class="feature">
          <div
            v-for="item in treeData"
            :key="item.id"
            class="about-us feature-list"
          >
            <div class="title">{{ item.name }}</div>
            <div v-for="res in item.child" :key="res.id">
              <a :href="res.link" target="_blank">
                {{ res.name }}
              </a>
            </div>
          </div>
        </div>
      </div> -->

      <div class="separate-line"></div>

      <!-- 法规政策 -->
      <div class="regulatory-policy">
        <!--政策 -->
        <div class="policy">
          <div class="policy-list" v-for="(item, index) in policy" :key="index">
            <div class="click-to-jump" @click="jump(item.url)">
              {{ item.title }}
            </div>
            <div class="vertical-line" v-if="index !== policy.length - 1">
              |
            </div>
          </div>
        </div>

        <!-- 版权相关 -->
        <div class="about-copyright">
          <div
            class="about-copyright-list"
            v-for="(item, index) in copyrightFont"
            :key="index"
          >
            <div
              :class="{ 'click-to-jump': item.url !== undefined }"
              @click="jump(item.url)"
            >
              {{ item.title }}
            </div>
            <div
              class="vertical-line"
              v-if="index !== copyrightFont.length - 1"
            >
              |
            </div>
          </div>
        </div>

        <!-- 网站图片 -->
        <div class="web-pic">
          <div
            :class="item.name"
            v-for="(item, index) in webPic"
            :key="index"
            @click="jump(item.url)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getNavbar, getLogo } from '@/utils/api/bottom.js';
export default {
  name: 'IndexBottom',
  props: {
    // 底部版心
    bottomWrapWidth: {
      type: String,
      default: '1200px',
    },
    // 背景颜色
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    // 是否有网站特色板块
    hasService: {
      type: Boolean,
      default: true,
    },
    // 是否展示友情链接
    showLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      token: '',
      treeData: [], // 底部数据
      logoImg: '', // 底部logo
      // 关于我们
      // aboutUs: [
      //   { title: '平台介绍', url: 'https://home.ximu.cn/HomePage' },
      //   { title: '用户指南', url: '' },
      // ],
      // 交易规则
      // tradingRule: [
      //   { title: '放款与提现规则', url: 'https://home.ximu.cn/PlatRule/44' },
      //   { title: '交易纠纷处理规则', url: 'https://home.ximu.cn/PlatRule/46' },
      //   { title: '交易违规管理规则', url: 'https://home.ximu.cn/PlatRule/48' },
      // ],
      // 发票管理
      // invoiceManage: [
      //   { title: '发票开具规则', url: 'https://home.ximu.cn/PlatRule/64' },
      //   { title: '发票常见问题解答', url: 'https://home.ximu.cn/PlatRule/84' },
      // ],
      // 售后服务
      // afterSales: [
      //   { title: '售后保障规则', url: 'https://home.ximu.cn/PlatRule/35' },
      //   { title: '退货政策', url: 'https://home.ximu.cn/PlatRule/60' },
      //   {
      //     title: '售后服务相关问题解答',
      //     url: 'https://home.ximu.cn/PlatRule/90',
      //   },
      // ],
      // 合作招商
      // cooperative: [
      //   { title: '供应商入驻', url: 'https://home.ximu.cn/register/supplier' },
      //   { title: '分销商入驻', url: 'https://home.ximu.cn/register/shop' },
      //   { title: '联系我们', url: '' },
      // ],
      // 法规政策
      policy: [
        { title: '隐私政策', url: 'https://home.ximu.cn/PlatRule/85' },
        { title: '法律声明', url: 'https://home.ximu.cn/PlatRule/87' },
        // {title: '廉正举报', url: ''},
        { title: '商品发布政策', url: 'https://home.ximu.cn/PlatRule/16' },
        { title: '友情链接', url: '/friendlyLinks' },
      ],
      // 版权文字
      copyrightFont: [
        {
          title: '湘ICP备2023010376号-1',
          url: 'https://beian.miit.gov.cn/#/Integrated/index',
        },
        {
          title: '湘公网备案 43019002001936',
          url: 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001936',
        },
        { title: '增值电信业务经营许可证: 湘B2-20230578' },
        { title: 'Copyright © 2023-2033 ximu.cn 版权所有' },
        { title: '违法和不良信息举报电话：0755-27761552' },
        {
          title: '营业执照',
          url: 'https://img.ximu.cn/test_upload_ZH12304010001_169094288685503563658',
        },
      ],
      webPic: [
        // {name: 'credible-web', title: '可信网站', url: ''},
        // {name: 'integrity-web', title: '诚信网站', url: ''},
        {
          name: 'complaint-reporting',
          title: '全国12315平台',
          url: 'https://www.12315.cn/',
        },
        {
          name: 'internet-police',
          title: '网络警察',
          url: 'http://cyberpolice.mps.gov.cn/wfjb/',
        },
        {
          name: 'internet-harmful-msg',
          title: '网络有害信息',
          url: 'https://www.12377.cn/',
        },
        {
          name: 'ipp',
          title: '知识产权',
          url: 'https://ggfw.cnipa.gov.cn/PatentCMS_Center/',
        },
        {
          name: 'crackdown',
          title: '扫黄打非',
          url: 'https://www.shdf.gov.cn/',
        },
      ],
    };
  },
  mounted() {
    // this.token = this.$cookies.get('token1');
    // this.getNavbar();
    // this.getLogo();
  },
  watch: {
    showLink: {
      handler(val) {
        if (val) {
          this.policy = [
            { title: '隐私政策', url: 'https://home.ximu.cn/PlatRule/85' },
            { title: '法律声明', url: 'https://home.ximu.cn/PlatRule/87' },
            // {title: '廉正举报', url: ''},
            { title: '商品发布政策', url: 'https://home.ximu.cn/PlatRule/16' },
            { title: '友情链接', url: '/friendlyLinks' },
          ];
        } else {
          this.policy = [
            { title: '隐私政策', url: 'https://home.ximu.cn/PlatRule/85' },
            { title: '法律声明', url: 'https://home.ximu.cn/PlatRule/87' },
            // {title: '廉正举报', url: ''},
            { title: '商品发布政策', url: 'https://home.ximu.cn/PlatRule/16' },
          ];
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 跳转
    jump(url) {
      if (url === '') {
        this.$message.warning('暂未开放，敬请期待');
        return;
      }
      if (url) {
        window.open(url);
      }
    },
    // getNavbar() {
    //   let option = {};
    //   this.$getNavbar(option)
    //     ?.then(res => {
    //       if (res.errcode == 0) {
    //         this.treeData = res.data.list;
    //       }
    //     })
    //     ?.catch(err => {
    //       console.log(err, 'err');
    //     });
    // },
    // getLogo() {
    //   let option = {};
    //   this.$getLogo(option)
    //     ?.then(res => {
    //       if (res.errcode == 0) {
    //         this.logoImg = res.data.image;
    //       }
    //     })
    //     ?.catch(err => {
    //       console.log(err, 'err');
    //     });
    // },
  },
};
</script>

<style scoped lang="scss">
.index-bottom {
  .wrap {
    margin: 0 auto;
    // width: 1200px;

    .service {
      display: flex;
      justify-content: center;
      height: 178px;

      .logo-img {
        width: 100px;
        height: 100px;
      }

      .logo {
        margin: 39px 75px;
        width: 100px;
        height: 100px;
        color: #ff7802;

        .iconfont {
          line-height: 100px;
          font-size: 36px;
        }
      }

      .feature {
        display: flex;

        .feature-list {
          padding: 30px 0 0 67px;
          width: 190px;

          .title {
            margin-bottom: 20px;
            line-height: 14px;
            color: #4d4d4d;
            font-size: 14px;
            font-weight: bold;
            cursor: auto;

            &:hover {
              color: #4d4d4d;
            }
          }

          div {
            margin-bottom: 10px;
            line-height: 18px;
            color: #808080;
            font-size: 12px;
            a {
              cursor: pointer;
              &:hover {
                color: #ff7802;
              }
            }
          }
        }
      }
    }

    .separate-line {
      border-bottom: 1px solid #e6e6e6;
    }

    .regulatory-policy {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0 40px;

      .policy,
      .about-copyright {
        display: flex;
        line-height: 12px;
        color: #808080;
        font-size: 12px;

        flex-wrap: wrap;
        justify-content: center;

        .click-to-jump {
          cursor: pointer;

          &:hover {
            color: #ff7802;
          }
        }
      }

      .policy-list,
      .about-copyright-list {
        display: flex;
      }

      .about-copyright {
        margin: 20px 0 20px;
        color: #999;

        .about-copyright-list {
          margin-bottom: 10px;
        }
      }

      .vertical-line {
        padding: 0 10px;
        color: #ccc !important;
        cursor: auto !important;
      }

      .web-pic {
        display: flex;

        div {
          margin-right: 10px;
          height: 32px;
          background-image: url('./images/bottomLong.png');
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }
        }

        .right-distance {
          margin-right: 10px;
        }

        .credible-web {
          width: 92px;
          background-position: 0 0;
        }

        .integrity-web {
          width: 85px;
          background-position: -92px 0;
        }

        .complaint-reporting {
          width: 89px;
          background-position: -177px 0;
        }

        .internet-police {
          width: 88px;
          background-position: -266px 0;
        }

        .internet-harmful-msg {
          width: 106px;
          background-position: -353px 0;
        }

        .ipp {
          width: 132px;
          background-position: -459px 0;
        }

        .crackdown {
          width: 100px;
          background-position: -590px 0;
        }
      }
    }
  }
}
</style>

/**
 * 通用的 js 工具函数的出口文件
 *
 * @author hukeyi
 * @date 2021-09-12
 * @description 通用的 javascript 工具函数
 * @example
 * import * as XmTools from '@/utils/xm-jstools/index';
 * Vue.prototype.$ximu = XmTools;
 *
 * this.$ximu.isPlainObject(obj);
 */

export * from './data';
export * from './object';
export * from './storage';

/**
 * 如果是开发环境，则控制台打印警告
 * @param { String } str 打印的警告
 */
export function devWarn(str) {
  if (process?.env?.NODE_ENV === 'development') {
    console.warn(str);
  }
}

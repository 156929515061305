<template>
  <div class="table-box-style">
    <!-- 批量操作 -->
    <div class="batch-peration-box" v-if="tableCheck">
      <div class="choose-text">
        已选择 <span class="choose-num">{{ selectedRows.length }}</span>
      </div>
      <slot name="batchBtn"></slot>
    </div>
    <!-- 更多字段 -->
    <div class="table-title-mfield" v-if="isTitle">
      <slot name="tableTitle"></slot>
      <div class="action-right" v-if="moreField && moreField.length > 0">
        <el-popover
          placement="bottom"
          trigger="click"
          popper-class="wareTable-popover-box"
          width="294"
        >
          <div class="wareTable-chekcbox-group">
            <el-checkbox-group
              v-model="tableFiledCtrl"
              @change="checkboxChange"
            >
              <el-checkbox
                v-for="(item, index) in moreFieldList"
                :label="item"
                :key="item.text + index"
                >{{ item.text }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="showBtn" slot="reference">
            <span>显示更多字段</span>
            <span class="iconfont">&#xe608; </span>
          </div>
        </el-popover>
      </div>
      <slot name="tableBtnIcon"></slot>
    </div>
    <!-- 表格 -->
    <el-table
      v-sticky-header="{ offsetTop: '50px' }"
      :data="tableData"
      :header-cell-style="headerStyle2"
      @select="handleSelect"
      @select-all="handleSelectAll"
      ref="allTable"
      class="table-style"
      v-if="isXmEmpty ? tableData && tableData.length > 0 : true"
    >
      <el-table-column
        :fixed="isFixed ? 'fixed' : false"
        v-if="tableCheck"
        type="selection"
        width="46"
      >
      </el-table-column>
      <el-table-column
        v-for="(item, index) in ConfigurationList"
        :prop="item.filed"
        :label="item.text"
        :min-width="item.width"
        :key="item.filed + index"
        :fixed="isFixed ? item.fixed : false"
      >
        <template #header>
          <slot
            v-if="item.isHeaderEdit"
            :row="{ row }"
            :name="item.filed + 'HeaderSlot'"
          ></slot>
          <div v-else>{{ item.text }}</div>
        </template>
        <template slot-scope="{ row }" v-if="item.filed">
          <div>
            <slot
              :row="{ row }"
              :name="item.filed + 'Slot'"
              v-if="item.isEdit"
            ></slot>
            <div v-else>{{ row[item.filed] | tableFilter }}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <XmEmpty v-else :isShow="true"></XmEmpty>
  </div>
</template>
<script>
export default {
  name: 'WareTable',
  data() {
    return {
      tableDataList: this.tableData,
      ConfigurationList: this.configuration,
      tableFiledCtrl: [], //更多字段选中的
      moreFieldList: this.moreField,
      selectedRows: [], //存储选中的数据
      reserveSelect: [], //全选的预留数据
      row: {},
    };
  },
  props: {
    // 表格配置列表
    configuration: {
      type: Array,
      default: null,
    },
    // 表格信息列表
    tableData: {
      type: Array,
      default: null,
    },
    // 是否有商品列表或更多字段
    isTitle: {
      type: Boolean,
      default: false,
    },
    // 更多字段列表
    moreField: {
      type: Array,
      default: null,
    },
    // 是否开启表格复选
    tableCheck: {
      type: Boolean,
      default: true,
    },
    // 需要拿来对比的字段(全选，单选等事件不放心可以用此字段)
    // 如哦存在表格数据更新的情况，请用这个字段
    compareFields: {
      type: [String, Number, Object],
      default: '',
    },
    // 决定是否固定列
    isFixed: {
      type: Boolean,
      default: true,
    },
    // 是否需要暂无数据图片
    isXmEmpty: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    tableFilter(val) {
      if (!val) return '--';
      if (val === 0) {
        return '0';
      } else {
        return val;
      }
    },
  },
  watch: {
    // 表格配置
    configuration(n) {
      if (n) {
        this.ConfigurationList = n;
      }
    },
    // 更多字段
    moreField(n) {
      if (n) {
        this.moreFieldList = n;
      }
    },
    // 表格数据
    tableData(n) {
      if (n) {
        this.recoveryCheck(this.tableData);
      }
    },
  },
  computed: {
    // tableDataList() {
    //   return this.tableData;
    // },
    // ConfigurationList() {
    //   // this.selectedRows = [];
    //   return this.Configuration;
    // },
    // moreFieldList() {
    //   return this.moreField;
    // },
  },
  mounted() {},
  methods: {
    // 单选
    handleSelect(selection, row) {
      let checkBox = '';
      let idx = '';
      if (this.compareFields && this.compareFields !== '') {
        // 判断是否选中
        checkBox = selection.find(
          item => item[this.compareFields] === row[this.compareFields]
        );
        /**
         * 使用这个方式比较有个注意点，对象中的属性，序列要一致
         */
        idx = this.selectedRows.findIndex(
          item => item[this.compareFields] === row[this.compareFields]
        );
      }
      if (this.compareFields === '') {
        // 判断是否选中
        checkBox = selection.find(
          item => JSON.stringify(item) === JSON.stringify(row)
        );
        /**
         * 使用这个方式比较有个注意点，对象中的属性，序列要一致
         */
        idx = this.selectedRows.findIndex(
          item => JSON.stringify(row) === JSON.stringify(item)
        );
      }
      // 未选中
      if (!checkBox) {
        if (idx > -1) {
          this.selectedRows.splice(idx, 1);
        }
      } else {
        if (idx === -1) {
          this.selectedRows.push(row);
        }
      }

      this.$emit('select', this.selectedRows);
    },
    // 全选
    handleSelectAll(selection) {
      // 全选或取消全选时更新选中行的数据
      // 全选的情况
      if (selection) {
        selection.forEach(item => {
          this.handleSelect(selection, item);
        });
      }
      // 取消全选的情况
      if (selection.length === 0) {
        this.reserveSelect.forEach(item => {
          this.handleSelect(selection, item);
        });
      }
      this.reserveSelect = JSON.parse(JSON.stringify(selection));
    },
    // 根据数据恢复选中状态
    recoveryCheck(arr) {
      if (arr && arr.length > 0) {
        // 有特定字段的情况
        if (this.compareFields && this.compareFields !== null) {
          arr.forEach(item => {
            if (
              this.selectedRows.findIndex(
                item2 => item2[this.compareFields] === item[this.compareFields]
              ) > -1
            ) {
              this.$nextTick(() => {
                this.$refs.allTable.toggleRowSelection(item, true);
              });
            }
          });
        } else {
          arr.forEach(item => {
            if (
              this.selectedRows.findIndex(
                item2 => JSON.stringify(item2) === JSON.stringify(item)
              ) > -1
            ) {
              this.$nextTick(() => {
                this.$refs.allTable.toggleRowSelection(item, true);
              });
            }
          });
        }
      }
    },

    // 更多字段的选择规则
    checkboxChange() {
      // 拷贝
      let arr = JSON.parse(JSON.stringify(this.configuration));
      // 初始化
      this.ConfigurationList = arr;
      // 选中进入
      if (this.tableFiledCtrl && this.tableFiledCtrl.length > 0) {
        this.tableFiledCtrl.forEach(item => {
          this.ConfigurationList.splice(
            this.ConfigurationList.length - 1,
            0,
            item.fieladList
          );
        });
      }
      if (this.$refs.allTable) {
        this.$refs.allTable.doLayout();
      }
    },

    // 重置表格选中的数据
    reSelectData() {
      this.tableFiledCtrl = [];
      this.selectedRows = [];
      if (this.$refs.allTable) {
        this.$refs.allTable.doLayout();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-box-style {
  .table-title-mfield {
    display: flex;
    padding: 20px 0;
  }
  .action-right {
    display: flex;
    cursor: pointer;
    margin-left: auto;
    .showBtn {
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #cccccc;
      padding: 9px 16px;
      > span {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #808080;
      }
      .iconfont {
        margin-left: 10px;
      }
    }
  }

  .batch-peration-box {
    display: flex;
    .choose-text {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      .choose-num {
        color: #ff7802;
      }
    }
  }

  .batch-btn-box {
    display: flex;
    .batch-btn-style {
      padding: 9px 20px;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      margin-left: 20px;
      cursor: pointer;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
}
.table-style {
  ::v-deep .cell {
    padding-right: 0px !important;
    padding-left: 6px !important;
  }
  ::v-deep .el-table__cell {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}
.margin-leftauto {
  margin-left: auto;
}
</style>
<style>
.wareTable-popover-box .wareTable-chekcbox-group .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.wareTable-popover-box .wareTable-chekcbox-group .el-checkbox {
  margin-right: 0px;
  width: 122px;
}
</style>

import { LogoutDetector } from './logout_detector';

const EXPIRED_TIME = 48 * 60 * 60 * 1000; // 供应商账号：同一浏览器48小时未操作自动退出

let detector = new LogoutDetector(EXPIRED_TIME, 1000);
let detectedOperationList = ['keydown', 'mousedown']; // 要检测的动态操作的事件名称

export function startDetector() {
  let recorder = detector.getRecorder();

  /**
   * 绑定目标事件的监听
   */
  for (const eventName of detectedOperationList) {
    window.addEventListener(eventName, recorder);
  }

  detector.start();
  recorder();

  return detector;
}

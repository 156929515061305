<template>
  <div class="fixpos" v-if="showAccountManager">
    <div class="ximuserve">
      <div class="serveinner" @click="chatWithCustomerService">
        <div class="iconfont iconserve">&#xe612;</div>
        <div>客服</div>
      </div>
    </div>
    <xmAccountManager></xmAccountManager>
  </div>
</template>

<script>
import xmAccountManager from '@/components/XimuComponents/XmAccountManager.vue';
import { CS_ROUTE_NAME } from '@/utils/chat_tools.js';
export default {
  name: 'contactBackend',
  components: {
    xmAccountManager,
  },
  computed: {
    // 是否展示联系客服和客户经理
    showAccountManager() {
      return !this.$route?.meta?.hideContactSticker;
    },
  },
  methods: {
    // 打开与客服聊天的界面
    chatWithCustomerService() {
      let routeData = this.$router.resolve({
        path: '/chat/' + CS_ROUTE_NAME,
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style scoped>
.fixpos {
  position: fixed;
  bottom: 200px;
  right: 60px;

  z-index: 2001;
}
.ximuserve {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #ffffff;
  color: #808080;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}

.ximuserve:hover {
  background: #ff7802;
  color: #ffffff;
}

.serveinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

.iconserve {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 22px;
  margin: auto;
}
</style>

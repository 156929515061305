<template>
  <!-- 类目 -->
  <el-cascader
    v-model="cascaderValue"
    :options="categoryList"
    :size="size"
    :props="categoryOptions"
    @change="$emit('change', $event, categoryContent)"
    @visible-change="valchang"
    placeholder="类目"
    class="cascader_box"
    popper-class="cascader_max"
    ref="cascader"
    :filterable="isfilterable"
    :clearable="isClearable"
    :append-to-body="appendToBody"
  ></el-cascader>
</template>

<script>
import { getCategoryApi } from '@/api/GoodsApi';
import { size } from './scripts/props';

export default {
  name: 'SelectCategory',
  props: {
    size,
    value: {},
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    isfilterable: {
      type: Boolean,
      default: false,
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      categoryList: [],
      keyindex: 0,
      categoryContent: '',
    };
  },
  created() {
    this.getcategory();
  },
  computed: {
    cascaderValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.getCategoryName();
      },
    },
    categoryOptions() {
      return {
        label: 'name',
        value: 'supplier_class_id',
        children: '_child',
        checkStrictly: this.checkStrictly,
      };
    },
  },
  methods: {
    getCategoryName() {
      let nodes = this.$refs.cascader.getCheckedNodes();
      if (nodes.length !== 0) {
        let level = nodes[0] ? nodes[0] : '';

        let level1 = level.parent ? level.parent.parent.label : '';
        let level2 = level.parent ? level.parent.label : '';
        let level3 = level.label;
        this.categoryContent = `${level1}/${level2}/${level3}`;
        // console.log('categoryContent=>', this.categoryContent);
      } else {
        this.categoryContent = '';
      }
    },
    // getCategoryList() {
    //     getCategoryApi().then(data => {
    //         this.categoryList = data;
    //     });
    // },
    getcategory() {
      this.categoryList = this.$store.state.category.categoryData;
      // const api = 'CategoryInfo.getAllCategoryInfo';
      // xmJson(
      //   api,
      //   {},
      //   res => {
      //     if (res.errcode === 0) {
      //       this.categoryList = res.data;
      //     } else {
      //     }
      //   },
      //   err => {}
      // );
    },
    valchang(val) {
      if (!val && !this.cascaderValue[0]) {
        this.keyindex++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cascader_box {
  ::v-deep .el-input__inner {
    width: 255px;
  }
  ::v-deep .el-cascader-node.in-active-path {
    color: #ff7802;
  }
}
</style>
<style>
.cascader_max .in-active-path {
  background-color: #fff2e6 !important;
}
.cascader_max .is-active {
  background-color: #fff2e6 !important;
}
</style>

<template>
  <div class="prew">
    <div class="mask">
      <div class="imgmodel" :style="cssVars">
        <div class="topx" @click="closeimg">
          <img src="@/assets/images/cross.svg" class="imgclosemodel" />
        </div>
        <div class="imgout" v-show="currenttype == '1'">
          <video
            id="myVideo"
            ref="video"
            class="video-js vjs-default-skin viedomodel"
            controls
            data-setup='{"controlBar": {"pictureInPictureToggle": false}}'
          >
            <source :src="showurl" type="video/mp4" />
          </video>
        </div>
        <div class="imgout" v-show="currenttype == '2'">
          <img :src="showurl" class="imgitem" @error="handleImgError" />
          <div class="rote-model iconfont" @click="rotateLeft" v-if="isRote">
            &#xe67b;
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Video from 'video.js';
// import 'video.js/dist/lang/zh-CN'
// import 'video.js/dist/video-js.css';
export default {
  name: 'previewimg',
  props: {
    showurl: {
      type: String,
      default: '',
    },
    // 是否可以旋转
    isRote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      player: null, // 用来存储当前 video
      currenttype: '', //
      degNum: 0, // 旋转角度
    };
  },
  computed: {
    cssVars() {
      return {
        '--degNum': this.degNum + 'deg',
      };
    },
  },
  mounted() {
    // this.player = this.$video(this.$refs.video);
    // var options = {
    //   muted: true,
    //   controls : true,
    //   height:300,
    //   width:300,
    //   loop : true,
    // };

    // this.player  = Video('example_video_1', options, function onPlayerReady() {

    // });
    let length = this.showurl.split('.').length;
    if (this.showurl.split('.')[length - 1] == 'mp4') {
      this.initVideo();
      this.currenttype = 1;
    } else {
      this.currenttype = 2;
    }
  },
  beforeDestroy() {
    if (this.currenttype === 1) {
      this.player.dispose();
    }
  },
  methods: {
    closeimg() {
      this.$emit('closepreimg');
    },
    initVideo() {
      this.player = videojs('myVideo', {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: 'auto',
        //设置视频播放器的显示宽度（以像素为单位）
        width: '500px',
        //设置视频播放器的显示高度（以像素为单位）
        height: '500px',
      });
    },
    // 点击旋转90°
    rotateLeft() {
      this.degNum = this.degNum - 90;
    },
  },
};
</script>

<style scoped>
.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: #000;
    opacity: 0.25; */
  background: rgba(0, 0, 0, 0.25);
  z-index: 99999;
}

.imgmodel {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: fit-content;
  /* height: 800px; */
  /* background: #ffffff; */
}

.imgout {
  max-width: 100vh;
  max-height: 90vh;
  position: relative;
  /* width: 500px;
  max-height: calc(100vh - 300px);
  overflow: auto; */
}

.rote-model {
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  font-size: 20px;
}

.imgitem {
  width: 100%;
  max-height: 90vh;
  object-fit: contain;
  transform: rotate(var(--degNum));
}

.topx {
  position: absolute;
  right: -39px;
  top: -36px;
  cursor: pointer;
}

.imgclosemodel {
  width: 32px;
  height: 32px;
}

.viedomodel ::v-deep .vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block !important;
}

.viedomodel ::v-deep .vjs-big-play-button {
  font-size: 2.5em;
  line-height: 2.3em;
  height: 2.5em;
  width: 2.5em;
  -webkit-border-radius: 2.5em;
  -moz-border-radius: 2.5em;
  border-radius: 2.5em;
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
  border-width: 0.15em;
  margin-top: -1.25em;
  margin-left: -1.75em;
}
/* 中间的播放箭头 */
.viedomodel ::v-deep .vjs-big-play-button .vjs-icon-placeholder {
  font-size: 1.63em;
}
/* 加载圆圈 */
.viedomodel ::v-deep .vjs-loading-spinner {
  font-size: 2.5em;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  margin-top: -1em;
  margin-left: -1.5em;
}

.viedomodel ::v-deep .vjs-play-progress {
  background: #ff7802;
}

.viedomodel ::v-deep .vjs-play-control.vjs-ended .vjs-icon-placeholder:before,
.vjs-icon-replay:before {
  content: '\f101';
}

.viedomodel ::v-deep .vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
  background: #ff7802;
}
</style>

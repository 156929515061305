import { copyText } from '@/utils/ctools';
import HeadNav from '@/components/common/headNav.vue';
import currentLocation from '@/components/common/currentLocation.vue';
import * as cmixin from './cmixin';
import IMG_ERROR_PLACEHOLDER from './imgConstant';
import QRCode from 'qrcode';
import axios from 'axios';
import { CS_ROUTE_NAME } from '@/utils/chat_tools.js';
// 记录用户筛选条件
import { getPageFilter } from '@/utils/page-filter/tool.js';

let mixin = {
  components: { HeadNav, currentLocation },
  data() {
    return {};
  },
  created() {},
  activated() {
    if (
      !localStorage.getItem('uploadBucketId') ||
      !localStorage.getItem('uploadAppid') ||
      !localStorage.getItem('uploadSecret')
    ) {
      //获取素材  用于调用图片上传组件
      // this.getMaterialList();
    }
  },
  filters: {
    //时间戳过滤
    getCurentDate(val) {
      if (!hasValue(val)) {
        return val;
      }
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      return y + '-' + m + '-' + d;
    },
    //时间戳过滤
    getCurentDateTime(val) {
      if (!hasValue(val)) {
        return val;
      }
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;

      var h = date.getHours();
      h = h < 10 ? '0' + h : h;
      var m = date.getMinutes();
      m = m < 10 ? '0' + m : m;
      var s = date.getSeconds();
      s = s < 10 ? '0' + s : s;
      return y + '-' + m + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
    // 图片过滤
    imgbaseurl(value) {
      if (!value) return '';
      const procotol = value.substring(0, 4);
      const prefix = procotol == 'http' ? '' : 'https://img.ximu.cn/';
      return prefix + value;
    },
  },
  methods: {
    // 图片过滤
    setImgbaseurl(value) {
      if (!value) return '';
      const procotol = value.substring(0, 4);
      const prefix = procotol == 'http' ? '' : 'https://img.ximu.cn/';
      return prefix + value;
    },
    // 当前是否是本地环境或者 188 测试环境 或者 191 或者 190
    isLocalEnv() {
      return (
        process.env.VUE_APP_ENV === 'local' ||
        process.env.VUE_APP_ENV === 'dev-188' ||
        process.env.VUE_APP_ENV === 'check-191' ||
        process.env.VUE_APP_ENV === 'release-190'
      );
    },
    /**
     * 生成二维码
     */
    createQRcode(options, cb) {
      if (!options) {
        return;
      }
      // 会返回一个base64的链接
      return QRCode.toDataURL(options.text, options, cb);
    },
    /**
     * 获取供应商是否被限制操作的
     */
    // 获取该供应商的状态
    async getOperation() {
      return new Promise(async (resolve, reject) => {
        let api = 'AccountStatusCheck/accountStatusCheck';
        const options = {};
        try {
          xmJson(
            api,
            options,
            res => {
              if (res.errcode === 0) {
                resolve(res);
              } else {
                resolve(res);
              }
            },
            err => {},
            'get',
            true
          );
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    /**
     * 防抖函数
     * @param Function func 目标函数
     * @param String wait 时间
     * @returns
     */
    _debounce: function (func, wait) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    ...cmixin.methods,
    //解析图片(将多个或一个string风格的图片url解析成数组返回)
    analysisImg(imgString) {
      let arr = [];
      if (imgString.indexOf(',') != -1) {
        arr = imgString.split(',');
        return arr;
      }
      return imgString ? [imgString] : arr;
    },
    //回到旧版本
    backOldVersion() {
      window.location.href = getProjectModuleUrl('supplier-pc');
    },
    imgError(e, imgName = '') {
      // 图片加载出错
      if (hasValue(imgName)) {
        e.target.src = `/static/images/error_images/${imgName}.png`;
      } else {
        // e.target.src = "/static/images/error_images/shopDefult.png";
      }
      e.srcElement.onerror = null; //防止闪图
    },
    appBack() {
      this.$router.go(-1);
    },
    //route replace
    loadPageReplaceOrPush(param, type = 'replace', item, status) {
      if (status && (status == '6' || status == '7')) {
        return;
      }
      if (item && item.isTip) {
        this.$warningToast('敬请期待');
        return;
      }
      if (typeof param == 'string' && param.indexOf('http') >= 0) {
        window.location.href = param;
      } else if (typeof param == 'string' && param.indexOf('/#/') >= 0) {
        //VUE_LOCATION  common.js  参数（微商城链接）
        window.location.href = VUE_LOCATION + param;
      } else {
        this.$router[type](param);
      }
    },
    // 限制数字输入
    numberSet(num, maxValue = '') {
      let str = num.target.value;
      let value = str.replace(/[^0-9]/g, '');
      //当前数字不能超过最大值
      if (hasValue(maxValue) && parseFloat(value) > parseFloat(maxValue)) {
        value = maxValue;
      }
      num.target.value = value;
    },
    //数字转换
    strTofolatNumber(str) {
      let value = '';
      if (hasValue(str)) {
        str += '';
        if (str.indexOf('.') >= 0) {
          let first = str.slice(0, str.indexOf('.'));
          let last = str.slice(str.indexOf('.') + 1);
          first = first.replace(/\D/g, '');
          last = last.replace(/\D/g, '');
          str = first + '.' + last.slice(0, 2);
        } else {
          str = str.replace(/\D/g, '');
        }
        value = parseFloat(str);
      }
      return value;
    },
    // 限制数字输入(2未小数点)  最大值
    numberSetDecimalPoint(num, maxValue = '') {
      let value = num.target.value;
      if (value.indexOf('.') >= 0) {
        let first = value.slice(0, value.indexOf('.'));
        let last = value.slice(value.indexOf('.') + 1);
        first = first.replace(/\D/g, '');
        last = last.replace(/\D/g, '');
        value = first + '.' + last.slice(0, 2);
      } else {
        value = value.replace(/\D/g, '');
      }
      //当前数字不能超过最大值
      if (hasValue(maxValue) && parseFloat(value) > parseFloat(maxValue)) {
        value = maxValue;
      }
      num.target.value = value;
    },
    getCurentDate(model = 'curent') {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (model === 'curent') {
        return year + 1;
      } else if (model == 'year') {
        return year;
      }
      if (day < 15) {
        day = 1;
      } else {
        day = 15;
      }
      return `${year}年${month}月${day}日`;
    },
    //获取当前日期
    getCurrentDate(type = 'date') {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      var day = date.getDate();
      day = day < 10 ? '0' + day : day;

      var h = date.getHours();
      h = h < 10 ? '0' + h : h;
      var m = date.getMinutes();
      m = m < 10 ? '0' + m : m;
      var s = date.getSeconds();
      s = s < 10 ? '0' + s : s;
      if (type == 'time') {
        return `${year}-${month}-${day} ${h}:${m}:${s}`;
      }
      return `${year}-${month}-${day}`;
    },
    formatDateFun(date) {
      if (typeof date == 'string') {
        return this.formatDateFunTwo(date);
      }
      if (!hasValue(date)) {
        return date;
      }
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      return y + '-' + m + '-' + d;
    },
    formatDateFunTwo(date) {
      if (!hasValue(date)) {
        return date;
      }
      var y = date.substring(0, 4);
      var m = date.substring(5, 7);
      var d = date.substring(8, 10);

      return y + '-' + m + '-' + d;
    },
    //根据当前路由返回数组中的某一项
    getItemOfRoutePath(arr = []) {
      let item = {};
      let that = this;
      if (arr.length > 0) {
        arr.map(val => {
          if (!val.children) {
            item = this.getItemOfRoutePathMeans(val, item);
          } else {
            val.children.forEach(obj => {
              item = this.getItemOfRoutePathMeans(obj, item);
            });
          }
        });
      }
      return item;
    },
    getItemOfRoutePathMeans(val = {}, oldItem = {}) {
      let item = {};
      let flag = false;
      if (this.$route.path == val.path) {
        item = val;
        flag = true;
      }
      let leftNavIndex = getFirstAvailableValue(
        ObjectValue('meta.leftNavIndex', this.$route),
        ''
      );
      if (leftNavIndex && leftNavIndex == val.path) {
        item = val;
        flag = true;
      }
      return flag ? item : oldItem;
    },
    //复制文本
    copyText(text) {
      copyText(text)
        ? this.$successToast('复制成功')
        : this.$warningToast('复制失败');
    },
    //保存登录信息
    saveLoginInfo(data) {
      // localStorage.setItem("token", getFirstAvailableValue(ObjectValue('token', data.token), ''));
      // localStorage.setItem("email", getFirstAvailableValue(ObjectValue('email', data.email), ''));
      // localStorage.setItem("account", getFirstAvailableValue(ObjectValue('account', data.account), ''));
      // localStorage.setItem("comId", getFirstAvailableValue(ObjectValue('comId', data.comId), ''));
      // localStorage.setItem("role", getFirstAvailableValue(ObjectValue('role', data.role), ''));
      // localStorage.setItem("status", getFirstAvailableValue(ObjectValue('status', data.status), ''));
      // localStorage.setItem("uid", getFirstAvailableValue(ObjectValue('uid', data.uid), ''));

      console.log(data, '-----------01');
      localStorage.setItem('token', data.token);
      localStorage.setItem('email', data.email);
      localStorage.setItem('account', data.account);
      localStorage.setItem('comId', data.comId);
      localStorage.setItem('role', data.role);
      localStorage.setItem('status', data.status);
      localStorage.setItem('uid', data.uid);
    },
    //类目转换树形结构
    categoryToTree(data) {
      let result = [];
      if (!Array.isArray(data)) {
        return result;
      }
      data.forEach(item => {
        delete item.children;
      });
      let map = {};
      data.forEach(item => {
        item['label'] = item.cat_name;
        item['value'] = item.cat_id;
        map[item.cat_id] = item;
      });
      data.forEach(item => {
        let parent = map[item.parent_id];
        item['label'] = item.cat_name;
        item['value'] = item.cat_id;
        if (parent) {
          (parent.children || (parent.children = [])).push(item);
        } else {
          result.push(item);
        }
      });
      return result;
    },
    //多级别数据转换树形结构
    multilevelDataToTree(
      data,
      label = 'name',
      value = 'id',
      parentId = 'parent_id'
    ) {
      let result = [];
      if (!Array.isArray(data)) {
        return result;
      }
      data.forEach(item => {
        delete item.children;
      });
      let map = {};
      data.forEach(item => {
        item['label'] = item[label];
        item['value'] = item[value];
        map[item[value]] = item;
      });
      data.forEach(item => {
        let parent = map[item[parentId]];
        item['label'] = item[label];
        item['value'] = item[value];
        if (parent) {
          (parent.children || (parent.children = [])).push(item);
        } else {
          result.push(item);
        }
      });
      return result;
    },
    //变更图片地址
    changeImgSrc(el, src) {
      el.target.src = src;
    },
    //获取素材管理列表
    getMaterialList() {
      let that = this;
      xmJson('bucketmanage.getindex', {}, res => {
        if (res.errcode == 0) {
          localStorage.setItem(
            'uploadBucketId',
            getFirstAvailableValue(ObjectValue('data.bucket_id', res), '')
          );
          localStorage.setItem(
            'uploadAppid',
            getFirstAvailableValue(ObjectValue('data.appid', res), '')
          );
          localStorage.setItem(
            'uploadSecret',
            getFirstAvailableValue(ObjectValue('data.secret', res), '')
          );
        }
      });
    },

    //联机选择器数据格式转换  "1-1-1,2-2-2" =>[[1,1,1],[2,2,]]
    cascaderDataChange(saleableArea = '') {
      let saleableAreaArrTwo = [];
      let saleableAreaArr = [];
      if (hasValue(saleableArea)) {
        saleableAreaArr = saleableArea.split(',');
        if (saleableAreaArr.length > 0) {
          saleableAreaArr.forEach(item => {
            saleableAreaArrTwo.push(item.split('-'));
          });
        }
      }
      return saleableAreaArrTwo;
    },
    //联机选择器 (idStr:'1-1-1,2-2-2',nameStr:'name1-name1-name1') =>[id:1,name:1,chilen:{id:2,name:2,pid:1}]
    cascaderData(idStr = '', nameStr = '', type = '1') {
      let idArr = this.cascaderDataChange(idStr);
      let options = [];
      let nameArr = this.cascaderDataChange(nameStr);
      if (idArr.length > 0 && nameArr.length > 0) {
        idArr.forEach((item, index) => {
          let pid = 0;
          item.forEach((val, i) => {
            if (i == 0) {
              pid = val;
            }
            let obj = {
              id: val,
              name: nameArr[index][i],
              cname: nameArr[index][i],
              pid: i == 0 ? 0 : pid,
              continent_id: i == 0 ? 0 : pid,
            };
            options.push(obj);
          });
        });
      }
      //去除重复的数据
      let obj = {};
      options = options.reduce((preVal, curVal) => {
        obj[curVal.id] ? '' : (obj[curVal.id] = preVal.push(curVal));
        return preVal;
      }, []);
      if (type != '1') {
        return options;
      }
      options = this.multilevelDataToTree(
        options,
        'name',
        'id',
        'continent_id'
      );
      return options;
    },
    //非同源实现点击下载功能
    downloadIamge(imgsrc, name) {
      let image = new Image();

      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');

      image.onload = function () {
        let canvas = document.createElement('canvas');

        canvas.width = image.width;

        canvas.height = image.height;

        let context = canvas.getContext('2d');

        context.drawImage(image, 0, 0, image.width, image.height);

        let url = canvas.toDataURL('image/png'); //得到图片的base64编码数据

        let a = document.createElement('a'); // 生成一个a元素

        let event = new MouseEvent('click'); // 创建一个单击事件

        a.download = name || 'photo'; // 设置图片名称

        a.href = url; // 将生成的URL设置为a.href属性

        a.dispatchEvent(event); // 触发a的单击事件
      };

      image.src = imgsrc;
    },
    //实现单击下载视频
    downloadVideo(url, name) {
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.display = 'none';
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name;
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
    },
    // 表头样式1(14px字体)
    headerStyle() {
      return {
        background: '#F4F5F6',
        color: '#333333',
        fontWeight: '400',
        fontSize: '14px',
      };
    },
    // 表头样式2(12px字体)
    headerStyle2() {
      return {
        background: '#F4F5F6',
        color: '#4D4D4D',
        fontWeight: '400',
        fontSize: '12px',
      };
    },
    // 表头样式3(12px字体，40px高)
    headerStyle3() {
      return {
        background: '#F4F5F6',
        color: '#333',
        fontWeight: '400',
        fontSize: '12px',
        padding: '0',
        height: '40px',
      };
    },
    // 表头样式4(12px字体，40px高)
    headerStyle4() {
      return {
        background: '#F4F5F6',
        color: '#333',
        fontWeight: '400',
        fontSize: '14px',
        height: '40px',
      };
    },
    // 表头样式5(12px字体，40px高)
    headerStyle5() {
      return {
        background: '#F4F5F6',
        color: '#333',
        fontWeight: '400',
        fontSize: '14px',
        height: '40px',
      };
    },
    // 表头样式6(14px字体，背景色F1F2F4)
    headerStyle6() {
      return {
        background: '#F1F2F4',
        color: '#666666',
        fontWeight: '400',
        fontSize: '14px',
        padding: '8px 0',
      };
    },
    //获取当前时间并计算时间差
    getNowTime(time) {
      let date3 = Number(time);
      // 处理非数字型值
      if (isNaN(date3)) {
        return '--';
      }
      if (date3 <= 0) {
        return '超时';
      } else {
        //计算出相差天数
        let days = Math.floor(date3 / (24 * 3600 * 1000));
        //计算出小时数
        let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        let hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        let minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        let seconds = Math.round(leave3 / 1000);
        let surplusTime =
          days + '天' + hours + '时' + minutes + '分' + seconds + '秒';
        return surplusTime;
      }
    },
    // 倒计时 （跟上面配套使用，有问题改或说）
    // 自己定义一个setInterval 放进自己定义的变量里 进行倒计时 ，注：记得销毁计时器
    countdown(gettime) {
      // 需要传进来的时间戳  10位数就*1000
      const t1 = Date.parse(new Date());
      return gettime - t1 > 0 ? gettime - t1 : 0;
    },
    // 下载资料
    baseurl(value) {
      if (!value) return '';

      return process.env.VUE_APP_SUPPLIER_HOST + '/supplierapi/' + value;
    },
    // 通过后缀名作文件判断
    matchType(fileName) {
      // 后缀获取
      var suffix = '';
      // 获取类型结果
      var result = '';
      try {
        var flieArr = fileName.split('.');
        suffix = flieArr[flieArr.length - 1]?.toLowerCase();
      } catch (err) {
        suffix = '';
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'jfif'];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'image';
        return result;
      }
      // 匹配txt
      var txtlist = ['txt'];
      result = txtlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'txt';
        return result;
      }
      // 匹配 excel
      var excelist = ['xls', 'xlsx'];
      result = excelist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'excel';
        return result;
      }
      // 匹配 word
      var wordlist = ['doc', 'docx'];
      result = wordlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'word';
        return result;
      }
      // 匹配 pdf
      var pdflist = ['pdf'];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'pdf';
        return result;
      }
      // 匹配 ppt
      var pptlist = ['ppt', 'pptx'];
      result = pptlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'ppt';
        return result;
      }
      // 匹配 视频
      var videolist = ['mp4', 'm2v', 'mkv'];
      result = videolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'video';
        return result;
      }
      // 匹配 音频
      var radiolist = ['mp3', 'wav', 'wmv'];
      result = radiolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'radio';
        return result;
      }
      // 其他 文件类型
      result = 'other';
      return result;
    },
    // 回到浏览器顶部
    backTop() {
      document.documentElement.scrollTop = 0;
    },
    // 链接下载
    down(url, fileName) {
      axios({
        url: url + '?attname=' + fileName,
        method: 'get',
        responseType: 'blob',
      }).then(res => {
        const filestream = res.data; // 返回的文件流
        const blob = new Blob([filestream], {
          type: res.headers['content-type'],
        });
        const a = document.createElement('a');
        const href = window.URL.createObjectURL(blob); // 创建下载连接
        a.href = href;
        a.download = decodeURI(fileName);
        document.body.appendChild(a);
        // console.log(a)
        // window.open(a.href)
        a.click();
        document.body.removeChild(a); // 下载完移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    // 下载文件(字符流)
    downloadFile(url, fileName) {
      let box = '/supplierapi' + url;
      axios({
        url: box,
        method: 'get',
        headers: { token: localStorage.getItem('token') },
        responseType: 'blob',
      }).then(res => {
        const blob = new Blob([res.data]);
        const objectURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
      });
    },
    // 打开与客服聊天的界面
    chatWithCustomerService(target = '_blank') {
      let routeData = this.$router.resolve({
        path: '/chat/' + CS_ROUTE_NAME,
      });
      this.$store.commit('clearUnread');
      window.open(routeData.href, target);
    },
    /**
     * 图片加载失败占位
     */
    handleImgError(event) {
      const img = event.target;
      // 用 base64 编码图片作为占位图片，避免出现 onerror 死循环导致递归爆栈
      img.src = IMG_ERROR_PLACEHOLDER;
    },
    /**
     * 节流函数
     * @param Function func 节流目标函数
     * @param String delay 延迟时间
     * @returns
     */
    throttle(func, delay) {
      let timer;
      return function () {
        let context = this;
        let args = arguments;
        if (!timer) {
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, delay);
        }
      };
    },
    /**
     *
     * @param {String} text 容器内的文字
     * @param {Number} widthNumber 容器的最大宽度
     * @param {String} fontStyle 文字的样式
     * @returns {Boolean} true表示溢出
     */
    //判断容器中的内容是否溢出
    isCheckOverflow(text, widthNumber, fontStyle = '14px Microsoft YaHei') {
      const canvasNode = document.createElement('canvas');
      const context = canvasNode.getContext('2d');
      context.font = fontStyle;
      const { width } = context.measureText(text);
      return width > widthNumber;
    },
    /**
     * 获取存在七牛云上的视频的第一帧图片
     * @param { String } videoSrc 七牛云的视频链接
     */
    getVideoPoster(videoSrc) {
      if (!videoSrc) return '';
      // 如果没有拼前缀，则拼上
      if (!videoSrc.startsWith('http')) {
        videoSrc = process.env.VUE_APP_SUPPLIER_STATIC + videoSrc;
      }
      return videoSrc + '?vframe/jpg/offset/1';
    },
    /**
     * 跳转商品预览的公共方法
     * 1）缓存商品的主图或价格最低sku的图片；2）再跳转详情页
     * @author hukeyi
     * @description 为了实现商品预览页主图瞬时出现无需过渡图片的效果
     * @param { String } itemId 商品id
     * @param { String } imgUrl 商品主图url
     * @param { String } skuUrl 商品最低价格sku的图片的url
     * @param { String } target 打开页面的方式，'_self' 当前窗口打开；'_blank' 新窗口打开（默认）
     * @param { String } lang 待预览商品的语言，中文 or 英文
     */
    toCachedGoodPreview(itemId, imgUrl, skuUrl, target = '_blank', lang) {
      if (!itemId || !imgUrl) return;

      let hasCached = this.$store.getters.hasGoodMainImg;
      // 如果当前商品无缓存或者当前 skuUrl 有传值，才缓存商品主图
      if (!hasCached || skuUrl) {
        // 如果没有拼接地址，则拼上
        if (!imgUrl.startsWith('http')) {
          imgUrl = process.env.VUE_APP_SUPPLIER_STATIC + imgUrl;
        }
        if (skuUrl && !skuUrl.startsWith('http')) {
          skuUrl = process.env.VUE_APP_SUPPLIER_STATIC + skuUrl;
        }
        // 首选 skuUrl；如果无，则使用商品主图 imgUrl
        let url = skuUrl ? skuUrl : imgUrl;
        // 缓存商品图片
        this.$store.dispatch('setGoodMainImg', { itemId, imgUrl: url });
      }
      let prefix = '';
      if (lang && lang !== '中文' && lang !== 'zh-cn') {
        prefix = '/en';
      }
      // 跳转商品预览页
      let routeData = this.$router.resolve({
        path: prefix + '/good/preview',
        query: { id: itemId },
      });
      window.open(routeData.href, target);
    },
    /**
     * 这个方法有个限制，只有用户进行筛选，切换tabs等操作的时候才触发，刷新页面的话是不管的
     * @param {Object} options 页面的筛选条件
     */
    setPageFilter(options) {
      // 获取页面筛选条件
      let pagefilterObj = getPageFilter(options);
      // 获取页面路由路径
      let routeLink = this.$route.path;
      this.$router.push({
        path: routeLink,
        query: {
          pageid: pagefilterObj.pageId,
        },
      });
      this.$store.commit('setPageFilterCriteria', pagefilterObj);
    },
    /**
     * 获取当前所在页面缓存的筛选条件
     * @returns 对应缓存的筛选条件
     */
    getPageFilterData() {
      // 获取vuex中的数据
      let pageFiler = this.$store.state.pageFilter.pageFiler;
      let query = this.$route.query;
      if (pageFiler?.[query?.pageid]) {
        let info = pageFiler?.[query?.pageid]?.pageFilter;
        return info || null;
        // 更新开始时间
        // this.$store.commit('upPageFilterStartTime', query?.pageid);
        // 如果缓存中有对应的页面Id
      }
    },
  },
};

export default mixin;

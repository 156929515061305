<template>
  <div class="un-image" :class="[imageSize]">
    <img
      v-if="matchType(src) == 'image'"
      :src="src | imgbaseurl"
      :alt="alt"
      @error="imgError"
    />
    <video
      v-if="matchType(src) == 'video'"
      :src="src | imgbaseurl"
      :alt="alt"
      @error="imgError"
      controls
      muted
      nofullscreen
    ></video>
    <div :class="showDelete?'mask':''">
      <!-- 放控制的东西，例如删除等 -->
      <!-- <span v-if="showLook" @click="showing = true">查看</span> -->
      <div
        v-if="showDelete"
        @click="$emit('remove')"
        class="el-icon-delete ico-size"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnImage',
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '图片',
    },
    // 控制删除
    showDelete: {
      type: Boolean,
      default: true,
    },
    showLook: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'small',
      validator(val) {
        return ['small', 'mini'].includes(val);
      },
    },
  },
  data() {
    return {
      showing: false,
    };
  },
  computed: {
    imageSize() {
      return `un-image__${this.size}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.max-image {
  max-width: 100%;
}
.un-image:last-child {
  margin-right: 0 !important;
}

.un-image {
  width: 100px;
  height: 100px;
  position: relative;

  &.un-image__small {
    width: 100px;
    height: 100px;
  }

  &.un-image__mini {
    width: 75px;
    height: 75px;
  }
  .mask {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #111111;
    transition: all 0.45s;
    opacity: 0;
    display: flex;
    line-height: 28px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;

    span {
      flex: 1;
      text-align: center;
      cursor: pointer;
    }

    .ico-size {
      font-size: 24px;
      opacity: 1 !important;
      line-height: 100px;
      cursor: pointer;
    }
  }

  &:hover .mask {
    opacity: 0.5;
  }

  > img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>

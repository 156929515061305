<template>
  <div class="img-content">
    <div class="cut">
      <vue-cropper
        ref="cropper"
        v-bind="option"
        @realTime="realTime"
      ></vue-cropper>
    </div>
    <div class="test-model">
      <div class="corp-title">裁剪比例</div>
      <div class="check-model">
        <div :class="type == 1 ? 'active-btn' : ''" @click="checkType('1')">
          自由裁剪
        </div>
        <div :class="type == 2 ? 'active-btn' : ''" @click="checkType('2')">
          1:1
        </div>
        <div :class="type == 3 ? 'active-btn' : ''" @click="checkType('3')">
          3:4
        </div>
      </div>
      <div class="corp-title">裁剪尺寸</div>
      <div class="flexLeft corr-input">
        <div>
          <div class="corp-title">宽度</div>
          <div class="flexLeft">
            <el-input
              v-model="cropWidth"
              @change="changeRope()"
              onkeyup="value=value.replace(/[^\d]/g,'')"
            ></el-input>
            &nbsp;
            <div>px</div>
          </div>
        </div>
        <div>
          <div class="corp-title">高度</div>
          <div class="flexLeft">
            <el-input
              v-model="cropHeight"
              @change="changeRope()"
              onkeyup="value=value.replace(/[^\d]/g,'')"
            ></el-input>
            &nbsp;
            <div>px</div>
          </div>
        </div>
      </div>
      <div class="corp-title">图片操作</div>
      <div class="flexLeft">
        <div class="opera-btn" @click="rotateRight">向右旋转</div>
        <div class="opera-btn left30" @click="rotateLeft">向左旋转</div>
      </div>
      <div class="reset-btn" @click="refreshCrop()">重置</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cropWidth: 400,
      cropHeight: 400,
      option: {
        img: this.imgUrl,
        size: 1,
        full: false,
        canScale: true,
        outputType: 'png',
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 400,
        autoCropHeight: 400,
        high: false,
        cropData: {},
        enlarge: 1,
        mode: 'contain',
        maxImgSize: 3000,
        limitMinSize: [50, 50],
        fixed: false,
        fixedNumber: [1, 1],
        fillColor: '',
        centerBox: true,
      },
      type: 1,
    };
  },
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
  },
  methods: {
    clearCrop() {
      // clear
      this.$refs.cropper.clearCrop();
    },
    refreshCrop() {
      // clear
      this.$refs.cropper.refresh();
      this.checkType(1);
      this.type = 1;
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    finish() {
      this.$refs.cropper.getCropData(data => {
        let option = {
          code: data,
          suffix: 'png',
        };
        let api = '/Material/decodeBase64';
        xmJson(
          api,
          option,
          res => {
            if (res.errcode == 0) {
              this.$emit('getCropUrl', res.data.file_key);
            }
          },
          err => {
            console.error(err);
          },
          'post'
        );
      });
    },
    changeRope() {
      if (Number(this.cropWidth) < 50) {
        this.cropWidth = 50;
      }
      if (Number(this.cropHeight) < 50) {
        this.cropHeight = 50;
      }
      this.option.autoCropWidth = Number(this.cropWidth);
      this.option.autoCropHeight = Number(this.cropHeight);
    },
    checkType(type) {
      this.type = type;
      if (type == 1) {
        this.option.autoCropWidth = 400;
        this.option.autoCropHeight = 400;
        this.option.fixed = false;
        this.option.fixedNumber = [1, 1];
      } else if (type == 2) {
        this.option.autoCropWidth = 400;
        this.option.autoCropHeight = 400;
        this.option.fixed = true;
        this.option.fixedNumber = [1, 1];
      } else if (type == 3) {
        this.option.autoCropWidth = 300;
        this.option.autoCropHeight = 400;
        this.option.fixedNumber = [3, 4];
        this.option.fixed = true;
      }
    },
    realTime(obj) {
      this.cropWidth = this.$refs.cropper.cropW;
      this.cropHeight = this.$refs.cropper.cropH;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.img-content {
  display: flex;
  justify-content: flex-start;
  .cut {
    width: 500px;
    height: 500px;
  }

  .test-model {
    margin-left: 20px;

    .check-model {
      display: flex;
      justify-content: space-between;
      > div {
        height: 40px;
        width: 70px;
        border: 1px solid #cccccc;
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
        font-size: 13px;
        cursor: pointer;
      }

      .active-btn {
        color: #ffffff;
        background: #ff7802;
        border: 1px solid #ff7802;
      }
    }

    .corp-title {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 40px;
    }
    .flexLeft {
      display: flex;
      justify-content: left;
    }

    .corr-input {
      ::v-deep .el-input__inner {
        width: 90px;
      }
      & > :first-child {
        margin-right: 20px;
      }
      font-size: 14px;
      color: #000;
      line-height: 40px;
    }

    .opera-btn {
      width: 100px;
      height: 40px;
      border: 1px solid #cccccc;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      color: #606266;
      font-size: 14px;
      border-radius: 4px;
    }
    .left30 {
      margin-left: 30px;
    }

    .reset-btn {
      color: #ff7802;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
</style>

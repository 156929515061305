var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outroot"},[_c('el-dialog',{attrs:{"title":'上传' + _vm.headline,"visible":_vm.dialogVisible,"width":"40%","before-close":_vm.handleClose,"append-to-body":"","custom-class":"accentuate"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":function($event){return _vm.closeDialogFunc(false)}}},[_c('div',{staticClass:"commodityPopover"},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":'/supplierapi/Material/addMaterial',"on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"before-remove":_vm.beforeRemove,"multiple":"","on-exceed":_vm.handleExceed,"file-list":_vm.fileList,"accept":"image/*","on-success":_vm.uploadSuccess,"data":{ token: _vm.token },"before-upload":_vm.beforeUpload,"show-file-list":false}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1),_vm._v(" "),_c('el-table',{staticClass:"top10",attrs:{"header-cell-style":{
          background: '#F5F7FA',
          textAlign: 'center',
          color: '#333333',
        },"row-class-name":_vm.tableRowClassName,"cell-style":{ textAlign: 'center' },"data":_vm.tableData,"highlight-current-row":"","setCurrentRow":"","border":""},on:{"current-change":_vm.rowClick}},[_c('el-table-column',{attrs:{"label":"素材图片","highlight-current-row":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[(scope.row.file_type == 0)?_c('div',{staticClass:"detalimg"},[_c('img',{attrs:{"src":require("@/assets/images/folder.svg")}})]):(scope.row.file_type == 1)?_c('div',{staticClass:"detalimg"},[_c('img',{attrs:{"src":_vm._f("imgbaseurl")(scope.row.key)}})]):(scope.row.file_type == 2)?_c('div',{staticClass:"detalimg"},[_c('video',{ref:"video",staticClass:"video-js vjs-default-skin viedomodel",staticStyle:{"width":"32px","height":"32px"},attrs:{"id":"tablemyVideo","data-setup":"{\"controlBar\": {\"pictureInPictureToggle\": false}}"}},[_c('source',{attrs:{"src":_vm._f("imgbaseurl")(scope.row.key),"type":"video/mp4"}})])]):(
                  scope.row.key.includes('.doc') ||
                  scope.row.key.includes('.docx')
                )?_c('div',{staticClass:"detalimg"},[_c('img',{attrs:{"src":require("@/assets/material/word.svg")}})]):(
                  scope.row.key.includes('.xls') ||
                  scope.row.key.includes('.xlsx')
                )?_c('div',{staticClass:"detalimg"},[_c('img',{attrs:{"src":require("@/assets/material/excel.svg")}})]):(scope.row.key.includes('.pdf'))?_c('div',{staticClass:"detalimg"},[_c('img',{attrs:{"src":require("@/assets/material/pdf.svg")}})]):(
                  scope.row.key.includes('.zip') ||
                  scope.row.key.includes('.rar')
                )?_c('div',{staticClass:"detalimg"},[_c('img',{attrs:{"src":require("@/assets/material/zip.svg")}})]):_vm._e()])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"素材名称"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"downloadBox"},[_c('span',[_vm._v(_vm._s(row.name))]),_vm._v(" "),_c('span',{staticClass:"download",on:{"click":function($event){return _vm.downloadFunc(row.key | _vm.imgbaseurl, row.file_type, row.name)}}},[_vm._v("点击下载")])])]}}])})],1),_vm._v(" "),_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-size":7,"layout":"total, prev, pager, next","total":parseInt(_vm.total)},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"next-click":_vm.handleCurrentChange,"prev-click":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1),_vm._v(" "),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.closeDialogFunc(false)}}},[_vm._v("取 消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.closeDialogFunc(true)}}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
/**
 * 物流模块
 * - 运费模版
 */

const logisticsRoutes = [
  //物流相关
  {
    path: '/freightTemplate',
    name: 'freightTemplate',
    meta: {
      title: '运费模板管理',
      path: '/freightTemplate',
      navIndex: 'freight',
      needsToken: true,
    },
    component: () =>
      import('@/pages/logistics/freightTemplate/FreightTemplate.vue'),
  },
  // 跨境运费模板
  {
    path: '/crossBorderfreight/:type?/:fre_id?',
    name: 'CrossBorderFreight',
    meta: {
      title: '新建直发物流运费模板',
      leftNavIndex: '/freightTemplate',
      navIndex: 'freight',
      path: '/CrossBorderFreight',
      needsToken: true,
    },
    component: () =>
      import(
        '@/pages/logistics/freightTemplate/crossBorder-freight/CrossBorderFreight.vue'
      ),
  },
  // 本土运费模板
  {
    path: '/localfreight/:type?/:fre_id?',
    name: 'localfreight',
    meta: {
      title: '新建本土物流运费模板',
      leftNavIndex: '/freightTemplate',
      navIndex: 'freight',
      path: '/localfreight',
      needsToken: true,
    },
    component: () =>
      import(
        '@/pages/logistics/freightTemplate/local-freight/LocalFreight.vue'
      ),
  },
  {
    path: '/localexpress/:type?/:fre_id?',
    name: 'localexpress',
    meta: {
      title: '新建本土快递运费模板',
      leftNavIndex: '/freightTemplate',
      navIndex: 'freight',
      path: '/localexpress',
      needsToken: true,
    },
    component: () =>
      import(
        '@/pages/logistics/freightTemplate/local-express/LocalExpress.vue'
      ),
  },
  {
    path: '/logisPlanInquiry',
    name: 'logisPlanInquiry',
    meta: {
      title: '物流方案查询',
      navIndex: 'freight',
      path: '/logisPlanInquiry',
      navIndex: 'freight',
      needsToken: true,
    },
    component: () => import('@/pages/logistics/logisPlanInquiry'),
  },
  {
    path: '/logisFreight',
    name: 'logisFreight',
    meta: {
      title: '运费统计',
      navIndex: 'freight',
      path: '/logisFreight',
      needsToken: true,
    },
    component: () => import('@/pages/logistics/logisFreight.vue'),
  },
  {
    path: '/logisticsOverview',
    name: 'logisticsOverview',
    meta: {
      title: '物流概览',
      navIndex: 'freight',
      path: '/logisticsOverview',
      needsToken: true,
    },
    component: () => import('@/pages/logistics/logisticsOverview.vue'),
  },
];

export default logisticsRoutes;

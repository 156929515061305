<template>
  <div class="circle-checkbox">
    <el-checkbox v-model="checked" @input="handleCheck"></el-checkbox>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleCheck(val) {
      this.checked = val;
      this.$emit('update:checked', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.circle-checkbox {
  .el-checkbox {
    ::v-deep .el-checkbox__input .el-checkbox__inner {
      width: 16px;
      height: 16px;
      border: 1px solid #ff7802 !important;
      border-radius: 100%;

      transition: none;
      -webkit-transition: none;

      &::after {
        width: 8px;
        height: 8px;
        border: 0px;
        border-radius: 100%;
        background-color: #fff;

        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);

        transition: none;
        -webkit-transition: none;
      }
    }

    ::v-deep .el-checkbox__input.is-checked {
      background-color: #fff;

      .el-checkbox__inner {
        background-color: #fff !important;

        &::after {
          background-color: #ff7802;
        }
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ximu-header",style:({ 'background-color': _vm.backgroundColor })},[_c('div',{staticClass:"header-left"},[_c('span',{staticClass:"logo-img cursor-pointer",on:{"click":function($event){return _vm.jumpindex()}}},[_c('span',{staticClass:"iconfont"},[_vm._v("")])]),_vm._v(" "),_c('span',{staticClass:"logo-font cursor-pointer",on:{"click":function($event){return _vm.jumpindex()}}},[_c('span',{staticClass:"iconfont"},[_vm._v("")])]),_vm._v(" "),_c('span',{staticClass:"language"},[_c('img',{attrs:{"src":_vm.language.imgUrl,"alt":"语言图标"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.language.name))])])]),_vm._v(" "),_c('div',{staticClass:"header-right"},_vm._l((_vm.menuList),function(item,index){return _c('span',{key:index,class:[
        {
          'highlight-text': item.isHighlight,
          'no-divider': item.hideDivider,
        },
        'menu-item',
        'cursor-pointer',
      ],on:{"click":function($event){return _vm.clickMenuItem(item.link)}}},[_vm._v(_vm._s(item.name))])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
const state = {
  categoryData: [], // 类目
};

const getters = {};

const mutations = {
  setCategoryData(state, data) {
    state.categoryData = data;
  },
  clearCategoryData(state) {
    state.categoryData = [];
  },
};

export default {
  state,
  getters,
  mutations,
};

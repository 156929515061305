<template>
  <div class="xm-tip-dialog">
    <!-- 遮罩层 -->
    <div class="mask" :style="{ opacity: opacity, 'z-index': zIndex }"></div>
    <!-- 弹窗主体 -->
    <div class="box" :style="{ width: width, 'z-index': Number(zIndex) + 1 }">
      <div class="box-header">
        <div class="box-header-title">
          {{ title }}
        </div>
        <i @click="close" class="box-close-icon el-icon-close"></i>
      </div>
      <div class="box-main">
        <div class="tip-text">
          <span>
            <img class="tip-img" src="@/assets/images/tip-warning.png" alt="" />
          </span>
          {{ titText }}
        </div>
        <div class="go-btn-box">
          <span class="go-btn" @click="gotoPage">{{ btnText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XimuDialog',
  props: {
    titText: {
      type: String,
      default: '提示的内容',
    },
    btnText: {
      type: String,
      default: '按钮文本',
    },
    // 需要跳转的页面路径
    goUrl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '默认标题',
    },
    width: {
      // 弹窗宽度
      type: String,
      default: '480px',
    },
    opacity: {
      // 遮罩层的透明度
      type: String,
      default: '0.25',
    },
    zIndex: {
      // 遮罩层的z轴坐标
      type: String,
      default: '1000',
    },
  },
  methods: {
    // 跳转页面
    gotoPage() {
      this.$router.push(this.goUrl);
    },
    close() {
      this.$emit('close-dialog'); // 父组件绑定的事件
    },
  },
};
</script>

<style lang="scss" scoped>
/* 遮罩层 */
.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

/* 弹窗 */
.box {
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  background-color: #fff;
  border-radius: 0px 0px 0px 0px;

  display: grid;
  grid-template-rows: 60px 1fr;

  // 弹窗头部标题行
  .box-header {
    height: 60px;
    background-color: #f2f2f2;

    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: space-around;
    align-items: center;

    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #111111;

    padding-left: 20px;
    padding-right: 20px;

    .box-close-icon {
      justify-self: end;
      color: #808080;
      font-size: 24px;
      cursor: pointer;
    }
  }
  // 弹窗主体，插槽
  .box-main {
    align-self: center;
    width: 100%;
    height: 100%;
    padding: 20px 20px 30px;

    .tip-text {
      color: #333333;
      font-size: 14px;
      line-height: 22px;

      .tip-img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
    }

    .go-btn-box {
      text-align: end;
      margin-top: 20px;
    }

    .go-btn {
      width: fit-content;
      padding: 9px 20px;
      color: #ffffff;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      background-color: #ff7802;
      cursor: pointer;
    }
  }
}
</style>

/**
 * 资金模块
 * - 资金中心
 * - 支付
 */

const fundRoutes = [
  {
    path: '/funds/:currency?',
    name: 'funds',
    meta: {
      title: '资金',
      navIndex: 'funds',
      path: '/funds',
      leftNavIndex: '/funds',
      needsToken: true,
    },
    component: () => import('@/pages/funds/funds'),
  },
  {
    path: '/withdraw/:type',
    name: 'withdraw',
    meta: {
      title: '提现申请',
      navIndex: 'funds',
      path: '/withdraw',
      leftNavIndex: '/funds',
      needsToken: true,
    },
    component: () => import('@/pages/funds/withdrawal/withdraw'),
  },
  {
    path: '/recharge/:type/:currency',
    name: 'recharge',
    meta: {
      title: '充值',
      navIndex: 'funds',
      leftNavIndex: '/funds',
      needsToken: true,
    },
    component: () => import('@/pages/funds/recharge'),
  },
  {
    path: '/tradeRecord',
    name: 'tradeRecord',
    meta: {
      title: '交易记录',
      navIndex: 'funds',
      path: '/tradeRecord',
      needsToken: true,
    },
    component: () => import('@/pages/funds/trade-record/tradeRecord'),
  },
  {
    path: '/tradeRecordDetail',
    name: 'tradeRecordDetail',
    meta: {
      title: '交易记录详情',
      leftNavIndex: '/tradeRecord',
      navIndex: 'funds',
      needsToken: true,
    },
    component: () => import('@/pages/funds/tradeRecordDetail'),
  },
  {
    path: '/historBill',
    name: 'historBill',
    meta: {
      title: '历史账单',
      navIndex: 'funds',
      path: '/historBill',
      needsToken: true,
    },
    component: () => import('@/pages/funds/historBill'),
  },
  {
    path: '/withdrawRecord',
    name: 'withdrawRecord',
    meta: {
      title: '提现记录',
      navIndex: 'funds',
      path: '/withdrawRecord',
      needsToken: true,
    },
    component: () => import('@/pages/funds/withdrawRecord.vue'),
  },
  {
    path: '/bankcardmanage',
    name: 'bankCardManage',
    meta: {
      title: '银行卡管理',
      navIndex: 'funds',
      path: '/bankcardmanage',
      needsToken: true,
    },
    component: () => import('@/pages/funds/bankCardManage'),
  },
  {
    path: '/edit/bankphone/:bankId?',
    name: 'editBankPhone',
    meta: {
      title: '修改银行卡绑定手机号',
      navIndex: 'funds',
      path: '/bankcardmanage',
      needsToken: true,
    },
    component: () => import('@/pages/funds/EditBankPhone'),
  },
  {
    path: '/bankcard/add/:bankId?',
    name: 'AddBankCard',
    meta: {
      title: '添加银行卡',
      navIndex: 'funds',
      path: '/bankcardmanage',
      needsToken: true,
    },
    component: () => import('@/pages/funds/AddBankCard'),
  },
  // 支付
  {
    path: '/pay/:pay_type/:currency/:id',
    name: 'pay',
    meta: { title: '支付', navIndex: 'funds', path: '/pay', needsToken: true },
    component: () => import('@/pages/funds/pay/PayChoose'),
  },
  // 发票管理
  {
    path: '/invoicemanagement',
    name: 'InvoiceManagement',
    meta: {
      title: '发票管理',
      navIndex: 'funds',
      path: '/invoicemanagement',
      needsToken: true,
    },
    component: () => import('@/pages/funds/invoice-management/index.vue'),
  },
  // 发票详情
  {
    path: '/invoicedetail/:id?',
    name: 'InvoiceDetail',
    meta: {
      title: '发票管理',
      navIndex: 'funds',
      path: '/invoicemanagement',
      needsToken: true,
    },
    component: () =>
      import(
        '@/pages/funds/invoice-management/invoice-detail/InvoiceDetail.vue'
      ),
  },
  // 发票设置
  {
    path: '/invoicesettings',
    name: 'InvoiceSettings',
    meta: {
      title: '发票管理',
      navIndex: 'funds',
      path: '/invoicemanagement',
      needsToken: true,
    },
    component: () =>
      import('@/pages/funds/invoice-management/invoice-setting/index.vue'),
  },
];

export default fundRoutes;

<template>
  <div class="systemNav">
    <!-- <div class="system_nav_title">{{ title }}</div> -->
    <div class="system_nav_box">
      <div
        class="system_nav_item_box"
        v-for="(item, index) in navArray"
        :key="index"
      >
        <div class="system_nav_item_parent_box" v-if="!item.children">
          <template v-if="!item.isShow">
            <div
              class="system_nav_item"
              @click="loadPageReplaceOrPush(item.path, item.type, item, status)"
              :class="[{ system_nav_item_active: activeIndex == item.id }]"
            >
              {{ item.name }}
              <i class="el-icon-arrow-right"></i>
            </div>
          </template>
        </div>
        <div class="system_nav_item_parent_box" v-if="item.children">
          <div class="system_nav_item_parent">{{ item.name }}</div>
          <div
            class="system_nav_item"
            v-for="(val, i) in item.children"
            :key="i"
            @click="loadPageReplaceOrPush(val.path, val.type, val)"
            :class="[{ system_nav_item_active: activeIndex == val.id }]"
          >
            {{ val.name }}
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <!-- <div class="system_nav_item "  @click="loadPageReplaceOrPush(item.path,item.type)" :class="[{'system_nav_item_active':activeIndex==item.id}]">{{item.name}}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'systemNav',
  data() {
    return {
      activeIndex: 0,
      navArray: [{ id: 1, name: '全部商品', path: '/goodGroup', type: 'push' }],
      jsq: null,
      status: localStorage.getItem('status'),
    };
  },
  props: {
    title: {
      type: String,
      default: '标题',
    },
    navItems: {
      type: Array,
      default: function () {
        return [];
      },
    },

    id: {
      type: [Number, String],
      default: -1,
    },
  },
  created() {
    this.navArray = this.navItems;
    if (this.id != -1) {
      this.activeIndex = this.id;
    }
    this.getActiveIndex();
    this.getNav();
  },
  // activated(){
  //     this.navArray=this.navItems;
  //     // this.getActiveIndex();
  // },
  methods: {
    getActiveIndex() {
      this.activeIndex = getFirstAvailableValue(
        ObjectValue('id', this.getItemOfRoutePath(this.navArray)),
        this.activeIndex
      );
    },
    // 根据权限判断侧边栏显示
    getNav() {
      this.jsq = setInterval(() => {
        this.status = localStorage.getItem('status');
        if (this.navArray.length > 0) {
          // 用户 status 状态,-1删除,0-待完善资料 1-待审核 2-已通过 3-已驳回  4-拉黑 5-二次审核 6-平台审核通过退店申请 7-供应商已提交退款账号
          // item['isShow'] = true; 会隐藏item侧边栏
          this.navArray.forEach(item => {
            if (
              this.status !== '2' &&
              this.status !== '5' &&
              this.status !== '6' &&
              this.status !== '7' &&
              item.isShow
            ) {
              item['isShow'] = true;
            } else {
              item['isShow'] = false;
            }
            // item.isshow不存在的全部隐藏
            if ((this.status == '6' || this.status == '7') && !item.isshow) {
              item['isShow'] = true;
            }
          });
          this.$forceUpdate();
        }
        clearInterval(this.jsq);
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.systemNav {
  width: 120px;
  height: 100%;
  /* min-height: calc(100vh - 80px); */
  background: #fff;
  position: sticky;
  top: 64px;
  flex-shrink: 0;
  margin-right: 20px;
}
.system_nav_title {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #eeeeee;
}
.system_nav_box {
  width: 100%;
}
.system_nav_item {
  width: 100%;
  height: 40px;
  padding-left: 8px;
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  cursor: pointer;
  border-left: 3px solid #fff;
  position: relative;
  .el-icon-arrow-right {
    position: absolute;
    right: 8px;
    top: 14px;
  }
}
.system_nav_item_active,
.system_nav_item:hover {
  background: var(--xm-color);
  color: #ffffff;
  border: 0;
}
.system_nav_item_parent {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 8px;
  font-weight: bold;
  color: #333333;
}
</style>

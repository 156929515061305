import Vue from 'vue';
import UnImage from './image/UnImage.vue';
import FormComponents from './form/install.js';
import UnTitle from './PageTitle/UnTitle.vue';
import publicUpload from './publicUpload/publicUpload.vue';
import XmDialog from './XimuComponents/XimuDialog.vue'; //弹框
import XmConfirmDialog from './XimuComponents/XimuConfirmDialog.vue'; //弹框
import pagTools from '@/components/common/pagTools.vue'; //分页
import pagToolsDialog from '@/components/common/pagToolsDialog.vue'; //分页
import AreaSelect from '@/components/common/areaSelect.vue'; // 地址选择器
import CountrySelect from '@/components/common/countrySelect.vue'; // 国家选择器
import ChauCountriesSelect from '@/components/common/chauCountriesSelect.vue'; //洲国选择器
import XmAreaSelect from '@/components/common/snAreaSelect.vue'; //国省市（省市只显示一个）
import deleteConfig from '@/components/common/deleteConfig.vue'; //确认弹框
import XmTip from '@/components/XimuComponents/XmTip.vue'; //提示
import XmDatePicker from '@/components/XimuComponents/XmDatePicker.vue'; //快捷时间范围
import ImgUploader from '@/components/common/imgUploader.vue'; // 单张图片上传
import ImgsUploader from '@/components/common/imgsUploader.vue'; // 多张图片上传(不走素材库)
import previewimg from '@/components/preview/previewimg.vue'; // 图片预览
import previewdoc from '@/components/preview/previewdoc.vue'; // 文件预览
import XmHeader from '@/components/XimuComponents/XimuHeader.vue'; // 网页的顶部
import XmFooter from '@/components/XimuComponents/XimuFooter.vue'; // 网页的底部，网站信息（备案号等）
import XmPopover from '@/components/XimuComponents/XmPopover.vue'; //鼠标悬浮弹框
import XmProtocolDialog from '@/components/XimuComponents/XimuProtocolDialog.vue'; // 协议弹窗
import XmChauCountriesSelect from '@/components/XimuComponents/XmChauCountriesSelect.vue'; // 根基国际国内来决定地址选择器
import XmCircleCheckbox from '@/components/XimuComponents/XmCircleCheckbox.vue'; // 圆形复选
import XmContactBackend from '@/components/XimuComponents/XmContactBackend.vue'; // 客服经理
import XmContact from '@/components/XimuComponents/XmContact.vue'; // 联系xx
import XmMoreSelect from '@/components/XimuComponents/XmMoreSelect.vue'; // 下拉多选
import XmHoverPreviewImage from '@/components/XimuComponents/XmHoverPreviewImage.vue'; // 悬浮查看大图
import XmAddressMultiSelect from '@/components/XimuComponents/XmAddressMultiSelect.vue'; // 国家复选
import XmAddressSelect from '@/components/XimuComponents/XmAddressSelect.vue'; // 州/国家/已选择复选
import XmImgCrop from '@/components/XimuComponents/XimuImgCrop.vue'; // 图片裁剪
import XmUploadDia from './XimuComponents/XmUploadDia.vue'; //上传图片弹框
import XmCountdownDialog from './XimuComponents/XmCountdownDialog.vue'; // 提示成功倒计时对话框
import XmSuccessDialog from './XimuComponents/XmSuccessDialog.vue'; // 提交成功倒计时弹窗
import XmAddressShuttle from './XimuComponents/XmAddressShuttle.vue'; // 提示成功倒计时对话框
import XmSelect from './XimuComponents/XmSelect.vue'; // 带有input的select
import XmNoDataSelect from './XimuComponents/XmNoDataSelect.vue'; // 无匹配数据的下拉框
import XmSelectDatePicker from '@/components/XmSelectDatePicker/index.vue'; //带下拉框的时间选择器，下拉框可要可不要自己控制
import XmCarousel from './XimuComponents/XmCarousel.vue'; // 轮播图
import XmUnitInput from './XimuComponents/XmUnitInput.vue'; // 带有单位的input
import XmBackToTop from './XimuComponents/XmBackToTop.vue'; // 回到顶部
import XmTipDialog from './XimuComponents/XmTipDialog.vue'; // 带按钮的提示弹窗，点击按钮可以跳转目的页面
import XmVideo from './XimuComponents/XmVideo.vue'; // 视频组件
import XmUploadFileName from './XimuComponents/XmUploadFileName.vue'; // 带用户上传文件名的上传组件
import XmShowFileBox from './XimuComponents/XmShowFileBox.vue'; // 预览多个文件
import XmAreaSelectAll from './XimuComponents/XmAreaSelectAll.vue'; // 地区复选国家省市区
import XmAreaProvinceCity from './XimuComponents/XmAreaProvinceCity.vue'; // 地区复选国家省市区
import XmPreviewMedia from './XimuComponents/XmPreviewMedia.vue'; // 上一张下一张图片视频预览
import XmUploadAbbreviation from './XimuComponents/XmUploadAbbreviation.vue';
import XmGoodsTable from '@/components/XimuComponents/xm-goods-table/XmGoodsTable.vue'; //列表组件
import XmInput from '@/components/XimuComponents/XmInput/index.vue'; //输入框
import XmEmpty from '@/components/XimuComponents/xm-empty/XmEmpty.vue'; //表格列表无数据展示的组件
import XmCardTabs from '@/components/XimuComponents/XmCardTabs.vue'; // 卡片式标签页
import XmTable from '@/components/XimuComponents/XmTable.vue'; // 表格公共组件
import XmOverruleForm from '@/components/XimuComponents/XmOverruleForm.vue'; //原因弹框的内容（是否是输入的自己定）
import XmGoodsMark from '@/components/XimuComponents/XmGoodsMark.vue'; // 商品信息标识
import XmExportButton from '@/components/XimuComponents/xm-export/XmExportButton.vue'; // 导出按钮
import XmExportHistory from '@/components/XimuComponents/xm-export/XmExportHistory.vue'; // 导出历史弹窗
import XmMultipleChoice from '@/components/XimuComponents/XmMultipleChoice.vue'; // 下拉多选框
import XmNewMultipleChoice from '@/components/XimuComponents/XmNewMultipleChoice.vue'; //新的下拉多选框

import HeadNav from '@/components/common/headNav.vue'; //顶部导航栏
import FreightNav from '@/components/common/systemNav.vue'; //侧边栏导航栏
import PageTitle from '@/components/common/componentPannel.vue'; //页面标题
import XmSteps from '@/components/XimuComponents/xm-steps/index.vue'; // 进度条

const components = {
  UnImage,
  UnTitle,
  publicUpload,
  ...FormComponents,
  XmDialog,
  pagTools,
  pagToolsDialog,
  deleteConfig,
  XmConfirmDialog,
  AreaSelect,
  CountrySelect,
  XmTip,
  XmDatePicker,
  ImgUploader,
  previewimg,
  previewdoc,
  XmHeader,
  XmFooter,
  ImgsUploader,
  ChauCountriesSelect,
  XmPopover,
  XmProtocolDialog,
  XmChauCountriesSelect,
  XmCircleCheckbox,
  XmContactBackend,
  XmContact,
  XmMoreSelect,
  XmHoverPreviewImage,
  XmAddressMultiSelect,
  XmImgCrop,
  XmUploadDia,
  XmCountdownDialog,
  XmSuccessDialog,
  XmAddressShuttle,
  XmSelect,
  XmNoDataSelect,
  XmSelectDatePicker,
  XmCarousel,
  XmUnitInput,
  XmAddressSelect,
  XmBackToTop,
  XmAreaSelect,
  XmTipDialog,
  XmVideo,
  XmUploadFileName,
  XmShowFileBox,
  XmAreaSelectAll,
  XmPreviewMedia,
  XmUploadAbbreviation,
  XmGoodsTable,
  XmInput,
  XmEmpty,
  XmCardTabs,
  XmAreaProvinceCity,
  XmTable,
  XmOverruleForm,
  XmExportButton,
  XmExportHistory,
  HeadNav,
  FreightNav,
  PageTitle,
  XmGoodsMark,
  XmMultipleChoice,
  XmNewMultipleChoice,
  XmSteps,
};

export function initGlobalComponents() {
  Object.keys(components).forEach(component_name => {
    Vue.component(component_name, components[component_name]);
  });
}

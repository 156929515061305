<template>
  <div class="address-selector">
    <el-select
      clearable
      :value="completeAddress"
      popper-class="xm-area-select-all-popper"
      @focus="showDropdown = true"
      ref="selector"
      @clear="clearAddressData"
      :placeholder="placeholderKey"
    >
      <template slot="empty">
        <el-tabs v-show="showDropdown" v-model="tabName" type="border-card">
          <el-tab-pane name="province" label="省">
            <ul class="no-search-input">
              <li
                v-for="item in province_list"
                :key="item.id"
                :class="{ active: addressData.province_id == item.id }"
                @click="selectOption(1, item)"
              >
                {{ item.cname }}
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane
            name="city"
            :disabled="
              !addressData.province_id || addressData.province_id.length == 0
            "
            label="市"
          >
            <ul class="no-search-input">
              <li
                v-for="item in city_list"
                :key="item.id"
                :class="{ active: addressData.city_id == item.id }"
                @click="selectOption(2, item)"
              >
                {{ item.cname }}
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </template>
    </el-select>
  </div>
</template>
<script>
export default {
  name: 'XmAreaProvinceCity',
  props: {
    // 回显的地址数据
    areaData: {
      type: Object,
      default: () => {},
    },
    // 显示在选择器上的占位字符
    placeholderKey: {
      type: String,
      default: '',
    },
    // 当前省市区选择器的国家，默认为中国
    targetCountryId: {
      type: String,
      default: '44',
    },
  },
  data() {
    return {
      showDropdown: false,
      tabName: '',

      province_list: [],
      city_list: [],
      /**
       * 需要传出的数据：
       */
      addressData: {
        province: '',
        province_id: '',
        city: '',
        city_id: '',
      },
    };
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      if (!this.addressData) {
        return '';
      }
      const addr = `${this.addressData.province}${
        this.addressData.city?.length > 0 ? '/' : ''
      }${this.addressData.city}`;
      return addr;
    },
  },
  methods: {
    //点击 x 清空选择地址
    clearAddressData() {
      Object.keys(this.addressData).forEach(item => {
        this.addressData[item] = '';
      });
      this.changeTab('province');
      this.$emit('update', this.addressData);
    },
    // 切换下拉框标签页
    changeTab(val) {
      this.tabName = val;
    },
    // 关闭选择器下拉框
    closeDropdown() {
      this.$emit('update', this.addressData);
      this.showDropdown = false;
      this.$refs?.selector?.blur(); // 选择器的尖角符号向下
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 1：省份菜单；2：城市菜单；3：区菜单
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      if (index == 1) {
        this.city_list = [];
        // 选择省份
        this.addressData.province = item.cname;
        this.addressData.province_id = item.id;
        // 清空状态
        this.addressData.city = '';
        this.addressData.city_id = '';

        this.getCityList();
      } else if (index == 2) {
        // 选择城市
        this.addressData.city = item.cname;
        this.addressData.city_id = item.id;

        // 可以传出数据了
        this.closeDropdown();
      }
    },
    // 获取省份数据
    getProvinceList() {
      let that = this;
      return new Promise(async (resolve, reject) => {
        const api = 'supplier.getStates';
        const options = {
          id: this.targetCountryId,
        };
        try {
          xmJson(
            api,
            options,
            res => {
              if (res.errcode == 0) {
                that.province_list = res.data;
                that.changeTab('province');
                resolve(res.data);
              }
            },
            err => {
              console.error(err);
              reject(err);
            }
          );
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    // 获取城市数据
    getCityList() {
      return new Promise(async (resolve, reject) => {
        const api = 'supplier.getCity';
        const options = {
          id: this.addressData.province_id,
          type: '',
        };
        let that = this;
        try {
          xmJson(
            api,
            options,
            res => {
              if (res.errcode == 0) {
                that.city_list = res.data;
                if (that.city_list.length > 0) {
                  that.changeTab('city');
                  resolve(res.data);
                } else {
                  that.closeDropdown();
                }
              }
            },
            err => {
              console.error(err);
              reject(err);
            }
          );
        } catch (error) {
          console.error(err);
          reject(err);
        }
      });
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        province: '',
        province_id: '',
        city: '',
        city_id: '',
      };
      this.tabName = '';
      this.province_list = [];
      this.city_list = [];
    },
  },
  async mounted() {
    await this.getProvinceList();
    this.changeTab('province');

    this.addressData.province = this.areaData?.province || '';
    this.addressData.province_id = this.areaData?.province_id || '';
    this.addressData.city = this.areaData?.city || '';
    this.addressData.city_id = this.areaData?.city_id || '';

    if (this.addressData.city_id && this.addressData.city_id != '0') {
      await this.getCityList();
    }
  },
  beforeDestroy() {
    this.resetData();
  },
};
</script>
<style lang="scss">
$tab-width: 150px;
$select-height: 40px;
$highlight-color: #ff7802;
.xm-area-select-all-popper {
  position: relative;

  * {
    border: 0px;
  }
  .el-select {
    height: 40px;
  }
  ul {
    padding-left: 1em; // 选项名称距离左边的距离
  }

  li {
    list-style-type: none;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  li.active,
  span.active {
    color: $highlight-color;
  }
  li:hover {
    color: $highlight-color;
  }

  // 解决focus输入框边框变蓝问题
  .el-select .el-input.is-focus .el-input__inner,
  .el-select .el-input__inner:focus {
    border-color: $highlight-color;
  }
  // 隐藏el原本的下拉菜单
  .el-select-dropdown {
    visibility: hidden;
  }
  .popper__arrow {
    visibility: hidden;
  }

  .no-search-input {
    height: 290px;
    overflow: auto;
    padding-left: 5px;

    &::-webkit-scrollbar-track {
      background: none;
    }
  }
  .el-input {
    height: 40px;
    margin-bottom: 10px; // 搜索框离下面的选项文字的距离
  }

  .el-input__prefix {
    display: inline-block;
  }

  .el-tabs .el-input__inner {
    width: 100%;
    margin-left: -5px;
    border-radius: 0px 0px 0px 0px;
    background-color: #f7f7f7;

    font-size: 12px;
    color: #808080;
    border: 1px solid #ccc;
  }
  // 处理tabs位置问题
  .el-tabs {
    visibility: visible;
    position: absolute;
    top: -8px;
    left: -5px;
    z-index: 2; // 必须比原本的下拉菜单大

    width: fit-content;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    * {
      font-size: 14px;
      color: #666;
    }
  }

  .el-tabs--border-card > .el-tabs__header {
    border: 0px;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding-bottom: 10px;
  }
  // 解决高亮标签页两侧有1px缝隙
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-left-color: transparent;
    border-right-color: transparent;
  }
  // 调整标签页宽度，宽度相同且文本居中
  .el-tabs__item {
    width: $tab-width;
    text-align: center;
  }
  // 解决“城市”标签页头右侧有1px缝隙
  .el-tabs__item:nth-last-of-type(1) {
    width: $tab-width + 1;
  }
  // 隐藏标签页前后的滑动提示箭头
  .el-tabs__nav-prev,
  .el-tabs__nav-next {
    display: none;
  }

  .el-tabs--border-card > .el-tabs__header .el-tabs__item + .el-tabs__item {
    margin-left: 0px;
  }

  .el-tabs__content {
    padding-right: 2px; // 解决滚动条不贴右边问题（有 2px 缝隙）
    padding-top: 10px;
    height: 310px;
    width: 100%;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #808080;
  }
  // 解决点击和悬浮标签页，标签页文字蓝色高亮问题
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: #4d4d4d;
  }
  .el-tabs__item.is-top {
    background-color: #f2f2f2;
    color: #808080;
  }
  .el-tabs__item.is-active {
    background-color: #ffffff;
    color: #4d4d4d;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-list"},[_c('div',{staticClass:"search-top"},[_c('div',{staticClass:"search-top-label"},[_vm._v("商品分类")]),_vm._v(" "),_c('radio-select-category',{staticClass:"search-category",attrs:{"placeholder":"选择分类"},on:{"change":_vm.getList},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_vm._v(" "),_c('CurrencyChose',{attrs:{"pageCurrency":_vm.pageCurrency},on:{"change-currency":_vm.changeCurrency}})],1),_vm._v(" "),_c('div',{key:_vm.scorllKey,staticClass:"search-content"},[_c('div',{staticClass:"list-box"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"table-title"},[_c('div',{staticClass:"list-title"},[_vm._v("分销商ID")]),_vm._v(" "),_c('div',{staticClass:"list-title"},[_vm._v("采购额("+_vm._s(_vm.currencySign)+")")])]),_vm._v(" "),(_vm.tradeRank.length != 0)?_c('div',{staticClass:"scroll",style:({ height: _vm.tradeRankHeight })},[_c('vue-seamless-scroll',{attrs:{"data":_vm.tradeRank,"class-option":_vm.optiontTradeRank}},[_c('ul',_vm._l((_vm.tradeRank),function(item,index){return _c('li',{key:index,staticClass:"list-detail"},[_c('div',{staticClass:"detail-id"},[_c('div',{staticClass:"rank",class:index + 1 == 1
                        ? 'top-one'
                        : index + 1 == 2
                        ? 'top-two'
                        : index + 1 == 3
                        ? 'top-three'
                        : ''},[_c('span',[_vm._v(_vm._s(index + 1))])]),_vm._v(" "),_c('div',{staticClass:"width129",attrs:{"title":item.user_id}},[_vm._v("\n                    "+_vm._s(item.user_id)+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"detail-num"},[_vm._v(_vm._s(item.total_money))])])}),0)])],1):_c('div',{staticClass:"no-data"},[_vm._v("暂无数据")])])]),_vm._v(" "),_c('div',{staticClass:"list-box"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"box-content"},[_vm._m(2),_vm._v(" "),(_vm.agentSalesVolume.length != 0)?_c('div',{staticClass:"scroll",style:({ height: _vm.agentSalesVolumeHeight })},[_c('vue-seamless-scroll',{attrs:{"data":_vm.agentSalesVolume,"class-option":_vm.optionAgentSalesVolume}},[_c('ul',_vm._l((_vm.agentSalesVolume),function(item,index){return _c('li',{key:index,staticClass:"list-detail"},[_c('div',{staticClass:"detail-id"},[_c('div',{staticClass:"rank",class:index + 1 == 1
                        ? 'top-one'
                        : index + 1 == 2
                        ? 'top-two'
                        : index + 1 == 3
                        ? 'top-three'
                        : ''},[_c('span',[_vm._v(_vm._s(index + 1))])]),_vm._v(" "),_c('div',{staticClass:"width129",attrs:{"title":item.supplier_id}},[_vm._v("\n                    "+_vm._s(item.supplier_id)+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"detail-num"},[_vm._v(_vm._s(item.num))])])}),0)])],1):_c('div',{staticClass:"no-data"},[_vm._v("暂无数据")])])]),_vm._v(" "),_c('div',{staticClass:"list-box"},[_vm._m(3),_vm._v(" "),_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"table-title"},[_c('div',{staticClass:"list-title"},[_vm._v("供应商店铺名")]),_vm._v(" "),_c('div',{staticClass:"list-title"},[_vm._v("销售额("+_vm._s(_vm.currencySign)+")")])]),_vm._v(" "),(_vm.agentSalesMoney.length != 0)?_c('div',{staticClass:"scroll",style:({ height: _vm.agentSalesMoneyHeight })},[_c('vue-seamless-scroll',{attrs:{"data":_vm.agentSalesMoney,"class-option":_vm.optionAgentSalesMoney}},[_c('ul',_vm._l((_vm.agentSalesMoney),function(item,index){return _c('li',{key:index,staticClass:"list-detail"},[_c('div',{staticClass:"detail-id"},[_c('div',{staticClass:"rank",class:index + 1 == 1
                        ? 'top-one'
                        : index + 1 == 2
                        ? 'top-two'
                        : index + 1 == 3
                        ? 'top-three'
                        : ''},[_c('span',[_vm._v(_vm._s(index + 1))])]),_vm._v(" "),_c('div',{staticClass:"width129",attrs:{"title":item.supplier_id}},[_vm._v("\n                    "+_vm._s(item.supplier_id)+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"detail-num"},[_vm._v(_vm._s(item.num))])])}),0)])],1):_c('div',{staticClass:"no-data"},[_vm._v("暂无数据")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-title"},[_c('div',[_vm._v("分销商采购额TOP榜")]),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/images/fire_icon.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-title"},[_vm._v("\n        供应商销量TOP榜\n        "),_c('img',{attrs:{"src":require("@/assets/images/fire_icon.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-title"},[_c('div',{staticClass:"list-title"},[_vm._v("供应商店铺名")]),_vm._v(" "),_c('div',{staticClass:"list-title"},[_vm._v("销量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-title"},[_vm._v("\n        供应商销售额TOP榜\n        "),_c('img',{attrs:{"src":require("@/assets/images/fire_icon.svg"),"alt":""}})])
}]

export { render, staticRenderFns }
/**
 * 通用的业务工具函数，比如退出登录
 * @module utils/service
 * @author hukeyi
 * @date 2023-09-08
 */

import {
  hasLocalItem,
  removeLocalItems,
  clearSessionItems,
  getLocalItem,
} from '@/utils/xm-jstools/index';

import Vue from 'vue';

/**
 * 清空 localStorage 中保存的 ximu 相关数据
 * @author hukeyi
 * @date 2023-09-08
 */
export function clearLocalXimuItems() {
  const keys = [
    'token',
    'account',
    'status',
    'im_token',
    'uid',
    'email',
    'xm_csid',
    'is_limited_ximu_account',
  ];
  removeLocalItems(keys);
}

/**
 * 清空 sessionStorage 中保存的 ximu 相关数据
 * @author hukeyi
 * @date 2023-09-08
 */
export function clearSessionXimuItems() {
  clearSessionItems();
}

/**
 * 获取用户的登录 token
 * @author hukeyi
 * @date 2023-09-08
 * @returns 供应商账号的 token
 */
export function getXimuToken() {
  return getLocalItem('token');
}

/**
 * 判断当前用户是否为登录状态
 * @author hukeyi
 * @date 2023-09-08
 * @returns { Boolean } true：是登录状态
 */
export function isLogin() {
  return hasLocalItem('token');
}

/**
 * 退出登录
 * @author hukeyi
 * @date 2023-09-08
 */
export function logout() {
  // 清空网站缓存
  clearLocalXimuItems();
  clearSessionXimuItems();

  // 销毁 nim 实例
  if (Vue.prototype.$nim) {
    Vue.prototype.$nim.destroy();
    Vue.prototype.$nim = null;
  }
  // 跳转登录
  window.location.assign('/login');
}

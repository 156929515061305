<template>
  <div class="card-list">
    <!-- 商品 -->
    <GoodsDetails :detail="cardList.goodsData"></GoodsDetails>
    <!-- 订单 -->
    <OrderDetails :detail="cardList.ordersData"></OrderDetails>
    <!-- 消息通知 -->
    <MessageCenter :detail="cardList.messageNoticeData"></MessageCenter>
    <!-- 纠纷 -->
    <DisputeDetails :detail="cardList.disputeData"></DisputeDetails>
    <!-- 预警数据 -->
    <AlertData :detail="cardList.alertPaddingData"></AlertData>
    <!-- 待处理数据 -->
    <PendingData
      :pageCurrency="pageCurrency"
      :detail="cardList.alertPaddingData"
    ></PendingData>
    <!-- 仓库数据 -->
    <WarehouseData :detail="cardList.wareData"></WarehouseData>
    <!-- 物流数据 -->
    <LogisticsData :detail="cardList.logisticsData"></LogisticsData>
    <!-- 仓库 -->
    <!-- <WarehouseDetails></WarehouseDetails> -->
    <!-- 流量数据 -->
    <!-- <FlowData></FlowData> -->
  </div>
</template>

<script>
import GoodsDetails from './card-list/GoodsDetails.vue';
import OrderDetails from './card-list/OrderDetails.vue';
import WarehouseDetails from './card-list/WarehouseDetails.vue';
import MessageCenter from './card-list/MessageCenter.vue';
import DisputeDetails from './card-list/DisputeDetails.vue';
import AlertData from './card-list/AlertData.vue';
import PendingData from './card-list/PendingData.vue';
import FlowData from './card-list/FlowData.vue';
import WarehouseData from './card-list/WarehouseData.vue';
import LogisticsData from './card-list/LogisticsData.vue';

export default {
  name: 'CardList',
  components: {
    GoodsDetails,
    OrderDetails,
    WarehouseDetails,
    MessageCenter,
    DisputeDetails,
    AlertData,
    PendingData,
    FlowData,
    WarehouseData,
    LogisticsData,
  },
  props: {
    // 当前页面的展示币种, 1 人民币 2 美元
    pageCurrency: {
      type: String,
      default: '1',
    },
    // 各板块数据
    cardList: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.card-list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #fff;
  gap: 20px;
}
</style>

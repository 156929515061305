<template>
  <div class="un-select-area" :class="[computLayout]">
    <el-select
      clearable
      v-model="area.country"
      style="width: 150px"
      :size="size"
      placeholder="国家"
      filterable
      no-data-text="没有国家数据"
      @change="onCountryChange"
    >
      <el-option
        v-for="option in countryList"
        :key="option.id"
        :label="option.cname"
        :value="option.id"
      ></el-option>
    </el-select>
    <el-select
      clearable
      v-if="provinceList.length"
      v-model="area.province"
      style="width: 150px"
      :size="size"
      filterable
      placeholder="省份"
      no-data-text="没有省份数据"
      @change="onProvinceChange"
    >
      <el-option
        v-for="option in provinceList"
        :key="option.id"
        :label="option.cname"
        :value="option.id"
      ></el-option>
    </el-select>
    <el-select
      clearable
      v-if="cityList.length"
      v-model="area.city"
      style="width: 150px"
      :size="size"
      placeholder="城市"
      filterable
      no-data-text="没有城市数据"
    >
      <el-option
        v-for="option in cityList"
        :key="option.id"
        :label="option.cname"
        :value="option.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'UnSelectArea',
  props: {
    layout: {
      type: String,
      defualt: 'row',
      validator(val) {
        return ['row', 'col'].includes(val);
      },
    },
    value: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {
      area: {
        country: '',
        province: '',
        city: '',
      },
      countryList: [],
      provinceList: [],
      cityList: [],
      countryList1: [],
      provinceList1: [],
      cityList1: [],
    };
  },
  mounted() {
    if (this.value.length) {
      this.initDefautlValue();
    }

    this.getCountryList();
  },
  computed: {
    computLayout() {
      const { layout } = this;

      return `un-select-area__${layout}`;
    },
  },
  watch: {
    area: {
      deep: true,
      handler(area) {
        const { countryList, provinceList, cityList } = this;

        let val = [area.country, area.province, area.city];
        let changeValue = {
          country: '',
          country_id: area.country,
          province: '',
          province_id: area.province,
          city: '',
          city_id: area.city,
        };
        if (countryList.length) {
          changeValue.country = countryList.filter(item => {
            return item.id == area.country;
          })[0].cname;
        }
        if (provinceList.length) {
          changeValue.province = provinceList.filter(item => {
            return item.id == area.province;
          })[0].cname;
        }
        if (cityList.length) {
          changeValue.city = cityList.filter(item => {
            return item.id == area.city;
          })[0].cname;
        }

        this.$emit('input', val);

        if (changeValue.country && changeValue.province && changeValue.city) {
          this.$emit('change', val);
        }
        this.$emit('change-all', changeValue);
      },
    },
    value() {
      if (this.value.length) {
        this.initDefautlValue();
      }
    },
  },
  methods: {
    initDefautlValue() {
      this.area.country = this.value[0];
      this.area.province = this.value[1];
      this.area.city = this.value[2];

      this.getProvinceList(this.value[0]);
      this.getCityList(this.value[1]);
    },
    onProvinceChange(val) {
      this.area.city = '';
      this.cityList = [];

      this.getCityList(val);
    },
    onCountryChange(val) {
      this.area.province = '';
      this.provinceList = [];
      this.area.city = '';
      this.cityList = [];

      this.getProvinceList(val);
    },
    getCityList(pid) {
      const api = 'supplier.getCity';

      xmJson(
        api,
        { id: pid },
        res => {
          if (res.errcode === 0) {
            this.cityList = res.data || [];
          } else {
            this.cityList = [];
          }
        },
        err => {
          console.error(err);
          this.cityList = [];
        }
      );
    },
    getProvinceList(pid) {
      let api = '';
      this.countryList.map(item => {
        if (item.id == pid) {
          if (item.has_state == '1') {
            api = 'supplier.getStates';
          } else if (item.has_state == '0') {
            api = 'supplier.getCity';
          }
        }
      });

      // provinceList
      // cityList
      xmJson(
        api,
        { id: pid },
        res => {
          if (res.errcode === 0) {
            this.countryList.map(item => {
              if (item.id == pid) {
                if (item.has_state == '1') {
                  this.provinceList = res.data || [];
                } else if (item.has_state == '0') {
                  this.cityList = res.data || [];
                }
              }
            });
          } else {
            this.provinceList = [];
          }
        },
        err => {
          console.error(err);
          this.provinceList = [];
        }
      );
    },
    getCountryList() {
      const api = 'supplier.getCountries';
      xmJson(
        api,
        {},
        res => {
          if (res.errcode === 0) {
            console.log(res);
            this.countryList = res.data || [];
          }
        },
        err => {
          console.error(err);
          this.countryList = [];
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.un-select-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.un-select-area__row {
  flex-flow: row nowrap;
}

.un-select-area__col {
  flex-flow: column nowrap;
  align-items: flex-start;
}
</style>

import userRoutes from './modules/user'; // 用户模块
import orderRoutes from './modules/order'; // 订单模块
import warehouseRoutes from './modules/warehouse'; // 仓储模块
import managementRoutes from './modules/management'; // 管理模块
import noticeRoutes from './modules/notice'; // 消息模块
import logisticsRoutes from './modules/logistics'; // 物流模块
import goodsRoutes from './modules/goods'; // 商品模块
import fundsRoutes from './modules/funds'; // 资金模块
import featureServicesRoutes from './modules/feature_services'; // 特色服务模块
import chatRoutes from './modules/chat'; // 聊天模块

import Home from '@/pages/home/index';
import Error from '@/pages/error/error.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
      path: '/',
      navIndex: '/',
      needsToken: true,
    },
    component: Home,
  },
  ...userRoutes,
  ...orderRoutes,
  ...warehouseRoutes,
  ...managementRoutes,
  ...noticeRoutes,
  ...logisticsRoutes,
  ...goodsRoutes,
  ...fundsRoutes,
  ...featureServicesRoutes,
  ...chatRoutes,
  {
    path: '*',
    name: 'Not Found',
    meta: {
      title: '404 Not Found',
      needsToken: false,
    },
    component: Error,
  },
];

export default routes;

<template>
  <div class="ximu-dialog">
    <!-- 遮罩层 -->
    <div class="mask" :style="{ opacity: opacity, 'z-index': zIndex }"></div>
    <!-- 弹窗主体 -->
    <div class="box" :style="{ width: width, 'z-index': Number(zIndex) + 1 }">
      <div class="box-header">
        <div class="box-header-title">
          <!-- 标题插槽 有多个标题时可以用 -->
          {{ title
          }}<span class="subheading"><slot name="subheading"></slot></span>
        </div>
        <i
          v-if="haveCloseBtn"
          @click="close"
          class="box-close-icon el-icon-close"
        ></i>
      </div>
      <div class="box-main">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XimuDialog',
  props: {
    title: {
      type: String,
      default: '默认标题',
    },
    // 是否有关闭按钮
    haveCloseBtn: {
      type: Boolean,
      default: true,
    },
    width: {
      // 弹窗宽度
      type: String,
      default: '480px',
    },
    opacity: {
      // 遮罩层的透明度
      type: String,
      default: '0.25',
    },
    zIndex: {
      // 遮罩层的z轴坐标
      type: String,
      default: '2000',
    },
  },
  methods: {
    close() {
      this.$emit('close-dialog'); // 父组件绑定的事件
    },
  },
};
</script>

<style lang="scss" scoped>
/* 遮罩层 */
.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

/* 弹窗 */
.box {
  height: auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  background-color: #fff;
  border-radius: 0px 0px 0px 0px;

  display: grid;
  grid-template-rows: 60px 1fr;

  // 弹窗头部标题行
  .box-header {
    height: 60px;
    background-color: #f2f2f2;

    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: space-around;
    align-items: center;

    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #111111;

    padding-left: 20px;
    padding-right: 20px;

    .box-close-icon {
      justify-self: end;
      color: #808080;
      font-size: 24px;
      cursor: pointer;
    }
  }
  // 弹窗主体，插槽
  .box-main {
    align-self: center;
    // padding: 20px 20px 30px 20px;

    width: 100%;
    height: 100%;

    // 解决el-select 下拉框在弹窗组件上不显示问题
    ::v-deep .el-select .el-select-dropdown {
      position: absolute !important;
      top: calc(100%) !important;
      left: 0px !important;
    }
  }
}
</style>

<template>
  <div class="ximu-header" :style="{ 'background-color': backgroundColor }">
    <!-- 网站顶部左边的网站大标题和logo -->
    <div class="header-left">
      <span class="logo-img cursor-pointer" @click="jumpindex()">
        <span class="iconfont">&#xe634;</span>
      </span>
      <span class="logo-font cursor-pointer" @click="jumpindex()">
        <span class="iconfont">&#xe636;</span>
      </span>

      <span class="language">
        <img :src="language.imgUrl" alt="语言图标" />
        <span>{{ language.name }}</span>
      </span>
    </div>
    <!-- 网站顶部右边自定义的链接菜单 -->
    <div class="header-right">
      <span
        v-for="(item, index) of menuList"
        :key="index"
        :class="[
          {
            'highlight-text': item.isHighlight,
            'no-divider': item.hideDivider,
          },
          'menu-item',
          'cursor-pointer',
        ]"
        @click="clickMenuItem(item.link)"
        >{{ item.name }}</span
      >
    </div>
  </div>
</template>

<script>
/**
 *
 * 实际使用示例在：src/pages/login/loginHome.vue
 *
 * 特点：
 * 1. 可自定义头部标题栏背景颜色
 * 2. 可自定义头部标题栏右侧的菜单栏【可自定义菜单项的：1）是否高光；2）跳转链接；3）是否隐藏分割线】
 * 3. 可自定义头部标题栏左侧的语言
 *
 * 使用方法（已注册全局）：
 *
 * <xm-header
 *   :backgroundColor="xxx"
 *   :menuList="yyy"
 *   :language="zzz"></xm-header>
 */
export default {
  props: {
    // 网站头部栏的背景颜色
    backgroundColor: {
      type: String,
      default: '#F6F6F6',
    },
    // header 右边的菜单列表
    // name：菜单项名称（显示在页面上的名称）
    // link：点击目标菜单项会跳转的链接
    // isHighlight: 是否橘色高亮，默认否
    // hideDivider: 是否隐藏菜单项右边的竖向分割线，默认否
    menuList: {
      type: Array,
      default: () => [
        {
          name: '用户协议',
          link: '',
          isHighlight: false,
          hideDivider: false,
        },
        {
          name: '帮助中心',
          link: '',
          isHighlight: false,
          hideDivider: false,
        },
      ],
    },
    // 当前网站的语言
    // 默认中文
    // 传入语言名称以及其图标
    language: {
      type: Object,
      default: () => {
        return {
          name: '中文',
          imgUrl: require('@/assets/images/China_icon.svg'),
        };
      },
    },
  },
  methods: {
    // 点击菜单项跳转链接
    clickMenuItem(link) {
      if (link) {
        this.$router.push(link);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ximu-header {
  // width: 1920px;
  width: 100%;
  height: 50px;
  overflow: hidden;

  margin: 0 auto;
  color: #666;
  font-size: 12px;

  white-space: nowrap; // 防止菜单栏 span 换行变形

  .cursor-pointer {
    cursor: pointer;
  }

  .highlight-text {
    color: #ff7802;
  }

  // 头部左边，标题、logo和语言
  .header-left {
    display: flex;
    align-items: center;
    float: left;
    height: 50px;

    padding-left: 400px;

    .logo-img {
      position: relative;
      width: 40px;
      height: 40px;
      color: #ff7802;
      .iconfont {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 22px;
      }
    }

    .logo-font {
      position: relative;
      margin-left: 4px;
      width: 50px;
      height: 50px;
      color: #000;

      .iconfont {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
      }
    }
    .language {
      display: flex;
      align-items: center;
      margin-left: 40px;
      img {
        margin-right: 5px;
        width: 12px;
        height: 12px;
      }
    }
  }

  // 头部右边，菜单栏
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;

    padding-right: 420px;

    .menu-item:hover {
      color: #ff7802;
    }

    span {
      display: inline-block;
      width: fit-content;

      &::after {
        content: '|';
        color: #505050;
        margin: 0 10px;
        height: 16px;
      }

      &:hover {
        color: #ff7802;
      }

      &.no-divider::after {
        content: '';
        margin-right: 0px;
        height: 16px;
      }
    }

    span:last-child::after {
      content: '';
    }
  }
}
</style>

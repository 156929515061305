<template>
  <div class="card-tabs">
    <div
      v-for="item in tabs"
      :key="item.key"
      class="card-tab-item"
      :class="{ active: activeName === item.key }"
      @click="handleClick(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
/**
 * @author hukeyi
 * @example 示例见 admin 项目：src\pages\capital\capital-audit\index.vue
*  <xm-card-tabs
    v-model="activeBlock"
    :tabs="tabs"
    ></xm-card-tabs>
 */
export default {
  name: 'CardTabs',
  model: {
    prop: 'activeName',
    event: 'tab-click',
  },
  props: {
    activeName: {
      type: [String, Number],
      default: '',
    },
    tabs: {
      type: Array,
      default: () => [
        // {
        //   name: '充值记录',
        //   key: 'recharge',
        // },
        // {
        //   name: '保证金扣除记录',
        //   key: 'reduce',
        // },
      ],
    },
  },
  methods: {
    handleClick(item) {
      this.$emit('tab-click', item.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-tabs {
  display: flex;
  align-items: center;

  width: fit-content;
  padding: 2px 3px;
  background-color: #f7f7f7;

  /*选中文字时避免出现蓝色背景*/
  * {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .card-tab-item {
    height: 36px;
    padding: 6px 20px;

    background-color: #f7f7f7;
    color: #666;
    font-size: 16px;

    cursor: pointer;

    &.active {
      background-color: #fff;
      color: #ff7802;
    }
  }
}
</style>

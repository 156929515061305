<template>
  <div ref="app" id="app">
    <!-- 客服和客户经理 -->
    <xm-contact-backend></xm-contact-backend>
    <transition>
      <router-view class="appView"></router-view>
    </transition>
    <div
      id="backop"
      class="btn_meau_top"
      title="返回顶部"
      @click="scrollToTop"
      v-show="$route.meta.toTop && toTopShow"
    >
      <img
        src="@/../static/images/detail_ic_top.png"
        class="btn_meau_top_img"
        alt
      />
      <img
        src="@/../static/images/detail_ic_top_active.png"
        class="btn_meau_top_img_active"
        alt
      />
      <span>返回顶部</span>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import XmContactBackend from './components/XimuComponents/XmContactBackend.vue';
export default {
  name: 'App',
  components: { XmContactBackend },
  data() {
    return {
      exclude: ['goodDetailBefore', 'register'],
      toTopShow: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollChange);
    $('#app').scroll(this.scrollChange);
  },
  watch: {},
  methods: {
    // 回到顶部组件
    scrollChange() {
      //首先修改相对滚动位置
      let app = document.getElementById('app');
      this.scrollTop = window.pageYOffset || app.scrollTop;
      if (this.scrollTop > 300) {
        this.toTopShow = true;
      } else {
        this.toTopShow = false;
      }
    },
    scrollToTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scope>
body {
  font-family: 'Microsoft YaHei-Regular, Microsoft YaHei' !important;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  // overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background: #f7f7f7;
  // 设置未触底水平滚动条的高度(未触底滚动条插件)
  .el-scrollbar__bar,
  .is-horizontal {
    height: 10px;
  }
  // 设置滚动条滑块的背景颜色(未触底滚动条插件)
  .el-scrollbar__thumb {
    background-color: #c2c2c2;
  }
}

.appView {
  width: 100%;
  background: #f7f7f7;
  min-height: 100vh;
  /*transition: transform 0.2s;*/
  transition-property: transform, opacity;
  transition-duration: 0.24s;
  transition-timing-function: ease-out;
}

/*回到顶部*/

.btn_meau_top {
  width: 100px;
  height: 32px;
  line-height: 32px;
  background: #ffffff;
  border-radius: 4px;
  left: 5.83%;
  top: 80%;
  z-index: 110;
  position: fixed;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn_meau_top span {
  padding-left: 6px;
}
.btn_meau_top img {
  width: 16px;
  height: 16px;
}
.btn_meau_top_img {
  display: block;
}
.btn_meau_top_img_active {
  display: none;
}
.btn_meau_top:hover {
  color: #3271ff;
}
.btn_meau_top:hover .btn_meau_top_img {
  display: none;
}
.btn_meau_top:hover .btn_meau_top_img_active {
  display: block;
}

.righttip .el-carousel__indicators--horizontal {
  width: 100% !important;
  top: 60%;
  left: 63%;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  display: none;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item {
  // padding-right: 0px !important;
  // width: 159px;
  // text-align: center;
}

.main {
  width: 1200px;
  margin: 0 auto;
  display: flex;
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #ff7802 !important;
}

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: none !important;
}

.el-checkbox__inner {
  border: 1px solid #dcdfe6 !important;
}

.el-date-table td.available:hover {
  color: #ff7802 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ff7802 !important;
  border-color: #ff7802 !important;
}

.el-picker-panel__icon-btn:hover {
  color: #ff7802 !important;
}

.el-date-table td.today span {
  color: #606266 !important;
}

.el-select-dropdown__item.selected {
  color: #ff7802 !important;
  background: #fff2e6 !important;
  font-weight: 400 !important;
}

.el-select-dropdown__item.hover {
  background-color: #fff2e6 !important;
}
.el-select .el-input__inner:focus {
  border-color: #ff7802 !important;
}
.el-select-dropdown__item.hover {
  color: #ff7802 !important;
}
.el-date-editor--date .el-input__inner:focus {
  border-color: #ff7802 !important;
}
.el-input__inner {
  border: 1px solid #cbcbcb;
}
.el-date-table td.current:not(.disabled) span {
  background-color: #ff7802 !important;
  color: #fff2e6 !important;
}
.el-date-picker__header-label.active,
.el-date-picker__header-label:hover,
.el-year-table td.current:not(.disabled) .cell,
.el-year-table td .cell:hover,
.el-month-table td.current:not(.disabled) .cell,
.el-month-table td .cell:hover {
  color: #ff7802 !important;
}

.el-cascader .el-input .el-input__inner:focus,
.el-cascader .el-input.is-focus .el-input__inner {
  border-color: #ff7802;
}

.el-cascader__dropdown {
  border: none !important;
}

// 去除密码输入框眼睛
input[type='password']::-ms-reveal {
  display: none;
}

input[type='password']::-ms-clear {
  display: none;
}

input[type='password']::-o-clear {
  display: none;
}

// 去除i标签的倾斜
i {
  font-style: normal;
}
// 修改input聚焦的边框颜色
.el-input__inner:focus {
  border-color: #ff7802 !important;
}
//修改textarea聚焦的边框颜色
.el-textarea__inner:focus {
  border-color: #ff7802;
}
//修改下拉框的边框颜色
.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #ff7802 !important;
}

// iframe 样式(面单打印)
#print-iframe {
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
  border: 0;
}
#printJS {
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
  border: 0;
}
</style>

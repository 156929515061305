/**
 * 聊天模块
 */

const chatRoutes = [
  {
    // 聊天界面
    path: '/chat',
    meta: {
      hideContactSticker: true, // 是否隐藏右下侧的“联系客服”和“客户经理”
      needsToken: true,
      title: '聊天',
    },
    component: () => import('@/pages/chat/index.vue'),
    children: [
      {
        path: ':otherId',
        meta: {
          hideContactSticker: true, // 是否隐藏右下侧的“联系客服”和“客户经理”
          needsToken: true,
          title: '聊天',
        },
        component: () => import('@/pages/chat/messageWindow.vue'),
      },
    ],
  },
];

export default chatRoutes;

/**
 * 与 localStorage 和 sessionStorage 相关的工具函数
 * @module utils/storage
 * @author hukeyi
 * @date 2023-09-08
 */

import { devWarn, isEmptyObject, hasValue } from './index';

/**
 * 查询 localStorage 是否有 item
 * @author hukeyi
 * @date 2023-09-08
 * @param { String } item
 * @returns { Boolean } localStorage 是否有 item 的 key
 */
function hasLocalItem(item) {
  if (!hasValue(item) || typeof item !== 'string') {
    return false;
  }
  return !!localStorage.getItem(item);
}

/**
 * 查询 sessionStorage 是否有 item
 * @author hukeyi
 * @date 2023-09-08
 * @param { String } item
 * @returns { Boolean } sessionStorage 是否有 item 的 key
 */
function hasSessionItem(item) {
  if (!hasValue(item) || typeof item !== 'string') {
    return false;
  }
  return !!sessionStorage.getItem(item);
}

/**
 * 向 localStorage 中加入多个参数
 * @author hukeyi
 * @date 2023-09-08
 * @param { Object } paramObj
 */
function setLocalItems(paramObj) {
  if (isEmptyObject(paramObj) || Array.isArray(paramObj)) {
    devWarn('传入参数格式错误，应传入非空 Object');
    return;
  }
  for (let [key, val] of Object.entries(paramObj)) {
    if (hasValue(key)) {
      localStorage.setItem(key, val);
    }
  }
}

/**
 * 向 sessionStorage 中加入多个参数
 * @author hukeyi
 * @date 2023-09-08
 * @param { Object } paramObj
 */
function setSessionItems(paramObj) {
  if (isEmptyObject(paramObj) || Array.isArray(paramObj)) {
    devWarn('传入参数格式错误，应传入非空 Object');
    return;
  }
  for (let [key, val] of Object.entries(paramObj)) {
    if (hasValue(key) && hasValue(val)) {
      sessionStorage.setItem(key, val);
    }
  }
}

/**
 * 获取 localStorage 中保存的对应键的值
 * @author hukeyi
 * @date 2023-09-08
 * @param { String } item
 * @returns { * } localStorage 中保存的 item 值
 */
function getLocalItem(item) {
  if (!hasValue(item) || typeof item !== 'string') {
    return null;
  }
  return localStorage.getItem(item);
}

/**
 * 获取 sessionStorage 中保存的对应键的值
 * @author hukeyi
 * @date 2023-09-08
 * @param { String } item
 * @returns { * } sessionStorage 中保存的 item 值
 */
function getSessionItem(item) {
  if (!hasValue(item) || typeof item !== 'string') {
    return null;
  }
  return sessionStorage.getItem(item);
}

/**
 * 清空 localStorage
 * @author hukeyi
 * @date 2023-09-08
 */
function clearLocalItems() {
  localStorage.clear();
}

/**
 * 清空 sessionStorage
 * @author hukeyi
 * @date 2023-09-08
 */
function clearSessionItems() {
  sessionStorage.clear();
}

/**
 * 删除 localStorage 中保存部分数据
 * @author hukeyi
 * @date 2023-09-08
 */
function removeLocalItems(items) {
  if (isEmptyObject(items) || !Array.isArray(items)) {
    devWarn('传参格式错误，请传入数组');
    return;
  }
  for (let x of items) {
    localStorage.removeItem(x);
  }
}

/**
 * 删除 sessionStorage 中保存部分数据
 * @author hukeyi
 * @date 2023-09-08
 */
function removeSessionItems(items) {
  if (isEmptyObject(items) || !Array.isArray(items)) {
    devWarn('传参格式错误，请传入数组');
    return;
  }
  for (let x of items) {
    sessionStorage.removeItem(x);
  }
}

export {
  hasLocalItem,
  hasSessionItem,
  setLocalItems,
  setSessionItems,
  getLocalItem,
  getSessionItem,
  clearLocalItems,
  clearSessionItems,
  removeLocalItems,
  removeSessionItems,
};

/**
 * 对象相关的工具函数
 * @author hukeyi
 * @date 2023-09-08
 */

import { hasValue } from './index';

/**
 * 是否是纯对象 Object
 * @author hukeyi
 * @date 2023-09-08
 * @param { * } obj
 * @returns { Boolean }
 */
function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

/**
 * 是否是空对象或空数组或空值
 * @author hukeyi
 * @date 2023-09-08
 * @param { * } obj
 * @returns { Boolean }
 */
function isEmptyObject(obj) {
  if (obj == null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  for (let i in obj) {
    if (Object.hasOwnProperty.call(obj, i)) {
      return false;
    }
  }
  return true;
}

/**
 * 深拷贝数组或对象
 * @param {*} obj
 * @returns {*}
 */
function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  let copy;
  if (Array.isArray(obj)) {
    copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepCopy(obj[i]);
    }
  } else {
    copy = {};
    for (let key in obj) {
      copy[key] = deepCopy(obj[key]);
    }
  }
  return copy;
}

/**
 * 查询对象 obj 中的 key 属性的值
 * #todo 这个函数完全可以用 ?. 可选链运算符替代
 * #todo 为兼容旧项目代码保留，之后要么删掉要么重写
 * @author hukeyi
 * @date 2023-09-07
 * @param { String } key 对象属性调用链 e.g. (obj.)key1.key2
 * @param { Object } obj
 */
function ObjectValue(key, obj) {
  if (typeof key !== 'string') {
    return null;
  }
  let paramArr = key.split('.'); // 调用链参数数组
  let param = paramArr.shift(); // 调用链第一个参数
  if (paramArr.length === 0) {
    // 如果调用链只剩一个参数，可以结束递归了
    return hasValue(obj[param]) ? obj[param] : null;
  }
  // 往下递归
  return ObjectValue(paramArr.join('.'), obj[param]);
}

export { isPlainObject, isEmptyObject, ObjectValue, deepCopy };

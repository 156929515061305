/**
 * 商品模块
 * - 商品管理
 * - 品牌商标
 * - 授权书管理
 */

const goodRoutes = [
  {
    path: '/goodGroup/:euPersonId?',
    name: 'goodGroup',
    meta: {
      title: '全部商品',
      path: '/goodGroup/:euPersonId?',
      navIndex: 'good',
      leftNavIndex: '/goodGroup',
      needsToken: true,
    },
    component: () => import('@/pages/goods/group/goodGroup'),
  },
  // 品牌相关 顶部导航栏navIndex属于account
  {
    path: '/brandConsult/:type?',
    name: 'brandConsult',
    meta: {
      title: '品牌资质',
      navIndex: 'account',
      path: '/brandConsult',
      needsToken: true,
    },
    component: () => import('@/pages/goods/brand/brandConsult'),
  },
  {
    path: '/brand/:operation/:id?',
    name: 'brand',
    meta: {
      title: '添加商标',
      navIndex: 'account',
      leftNavIndex: '/brandConsult',
      path: '/brand',
      needsToken: true,
    },
    component: () => import('@/pages/goods/brand/brandAdd'),
  },
  {
    path: '/brandAudit/:id/:type',
    name: 'brandAudit',
    meta: {
      title: '品牌授权审核',
      navIndex: 'account',
      leftNavIndex: '/brandConsult',
      path: '/brandAudit',
      needsToken: true,
    },
    component: () =>
      import('@/pages/goods/brand/components/BrandAuthorizationAudit'),
  },
  {
    path: '/brandAuditDetail/:id/:type',
    name: 'brandAuditDetail',
    meta: {
      title: '详情',
      navIndex: 'account',
      leftNavIndex: '/brandConsult',
      path: '/brandAuditDetail',
      needsToken: true,
    },
    component: () =>
      import('@/pages/goods/brand/components/BrandAuthorizationAudit'),
  },
  // 授权管理+保单管理
  {
    path: '/authBookList',
    name: 'authBookList',
    meta: {
      title: '授权书列表',
      path: '/authBookList',
      needsToken: true,
    },
    component: () => import('@/pages/goods/auth-book/authBookList'),
  },
  {
    path: '/authBookDetail',
    name: 'authBookDetail',
    meta: {
      title: '授权书详情',
      path: '/authBookDetail',
      needsToken: true,
    },
    component: () => import('@/pages/goods/auth-book/authBookDetail'),
  },
  {
    path: '/authUpload',
    name: 'authUpload',
    meta: {
      title: '上传授权书',
      path: '/authUpload',
      needsToken: true,
    },
    component: () => import('@/pages/goods/auth-book/authUpload'),
  },
  // {
  //   path: '/authAuditList',
  //   name: 'authAuditList',
  //   meta: {
  //     title: '授权待审核',
  //     path: '/authAuditList',
  //     needsToken: true,
  //   },
  //   component: () => import('@/pages/goods/auth-book/authAuditList'),
  // },
  // {
  //   path: '/authAuditDetail',
  //   name: 'authAuditDetail',
  //   meta: {
  //     title: '授权书审核详情',
  //     path: '/authAuditDetail',
  //     needsToken: true,
  //   },
  //   component: () => import('@/pages/goods/auth-book/authAuditDetail'),
  // },
  // {
  //   path: '/authRejectList',
  //   name: 'authRejectList',
  //   meta: {
  //     title: '授权已拒绝',
  //     path: '/authRejectList',
  //     needsToken: true,
  //   },
  //   component: () => import('@/pages/goods/auth-book/authRejectList'),
  // },
  {
    path: '/authInsurList',
    name: 'authInsurList',
    meta: {
      title: '保险列表',
      path: '/authInsurList',
      needsToken: true,
    },
    component: () => import('@/pages/goods/auth-book/authInsurList'),
  },
  {
    path: '/authCertList',
    name: 'authCertList',
    meta: {
      title: '认证资质',
      path: '/authCertList',
      needsToken: true,
    },
    component: () => import('@/pages/goods/auth-book/authCertList'),
  },
  // 商品分组
  {
    path: '/goodManagement',
    name: 'goodManagement',
    meta: {
      title: '分组管理',
      navIndex: 'good',
      path: '/goodManagement',
      needsToken: true,
    },
    component: () => import('@/pages/goods/brand/groupManagement'),
  },
  // 发布商品
  {
    path: '/goodDetailBefore',
    name: 'goodDetailBefore',
    meta: {
      title: '新建商品',
      path: '/goodDetailBefore',
      navIndex: 'good',
      leftNavIndex: '/goodGroup',
      needsToken: true,
    },
    component: () => import('@/pages/goods/publish/goodDetailBefore.vue'),
  },
  {
    path: '/goodDetail',
    name: 'goodDetail',
    meta: {
      title: '发布商品',
      path: '/goodDetail',
      navIndex: 'good',
      leftNavIndex: '/goodGroup',
      needsToken: true,
    },
    component: () => import('@/pages/goods/publish/goodDetail'),
  },
  {
    path: '/:lang?/good/preview',
    name: 'goodPreview',
    meta: {
      title: '商品预览',
      path: '/good/preview',
      navIndex: 'good',
      leftNavIndex: '/goodGroup',
      needsToken: true,
    },
    component: () => import('@/pages/goods/preview/index'),
  },
];

export default goodRoutes;

<template>
  <div class="export-button-box" :style="{ width }">
    <el-dropdown trigger="click" placement="bottom-end">
      <span class="dropdown-max-title" :style="{ width }">
        导出<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu
        slot="dropdown"
        class="dropdown-menu xm-export-button-el-dropdown"
        :class="customStyleName ? customStyleName : ''"
      >
        <el-dropdown-item @click.native="handleExportAll"
          >导出全部信息</el-dropdown-item
        >
        <el-dropdown-item @click.native="handleExportPart"
          >导出选中信息</el-dropdown-item
        >
        <slot></slot>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: 'XmExportButton',
  props: {
    customStyleName: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '154px',
    },
  },
  methods: {
    // 导出选中信息
    handleExportPart() {
      this.$emit('export-part');
    },
    // 导出全部信息
    handleExportAll() {
      this.$emit('export-all');
    },
  },
};
</script>

<style lang="scss">
.xm-export-button-el-dropdown {
  width: 160px;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

  .popper__arrow {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.export-button-box {
  .dropdown-max-title {
    display: inline-flex;
    justify-content: space-between;
    height: 40px;
    padding: 9px 16px;

    font-size: 14px;
    color: #808080;

    border: 1px solid #d9d9d9;
    border-radius: 4px;

    cursor: pointer;

    .el-icon--right {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
</style>

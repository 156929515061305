<template>
  <div class="un-title" :class="{ 'un-title__gap': showGap }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UnTitle',
  props: {
    showGap: {
      type: Boolean,
      default: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.un-title {
  position: relative;
  padding-left: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 19px;
}

.un-title__gap {
  margin-bottom: 20px;
}

.un-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 20px;
  background-color: #3371fd;
}
</style>
<template>
  <div class="quick-enter">
    <div class="quick_enter_title">
      <div>快捷入口</div>
      <!-- <span>编辑</span> -->
    </div>
    <ul class="enter_list">
      <li
        class="enter_list_item"
        v-for="(item, index) in enterList"
        :key="index"
        @click="loadPagePush(item.url)"
      >
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'QuickEnter',
  data() {
    return {
      enterList: [],
    };
  },
  created() {
    this.getQuiteEntry();
  },
  methods: {
    getQuiteEntry() {
      const api = 'platformReport.quickEntry';
      xmJson(
        api,
        {},
        res => {
          if (res?.errcode == 0) {
            // console.log(res,'快捷入口');
            this.enterList = res?.data;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    loadPagePush(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-enter {
  padding: 20px;
  width: 1200px;
  height: 99px;
  background-color: #fff;

  .quick_enter_title {
    margin-bottom: 20px;
    height: 18px;
    line-height: 18px;
    color: #111;
    font-size: 16px;

    span {
      position: absolute;
      top: 3px;
      right: 0;
      height: 16px;
      line-height: 16px;
      color: #3d8df5;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .enter_list {
    display: flex;

    .enter_list_item {
      margin-right: 30px;
      height: 16px;
      line-height: 16px;
      color: #808080;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>

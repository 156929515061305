<template>
  <div
    class="accountManager_box"
    @mouseleave="mouseLeave"
    @mouseenter="mouseEnter()"
  >
    <div class="tip_box" @click="controlArrow">
      <!-- 客户 -->
      <span class="tip_text" v-if="isAccount">客户经理</span>

      <!-- 左箭头 -->
      <div class="icon_arrow" v-if="arrowLeft">
        <svg
          t="1681094846971"
          class="icon icon-zuojiantou"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4523"
          width="16"
          height="16"
        >
          <path
            d="M213.59837 563.760271a73.064893 73.064893 0 0 1 0-103.391303L652.243648 21.59573a73.192853 73.192853 0 1 1 103.519262 103.519262L368.685323 512.06462l387.077587 386.821667a73.192853 73.192853 0 0 1-103.647222 103.647222L213.726329 563.632311z"
            fill="#FF7802"
            p-id="4524"
          ></path>
        </svg>
      </div>
      <div class="icon_arrow" v-if="arrowRight">
        <!-- 右箭头 -->
        <svg
          t="1681094872524"
          class="icon icon-youjiantou"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4699"
          width="16"
          height="16"
        >
          <path
            d="M755.684199 563.7604a73.065076 73.065076 0 0 0 0-103.391561L317.165784 21.594505a73.193036 73.193036 0 0 0-103.64748 103.51952L600.724818 512.06462 213.646264 898.887254a73.193036 73.193036 0 0 0 103.51952 103.647481l438.518415-438.774335z"
            fill="#FF7802"
            p-id="4700"
          ></path>
        </svg>
      </div>
      <!--客户图标 -->

      <svg
        t="1681093309066"
        class="icon icon-kehujingli1"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="3606"
        width="16"
        height="16"
        v-if="!isAccount"
      >
        <path
          d="M309.853777 747.196632C239.790619 697.721263 156.253777 614.561684 128.929145 533.180632a404.210526 404.210526 0 1 1 766.167579 0c-27.378526 81.381053-97.926737 164.540632-168.151579 214.016L882.431461 1024H175.925356l133.982316-276.749474z m437.355789-284.186948l6.629053-26.192842-26.192842-6.521263-104.55579-26.138947-26.192842-6.575158-6.467368 26.192842a80.842105 80.842105 0 0 1-156.941474 0l-6.521263-26.192842-26.192842 6.575158-104.55579 26.138947-26.192842 6.521263 6.629053 26.192842a242.634105 242.634105 0 0 0 470.554947 0z"
          fill="#FF7802"
          p-id="3607"
        ></path>
      </svg>
    </div>

    <!-- ref="tipDetail" -->
    <div class="tip_detail" v-if="hover" @mouseenter="mouseEnter1()">
      <h1 class="onwer">专属客户经理</h1>
      <div class="dialog">
        Hi，我是您的专属客户经理，如需帮助可以随时联系我哦~
      </div>
      <i class="icon el-icon-phone">
        <span class="icon-info">：{{ phoneNumber }}</span>
      </i>
      <i class="icon el-icon-message">
        <span class="icon-info">：{{ message }}</span>
      </i>
    </div>
  </div>
</template>
<script>
export default {
  name: 'accountManager',
  data() {
    return {
      phoneNumber: '0755-27761552',
      message: '575675461@qq.com',
      isAccount: true,
      hover: false,
      arrowLeft: false,
      arrowRight: false,
      isClick: '-1', //记录是否被点击
    };
  },
  methods: {
    // 鼠标点击效果
    controlArrow() {
      if (this.isClick === '1') {
        this.hover = false;
        // console.log("哟呼~被点击了");
        this.arrowRight = false;
        this.arrowLeft = true;
        this.isClick = '-1';
        return;
      }
      if (this.isClick === '-1') {
        this.hover = true;
        // console.log("哟呼~被点击了");
        this.arrowRight = true;
        this.arrowLeft = false;
        this.isClick = '1';
      }
    },
    mouseEnter() {
      if (this.isClick === '-1') {
        this.arrowLeft = true;
        this.isAccount = false;
      }
    },
    mouseEnter1() {
      if (this.isClick === '') {
      }
      this.arrowRight = true;
      this.isAccount = false;
    },
    mouseLeave() {
      if (this.isClick === '-1') {
        this.arrowLeft = false;
        this.arrowRight = false;
        this.isAccount = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.accountManager_box {
  position: relative;
}

.tip_box {
  display: flex;
  justify-content: center;
  align-items: center;
  // position: absolute;

  // top: 50vh;
  // right: -5vw;
  width: 56px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 100%;
  line-height: 56px;
  text-align: center;
  cursor: default;

  .icon-kehujingli1 {
    position: absolute;
    width: 28px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #808080;
    line-height: 16px;
    // display: none;
  }

  .tip_text {
    width: 28px;
    height: 36px;
    line-height: 18px;
    font-size: 12px;
    font-weight: 400;
    color: #808080;
  }
}

.icon_arrow {
  position: absolute;
  left: 2px;
}

.tip_box:hover {
  cursor: pointer;
  color: #ff7802;
  .tip_text {
    // display: none;
  }
  .icon-kehujingli1 {
    // display: block;
  }
}

// 客户经理详情
.tip_detail {
  display: flex;
  position: absolute;
  top: 16px;
  right: 65px;
  flex-direction: column;
  width: 214px;
  height: 144px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 0px 0px;
  background: rgba(255, 255, 255, 1);
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  color: #4d4d4d;
  z-index: 999;
  justify-content: center;
  padding: 20px 10px;
  gap: 8px;
}

.dialog {
  font-size: 12px;
  color: #808080;
  font-weight: 400;
}

.tip_detail h1 {
  width: 72px;
  height: 16px;
  line-height: 16px;
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 400;
  // 横线
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 27px;
    right: 20px;
    width: 102px;
    height: 1px;
    background-color: #d9d9d9;
  }
}
.tip_detail .icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  white-space: nowrap;
  color: #4d4d4d;
}
.icon-info {
  font-size: 12px;
  font-weight: 400;
  color: #4d4d4d;
}
//hover
.tip_box1:hover {
  .icon-zuojiantou {
    position: absolute;
    display: inline;
    width: 15px;
    height: 19px;
    line-height: 19px;
    font-size: 12px;
    margin-right: 5px;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
  }
  color: #ff7802;
}
</style>

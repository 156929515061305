<template>
  <div class="statistical-time">
    <div class="statistical-time-title">
      <div class="title-top">
        <span class="time-title-font">支付数据</span>
        <!-- <span class="time-title-num">{{ currTime }}</span> -->
      </div>

      <div class="flex">
        <el-date-picker
          clearable
          class="select-time"
          v-model="search.timer"
          type="daterange"
          :unlink-panels="false"
          @change="choseDate"
          :picker-options="pickerOptions"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :start-placeholder="startDate"
          range-separator="至"
          :end-placeholder="endDate"
        ></el-date-picker>

        <CurrencyChose
          :pageCurrency="pageCurrency"
          @change-currency="changeCurrency"
        ></CurrencyChose>
      </div>
    </div>
    <div class="statistical-time-box">
      <!-- 营收金额(￥) -->
      <div
        class="box-list"
        @click="search.type = 1"
        :class="[{ 'box-list-active': search.type == 1 }]"
      >
        <div class="box-list-title">
          <div>
            营收金额({{ currencySign }})
            <el-popover
              placement="bottom-start"
              width="192"
              trigger="hover"
              content="统计时间周期内，含税营收金额"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
        </div>
        <div class="box-list-num">
          {{ modelInfo.orderMoney | decimal }}
        </div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.payment == true }]"
            >{{ modelInfo.orderMoneyRadio }}%</span
          >
        </div>
      </div>
      <!-- 订单数 -->
      <div
        class="box-list"
        @click="search.type = 2"
        :class="[{ 'box-list-active': search.type == 2 }]"
      >
        <div class="box-list-title">
          <div>订单数</div>
        </div>
        <div class="box-list-num">
          {{ modelInfo.orderNum }}
        </div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.orderPaid == true }]"
            >{{ modelInfo.orderNumRadio }}%</span
          >
        </div>
      </div>
      <!-- 有交易分销商 -->
      <div
        class="box-list"
        @click="search.type = 3"
        :class="[{ 'box-list-active': search.type == 3 }]"
      >
        <div class="box-list-title">
          <div>有交易分销商</div>
        </div>
        <div class="box-list-num">
          {{ modelInfo.user }}
        </div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.user == true }]"
            >{{ modelInfo.userRadio }}%</span
          >
        </div>
      </div>
      <!-- 客单价(￥) -->
      <div
        class="box-list"
        @click="search.type = 4"
        :class="[{ 'box-list-active': search.type == 4 }]"
      >
        <div class="box-list-title">
          <div>
            客单价({{ currencySign }})
            <el-popover
              placement="bottom-start"
              width="234"
              trigger="hover"
              content="客单价=营收金额/有交易分销商"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
        </div>
        <div class="box-list-num">
          {{ modelInfo.customer | decimal }}
        </div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.pricePer == true }]"
            >{{ modelInfo.customerRadio }}%</span
          >
        </div>
      </div>
      <!-- 收藏分销商数 -->
      <!-- <div
        class="box-list"
        @click="search.type = 5"
        :class="[{ 'box-list-active': search.type == 5 }]"
      >
        <div class="box-list-title">
          <div>
            收藏分销商数
            <el-popover
              placement="bottom-start"
              width="240"
              trigger="hover"
              content="统计时间周期内，收藏商品的分销商总数"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
        </div>
        <div class="box-list-num">
          {{ modelInfo.fav }}
        </div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.collectUser == true }]"
            >{{ modelInfo.favRadio }}%</span
          >
        </div>
      </div> -->
    </div>
    <div class="statistical-time-echarts-box">
      <div
        ref="statistEcharts"
        class="echartsLine"
        :style="{ height: echartsHeight }"
      ></div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
// import * as echarts from 'echarts';
import { get, defaultTo } from 'lodash';
import StatistController from './controller/StatistController';
import CurrencyChose from '../CurrencyChose.vue';
export default {
  name: 'StatisticalTime',
  components: {
    CurrencyChose,
  },
  props: {
    // 当前页面币种, 1 人民币 2 美元
    pageCurrency: {
      type: String,
      default: '1',
    },
    // 支付数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // currTime: '',
      search: {
        type: 1,
        timer: [],
      },
      pickerOptTimer: [],
      symbolsNum: {
        payment: false,
        orderPaid: false,
        user: false,
        pricePer: false,
        collectUser: false,
      },
      modelInfo: {},
      chartData: [],
      startDate: '',
      endDate: '',
      echartsHeight: '221px',
    };
  },
  computed: {
    // 日期选择器限制
    pickerOptions() {
      return {
        onPick: ({ maxDate, minDate }) => {
          if (maxDate != null && minDate != null) {
            this.pickerOptTimer = [];
          }
          this.pickerOptTimer[0] = minDate.getTime();
        },
        disabledDate: time => {
          if (this.pickerOptTimer.length !== 0) {
            const pickTime = 30 * 24 * 3600 * 1000;
            const afterTime = this.pickerOptTimer[0] + pickTime;
            const beforeTime = this.pickerOptTimer[0] - pickTime;
            const nowTmie = new Date() - 24 * 3600 * 1000;
            return (
              time.getTime() > afterTime ||
              time.getTime() < beforeTime ||
              time.getTime() > nowTmie
            );
          } else {
            const nowTmie = new Date() - 24 * 3600 * 1000;
            return time.getTime() > nowTmie;
          }
        },
      };
    },
    // 货币符号
    currencySign() {
      switch (this.pageCurrency) {
        case '1':
          return '￥';
        case '2':
          return '$';
      }
    },
  },
  filters: {
    decimal(val) {
      // 是金额， 需要2位小数
      if (!val || val == 0) {
        return 0;
      }
      return Number(val).toFixed(2);
    },
  },
  mounted() {},
  watch: {
    'search.type'() {
      this.choseDataBox();
    },
    // 获取支付数据
    detail: {
      immediate: true,
      deep: true,
      handler(val) {
        let modelInfo = {};
        let chartData = [];
        for (let key in val) {
          if (key == 'list') {
            chartData = defaultTo(val[key], []);
          } else {
            modelInfo[key] = defaultTo(val[key], 0);
          }
        }

        this.modelInfo = modelInfo;
        this.chartData = chartData;
        this.startDate = this.chartData[0]?.times;
        this.endDate = this.chartData?.slice(-1)[0]?.times;
        if (this.startDate && this.endDate) {
          this.search.timer = [this.startDate, this.endDate];
        }
        // this.currTime = dayjs().format('YYYY-MM-DD');
        // 营收金额
        if (this.modelInfo?.orderMoneyRadio?.slice(0, 1) != '-') {
          this.modelInfo.orderMoneyRadio =
            '+' + this.modelInfo?.orderMoneyRadio;
          this.symbolsNum.payment = true;
        } else {
          this.symbolsNum.payment = false;
        }

        // 订单数
        if (this.modelInfo?.orderNumRadio?.slice(0, 1) != '-') {
          this.modelInfo.orderNumRadio = '+' + this.modelInfo?.orderNumRadio;
          this.symbolsNum.orderPaid = true;
        } else {
          this.symbolsNum.orderPaid = false;
        }

        // 有交易分销商数
        if (this.modelInfo?.userRadio?.slice(0, 1) != '-') {
          this.modelInfo.userRadio = '+' + this.modelInfo?.userRadio;
          this.symbolsNum.user = true;
        } else {
          this.symbolsNum.user = false;
        }

        // 客单价
        if (this.modelInfo?.customerRadio?.slice(0, 1) != '-') {
          this.modelInfo.customerRadio = '+' + this.modelInfo?.customerRadio;
          this.symbolsNum.pricePer = true;
        } else {
          this.symbolsNum.pricePer = false;
        }

        // if (this.modelInfo.favRadio?.slice(0, 1) != '-') {
        //   this.modelInfo.favRadio = '+' + this.modelInfo.favRadio;
        //   this.symbolsNum.collectUser = true;
        // } else {
        //   this.symbolsNum.collectUser = false;
        // }

        if (chartData && chartData.length != 0) {
          this.initEcharts(chartData);
        }
      },
    },
  },
  methods: {
    // resetSearch() {
    //   this.search.timer = [];
    // },
    initEcharts(data) {
      // 避免报错：There is a chart instance already initialized on the dom
      const el = this.$refs.statistEcharts;
      let chart = echarts.getInstanceByDom(el);
      if (chart == null) {
        chart = echarts.init(el);
      }

      const labels = data.map(item => item.times);
      let margin = '';
      let padding = '';
      let bottom = '';
      // 是否同年
      if (labels[0]?.substring(0, 4) == labels?.slice(-1)[0]?.substring(0, 4)) {
        for (let i = 0; i < labels.length; i++) {
          labels[i] = labels[i]?.substring(5, 10);
        }
        this.echartsHeight = '221px';
        chart.resize({ height: this.echartsHeight });
        margin = '20';
        bottom = '30';
        StatistController.setXAxisMargin(margin);
        StatistController.setGridBottom(bottom);
      } else {
        for (let i = 0; i < labels.length; i++) {
          labels[i] = labels[i].replace('-', ' - ');
        }
        this.echartsHeight = '233px';
        chart.resize({ height: this.echartsHeight });
        margin = '10';
        bottom = '44';
        StatistController.setXAxisMargin(margin);
        StatistController.setGridBottom(bottom);
      }

      const value = data.map(item => item.nums);

      switch (this.search.type) {
        case 1:
          padding = [0, 0, 5, -45];
          StatistController.setYAxisPadding(padding);
          // StatistController.setYAxisName('营收金额(￥)');
          StatistController.setYAxisName(
            `${'营收金额(' + this.currencySign + ')'}`
          );
          break;
        case 2:
          padding = [0, 0, 5, -55];
          StatistController.setYAxisPadding(padding);
          StatistController.setYAxisName('订单数');
          break;
        case 3:
          padding = [0, 0, 5, -40];
          StatistController.setYAxisPadding(padding);
          StatistController.setYAxisName('有交易分销商');
          break;
        case 4:
          padding = [0, 0, 5, -58];
          StatistController.setYAxisPadding(padding);
          // StatistController.setYAxisName('客单价(￥)');
          StatistController.setYAxisName(
            `${'客单价(' + this.currencySign + ')'}`
          );
          break;
        // case 5:
        //   padding = [0, 0, 5, -40];
        //   StatistController.setYAxisPadding(padding);
        //   StatistController.setYAxisName('收藏分销商数');
        default:
          break;
      }
      StatistController.setLabel(labels);
      StatistController.setData(value);

      //计算ehcarts y轴最大值 和 步长
      let maxVal = 0; //最大值
      let intVal = 0; //步长
      // 1. 遍历 dataList（图表需要展示的数据） 获取最大值
      value.forEach(res => {
        let number = parseFloat(res);
        if (number > maxVal) {
          maxVal = number;
        }
      });
      // 2. 最大值 向上取整
      maxVal = Math.ceil(maxVal);
      // 3. 最大值 能整除4，防止y轴步长出现小数
      for (let i = 0; i < 4; i++) {
        if (maxVal % 4 != 0) {
          maxVal++;
        }
      }
      // 4. 计算y轴步长
      intVal = maxVal / 4;
      if (maxVal == 0) {
        maxVal = 1;
        intVal = 1;
      }
      StatistController.setYAxisMax(maxVal);
      StatistController.setYAxisInterval(intVal);

      const options = StatistController.getOptions();
      chart.setOption(options);
    },
    // 选择日期
    choseDate() {
      this.pickerOptTimer = [];
      this.$emit('chose-date', this.search.timer);
    },
    // 选择数据盒子
    choseDataBox() {
      this.$emit('chose-type', this.search.type);
    },
    // 改变币种
    changeCurrency(type) {
      this.$emit('change-currency', type);
    },
  },
};
</script>

<style lang="scss" scoped>
.statistical-time {
  padding: 20px;
  background-color: #fff;

  .flex {
    display: flex;
  }

  .statistical-time-title {
    margin-bottom: 10px;

    .title-top {
      margin-bottom: 10px;
      height: 21px;

      .time-title-font {
        color: #111;
        font-size: 16px;
        line-height: 19px;
      }

      .time-title-num {
        margin-left: 10px;
        color: #999;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .select-time {
      margin-right: 20px;
    }

    .el-date-editor--daterange.el-input__inner {
      width: 300px;
      height: 40px;
      border-radius: 0;
    }

    ::v-deep .el-range-editor.el-input__inner {
      padding: 4px 12px !important;
    }

    ::v-deep .el-input__inner {
      border: 1px solid #ccc !important;
    }

    ::v-deep .el-date-editor .el-range-input {
      // width: 120px !important;
      font-size: 14px;
      color: #808080 !important;
    }

    ::v-deep .el-date-editor .el-range-separator {
      padding: 0 6px !important;
      width: 126px !important;
      line-height: 32px;
      font-size: 12px !important;
      width: 24px !important;
      color: #4d4d4d !important;
    }
  }

  .statistical-time-box {
    display: flex;
    margin-bottom: 20px;
    gap: 20px;

    .box-list {
      padding: 20px;
      width: 275px;
      height: 115px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      cursor: pointer;

      .box-list-title {
        div {
          height: 18px;
          color: #4d4d4d;
          font-size: 14px;
          line-height: 16px;

          i {
            height: 16px;
            line-height: 14px;
            color: #3d8df5;
            font-size: 16px;
            vertical-align: bottom;
            cursor: pointer;
          }
        }
      }

      .box-list-num {
        margin: 10px 0;
        height: 21px;
        line-height: 19px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
      }

      .box-list-week {
        height: 16px;
        font-size: 12px;

        span:first-child {
          color: #808080;
        }

        span:last-child {
          margin-left: 4px;
          color: #07c160;
        }

        .increase {
          color: #d22316 !important;
        }
      }
    }

    .box-list-active {
      border: 2px solid #ff7802;
    }
  }

  // 折线图悬浮样式
  .echartsLine {
    ::v-deep .tooltip {
      color: #808080 !important;

      .hoverDate {
        height: 16px;
        line-height: 16px;
        font-size: 12px;
      }
      .detailNum {
        display: flex;
        align-items: center;
        margin-top: 10px;
        height: 16px;
        line-height: 16px;
        font-size: 12px;

        span {
          margin-left: 10px;
        }
      }

      .detailNum::before {
        content: '';
        display: block;
        margin-right: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #ff7802;
      }
    }
  }
}
</style>

<template>
  <div class="pop-box">
    <el-popover
        :placement="placement"
        :width="width"
        :trigger="trigger"
        :popper-class="popStyle()"
        :style="{ 'text-align':Align}"
        :data-Align1="Align1"
      >
        <div class="font_style">
          <slot name="text"></slot>
        </div>
        <div slot="reference">
          <slot name="Identification"></slot>
        </div>
      </el-popover>
  </div>
</template>
<script>
export default {
  props:{
    trigger:{
      type:String,
      default:'hover'
    },
    placement:{
      type:String,
      default:'bottom-start'
    },
    width:{
      type:String,
      default:''
    },
    Align:{
      type:String,
      default:'left'
    }
  },
  data(){
    return{

    }

  },
  mounted(){

  },
  methods:{
    // 剩下自己来
    popStyle(){
      switch(this.Align){
        case 'center':
          return 'pop-center'
        case 'left':
          return 'pop-left'
        default:
          break
      }
    }
  }
}
</script>
<style>
.pop-center{
  text-align: center !important;
  min-width: 10px !important;
}
.pop-left{
  text-align: left !important;
  min-width: 10px !important;
}
.font_style{
  font-size: 12px;
  color: #999999;
}
</style>

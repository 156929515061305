export const methods = {
  $successToast(msg) {
    this.$message({
      message: msg,
      type: 'success',
      duration: 1000,
    });
  },
  $warningToast(msg) {
    this.$message({
      message: msg,
      type: 'warning',
      duration: 1000,
    });
  },
  /**
   * 路由跳转 $route.push && $route.replace
   * 
   * 如果 path 不是以 / 开头的, 则是以 params 的方式传参的
   * 反之 path 是以 / 开头的, 则是以 query 的方式传参的
   * 
   * params 传参的方式在刷新页面的时候会丢失数据
   * 如果不想丢失数据的话, 可以调用 getRouteParams() 方法, 来获取 params 参数
   * 
   * @param {String} path 路由路径
   * @param {Object} params 路由参数
   * @param {String} prop 路由跳转方式 push: $route.push(); replace: $route.replace()
   */
  navigateTo(path, params = {}, prop = 'push') {
    const __props = ['push', 'replace'];

    // 如果 __props 内没有 prop, 就默认 push 跳转
    if (!__props.includes(prop)) {
      prop = 'push';
    }
    
    // params 否则 query
    if (path[0] !== '/') {

      localStorage.setItem('VIEW_PARAMS', JSON.stringify(params));
      this.$router[prop]({ name: path, params });

    } else {
      this.$router[prop]({ path, query: params });
    }
  },
  // 获取 $route.params 参数
  // 解决 params 传参, 页面刷新数据丢失的问题
  getRouteParams() {
    let result = {};

    if (this.isEmptyObject(this.$route.params)) {
      result = JSON.parse(localStorage.getItem('VIEW_PARAMS'));
    } else {
      result = this.$route.params;
    }

    return result;
  },
  /**
  * 获取一个真实api
  * @param {*} api 
  * @returns 
  */
  getRequestUrl(api) {
    api = api.trim();
    return api + '';
  }
};

<template>
  <div class="currency-chose">
    <el-select
      @change="changeType"
      v-model="currencyValue"
      placeholder="人民币"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'CurrencyChose',
  props: {
    // 当前页面币种, 1 人民币 2 美元
    pageCurrency: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      currencyValue: '1',
      options: [
        {
          value: '1',
          label: '人民币',
        },
        {
          value: '2',
          label: '美元',
        },
      ],
    };
  },
  watch: {
    pageCurrency(val) {
      this.currencyValue = val;
    },
  },
  methods: {
    // 改变币种
    changeType() {
      this.$emit('change-currency', this.currencyValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.currency-chose {
  ::v-deep .el-select {
    width: 98px;

    .el-input__inner {
      border: 1px solid #ccc;
      border-radius: 0;
    }
  }
}
</style>

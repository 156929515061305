/**
 * 特色服务模块
 */
const FeatureServicesPlate = () => import('@/pages/feature-services/index.vue'); //特色服务板块
const Transit = () => import('@/pages/feature-services/transit/index.vue'); //中间页
const NoContract = () =>
  import('@/pages/feature-services/no-contract/index.vue'); //查看内容（未签约）
const HasContract = () =>
  import('@/pages/feature-services/has-contract/index.vue'); //查看内容（已经签约）
const signAgreement = () =>
  import('@/pages/feature-services/sign-agreement/index.vue'); //签署协议
const featureServicesRoutes = [
  //特色服务中间板块
  {
    path: '/featureservices',
    name: 'FeatureServices',
    meta: {
      title: '特色服务',
      path: '/featureservices',
      navIndex: 'featureservices',
      needsToken: true,
    },
    component: FeatureServicesPlate,
    redirect: '/featureservices/transit',
    children: [
      {
        path: 'transit',
        name: 'Transit',
        component: Transit,
        meta: {
          needsToken: true,
        },
      },
      {
        path: 'nocontract/:id?',
        name: 'NoContract',
        component: NoContract,
        meta: {
          needsToken: true,
        },
      },
      {
        path: 'hascontract/:id?',
        name: 'HasContract',
        component: HasContract,
        meta: {
          needsToken: true,
        },
      },
    ],
  },
  {
    path: '/signagreement/:id?',
    name: 'signAgreement',
    component: signAgreement,
    meta: {
      needsToken: true,
    },
  },
];

export default featureServicesRoutes;

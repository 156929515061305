<template>
  <div class="xm-countdown-dialog">
    <!-- 遮罩层 -->
    <div class="mask"></div>
    <!-- 注册协议 -->
    <div class="box-container">
      <main class="box-main">
        <i class="el-icon-success success-icon"></i>
        <div class="success-label">{{ title }}</div>
        <div class="success-prompt">
          <!-- 提交成功弹窗的提示语 -->
          <slot></slot>
        </div>
      </main>
      <footer class="box-footer">
        <button @click="handleClickBtn" class="footer-btn">
          <p>
            <span v-if="timeCount > 0">({{ countTime }}s)</span>{{ btnText }}
          </p>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
// 带倒计时的成功提示弹窗
export default {
  name: 'XmCountdownDialog',
  data() {
    return {
      countTime: this.timeCount,
    };
  },
  props: {
    title: {
      type: String,
      default: '提交成功',
    },
    btnText: {
      type: String,
      default: '确定',
    },
    timeCount: {
      type: Number,
      default: 3,
    },
  },
  watch: {},
  methods: {
    // 倒计时
    countdown() {
      if (this.countTime < 0) return;

      const timer = setInterval(() => {
        if (this.countTime > 1) {
          --this.countTime;
        } else {
          this.handleCountdownEnd();
          clearInterval(timer);
        }
      }, 1000);
    },
    // 点击弹窗按钮
    handleClickBtn() {
      this.$emit('click-btn');
    },
    // 倒计时结束的事件
    handleCountdownEnd() {
      this.$emit('countdown-end');
    },
  },
  mounted() {
    this.countdown(); // 开启倒计时
  },
};
</script>

<style lang="scss" scoped>
.xm-countdown-dialog {
  * {
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
  }
  /* 遮罩层 */
  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #111;
    opacity: 0.4;
    z-index: 1000;
  }

  .box-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 600px;
    // height: 274px;
    z-index: 1010;
    background-color: #fff;
    border-radius: 4px;

    padding-top: 34px;
    padding-bottom: 30px;

    .box-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .success-icon {
        color: #07c160;
        width: 56px;
        height: 56px;
        font-size: 56px;
        margin-bottom: 20px; // fixme: 间隔
      }

      .success-label {
        margin-bottom: 20px;

        font-size: 18px;
        color: #111111;
        line-height: 32px;
      }

      .success-prompt {
        margin-bottom: 20px;

        font-size: 14px;
        color: #111111;
        line-height: 16px;
      }
    }

    .box-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding: 0 24px;

      position: relative;

      button {
        width: 160px;
        height: 40px;

        background-color: #ff7802;
        color: #fff;

        border-radius: 4px 4px 4px 4px;
        cursor: pointer;

        p,
        span {
          display: inline-block;
          height: 24px;
          line-height: 22px;

          font-size: 18px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;

          color: #ffffff;
        }
      }
    }
  }
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import routes from './routes'; // 引入所有路由对象

import { NimChat } from '@/utils/NimChat';
import { startDetector } from '@/utils/auto_logout';
import { resetLoginFailedCount } from '@/utils/login_fail_handler';

// router meta属性 goBack:true 顶部返回、首页按钮  toTop:true 底部置顶按钮   showTabBar: true 底部导航  disableSwipe：true 禁用左右滑动的返回前进
const router = new VueRouter({
  mode: 'history',
  routes: routes,
});
// 有效的 status 集合
const validStatusSet = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '-1']);

router.beforeEach((to, from, next) => {
  // #delme 检查 comId 是否可以删掉
  let comId = '';
  if (hasValue(localStorage.comId)) {
    comId = '_comId' + localStorage.comId;
  }

  if (to.matched.length === 0) {
    //判断路由是否存在
    //匹配前往的路由不存在
    next('/error');
  } else if (to.meta.needsToken) {
    // 判断页面是否需要登录进入
    //页面可在未登录下直接进入
    const Token = localStorage.getItem('token');
    if (Token) {
      resetLoginFailedCount(); // 清空登录失败计数
      /**
       * 页面埋点
       */
      let parameterarr = Object.keys(to.params); // params参数长度
      let queryarr = Object.keys(to.query); // query参数长度
      console.log('queryarr', queryarr);
      console.log('parameterarr', parameterarr);
      if (parameterarr.length == 0 && queryarr.length == 0 && to.path !== '/') {
        supplierReport(to);
      }

      // 用户 status 状态,-1删除,0-待完善资料 1-待审核 2-已通过 3-已驳回  4-拉黑 5-二次审核 6-平台审核通过退店申请 7-供应商已提交退款账号
      let status = '' + localStorage.getItem('status');

      // 检查用户 Status 状态是否合法
      if (!validStatusSet.has(status)) {
        localStorage.removeItem('token'); // 非法状态，清空 token，弹回登录页
        next(false);
        next('/login');
        return;
      }
      if (
        status == '6' &&
        to.path.indexOf('/checkOut') === -1 &&
        to.path.indexOf('/bankcard/add') === -1 &&
        to.path.indexOf('/chat') === -1
      ) {
        // 6 - 平台审核通过退店申请 能去退店页面和添加银行卡页面
        next(false);
        next('/checkOut');
        return;
      } else if (
        status == '7' &&
        to.path.indexOf('/checkOut') === -1 &&
        to.path.indexOf('/chat') === -1
      ) {
        // 7 - 供应商已提交退款账号 只能去退店页面
        next(false);
        next('/checkOut');
        return;
      } else if (
        status == '0' &&
        to.path.indexOf('/account/verify/information/edit') === -1 &&
        to.path.indexOf('/chat') === -1 &&
        to.path.indexOf('/bulletinlist') === -1 &&
        to.path.indexOf('/bulletindetail') === -1
      ) {
        // 0 - 新注册供应商，去账号及认证基础信息
        next(false);
        next('/account/verify/information/edit');
        return;
      } else if (
        status == '1' &&
        to.path.indexOf('/accountSetting') === -1 &&
        to.path.indexOf('/chat') === -1 &&
        to.path.indexOf('/bulletinlist') === -1 &&
        to.path.indexOf('/bulletindetail') === -1
      ) {
        next(false);
        next('/accountSetting');
        return;
      } else if (
        status == '3' &&
        to.path.indexOf('/accountSetting') === -1 &&
        to.path.indexOf('/account/verify/information/edit') === -1 &&
        to.path.indexOf('/chat') === -1 &&
        to.path.indexOf('/bulletinlist') === -1 &&
        to.path.indexOf('/bulletindetail') === -1
      ) {
        next(false);
        next('/accountSetting');
        return;
      }

      /**
       * 初始化与云信服务器的连接
       */
      let isToChat = to.path?.indexOf('/chat') >= 0;
      if (!Vue.prototype.$nim) {
        const uid = localStorage.getItem('uid');
        if (uid) {
          let IMToken = localStorage.getItem('im_token');
          Vue.prototype.$nim = new NimChat(uid, IMToken);
          if (Vue.prototype.$nim.isValid && !isToChat) {
            Vue.prototype.$nim.init();
          }
        }
      }

      // 初始化监听用户动态操作的检测器
      if (!Vue.prototype.$dynamicDetector) {
        Vue.prototype.$dynamicDetector = startDetector();
      }

      let categoryInfo = [];
      categoryInfo = store?.state?.category?.categoryData || [];

      if (categoryInfo?.length === 0 && !isToChat) {
        // 获取多级类目，在聊天页面不获取类目数据
        getCategory();
      }

      document.title = '页面跳转中...';
      next();
    } else {
      // 关闭检测用户动态操作的检测器
      if (Vue.prototype.$dynamicDetector) {
        Vue.prototype.$dynamicDetector.close();
        Vue.prototype.$dynamicDetector = null;
      }
      // 销毁 nim 实例
      if (Vue.prototype.$nim) {
        Vue.prototype.$nim.destroy();
        Vue.prototype.$nim = null;
      }
      store.commit('clearCategoryData');
      next('/login');
      return;
    }
  } else {
    document.title = '页面跳转中...';
    next();
  }
});

router.afterEach((to, from, next) => {
  let documentTitle = '';
  to.matched.forEach(path => {
    if (path.meta.title) {
      documentTitle = path.meta.title;
    }
  });
  document.title = documentTitle;
});

// 获取类目
function getCategory() {
  let api = '/CategoryInfo/getAllCategoryInfo';
  xmJson(
    api,
    {},
    res => {
      if (res.errcode == 0) {
        store.commit('setCategoryData', res.data);
      }
    },
    null,
    'get'
  );
}

// 记录路由跳转的页面
function supplierReport(obj) {
  if (!obj?.meta?.path) return;
  let api = '/Report/supplierReport';
  let option = {
    menu_name: obj.meta.title,
    url: obj.meta.path,
    type: 1,
  };
  xmJson(api, option, res => {}, null, 'get');
}

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

export default router;

<template>
  <div class="addressinsure">
    <el-select
      ref="selectinput"
      v-model="countvalue"
      placeholder="请选择"
      multiple
      :popper-append-to-body="false"
      style="width: 300px"
      @change="changeValue"
    >
      <div>
        <div class="topbar">
          <div>大洲</div>
          <div>国家</div>
          <div>已选择</div>
        </div>
        <div class="optionmodel">
          <el-option style="display: none"></el-option>
          <div class="optionContent">
            <div>
              <div
                v-for="item in continents_list"
                :key="item.id"
                class="contente-left"
              >
                <el-checkbox
                  v-model="item.checkValue"
                  @change="continentChange(item)"
                ></el-checkbox>
                <div
                  class="con-right"
                  @click="getCountryList(item.id)"
                  :class="continentId == item.id ? 'activeColor' : ''"
                >
                  <div>
                    {{ item.cname }}
                  </div>
                  <div
                    class="iconfont left-incon-active"
                    v-if="continentId == item.id"
                  >
                    &#xe639;
                  </div>
                  <div class="iconfont left-incon" v-else>&#xe632;</div>
                </div>
              </div>
            </div>
            <div class="count-content">
              <el-option
                v-for="item in filterCountry()"
                :key="item.id"
                @click.native="selectCountry(item)"
                :value="item.id"
                :label="item.cname"
              >
                <!-- <div>
                  {{ item.cname }}
                </div> -->
              </el-option>
            </div>
            <div class="selectModel">
              <el-collapse accordion>
                <el-collapse-item
                  v-for="(item, index) in filterSelect()"
                  :key="index"
                >
                  <template slot="title">
                    {{ selectName(item[0].continent_id) }}
                  </template>
                  <div v-for="res in item" :key="res.id" class="selectItem">
                    <div>{{ res.cname }}</div>
                    <div class="icon-x" @click="delSelect(res)">x</div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
        <div class="opera-model">
          <div @click="confirmSelect">确定</div>
          <div @click="cancel">取消</div>
        </div>
      </div>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'XmAddressMultiSelect',
  data() {
    return {
      continents_list: [], // 大洲列表
      country_list: [],
      selectList: [], // 选择的数据
      continentId: '',
      countvalue: [], // 展示的数据
      // searchTarget: '',
      // searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标
      // conutryid: [],
      // countrydimen: [], // 一维城市数组
      // allvalue: false,
      // countryName: [],
      // sumWidth: 0,
    };
  },
  mounted() {
    this.getcontinentsList();
  },
  props: {
    countIds: {
      type: Array,
      default: [],
    },
    allTag: {
      type: [String, Number],
      default: 0,
    },
    // 是否隐藏“全球”选项
    hideAll: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectList(val) {
      this.countvalue = [];
      val.forEach(item => {
        this.countvalue.push(item.cname);
      });
    },
  },
  methods: {
    initarr() {
      let api = '/Supplier/getCountries';
      xmJson(
        api,
        {},
        res => {
          for (let i = 0; i < res.data.length; i++) {
            if (this.countIds.length == 1 && this.countIds[0] == 'all') {
              this.selectList.push(res.data[i]);
              this.countvalue.push(res.data[i].cname);
            } else {
              this.countIds.forEach(item => {
                if (res.data[i].id == item) {
                  this.selectList.push(res.data[i]);
                  this.countvalue.push(res.data[i].cname);
                }
              });
            }
          }
          // 判断全选
          // 1 44
          // 2 47
          // 3 54
          // 4 17
          // 5 0
          // 6 16
          // 7 27
          for (let item in this.filterSelect()) {
            let length = this.filterSelect()[item].length;
            console.log(length, 'length');
            let type = null;
            if (item == 1 && length == 44) {
              type = item;
            } else if (item == 2 && length == 47) {
              type = item;
            } else if (item == 3 && length == 54) {
              type = item;
            } else if (item == 4 && length == 17) {
              type = item;
            } else if (item == 6 && length == 16) {
              type = item;
            } else if (item == 7 && length == 27) {
              type = item;
            }
            this.continents_list.forEach(res => {
              if (res.id == type) {
                res.checkValue = true;
              }
            });
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 获取大洲列表
    getcontinentsList() {
      const api = '/supplier/getContinents';
      const options = {};
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            res.data.forEach(item => {
              item.checkValue = false;
            });
            this.continents_list = res.data;
          }
          this.initarr();
        },
        err => {
          console.error(err);
        }
      );
    },
    continentChange(obj) {
      const api = 'Supplier.getCountries';
      const options = {
        id: obj.id,
        is_initial_group: 0,
      };
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            let country_list = res.data;
            if (obj.checkValue) {
              // 选中
              for (let i = 0; i < country_list.length; i++) {
                let flag = false;
                this.selectList.forEach(item => {
                  if (item.id == country_list[i].id) {
                    flag = true;
                  }
                });
                if (!flag) {
                  this.selectList.push(country_list[i]);
                }
              }
            } else {
              //取消
              for (let i = 0; i < country_list.length; i++) {
                let flag = false;
                let arrindex = -1;
                this.selectList.forEach((item, index) => {
                  if (item.id == country_list[i].id) {
                    flag = true;
                    arrindex = index;
                  }
                });
                if (flag) {
                  this.selectList.splice(arrindex, 1);
                }
              }
            }
          }
        },
        err => {
          console.error(err);
        }
      );
    },

    getCountryList(id) {
      this.continentId = id;
      const api = 'Supplier.getCountries';
      const options = {
        id,
        is_initial_group: 0,
      };
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            this.country_list = res.data;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    selectCountry(obj) {
      this.selectList.push(obj);
      if (this.filterCountry().length == 0) {
        this.continents_list.forEach(item => {
          if (item.id == obj.continent_id) {
            item.checkValue = true;
          }
        });
      }
    },

    filterCountry() {
      let arr = [];
      for (let i = 0; i < this.country_list.length; i++) {
        let flag = false;
        this.selectList.forEach(item => {
          if (item.id == this.country_list[i].id) {
            flag = true;
          }
        });
        if (!flag) {
          arr.push(this.country_list[i]);
        }
      }
      return arr;
    },

    filterSelect() {
      let obj = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      };
      for (let i = 0; i < this.selectList.length; i++) {
        if (this.selectList[i].continent_id) {
          obj[this.selectList[i].continent_id].push(this.selectList[i]);
        }
      }
      for (let item in obj) {
        if (obj[item].length == 0) {
          delete obj[item];
        }
      }
      return obj;
    },

    selectName(id) {
      let name = '';
      this.continents_list.forEach(item => {
        if (item.id == id) {
          name = item.cname;
        }
      });
      return name;
    },

    delSelect(obj) {
      let arrindex = 0;
      this.selectList.forEach((item, index) => {
        if (obj.id == item.id) {
          arrindex = index;
        }
      });
      this.selectList.splice(arrindex, 1);

      this.continents_list.forEach(item => {
        if (item.id == obj.continent_id) {
          item.checkValue = false;
        }
      });
    },

    changeValue() {
      for (let i = 0; i < this.selectList.length; i++) {
        let flag = false;
        this.countvalue.forEach((item, index) => {
          if (item == this.selectList[i].cname) {
            flag = true;
          }
        });
        if (!flag) {
          // 没有数据
          this.continents_list.forEach(item => {
            if (item.id == this.selectList[i].continent_id) {
              item.checkValue = false;
            }
          });
          this.selectList.splice(i, 1);
          i++;
        }
      }
    },
    confirmSelect() {
      this.$refs.selectinput.blur();
      let allselect = true;
      this.continents_list.forEach(item => {
        if (!item.checkValue) {
          allselect = false;
        }
      });
      if (allselect) {
        // 全选
        this.$emit('getAddressID', ['all']);
      } else {
        let idArr = [];
        this.selectList.forEach(item => {
          idArr.push(item.id);
        });
        this.$emit('getAddressID', idArr);
      }
    },
    cancel() {
      this.$refs.selectinput.blur();
      this.selectList = [];
      this.continents_list.forEach(item => {
        item.checkValue = false;
      });
      this.initarr();
    },
  },
};
</script>

<style lang="scss" scoped>
.addressinsure {
  .topbar {
    width: 700px;
    height: 36px;
    background: #f2f2f2;
    justify-content: flex-start;
    display: flex;
    & > div {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #4d4d4d;
      padding: 0px 20px;
      line-height: 36px;
      width: 250px;
    }

    & > :first-child {
      width: 200px;
    }
  }

  .optionmodel {
    width: 100%;
    height: 275px;
    overflow: hidden;

    .optionContent {
      display: flex;
      justify-content: flex-start;
      > div {
        width: 250px;
        box-sizing: border-box;
        padding-left: 20px;
      }

      & > :first-child {
        width: 200px;
      }

      .contente-left {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        .con-right {
          display: flex;
          justify-content: space-between;
          width: 130px;
          cursor: pointer;
          .left-incon {
            scale: 0.7;
            color: #666666;
          }

          .left-incon-active {
            color: #ff7802;
          }
        }
      }
    }

    .count-content {
      max-height: 275px;
      height: fit-content;
      overflow: auto;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
      & > div {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }
    }

    .selectModel {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      max-height: 275px;
      height: fit-content;
      overflow: auto;
      // &::-webkit-scrollbar {
      //   display: none;
      // }

      .selectItem {
        width: 350px;
        height: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        display: flex;
        justify-content: flex-start;
        line-height: 22px;
        .icon-x {
          display: none;
          line-height: 20px;
          cursor: pointer;
        }

        & > :first-child {
          width: 185px;
        }
        &:hover {
          background: #f7f7f7;
          .icon-x {
            display: block;
          }
        }
      }
    }
  }

  .flex-left {
    display: flex;
    justify-content: flex-start;
  }

  .activeColor {
    color: #ff7802;
  }

  .opera-model {
    width: 100%;
    height: 70px;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    & > div {
      width: 60px;
      height: 40px;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      cursor: pointer;
    }

    & > :first-child {
      background: #ff7802;
      color: #ffffff;
    }

    & > :last-child {
      margin-left: 20px;
      border: 1px solid #cccccc;
      color: #606266;
    }
  }

  ::v-deep .el-select-dropdown__list {
    padding: 0px;
    border-radius: 0px;
  }

  ::v-deep .el-popper[x-placement^='bottom'] .popper__arrow::after {
    border-bottom-color: #f2f2f2;
  }

  ::v-deep .el-select-dropdown__wrap {
    max-height: 1000px;
  }

  ::v-deep .el-select-dropdown {
    border-radius: 0px;
  }

  ::v-deep .el-collapse-item__header {
    // height: 38px;
    border-bottom: none;
  }

  ::v-deep .el-collapse-item__header.is-active {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ff7802;
  }

  ::v-deep .el-collapse-item__content {
    padding-bottom: 0px;
  }

  ::v-deep .el-collapse-item__wrap {
    border-bottom: none;
  }

  ::v-deep .el-select__tags {
    height: 30px;
    overflow: hidden;
  }
}
</style>

<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>物流数据</div>
        <!-- <span @click="goUrl">查看全部</span> -->
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>24小时上网率</div>
          <span :class="[{ 'have-num': detail.twentyFour != 0.0 }]"
            >{{ detail.twentyFour }}%</span
          >
        </div>
        <div class="label-list">
          <div>72小时上网率</div>
          <span :class="[{ 'have-num': detail.seventyTwo != 0.0 }]"
            >{{ detail.seventyTwo }}%</span
          >
        </div>
        <div class="label-list">
          <div>签收率</div>
          <span :class="[{ 'have-num': detail.receipt != 0.0 }]"
            >{{ detail.receipt }}%</span
          >
        </div>
        <div class="label-list">
          <div>平均时效(天)</div>
          <span :class="[{ 'have-num': detail.average != 0.0 }]">{{
            detail.average
          }}</span>
        </div>
        <div class="label-list">
          <div>未收到货物纠纷率</div>
          <span :class="[{ 'have-num': detail.notReceived != 0.0 }]"
            >{{ detail.notReceived }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultTo } from 'lodash';

export default {
  name: 'LogisticsData',
  props: {
    // 物流数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // detail: {},
    };
  },
  methods: {
    goUrl() {},
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

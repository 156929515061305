<template>
  <div class="xm-goods-mark">
    <el-popover
      v-if="authentic"
      placement="bottom-start"
      trigger="hover"
      popper-class="goods-mark"
    >
      <div class="mark-content">{{ authentic }}</div>
      <div slot="reference" class="hover-text dark-color-style">认证</div>
    </el-popover>
    <el-popover
      v-if="lateCompensate"
      placement="bottom-start"
      trigger="hover"
      popper-class="goods-mark"
    >
      <div class="mark-content">晚发必赔</div>
      <div
        slot="reference"
        class="hover-text light-color-style"
        @click="handleClickMark(178)"
      >
        赔
      </div>
    </el-popover>
    <el-popover
      v-if="fakeCompensate"
      placement="bottom-start"
      trigger="hover"
      popper-class="goods-mark"
    >
      <div class="mark-content">假一赔三</div>
      <div
        slot="reference"
        class="hover-text light-color-style"
        @click="handleClickMark(179)"
      >
        正
      </div>
    </el-popover>
    <el-popover
      v-if="returnPolicyInfo?.supplierContent || returnPolicyInfo?.shopContent"
      placement="bottom-start"
      trigger="hover"
      popper-class="goods-mark"
    >
      <div class="mark-content return-policy">
        <slot name="returnPolicySlot">
          <div class="return-title">退货政策：</div>
          <div>
            <div>
              供应商问题：{{ returnPolicyInfo?.supplierContent || '--' }}
            </div>
            <div>分销商问题：{{ returnPolicyInfo?.shopContent || '--' }}</div>
          </div>
        </slot>
      </div>
      <div slot="reference" class="hover-text light-color-style">退</div>
    </el-popover>
    <el-popover
      v-if="wholesale"
      placement="bottom-start"
      trigger="hover"
      popper-class="goods-mark"
    >
      <div class="mark-content">批发</div>
      <div slot="reference" class="hover-text light-color-style">批</div>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    // 认证：认证
    authentic: {
      type: String,
      default: '',
    },
    // 赔：晚发必赔
    lateCompensate: {
      type: Boolean,
      default: false,
    },
    // 正：假一赔三
    fakeCompensate: {
      type: Boolean,
      default: false,
    },
    // 退：退货政策
    returnPolicy: {
      type: Object,
      default: () => {
        // supplier_problem: [],
        // user_problem: []
      },
    },
    // 批：批发
    wholesale: {
      type: String,
      default: '',
    },
    isClickJump: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  computed: {
    returnPolicyInfo() {
      let info = {};
      let supplierContent = '';
      let shopContent = '';
      if (this.returnPolicy?.supplier_problem?.length > 0) {
        supplierContent = this.returnPolicy.supplier_problem?.join('；');
      }
      if (this.returnPolicy?.user_problem?.length > 0) {
        shopContent = this.returnPolicy.user_problem?.join('；');
      }
      info = {
        supplierContent,
        shopContent,
      };
      return info;
    },
  },
  mounted() {},
  methods: {
    // 点击标识跳转  (目前只有点击 假一赔三、晚发必赔标识有点击跳转效果)
    handleClickMark(id) {
      if (!this.isClickJump) return;
      let url = 'https://home.ximu.cn/platrule/' + id;
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss">
.xm-goods-mark {
  display: flex;
  .dark-color-style {
    width: 32px;
    height: 18px;
    color: #ffffff;
    text-align: center;
    line-height: 18px;
    border-radius: 2px;
    background-color: #ff5722;
  }
  .light-color-style {
    width: 20px;
    height: 18px;
    background: linear-gradient(to right, #ffc18a, #ff8010);
  }
  .hover-text {
    color: #ffffff;
    text-align: center;
    line-height: 18px;
    border-radius: 2px;
    margin-right: 4px;
    font-size: 12px;
    cursor: pointer;
  }
}
.goods-mark {
  border-radius: 0px;
  font-size: 14px;
  line-height: 22px;
  color: #333333;

  .return-policy {
    max-width: 350px;
    display: flex;
    .return-title {
      flex-shrink: 0;
    }
  }
}
</style>

<template>
  <div class="ad-card">
    <img class="ad-img" src="./images/index_bannel_img.png" alt="侧边广告" />
  </div>
</template>

<script>
  export default {
    name: "AdCard"
  }
</script>

<style lang="scss" scoped>
.ad-card {
  height: 139px;

  .ad-img {
    width: 100%;
    height: 100;
    border-radius: 8px;
  }
}
</style>

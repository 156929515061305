<template>
  <div class="err-model">
    <div class="err-content">
      <div class="err-img">
        <img src="@/assets/images/404.png" />
      </div>
      <div class="err-text">
        <div>
          <span class="iconfont">&#xe69d;</span>&nbsp;您访问的页面不存在！
        </div>
        <div>可能是如下原因引起的这个错误：</div>
        <div>地址输入错误，链接已经失效过期。</div>
      </div>
      <div class="err-back" @click="goback">
        <span class="iconfont">&#xe69c;</span>
        返回上一页
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error',
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.err-model {
  .err-content {
    padding-top: 100px;
    margin: 0px auto;
    width: fit-content;
    .err-img {
      width: 400px;
      & > img {
        width: 100%;
      }
    }

    .err-text {
      width: 400px;
      padding-left: 63px;
      margin-top: 50px;

      & > :nth-child(1) {
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;

        & > span {
          color: #e6a23c;
          font-size: 24px;
        }
      }

      & > :nth-child(2) {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 10px;
      }

      & > :nth-child(3) {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .err-back {
      width: 130px;
      height: 40px;
      background: #ff7802;
      cursor: pointer;
      text-align: center;
      color: #ffffff;
      line-height: 40px;
      margin-left: 63px;
      margin-top: 30px;
    }
  }
}
</style>

<template>
  <div :style="{ height: height }">
    <div class="header-model">
      <div class="tip-model" v-if="tipshow">
        <div class="tip-content">
          <div>
            <img src="@/assets/images/redtip.svg" />
            <div>您的浏览器版本过低，为更好的体验，请升级您的浏览器。</div>
          </div>
          <div class="iconfont cancel-tip" @click="closetip">&#xe609;</div>
        </div>
      </div>
      <div class="headNav">
        <div class="head-nav-box">
          <div class="left">
            <div class="head-supplier-name" @click="logourl">
              <div class="logofont">
                <span class="iconfont">&#xe636;</span>
              </div>
            </div>
            <div class="head-language-box">
              <!-- 为了展示支付页面的收银台 -->
              <slot></slot>
              <a style="display: none">
                <img
                  class="head-language-icon"
                  src="../../../static/images/country_icon/china_icon.png"
                  @error="imgError($event)"
                />
                <el-dropdown trigger="click" size="medium">
                  <span>
                    <span>{{ languageName }}</span>
                    <img
                      class="head-language-select"
                      src="./images/head_language_select.png"
                    />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>中文</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </a>
            </div>
          </div>
          <div class="right">
            <!-- #todo 优化顶部状态栏，加头像替代“我的”，el-dropdown 鼠标悬浮显示菜单 -->
            <div class="head-menu-box">
              <div
                class="head-menu-item"
                v-for="(item, index) in headNavArray"
                :key="index"
                v-show="item.show"
                :class="[{ 'head-menu-item-active': activeIndex == item.id }]"
              >
                <span
                  class="head-menu-title"
                  v-if="!item.iconUrl"
                  @click="goToUrl(item.link, 'push')"
                  >{{ item.name }}
                </span>
                <el-dropdown
                  v-else-if="item.hasDropdown"
                  placement="bottom"
                  class="head-menu-title head-menu-icon"
                  @command="goToUrl($event, 'push')"
                >
                  <!-- 通知（一定要用空字符串 + 变量，不然会报错） -->
                  <img :src="require('' + item.iconUrl)" alt="" />
                  <div class="unread-msgs-item" v-if="hasUnreadNotices">
                    <span>{{ unreadNoticeNum }}</span>
                  </div>
                  <el-dropdown-menu
                    class="head-nav-notice-dropdown-menu"
                    slot="dropdown"
                  >
                    <el-dropdown-item
                      v-for="submenu in item.children"
                      :key="submenu.id"
                      :command="submenu.link"
                      >{{ submenu.name }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- 点击图标直接跳转 -->
                <div
                  class="head-menu-title head-menu-icon"
                  v-else-if="item.hasIconLink"
                  @click="goToUrl(item.link, 'push')"
                >
                  <img :src="require('' + item.iconUrl)" alt="" />
                </div>
                <div
                  v-else
                  class="head-menu-title head-menu-icon"
                  @click="chatWithCustomerService()"
                >
                  <!-- 消息（一定要用空字符串 + 变量，不然会报错） -->
                  <img :src="require('' + item.iconUrl)" alt="" />
                  <div class="unread-msgs-item" v-if="hasUnreadMsgs">
                    <span>{{ unreadMsgNum }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="head-user-box">
              <div class="head-user-name">
                <el-dropdown
                  ref="dropdown"
                  trigger="hover"
                  @visible-change="getInfo"
                >
                  <span>
                    <img
                      class="my-avatar"
                      :class="myStyle ? 'active-style' : ''"
                      :src="logo | imgbaseurl"
                    />
                    <img
                      src="@/assets/images/color606266Down.png"
                      class="head-user-item-icon dropdown-down-arrow-icon"
                      style=""
                      v-show="!myStyle"
                    />
                    <img
                      src="@/assets/images/colorff7802Down.png"
                      class="dropdown-down-arrow-icon"
                      v-show="myStyle"
                    />
                    <img
                      src="@/assets/images/colorff7802Down.png"
                      class="head-user-active-icon dropdown-down-arrow-icon"
                      v-show="myID == '-1'"
                    />
                  </span>

                  <el-dropdown-menu
                    class="head-nav-user-dropdown-menu"
                    slot="dropdown"
                  >
                    <div class="dropdown-user-avatar-name">
                      <img
                        :src="logo | imgbaseurl"
                        alt=""
                        class="dropdown-avatar"
                      />
                      <span class="dropdown-name">{{ nickName }}</span>
                    </div>
                    <el-dropdown-item
                      v-if="getUserStatus()"
                      @click.native="getRouteFunc"
                      >保险列表</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="closeLogin"
                      >登出</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import navItem from '@/mixin/navItemClass.js';
import topHeaderConfig from '@/assets/json/top_header_data.json';
import { getApplyInfo } from '@/api/account/basic_info';

export default {
  name: 'headNav',
  data() {
    return {
      nickName: localStorage.getItem('nickName')
        ? localStorage.getItem('nickName')
        : localStorage.getItem('account'),
      logo:
        localStorage.getItem('logo') ||
        'https://img.ximu.cn/default_avatar.png',
      languageName: '中文',
      activeIndex: this.activeIdx,
      navItems: new navItem(),
      headNavArray: [],
      myStyle: false, //控制我的下拉框的颜色
      myID: '-1',
      status: localStorage.getItem('status'),
      jsq: null,
      tipshow: false,
      height: '50px',
    };
  },
  props: {
    activeIdx: {
      type: [Number, String],
      default: '',
    },
  },
  watch: {
    tipshow(val) {
      if (val) {
        this.height = '100px';
      } else {
        this.height = '50px';
      }
    },
  },
  computed: {
    // 是否有未读消息
    hasUnreadMsgs() {
      return this.$store.getters.totalUnread > 0;
    },
    // 未读消息数展示
    unreadMsgNum() {
      let num = this.$store.getters.totalUnread;
      return num > 99 ? '99+' : num;
    },
    // 是否有未读通知
    hasUnreadNotices() {
      return false; // #todo
    },
    // 未读通知数展示
    unreadNoticeNum() {
      return '0'; //  #todo
    },
  },
  mounted() {
    this.getTopNav();
    this.getActiveIndex();
    this.nickName = localStorage.getItem('nickName')
      ? localStorage.getItem('nickName')
      : localStorage.getItem('account');
    if (!this.nickName) this.getStoreName();
    this.getHeadNav();
    this.browserVersion();
  },
  methods: {
    getTopNav() {
      // 在这里配置不同账户状态展示的不同顶部栏菜单
      // 用户 status 状态,-1删除,0-待完善资料 1-待审核 2-已通过 3-已驳回 4-拉黑 5-二次审核 6-平台审核通过退店申请 7-供应商已提交退款账号
      if (this.status == '2' || this.status == '5') {
        this.headNavArray = topHeaderConfig;
      }
      if (this.status == '0' || this.status == '1' || this.status == '3') {
        this.headNavArray = [
          {
            id: 5,
            name: '账号及认证',
            nav: 'account',
            link: '/accountSetting',
            key: 'systemNav',
            show: true,
            isshow: false,
          },
          {
            id: 10,
            name: '通知',
            nav: 'notice',
            link: '/notice/setting',
            key: 'noticeNav',
            show: true,
            isshow: false,
          },
        ];
      }
      if (this.status == '6' || this.status == '7') {
        this.headNavArray = [
          {
            id: 5,
            name: '账号及认证',
            nav: 'account',
            link: '/accountSetting',
            key: 'systemNav',
            show: true,
            isshow: false,
          },
        ];
      }
    },
    getRouteFunc() {
      this.$router.push('/authInsurList');
    },
    // 检测浏览器版本
    browserVersion() {
      if (this.$store.state.shopIndex) {
        return;
      }
      let varseion = null;
      if (navigator.userAgent) {
        let arr = navigator.userAgent.split(' ');
        arr.forEach(item => {
          if (item.indexOf('Chrome/') > -1) {
            varseion = Number(item.split('/')[1].split('.')[0]);
          }
        });
        if (varseion && varseion < 95) {
          this.tipshow = true;
        }
      }
    },
    getUserStatus() {
      if (this.status === '2' || this.status === '5') {
        return true;
      }
      return false;
    },
    getInfo(n) {
      this.myStyle = n;
      if (n === true) {
        this.myID = '1';
      } else {
        this.myID = '-1';
      }
    },
    logourl() {
      this.$router.push('/');
    },
    //判断是否显示顶部菜单
    isShowHeaderNav(item) {
      let key = getFirstAvailableValue(ObjectValue('key', item), '');
      let link = getFirstAvailableValue(ObjectValue('link', item), '');
      if (!key || !link) {
        return false;
      }
      let arr = getFirstAvailableValue(ObjectValue(key, this.navItems), []);
      if (arr.length == 0 && key != 'index') {
        return false;
      }
      return true;
    },
    // 根据权限是否显示顶部导航
    getHeadNav() {
      this.jsq = setInterval(() => {
        let status = localStorage.getItem('status');
        if (this.headNavArray.length > 0) {
          this.headNavArray.forEach(item => {
            if (status !== '2' && status !== '5' && item.isshow) {
              item['show'] = false;
            } else {
              item['show'] = true;
              this.isShowNav(item);
            }
          });
        }
        clearInterval(this.jsq);
      }, 100);
    },
    // 根据权限是否显示
    isShowNav(item) {
      let key = getFirstAvailableValue(ObjectValue('key', item), '');
      let arr = getFirstAvailableValue(ObjectValue(key, this.navItems), []);
    },
    goToUrl(path, type = 'push') {
      if (!hasValue(path)) {
        this.$message.warning('敬请期待');
        return;
      }
      this.$router[type](path);
    },
    getActiveIndex() {
      let path = this.$route.path;
      let navIndex = getFirstAvailableValue(
        ObjectValue('meta.navIndex', this.$route),
        '0'
      );
      if (navIndex === '0') return;
      let that = this;
      this.headNavArray.map(item => {
        // if (path.indexOf(item.nav) > -1) {
        //   that.activeIndex = item.id;
        // }
        if (hasValue(navIndex) && item.nav == navIndex) {
          that.activeIndex = item.id;
        }
      });
      $('#head-menu-item_' + that.activeIndex).addClass(
        'head-menu-item-active'
      );
    },
    closeLogin() {
      this.$ximu.logout();
      sessionStorage.clear();
    },
    closetip() {
      this.tipshow = false;
      this.$store.commit('setIndexShow', true);
    },
    // 获取店铺名称
    async getStoreName() {
      try {
        const res = await getApplyInfo({
          type: 'information',
          MUTE_WARNING: 1,
        });
        if (res?.errcode == 0) {
          this.nickName = res?.data?.shop_name;
          localStorage.setItem('account', this.nickName);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header-model {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  .tip-model {
    width: 100%;
    height: 50px;
    background: #ffffff;
    position: relative;
    .tip-content {
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      position: absolute;
      z-index: 20;
      width: 100%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      padding: 0px 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      & > :first-child {
        width: calc(100% - 10px);
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #d22316;
        display: flex;
        justify-content: center;
        & > :first-child {
          margin-right: 10px;
        }
      }
      & > :last-child {
        width: 10px;
      }

      .cancel-tip {
        transform: rotate(45deg);
        cursor: pointer;
      }
    }
  }
  .headNav {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    /* box-shadow: 0px 3px 10px rgb(0 0 0 / 10%); */
  }
}

.head-nav-box {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 1200px;
  background-color: #fff;
  .left,
  .right {
    display: flex;
    height: 100%;
  }
}
.head-supplier-name {
  color: var(--xm-color);
  display: flex;
  align-items: center;
  cursor: pointer;

  .logo {
    width: 40px;
  }

  .logo .iconfont {
    font-size: 22px;
  }

  .logofont {
    width: 50px;
  }

  .logofont .iconfont {
    // margin-left: 4px;
    font-size: 18px;
  }
}
.head-language-box {
  // width: 216px;
  width: fit-content;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.head-language-box span {
  padding: 0 8px 0 6px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: normal;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
.head-language-icon {
  width: 24px;
  height: 24px;
}
.head-language-select {
  width: 16px;
  height: 16px;
  vertical-align: bottom;
}
.head-menu-box {
  display: flex;
  height: 100%;
  align-items: center;
}
.head-menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 2px;
  color: #666;

  .head-menu-title {
    padding: 0 8px;
    font-size: 12px;
    font-weight: 400;
    // color: #666;
    cursor: pointer;
    position: relative;

    &::after {
      position: absolute;
      right: -3px;
      content: '|';
      font-size: 12px;
      color: #666;
    }
  }
  &:last-of-type {
    padding-right: 0px;
    // 去掉通知图标右边的竖线
    .head-menu-title::after {
      content: '';
    }
  }

  .head-menu-icon {
    display: flex;
    align-items: center;
    img {
      width: 21px;
      height: 21px;
    }
    .unread-msgs-item {
      position: relative;
      top: -10px;
      left: -3px;

      padding: 4px;
      width: 31px;
      height: 20px;
      text-align: center;

      background: #fc5959;
      border-radius: 16px;

      span {
        display: inline-block;
        height: 12px;
        line-height: 12px;
        vertical-align: top;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}

.head-menu-item-active span,
.head-menu-item:hover span {
  // font-weight: bold;
  color: var(--xm-color);
}
/* 右侧 */
.head-user-box {
  /* max-width: 450px; */
  /* margin-left: auto; */
  display: flex;
  align-items: center;
}
.head-user-item {
  // width: 48px;
  font-size: 12px;
  color: #666666;
  margin-right: 10px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
}
.head-menu-item-active {
  color: #ff7802 !important;
}
.head-user-item-icon {
  display: inline-block;
}
.head-user-active-icon {
  display: none;
}
.head-user-item:hover,
.head-user-name:hover {
  color: var(--xm-color);

  .head-user-active-icon {
    display: inline-block;
  }
  .head-user-item-icon {
    display: none;
  }
}

.head-user-name {
  margin-left: auto;
  align-items: center;
  color: #666666;
  cursor: pointer;
  line-height: 16px;

  &:hover .el-dropdown {
    color: var(--xm-color);
  }

  .el-dropdown {
    vertical-align: middle;
    .my-avatar {
      width: 24px;
      height: 24px;
    }

    .el-dropdown-selfdefine {
      align-items: center;
    }
  }

  span {
    padding-right: 4px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
  }

  img {
    width: 12px;
    height: 12px;
  }

  .dropdown-down-arrow-icon {
    width: 10px;
    height: 10px;
    margin-left: 2px;
  }
}
.active-style {
  color: var(--xm-color);
}
</style>
<style lang="scss">
.el-dropdown-menu.head-nav-notice-dropdown-menu {
  top: 42px !important;
}
.el-dropdown-menu.head-nav-user-dropdown-menu {
  width: 204px;
  padding-top: 0px;
  top: 43px !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  .dropdown-user-avatar-name {
    width: 100%;
    height: 44px;
    padding: 13px 9px 9px 10px;
    margin-bottom: 8px;

    background: #f7f7f7;
    display: flex;
    align-items: center;
    .dropdown-avatar {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    .dropdown-name {
      display: inline-block;
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      color: #333333;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>

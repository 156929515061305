/**
 * 供应商跳转登录失败的处理
 * @author hukeyi
 * @date 2023-12-11
 * @description 避免 '/supplier/getSupplierInfo' 出问题，故对重定向页面的错误 handler 进行特殊处理：
 * 1. 用户第一次登录供应商失败，提示“登录信息获取失败，请重新登录”，并将用户弹回登录界面
 * 2. 用户第二次登录供应商失败，提示“服务器异常，请稍后”，将用户弹回登录界面？
 */

const flagName = 'login_failed_count';
const limit = 1; // 第 limit+1 次登录失败，提示“服务器异常”

// 获取当前浏览器供应商登录失败次数
function getLoginFailedCount() {
  let count = Number(localStorage?.getItem(flagName) || 0);
  return isNaN(count) ? 0 : count;
}

// 弹回分销商统一登录界面
export function goLogin() {
  let url = process.env.VUE_APP_SHOP_HOST;
  if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:3000';
  }

  window.location.href = url + '/login';
}

// 是否弹出服务器异常提示
export function warnServerError() {
  return getLoginFailedCount() > limit;
}

// 登录后将 flag 置为 0
export function resetLoginFailedCount() {
  localStorage?.setItem(flagName, 0);
}

// 登录失败次数加一
export function addLoginFailedCount() {
  localStorage?.setItem(flagName, getLoginFailedCount() + 1);
}

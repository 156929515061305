<template>
  <div class="country-selector" :style="{ width: width }">
    <el-select
      clearable
      :value="completeAddress"
      :popper-append-to-body="false"
      popper-class="el-select-popper"
      @focus="showDropdown = true"
      ref="selector"
      :disabled="disabled"
      @clear="clearOptions"
      :placeholder="selectPlaceholder"
    >
      <template slot="empty">
        <el-tabs v-show="showDropdown" v-model="tabName" type="border-card">
          <el-tab-pane label="国家" name="country">
            <el-input
              placeholder="搜索国家"
              v-model="searchTarget"
              @input="searchCountry"
            >
              <img
                class="search-icon"
                width="16px"
                height="16px"
                slot="prefix"
                :src="searchIcon"
              />
            </el-input>
            <div class="country-list-wrapper">
              <div
                class="country-groups"
                v-for="group in country_list"
                :key="group.initial"
              >
                <div>
                  <span
                    :class="{
                      active:
                        initialActive.toUpperCase() ==
                        group.initial.toUpperCase(),
                    }"
                    >{{ group.initial }}</span
                  >
                  <hr class="country-group-divider" />
                </div>
                <ul>
                  <li
                    v-for="item in group.list"
                    :key="item.id"
                    :class="{
                      active: addressData.country_id == item.id,
                    }"
                    @click="selectOption(0, item)"
                  >
                    {{ item.cname }}
                  </li>
                </ul>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs></template
      ></el-select
    >
  </div>
</template>
<script>
export default {
  props: [
    'country_id',
    'country',
    'width',
    'countryType',
    'disabled',
    'selectPlaceholder',
  ],
  //   props: ['areaData'],

  data() {
    return {
      showDropdown: false,
      tabName: '',
      initialActive: '', // 搜索出来的符合条件的首字母
      searchTarget: '', // 用户搜索框的输入
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标

      country_list: [],
      /**
       * 需要传出的数据：
       */
      addressData: {
        country: '',
        code: '',
        country_id: '',
        countryObj: {},
      },
    };
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      const addr = `${this.addressData.country}`;
      return addr;
    },
  },
  methods: {
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 0：国家菜单；1：省份菜单；2：城市菜单
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      if (index == 0) {
        // 选择国家
        this.addressData.country = item.cname;
        this.addressData.country_id = item.id;
        this.addressData.countryObj = item;
        this.addressData.code = item.code;

        // 可以传出数据了
        this.$emit('update', this.addressData);
        this.showDropdown = false;
        this.$refs.selector.blur(); // 选择器的尖角符号向下
      }
    },
    // 搜索国家，高亮用户输入数据后的首字母
    searchCountry() {
      const api = 'Supplier.getCountries';
      const options = {
        is_initial_group: 1,
        name: this.searchTarget,
        type: this.countryType ? this.countryType : '',
      };
      console.log('search', options);
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            that.country_list = res.data;
            that.initialActive = res.data[0].initial;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    getCountryList() {
      const api = 'Supplier.getCountries';
      const options = {
        is_initial_group: 1,
        type: this.countryType ? this.countryType : '',
      };
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            that.country_list = res.data;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        country: '',
        code: '',
        country_id: '',
        countryObj: {},
      };
      this.initialActive = '';
      this.searchTarget = '';
      this.tabName = '';
      // this.country_list = [];
      this.changeTab('country');
      this.$emit('update', this.addressData);
    },
    clearOptions() {
      this.resetData();
    },
    // 父组件调用回显
    echoData(info) {
      this.$nextTick(() => {
        this.addressData.country = info.country || '';
        this.addressData.country_id = info.country_id || '';
      });
    },
  },
  mounted() {
    this.getCountryList();
    this.changeTab('country');
    this.addressData.country = this.country || '';
    this.addressData.country_id = this.country_id || '';
  },
  beforeDestroy() {
    this.resetData();
  },
};
</script>
<style lang="scss" scoped>
$tab-width: 100px;
$select-height: 40px;
$highlight-color: #ff7802;

.country-selector {
  position: relative;
  // width: $tab-width * 3;
  width: 200px;
  height: $select-height;

  * {
    border: 0px;
  }
  ul {
    padding-left: 1em; // 选项名称距离左边的距离
  }

  li {
    list-style-type: none;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  li.active,
  span.active {
    color: $highlight-color;
  }
  li:hover {
    color: $highlight-color;
  }
  ::v-deep .el-input.el-input--suffix {
    width: 100%;
  }

  // 解决focus输入框边框变蓝问题
  ::v-deep .el-select .el-input.is-focus .el-input__inner,
  ::v-deep .el-select .el-input__inner:focus {
    border-color: $highlight-color;
  }
  // 隐藏el原本的下拉菜单
  ::v-deep .el-select-dropdown {
    visibility: hidden;
  }
  ::v-deep .popper__arrow {
    visibility: hidden;
  }

  .no-search-input {
    max-height: 314px;
    overflow: auto;
  }
  ::v-deep .el-input {
    // width: 280px;
    height: 40px;
    margin-bottom: 10px; // 搜索框离下面的选项文字的距离
  }

  ::v-deep .el-input__prefix {
    display: inline-block;
  }

  ::v-deep .search-icon {
    display: inline-block;
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-tabs .el-input__inner {
    width: 280px;
    margin-left: -5px;
    border-radius: 0px 0px 0px 0px;
    background-color: #f7f7f7;

    font-size: 12px;
    color: #808080;
  }
  // 处理tabs位置问题
  ::v-deep .el-tabs {
    visibility: visible;
    position: absolute;
    top: -17px;
    left: -1px;
    z-index: 2; // 必须比原本的下拉菜单大

    width: $tab-width * 3;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    * {
      font-size: 14px;
      color: #666;
    }
  }
  // // 固定下拉菜单整体高度为 350px
  // ::v-deep .el-tabs .el-tabs--top .el-tabs--border-card {
  //   height: 350px;
  // }
  ::v-deep .el-tabs__header .is-top {
    background-color: #f2f2f2;
  }

  ::v-deep .el-tabs--border-card > .el-tabs__header {
    border: 0px;
  }
  ::v-deep .el-tabs--border-card > .el-tabs__content {
    padding-bottom: 10px;
  }
  // 解决高亮标签页两侧有1px缝隙
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-left-color: transparent;
    border-right-color: transparent;
  }
  // 调整标签页宽度，宽度相同且文本居中
  ::v-deep .el-tabs__item {
    width: $tab-width;
    text-align: center;
  }
  // 解决“城市”标签页头右侧有1px缝隙
  ::v-deep .el-tabs__item:nth-last-of-type(1) {
    width: $tab-width + 1;
  }
  // 隐藏标签页前后的滑动提示箭头
  ::v-deep .el-tabs__nav-prev,
  .el-tabs__nav-next {
    display: none;
  }
  ::v-deep
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item
    + .el-tabs__item {
    margin-left: 0px;
  }

  ::v-deep .el-tabs__content {
    padding-right: 2px; // 解决滚动条不贴右边问题（有 2px 缝隙）
    padding-top: 10px;
    // padding: 0px;
    height: 310px;
  }
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #808080;
  }
  // 解决点击和悬浮标签页，标签页文字蓝色高亮问题
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: #4d4d4d;
  }
  ::v-deep .el-tabs__item.is-top {
    background-color: #f2f2f2;
    color: #808080;
  }
  ::v-deep .el-tabs__item.is-active {
    background-color: #ffffff;
    color: #4d4d4d;
  }

  .country-list-wrapper {
    height: 240px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      background: none;
    }
  }
  .country-groups {
    & > span {
      color: #666;
      padding-right: 5px;
      margin-top: 5px;
    }
    & > span.active {
      color: $highlight-color;
    }
    // 国家按拼音分组，分组的水平分割线
    .country-group-divider {
      border: 1px solid #e6e6e6;
      display: inline-block;
      width: $tab-width * 3 - 50px;

      margin-bottom: 0.33em;
    }
  }
}
</style>

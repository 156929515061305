/**
 * 错误处理的公共函数
 * @author hukeyi
 * @date 2023-09-08
 * @description 主要包含接口错误处理函数。接口错误有以下两种：
 * - HTTP 响应返回异常状态码（4xx，5xx）
 * - HTTP 响应码正常 + 后端接口返回的状态码异常（非0）
 *   - 后端验证未通过
 *   - token 失效
 */

const httpErrMsg = {
  400: '错误请求',
  401: '未授权，请重新登录',
  403: '拒绝访问',
  404: '请求错误，未找到该资源',
  405: '请求方法未允许',
  408: '请求超时',
  500: '服务器繁忙，请稍后',
  501: '网络未实现',
  502: '网络错误',
  503: '服务不可用',
  504: '网络超时',
  505: 'HTTP版本不支持该请求',
};

/**
 * 获取HTTP响应状态码对应的错误提示文本
 * @param {*} status
 * @returns { String } 错误提示文本
 */
export function getErrMsgByHttpCode(status) {
  return httpErrMsg[status] || `连接错误，错误码 ${status}`;
}

const tokenErrMsg = {
  '-100': '登录状态失效，请重新登录',
  '-102': '登录状态失效，请重新登录',
  '-101': '账号在其他设备登录，请重新登录',
};

/**
 * 获取 token 失效对应的错误提示文本
 * @param {*} status
 * @returns { String } 错误提示文本
 */
export function getErrMsgByApiCode(status) {
  return tokenErrMsg['' + status] || '';
}

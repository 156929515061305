/**
 * 管理模块
 * - 投诉&举报
 * - 违规
 */

const managementRoutes = [
  {
    path: '/complaint/list',
    name: 'Complaint',
    meta: {
      title: '投诉总览',
      navIndex: 'complaint',
      path: '/complaint/list',
      needsToken: true,
    },
    component: () => import('@/pages/management/complaint/OverviewList'),
  },
  {
    path: '/complaint/detail/:id',
    name: 'ReportDetail',
    meta: {
      title: '投诉&举报详情',
      navIndex: 'complaint',
      path: '/complaint/detail/:id',
      needsToken: true,
    },
    component: () =>
      import('@/pages/management/complaint/overview-detail/ReportDetail.vue'),
  },
  {
    path: '/complaint/review/detail/:id',
    name: 'ReviewReportDetail',
    meta: {
      title: '评价举报详情',
      navIndex: 'complaint',
      path: '/complaint/review/detail/:id',
      needsToken: true,
    },
    component: () =>
      import(
        '@/pages/management/complaint/overview-detail/ReviewReportDetail.vue'
      ),
  },
  {
    path: '/complaint/ipp/detail/:id',
    name: 'ComplaintIppDetail',
    meta: {
      title: '举报知识产权详情',
      navIndex: 'complaint',
      path: '/complaint/list',
      needsToken: true,
    },
    component: () =>
      import('@/pages/management/complaint/overview-detail/IppDetail.vue'),
  },
  {
    path: '/ipp/detail/:id',
    name: 'IppDetail',
    meta: {
      title: '知识产权详情',
      navIndex: 'complaint',
      path: '/complaint/list',
      needsToken: true,
    },
    component: () => import('@/pages/management/complaint/IppDetail.vue'),
  },
  {
    path: '/violations/list',
    name: 'Violations',
    meta: {
      title: '违规列表',
      navIndex: 'complaint',
      path: '/violations/list',
      needsToken: true,
    },
    component: () => import('@/pages/management/violations/index'),
  },
  {
    path: '/violations/detail/:id',
    name: 'ViolationsDetail',
    meta: {
      title: '违规详情',
      navIndex: 'complaint',
      path: '/violations/detail/:id',
      needsToken: true,
    },
    component: () => import('@/pages/management/violations/ViolationsDetail'),
  },
];

export default managementRoutes;

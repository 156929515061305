<template>
  <div class="statistical-time">
    <div class="statistical-time-title">
      <div class="title-top">
        <span class="time-title-font">订单数据</span>
        <!-- <span class="time-title-num">{{ currTime }}</span> -->
      </div>
      <el-date-picker
        clearable
        class="select-time"
        v-model="search.timer"
        type="daterange"
        :unlink-panels="false"
        @change="choseDate"
        :picker-options="pickerOptions"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        :start-placeholder="startDate"
        range-separator="至"
        :end-placeholder="endDate"
      ></el-date-picker>
    </div>
    <div class="statistical-time-box">
      <!-- 退货率 -->
      <div
        class="box-list"
        @click="search.type = 1"
        :class="[{ 'box-list-active': search.type == 1 }]"
      >
        <div class="box-list-title">
          <span>退货率</span>
        </div>
        <div class="box-list-num">{{ modelInfo.returnRate }}%</div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.returnGood == true }]"
            >{{ modelInfo.returnRateRadio }}%</span
          >
        </div>
      </div>
      <!-- 物流异常率 -->
      <div
        class="box-list"
        @click="search.type = 2"
        :class="[{ 'box-list-active': search.type == 2 }]"
      >
        <div class="box-list-title">
          <span>物流异常率</span>
        </div>
        <div class="box-list-num">{{ modelInfo.anomalyRate }}%</div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.anomalyStream == true }]"
            >{{ modelInfo.anomalyRateRadio }}%</span
          >
        </div>
      </div>
      <!-- 订单投诉率 -->
      <div
        class="box-list"
        @click="search.type = 3"
        :class="[{ 'box-list-active': search.type == 3 }]"
      >
        <div class="box-list-title">
          <span>订单投诉率</span>
        </div>
        <div class="box-list-num">{{ modelInfo.complaint }}%</div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.orderComplaint == true }]"
            >{{ modelInfo.complaintRadio }}%</span
          >
        </div>
      </div>
      <!-- 订单评价率 -->
      <div
        class="box-list"
        @click="search.type = 4"
        :class="[{ 'box-list-active': search.type == 4 }]"
      >
        <div class="box-list-title">
          <span>订单评价率</span>
        </div>
        <div class="box-list-num">{{ modelInfo.eva }}%</div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.goodeValuate == true }]"
            >{{ modelInfo.evaRadio }}%</span
          >
        </div>
      </div>
      <!-- 订单纠纷率 -->
      <!-- <div
        class="box-list"
        @click="search.type = 5"
        :class="[{ 'box-list-active': search.type == 5 }]"
      >
        <div class="box-list-title">
          <span>订单纠纷率</span>
        </div>
        <div class="box-list-num">{{ modelInfo.dispute }}%</div>
        <div class="box-list-week">
          <span>环比</span>
          <span :class="[{ increase: symbolsNum.orderIssue == true }]"
            >{{ modelInfo.disputeRadio }}%</span
          >
        </div>
      </div> -->
    </div>
    <div class="statistical-time-echarts-box">
      <div
        ref="statistEcharts"
        class="echartsLine"
        :style="{ height: echartsHeight }"
      ></div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
// import * as echarts from 'echarts';
import { get, defaultTo } from 'lodash';
import ReturnRateController from './controller/ReturnRateController';

export default {
  name: 'ReturnRate',
  props: {
    // 订单数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // currTime: '',
      search: {
        type: 1,
        timer: [],
      },
      pickerOptTimer: [],
      symbolsNum: {
        returnGood: false,
        anomalyStream: false,
        orderComplaint: false,
        goodeValuate: false,
        orderIssue: false,
      },
      modelInfo: {},
      chartData: [],
      startDate: '',
      endDate: '',
      echartsHeight: '221px',
    };
  },
  computed: {
    pickerOptions() {
      return {
        onPick: ({ maxDate, minDate }) => {
          if (maxDate != null && minDate != null) {
            this.pickerOptTimer = [];
          }
          this.pickerOptTimer[0] = minDate.getTime();
        },
        disabledDate: time => {
          if (this.pickerOptTimer && this.pickerOptTimer?.length != 0) {
            const pickTime = 30 * 24 * 3600 * 1000;
            const afterTime = this.pickerOptTimer[0] + pickTime;
            const beforeTime = this.pickerOptTimer[0] - pickTime;
            const nowTmie = new Date() - 24 * 3600 * 1000;
            return (
              time.getTime() > afterTime ||
              time.getTime() < beforeTime ||
              time.getTime() > nowTmie
            );
          } else {
            const nowTmie = new Date() - 24 * 3600 * 1000;
            return time.getTime() > nowTmie;
          }
        },
      };
    },
  },
  mounted() {},
  watch: {
    'search.type'() {
      this.choseDataBox();
    },
    detail: {
      immediate: true,
      deep: true,
      handler(val) {
        let modelInfo = {};
        let chartData = [];
        for (let key in val) {
          if (key == 'list') {
            chartData = defaultTo(val[key], []);
          } else {
            modelInfo[key] = defaultTo(val[key], 0);
          }
        }

        this.modelInfo = modelInfo;
        this.chartData = chartData;
        this.startDate = this.chartData[0]?.times;
        this.endDate = this.chartData?.slice(-1)[0]?.times;
        if (this.startDate && this.endDate) {
          this.search.timer = [this.startDate, this.endDate];
        }
        // this.currTime = dayjs().format('YYYY-MM-DD');

        // 退货率
        if (this.modelInfo.returnRateRadio?.slice(0, 1) != '-') {
          this.modelInfo.returnRateRadio = '+' + this.modelInfo.returnRateRadio;
          this.symbolsNum.returnGood = true;
        } else {
          this.symbolsNum.returnGood = false;
        }

        // 物流异常率
        if (this.modelInfo.anomalyRateRadio?.slice(0, 1) != '-') {
          this.modelInfo.anomalyRateRadio =
            '+' + this.modelInfo.anomalyRateRadio;
          this.symbolsNum.anomalyStream = true;
        } else {
          this.symbolsNum.anomalyStream = false;
        }

        // 订单投诉率
        if (this.modelInfo.complaintRadio?.slice(0, 1) != '-') {
          this.modelInfo.complaintRadio = '+' + this.modelInfo.complaintRadio;
          this.symbolsNum.orderComplaint = true;
        } else {
          this.symbolsNum.orderComplaint = false;
        }

        // 订单评价率
        if (this.modelInfo.evaRadio?.slice(0, 1) != '-') {
          this.modelInfo.evaRadio = '+' + this.modelInfo.evaRadio;
          this.symbolsNum.goodeValuate = true;
        } else {
          this.symbolsNum.goodeValuate = false;
        }

        // if (this.modelInfo.disputeRadio?.slice(0, 1) != '-') {
        //   this.modelInfo.disputeRadio = '+' + this.modelInfo.disputeRadio;
        //   this.symbolsNum.orderIssue = true;
        // } else {
        //   this.symbolsNum.orderIssue = false;
        // }

        if (chartData && chartData.length != 0) {
          this.initEcharts(chartData);
        }
      },
    },
  },
  methods: {
    // resetSearch() {
    //   this.search.timer = [];
    // },
    initEcharts(data) {
      // 避免报错：There is a chart instance already initialized on the dom
      const el = this.$refs.statistEcharts;
      let chart = echarts.getInstanceByDom(el);
      if (chart == null) {
        chart = echarts.init(el);
      }

      const labels = data.map(item => item.times);
      let margin = '';
      let padding = [];
      let bottom = '';
      // 是否同年
      if (labels[0]?.substring(0, 4) == labels?.slice(-1)[0]?.substring(0, 4)) {
        for (let i = 0; i < labels.length; i++) {
          labels[i] = labels[i]?.substring(5, 10);
        }
        this.echartsHeight = '221px';
        chart.resize({ height: this.echartsHeight });
        margin = '20';
        bottom = '30';
        ReturnRateController.setXAxisMargin(margin);
        ReturnRateController.setGridBottom(bottom);
      } else {
        for (let i = 0; i < labels.length; i++) {
          labels[i] = labels[i].replace('-', ' - ');
        }
        this.echartsHeight = '233px';
        chart.resize({ height: this.echartsHeight });
        margin = '10';
        bottom = '44';
        ReturnRateController.setXAxisMargin(margin);
        ReturnRateController.setGridBottom(bottom);
      }

      const value = data.map(item => item.nums);

      switch (this.search.type) {
        case 1:
          padding = [0, 0, 5, -58];
          ReturnRateController.setYAxisPadding(padding);
          ReturnRateController.setYAxisName('退货率(%)');
          break;
        case 2:
          padding = [0, 0, 5, -30];
          ReturnRateController.setYAxisPadding(padding);
          ReturnRateController.setYAxisName('物流异常率(%)');
          break;
        case 3:
          padding = [0, 0, 5, -30];
          ReturnRateController.setYAxisPadding(padding);
          ReturnRateController.setYAxisName('订单投诉率(%)');
          break;
        case 4:
          padding = [0, 0, 5, -30];
          ReturnRateController.setYAxisPadding(padding);
          ReturnRateController.setYAxisName('订单评价率(%)');
          break;
        // case 5:
        //   padding = [0, 0, 5, -30];
        //   ReturnRateController.setYAxisPadding(padding);
        //   ReturnRateController.setYAxisName('订单纠纷率(%)');
        default:
          break;
      }

      ReturnRateController.setLabel(labels);
      ReturnRateController.setData(value);

      //计算ehcarts y轴最大值 和 步长
      let maxVal = 0; //最大值
      let intVal = 0; //步长
      // 1. 遍历 dataList（图表需要展示的数据） 获取最大值
      value.forEach(res => {
        let number = parseFloat(res);
        if (number > maxVal) {
          maxVal = number;
        }
      });
      // 2. 最大值 向上取整
      maxVal = Math.ceil(maxVal);
      // 3. 最大值 能整除4，防止y轴步长出现小数
      for (let i = 0; i < 4; i++) {
        if (maxVal % 4 != 0) {
          maxVal++;
        }
      }
      // 4. 计算y轴步长
      intVal = maxVal / 4;
      if (maxVal == 0) {
        maxVal = 1;
        intVal = 1;
      }
      ReturnRateController.setYAxisMax(maxVal);
      ReturnRateController.setYAxisInterval(intVal);

      const options = ReturnRateController.getOptions();
      chart.setOption(options);
    },
    choseDate() {
      this.pickerOptTimer = [];
      this.$emit('chose-date', this.search.timer);
    },
    // 选择数据盒子
    choseDataBox() {
      this.$emit('chose-type', this.search.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.statistical-time {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;

  .statistical-time-title {
    margin-bottom: 10px;

    .title-top {
      margin-bottom: 10px;
      height: 21px;

      .time-title-font {
        color: #111;
        font-size: 16px;
        line-height: 19px;
      }

      .time-title-num {
        margin-left: 10px;
        color: #999;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .el-date-editor--daterange.el-input__inner {
      width: 300px;
      height: 40px;
      border-radius: 0;
    }

    ::v-deep .el-range-editor.el-input__inner {
      padding: 4px 12px !important;
    }

    ::v-deep .el-input__inner {
      border: 1px solid #ccc !important;
    }

    ::v-deep .el-date-editor .el-range-input {
      // width: 120px !important;
      font-size: 14px;
      color: #808080 !important;
    }

    ::v-deep .el-date-editor .el-range-separator {
      padding: 0 6px !important;
      width: 126px !important;
      line-height: 32px;
      font-size: 12px !important;
      width: 24px !important;
      color: #4d4d4d !important;
    }
  }

  .statistical-time-box {
    display: flex;
    margin-bottom: 20px;
    gap: 20px;

    .box-list {
      padding: 20px;
      width: 275px;
      height: 115px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      cursor: pointer;

      .box-list-title {
        span {
          display: block;
          height: 18px;
          color: #4d4d4d;
          font-size: 14px;
          line-height: 16px;

          i {
            height: 16px;
            line-height: 14px;
            color: #3d8df5;
            font-size: 16px;
            vertical-align: bottom;
            cursor: pointer;
          }
        }
      }

      .box-list-num {
        margin: 10px 0;
        height: 21px;
        line-height: 19px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
      }

      .box-list-week {
        display: flex;

        span:first-child {
          display: block;
          height: 16px;
          line-height: 14px;
          color: #808080;
          font-size: 12px;
        }

        span:last-child {
          display: block;
          margin-left: 4px;
          height: 16px;
          line-height: 14px;
          color: #07c160;
          font-size: 12px;
        }

        .increase {
          color: #d22316 !important;
        }
      }
    }

    .box-list-active {
      border: 2px solid #ff7802;
    }
  }

  // 折线图悬浮样式
  .echartsLine {
    ::v-deep .tooltip {
      color: #808080 !important;

      .hoverDate {
        height: 16px;
        line-height: 16px;
        font-size: 12px;
      }
      .detailNum {
        display: flex;
        align-items: center;
        margin-top: 10px;
        height: 16px;
        line-height: 16px;
        font-size: 12px;

        span {
          margin-left: 10px;
        }
      }

      .detailNum::before {
        content: '';
        display: block;
        margin-right: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #ff7802;
      }
    }
  }
}
</style>

const state = {
  selectedCateId: [], // 发布商品所选类目id
  languageType: '2', // 发布商品语言类目
  /**
   * 商品评价
   */
  evaluationTotalCount: 0, // 评价总数
  wareType: [], // 仓库库类型

  /**
   * 商品预览主图缓存列表
   */
  goodMainImgMap: {}, // 键值对 (itemId, imgUrl)
  isCosmetics: '', // 是否是化妆品类
  special: '', //是否属于特殊化妆品
  isMustCertification: '', // ccc认证是否必填
};

const getters = {
  /**
   * 商品预览主图
   */

  /**
   * 通过商品id获取商品的主图地址
   * @param String itemId 商品的id
   * @returns 商品主图地址，拼接了 static.ximu.cn 的地址
   */
  getGoodMainImgByItemId: state => itemId => {
    if (!itemId) return '';
    let hasCached = !!state.goodMainImgMap[itemId];
    return hasCached ? state.goodMainImgMap[itemId] : '';
  },
  /**
   * 当前商品是否已缓存商品主图
   * @returns { Boolean } 已缓存 true；无缓存 false
   */
  hasGoodMainImg: state => itemId => {
    return itemId && !!state.goodMainImgMap[itemId];
  },
};

const mutations = {
  setSelectedCateId(state, data) {
    state.selectedCateId = data;
  },
  setLanguageType(state, type) {
    state.languageType = type;
  },
  /*
    是否是特殊类目化妆品
  */
  setCosmetics(state, data) {
    state.isCosmetics = data;
  },
  /*
    ccc认证是否必填
  */
  setCertification(state, data) {
    state.isMustCertification = data;
  },
  /*
   * 是否属于特殊化妆品
   */
  setSpecial(state, data) {
    state.special = data;
  },
  /**
   * 商品评价
   */

  /**
   * 设置商品评价总数
   * @param {*} state
   * @param {*} payload { total: x }
   */

  SET_EVAL_TOTAL_COUNT(state, payload) {
    state.evaluationTotalCount = payload.total;
  },
  SET_NEWGOOD_WARE: (state, data) => {
    state.wareType = data;
  },

  /**
   * 商品预览
   * @param Object payload { itemId: 'xxx', imgUrl: '拼接好的完整url' }
   */
  SET_GOOD_MAIN_IMG(state, payload) {
    if (!payload || !payload.itemId || !payload.imgUrl) {
      return;
    }
    state.goodMainImgMap[payload.itemId] = payload.imgUrl;
  },
};

const actions = {
  /**
   * 商品评价
   */
  /**
   * 设置商品评价总数
   * @param {*} state
   * @param {*} payload { total: x }
   */
  setEvaluationTotalCount({ commit }, payload) {
    commit('SET_EVAL_TOTAL_COUNT', payload);
  },
  setWareArr(context, wareArr) {
    context.commit('SET_NEWGOOD_WARE', wareArr);
  },

  /**
   * 商品预览
   */
  /**
   * 设置商品预览的主图
   * @param { Object } payload { itemId: 'xxx', imgUrl: '拼接好的完整url' }
   */
  setGoodMainImg({ commit }, payload) {
    commit('SET_GOOD_MAIN_IMG', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

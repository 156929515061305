import Vue from 'vue';
import App from './App';
import router from './router/index';
import store from './store/index';

import './assets/styles/global_var.scss';
import './assets/styles/global.scss';
import '../static/common/css/common.css';
import '../static/fonts/iconfont.css';

// import Video from 'video.js';
// import 'video.js/dist/video-js.css';
// Vue.prototype.$video = Video;

import print from 'print-js';
import Print from '../static/js/print'; // 打印
Vue.use(Print);

import VueCropper from 'vue-cropper';
Vue.use(VueCropper);

import horizontalScroll from 'el-table-horizontal-scroll';
Vue.use(horizontalScroll);

// NIM 云信实例对象
Vue.prototype.$nim = null; // 初始化为 null，用户登录后再初始化

// 监听用户动态操作的 Web Worker
Vue.prototype.$dynamicDetector = null;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss';
import './styles/element-ui.scss';
import './styles/el-table-sticky.scss'; // el-table表头吸顶以及表格横向滚动条样式
// 表格吸顶效果以及表格滚动条
import elTableSticky from './utils/el_table_sticky';
Vue.use(ElementUI);

import { Plugin } from './utils/directive';
Vue.use(Plugin);
Vue.use(elTableSticky);

import Mixins from './mixin/mixin';
Vue.mixin(Mixins);

// 注册自定义指令
import '@/utils/custom_directives';

// 混入全局组件
import { initGlobalComponents } from './components/install';
initGlobalComponents();

// 兼容旧 supplier 的工具函数挂载到 window 上
import { installOldTool } from './utils/old_supplier';
installOldTool();

// 通用的项目业务工具函数
import * as XmTool from '@/utils/service';
Vue.prototype.$ximu = XmTool;

// 通用的 js 工具函数
import * as ToolBox from '@/utils/xm-jstools/index';
Vue.prototype.$toolbox = ToolBox;

// 增加权限控制器
import perCtrl from './utils/perCtrl';
window.perCtrl = perCtrl;
Vue.prototype.perCtrl = perCtrl;
// 重新定义message
import { Message } from 'element-ui';
let messageInstance = null;
const resetMessage = options => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});
Vue.prototype.$message = resetMessage;

new Vue({
  router,
  store,
  render: h => h(App),
  components: { App },
}).$mount('#app');

<template>
  <div class="back-to-top" @click="totop" v-if="btnFlag">
    <div class="all">
      <div class="iconfont">&#xe614;</div>
      <div class="backtopfont">顶部</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'XmBackToTop',
  data() {
    return {
      scrollTop: 0,
      btnFlag: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
  methods: {
    // 返回顶部
    totop() {
      let timer = setInterval(() => {
        let ispeed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        if (this.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 900) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.back-to-top {
  position: fixed;
  right: 60px;
  bottom: 350px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #ffffff;
  color: #808080;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #ff7802;
  }

  .all {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .iconfont {
      font-size: 24px;
    }

    .backtopfont {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>

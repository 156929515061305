<template>
  <div class="xm-show-file-box" :class="{ 'show-file-no-title': !showTitle }">
    <div v-if="showFileBox" class="file-box">
      <div
        class="success-list flex"
        v-for="(item, index) in fileList"
        :key="index"
      >
        <div class="left-side flex" @click="showPreviewimgFunc(item.link)">
          <!-- 悬浮小图放大 -->
          <XmHoverPreviewImage
            v-if="item.type == 'image' && needHoverZoom"
            :width="width"
            :height="height"
            :imgSrc="item.preview"
          ></XmHoverPreviewImage>
          <!-- 纯图片展示 -->
          <img
            v-if="item.type == 'image' && !needHoverZoom"
            :style="{ width: width, height: height }"
            :src="item.preview | imgbaseurl"
            @error="handleImgError"
            alt=""
            class="pointer img-show"
          />
          <!-- 视频 -->
          <video
            :style="{ width: width, height: height }"
            v-if="item.type == 'video'"
            :src="item.preview"
          ></video>
          <img
            class="video-icon"
            v-if="item.type == 'video' && needVideoIcon"
            src="@/assets/images/icon_play.svg"
          />
          <!-- excel/pdf/word/zip/ppt -->
          <img
            :style="{ width: width, height: height }"
            v-if="item.type != 'image' && item.type != 'video'"
            :src="item.type | uploadFileType"
            @error="handleImgError"
            alt=""
          />
          <div
            v-if="showTitle"
            class="file-name overflow-line"
            :title="item.name"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="right-side">
          <div
            v-if="showDownloaded"
            class="down-btn"
            @click.stop="handleDown(item)"
          >
            下载
          </div>
        </div>
      </div>
    </div>
    <!-- 文件列表为空，显示“--” -->
    <div v-else>--</div>

    <!-- 预览图片 -->
    <previewimg
      :showurl="previewImgUrl"
      :isRote="isPicRote"
      @closepreimg="previewCloseFunc"
      v-if="showPreviewimg"
    ></previewimg>
    <!-- 预览文件 -->
    <previewdoc
      :showurl="previewImgUrl"
      @closepredox="previewCloseFunc"
      v-if="showPreviewdoc"
    ></previewdoc>
  </div>
</template>
<script>
export default {
  name: 'XmShowFileBox',
  props: {
    /**
     * 需要展示的文件
     * 字符串
     * 逗号分隔
     * 要拼好路径的
     */
    showFile: {
      type: String,
      default: '',
    },
    // 是否需要展示文件名
    showTitle: {
      type: Boolean,
      default: true,
    },
    width: {
      // 小图的宽度
      type: String,
      default: '22px',
    },
    height: {
      // 小图的高度
      type: String,
      default: '22px',
    },
    // 是否需要下载按钮
    showDownloaded: {
      type: Boolean,
      default: false,
    },
    // 预览图片是否需要旋转
    isPicRote: {
      type: Boolean,
      default: false,
    },
    // 是否需要悬浮小图放大
    needHoverZoom: {
      type: Boolean,
      default: true,
    },
    // 是否需要视频上的播放icon
    needVideoIcon: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // 父组件传过来要展示的文件们
    showFile: {
      immediate: true,
      handler(val) {
        if (val && val !== '') {
          this.handleFile(val);
        }
      },
    },
  },
  data() {
    return {
      fileList: [], // 传递过来的文件数组

      // 预览相关
      showPreviewimg: false, // 预览图片弹窗标识
      previewImgUrl: '', // 预览图片

      showPreviewdoc: false, // 预览文档弹窗标识
    };
  },
  computed: {
    // 当前是否有文件列表，否则展示 --
    showFileBox() {
      return this.fileList?.length > 0;
    },
  },
  filters: {
    uploadFileType(val) {
      if (val == 'excel') {
        return require('@/assets/material/excel.svg');
      }
      if (val == 'pdf') {
        return require('@/assets/material/pdf.svg');
      }
      if (val == 'word') {
        return require('@/assets/material/word.svg');
      }
      if (val == 'zip') {
        return require('@/assets/material/zip.svg');
      }
      if (val == 'ppt') {
        return require('@/assets/material/ppt.svg');
      }
    },
  },
  mounted() {},
  methods: {
    //预览图片回调
    showPreviewimgFunc(key) {
      let fileType = key.split('.').pop();
      let isImage = this.matchType(fileType).toLowerCase() == 'image';
      let isVideo = this.matchType(fileType).toLowerCase() == 'video';
      if (isImage || isVideo) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
      this.previewImgUrl = this.$options.filters.imgbaseurl(key);
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    // 处理传递的文件们
    handleFile(val) {
      // console.log('【父组件传过来要展示的文件们】', val);
      let arr = val.split(',').map(item => {
        let fileName = item.trim();

        // 特殊处理未拼接前缀的文件
        if (!fileName.startsWith('http')) {
          fileName = process.env.VUE_APP_SUPPLIER_STATIC + fileName;
        }
        const type = this.matchType(fileName.split('.').pop());
        const preview = fileName;
        const name = fileName.split('/').pop();
        const link = fileName.split('/').pop();

        return {
          type: type,
          preview: preview,
          name: name,
          link: link,
        };
      });
      this.fileList = arr;
    },
    handleDown(item) {
      this.down(item.preview, item.name);
    },
  },
};
</script>
<style lang="scss" scoped>
.show-file-no-title .file-box {
  display: flex;
  .success-list {
    margin-top: 0px !important;
    margin-right: 10px;
  }
}
.xm-show-file-box {
  padding: 9px 0;

  .flex {
    display: flex;
  }

  .success-list {
    margin-top: 10px;

    .left-side {
      position: relative;
      cursor: pointer;
      img {
        // width: 22px;
        // height: 22px;
        object-fit: contain;
      }
      video {
        // width: 22px;
        // height: 22px;
        object-fit: contain;
      }
      .video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
      }
    }
    .file-name {
      margin-left: 10px;
      width: fit-content;
      color: #4d4d4d;
      font-size: 14px;
      letter-spacing: -0.01px;
      &:hover {
        color: #ff7802;
      }
    }
    .down-btn {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ff7802;
      margin-left: 10px;
      cursor: pointer;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  ::v-deep img {
    object-fit: contain !important;
  }
}
</style>

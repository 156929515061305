import { render, staticRenderFns } from "./UnImage.vue?vue&type=template&id=c85e2586&scoped=true"
import script from "./UnImage.vue?vue&type=script&lang=js"
export * from "./UnImage.vue?vue&type=script&lang=js"
import style0 from "./UnImage.vue?vue&type=style&index=0&id=c85e2586&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c85e2586",
  null
  
)

export default component.exports
<template>
    <div class="currentLocation">您当前所在位置 > {{title}}</div>
</template>

<script>
export default {
    name:'currentLocation',
    props:{
        title:{
            type:String,
            default:function(){
                return '商品管理 > 全部商品 '
            }
        }
    },
    data(){
        return {
            
        }
    }
}
</script>

<style scoped>
.currentLocation{
    width: 100%;
    height: 36px;
    border-bottom: 1px solid #EEEEEE;
    line-height: 36px;
    padding-left: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #fff;
}
</style>
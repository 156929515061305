<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>消息通知</div>
        <!-- <span>查看全部</span> -->
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>分销商消息</div>
          <span :class="[{ 'have-num': detail.distributor != 0 }]">{{
            detail.distributor
          }}</span>
        </div>
        <div class="label-list">
          <div>店铺经营</div>
          <span :class="[{ 'have-num': detail.shop != 0 }]">{{
            detail.shop
          }}</span>
        </div>
        <div class="label-list">
          <div>平台公告</div>
          <span :class="[{ 'have-num': detail.platform != 0 }]">{{
            detail.platform
          }}</span>
        </div>
        <!-- <div class="label-list">
          <div>重要通知</div>
          <span :class="[{ 'have-num': detail.important != 0 }]">{{
            detail.important
          }}</span>
        </div>
        <div class="label-list">
          <div>仲裁通知</div>
          <span :class="[{ 'have-num': detail.arbitration != 0 }]">{{
            detail.arbitration
          }}</span>
        </div> -->
        <div class="label-list">
          <div>系统消息</div>
          <span :class="[{ 'have-num': detail.system != 0 }]">{{
            detail.system
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultTo } from 'lodash';
export default {
  name: 'MessageCenter',
  props: {
    // 消息通知数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // detail: {},
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

// 工具函数将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
import dayjs from 'dayjs';

export function showCurrentTime() {
  // yyyy年mm月dd日 hh:mm:ss
  let curr = new Date();
  let time = {
    y: curr.getFullYear(),
    m: curr.getMonth() + 1,
    d: curr.getDate(),
    hour: curr.getHours(),
    minute: curr.getMinutes(),
    second: curr.getSeconds(),
  };
  if (time.hour.toString().length < 2) time.hour = '0' + time.hour;
  if (time.minute.toString().length < 2) time.minute = '0' + time.minute;
  if (time.second.toString().length < 2) time.second = '0' + time.second;
  const timestamp = `${time.y}年${time.m}月${time.d}日 ${time.hour}:${time.minute}:${time.second}`;
  return timestamp;
}

/**
 * 判断传入时间是否为现在的 x 天前
 * @param {*} x
 * @param {*} time 时间戳
 * @returns 是否为从当前时间算起的 x 天前
 */
function isDayXBefore(x, time) {
  // 今天为 0 天前；昨天为 1 天前；前天为 2 天前
  const targetDate = dayjs().subtract(x, 'day').format('YYYY-MM-DD');
  return targetDate === dayjs(time).format('YYYY-MM-DD');
}

export function formatChatSessionTime(time) {
  let isToday = isDayXBefore(0, time),
    isYesterday = isDayXBefore(1, time),
    isDayBeforeYesterday = isDayXBefore(2, time),
    isThisYear = dayjs().isSame(time, 'year');
  if (isToday) {
    return dayjs(time).format('HH:mm');
  } else if (isYesterday) {
    return '昨天';
  } else if (isDayBeforeYesterday) {
    return '前天';
  } else if (isThisYear) {
    return dayjs(time).format('MM/DD');
  } else {
    return dayjs(time).format('YYYY/MM/DD');
  }
}

// 传入 10位 unix时间戳字符串，转换为 YYYY-MM-DD HH:mm
export function formatEvaluationTime(time) {
  return dayjs.unix(time).format('YYYY-MM-DD HH:mm');
}

/**
 * 聊天窗口的时间戳自定义消息
 * @param {*} dateTimeStamp
 * @returns
 */
export function caculateChatTimeAgo(time) {
  time = Number(time);
  const now = new Date().getTime(); // 获取当前时间毫秒
  const diffValue = now - time; // 时间差

  if (diffValue < 0) {
    return '刚刚';
  }
  const diffMinutes = Math.floor(diffValue / (1000 * 60)); // 计算时间差的分
  let isToday = isDayXBefore(0, time),
    isYesterday = isDayXBefore(1, time),
    isDayBeforeYesterday = isDayXBefore(2, time),
    isThisYear = dayjs().isSame(time, 'year');

  let thenTime = dayjs(time).format('HH:mm');
  if (isToday) {
    if (diffMinutes == 0) {
      return '刚刚';
    } else if (diffMinutes >= 1 && diffMinutes <= 59) {
      return `${diffMinutes}分钟前`;
    } else {
      return `${thenTime}`;
    }
  } else if (isYesterday) {
    return `昨天 ${thenTime}`;
  } else if (isDayBeforeYesterday) {
    return `前天 ${thenTime}`;
  } else if (isThisYear) {
    return dayjs(time).format('MM/DD HH:mm');
  } else {
    return dayjs(time).format('YYYY/MM/DD HH:mm');
  }
}

<template>
  <div class="img-uploader">
    <el-upload
      class="uploader-box"
      :action="'/supplierapi/' + url"
      :data="{ token }"
      :show-file-list="false"
      :on-success="handleImgUploadSuccess"
      :before-upload="beforeImgUpload"
      :on-preview="handleImgDelete"
      :on-progress="handleImgUploading"
      :on-error="handleImgUploadError"
      :accept="fileType"
    >
      <!-- 图片预览 -->
      <div v-if="imageUrl.length > 0" class="uploader-preview">
        <img
          v-if="imageUrl.length > 0"
          :src="imageUrl | imgbaseurl"
          class="uploader-img uploader-status"
          @error="handleImgError"
        />
        <div class="uploader-img-mask">
          <img
            class="uploader-delete-icon"
            src="@/assets/images/resetImg.svg"
            @error="handleImgError"
          />
        </div>
      </div>

      <!-- 用户未选择图片的状态 -->
      <div
        v-else-if="uploadStatus == 0"
        class="uploader-status uploader-no-img"
      >
        <span id="uploader-no-img-plus">+</span>
        <p style="bottom: 18px">{{ label }}</p>
      </div>
      <!-- 图片上传中 -->
      <div v-else-if="uploadStatus == 1" class="uploader-status uploading">
        <span>上传中</span>
      </div>
      <!-- 图片上传失败 -->
      <div v-else-if="uploadStatus == 2" class="uploader-status uploader-fail">
        <span>上传失败</span>
        <span>取消</span>
      </div>
    </el-upload>
    <p class="uploader-tip" v-if="isShowTip">
      <i class="el-icon-warning uploader-tip-icon"></i>
      <span>{{ tipContent }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    tipContent: {
      type: String,
      default: '图片大小不大于5M，支持图片格式JPEG/JPG/PNG',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    label: {
      // 未选择图片时，上传器上的标签文本
      type: String,
      default: '上传图片',
    },
    fileType: {
      type: String,
      default: '.png,.jpeg,.jpg',
    },
    singleMaxSize: {
      //单次最大上传大小
      type: Number,
      default: 5,
    },
    //是否需要展示提示信息,默认展示
    isShowTip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      /**
       * 0 -> 未上传
       * 1 -> 上传中
       * 2 -> 上传失败
       * 3 -> 上传成功
       */
      uploadStatus: 0,
      url: 'Material/addMaterial',
      token: localStorage.getItem('token'),
    };
  },
  methods: {
    //上传前效验图片格式
    beforeImgUpload(file) {
      let MB = file.size / 1024 / 1024;
      if (MB > this.singleMaxSize) {
        this.$message({
          type: 'warning',
          message: '已超出单个上传最大限制,单个最大限制5MB!',
        });
      }
      if (MB > 5) return false;
      this.uploadStatus = 1;
    },
    handleImgUploadSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      if (res.errcode == 0) {
        this.imageUrl = res.data.link;
        this.$emit('update', this.imageUrl);
      } else {
        this.handleImgUploadError();
      }
    },
    handleImgDelete() {
      console.log('click delete icon');
      // 为什么需要删除按钮？上传证书明明是必须选项？
    },
    handleImgUploading() {
      this.uploadStatus = 1;
    },
    handleImgUploadError() {
      this.uploadStatus = 2;
    },
  },
};
</script>

<style lang="scss" scoped>
$z-index: 10002;
$loader-width: 100px;
$loader-height: 100px;

.img-uploader {
  * {
    margin: 0;
    padding: 0;

    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;

    color: #808080;

    box-sizing: border-box;
  }

  .uploader-box {
    width: $loader-width;
    height: $loader-height;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #cccccc;

    position: relative;

    margin-bottom: 10px;

    ::v-deep .uploader-icon {
      display: none;
    }
    ::v-deep .el-upload {
      width: 100%;
      height: 100%;
    }
    ::v-deep .el-upload > input {
      width: 100%;
      height: 100%;

      background-color: red;
      position: absolute;
      //   opacity: 0;
      z-index: 10002; // 保持在最上层

      cursor: pointer;
    }
    // 图片上传器容器
    .uploader-status {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
    }
    // 尚未上传图片的状态
    .uploader-no-img {
      p,
      span {
        position: absolute;

        left: 0;
        right: 0;
      }

      #uploader-no-img-plus {
        display: inline-block;
        top: 20px;
        font-size: 28px;
        vertical-align: middle;
      }
    }
    // 图片上传中
    .uploading {
      background: rgba(17, 17, 17, 0.5);

      span {
        font-size: 14px;
        color: #fff;
        line-height: $loader-height;
      }
    }
    // 图片上传失败
    .uploader-fail {
      border-radius: 4px 4px 4px 4px;
      background: #ffffff;
      border: 1px solid #d22316;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        display: block;
        color: #d22316;
        font-size: 14px;
        text-align: center;
      }
    }

    // 图片预览
    .uploader-preview {
      position: relative;

      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .uploader-img {
        position: relative;
        width: 90px;
        height: 90px;
      }

      .uploader-img-mask {
        display: none;

        width: 100%;
        height: 100%;

        position: absolute;

        background-color: rgba(17, 17, 17, 0.5);
        z-index: $z-index + 5;

        // 悬浮显示图片删除
        .uploader-delete-icon {
          color: #ffffff;
          font-size: 24px;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:hover .uploader-img-mask {
        display: block;
      }
    }
  }

  .uploader-tip {
    .uploader-tip-icon {
      color: #e6a23c;
    }
  }
}
</style>

<template>
  <div>
    <head-nav></head-nav>
    <div class="container">
      <div class="supplier-info">
        <!-- 经营总览 -->
        <BusOverview
          :pageCurrency="pageCurrency"
          :detail="totalBoxDetail"
          :raderPicMsg="raderPicMsg"
          @change-currency="changeCurrency"
        ></BusOverview>
        <!-- 公告/通知 -->
        <Circular></Circular>
      </div>

      <div class="quick-entry">
        <!-- 快捷入口 -->
        <QuickEnter></QuickEnter>
      </div>

      <!-- 详细数据 -->
      <CardList :pageCurrency="pageCurrency" :cardList="cardList"></CardList>
      <!-- 实时概况 -->
      <RealTimeOverview
        :pageCurrency="pageCurrency"
        :detail="realTimeOverviewData"
      ></RealTimeOverview>
      <!-- 支付数据 -->
      <StatisticalTime
        :pageCurrency="payDataCurrency"
        :detail="payEchartsData"
        @chose-type="chosePayType"
        @chose-date="chosePayDate"
        @change-currency="changePayDataCurrency"
      ></StatisticalTime>
      <!-- 订单数据 -->
      <ReturnRate
        :detail="ordersEchartsData"
        @chose-type="choseOrderType"
        @chose-date="choseOrderDate"
      ></ReturnRate>
      <!-- 优惠券 -->
      <!-- <Coupon></Coupon> -->
      <!-- 排行榜 -->
      <!-- <TopList></TopList> -->
      <!-- 侧边广告 -->
      <!-- <AdCard></AdCard> -->
    </div>
  </div>
</template>

<script>
import { get, defaultTo } from 'lodash';
import AsideComponents from './components/aside/install';
import MainComponents from './components/main/install';

const components = {
  ...AsideComponents,
  ...MainComponents,
};

export default {
  name: 'Index',
  components,
  data() {
    return {
      /**
       * 币种相关
       */
      pageCurrency: '1', // 当前页面展示的币种, 1 人民币 2 美元
      payDataCurrency: '1', // 支付数据板块的币种, 1 人民币 2 美元
      topDataCurrency: '1', // top榜数据板块的币种, 1 人民币 2 美元

      /**
       * 营业总览板块数据
       */
      totalBoxDetail: {}, // 获取经营总览用户积分等级信息
      raderPicMsg: {}, // 雷达图数据

      /**
       * 多板块信息
       */
      cardList: {
        goodsData: {}, // 商品
        ordersData: {}, // 订单
        messageNoticeData: {}, // 消息通知
        disputeData: {}, // 纠纷
        alertPaddingData: {}, // 预警&待处理数据
        // alertData: {}, // 预警数据
        // handleData: {}, // 待处理数据
        wareData: {}, // 仓库数据
        logisticsData: {}, // 物流数据
      },

      realTimeOverviewData: {}, // 实时概况数据

      /**
       * 支付数据相关
       */
      payDataType: '1', // 当前要查看的支付数据盒子
      payDataTime: [], // 支付数据统计时间区间
      payEchartsData: {}, // 支付数据

      /**
       * 订单数据相关
       */
      orderDataType: '1', // 当前要查看的订单数据盒子
      ordersDataTime: [], // 订单数据统计时间区间
      ordersEchartsData: {}, // 订单数据

      /**
       * top榜相关
       */
      topCategory: [], // 选择的top榜搜索的类目
      // top榜数据
      topDataList: {
        tradeRank: {},
        agentSalesVolume: {},
        agentSalesMoney: {},
      },
    };
  },
  mounted() {
    this.getTotalDetail(); // 获取经营总览用户积分等级信息
    this.getRadarMsg(); // 获取雷达图数据信息

    this.getCardListData();

    this.getRealTimeOverview(); // 获取实时概况数据

    this.getPayEchartsData(); // 获取支付echarts图表数据
    this.getOrdersEchartsData(); // 获取订单echarts图表数据

    this.getTopDataList(); // 获取top榜数据
  },
  methods: {
    // 改变当前页面的币种
    changeCurrency(type) {
      this.pageCurrency = type;
      this.payDataCurrency = type;
      this.topDataCurrency = type;
      this.getTotalDetail(); // 获取经营总览用户积分等级信息
      this.getRadarMsg(); // 获取雷达图数据信息
      this.getCardListData(); // 获取多板块信息数据
      this.getRealTimeOverview(); // 获取实时概况数据
      this.getPayEchartsData(); // 获取支付echarts数据
      this.getTopDataList(); // 获取top榜数据
    },
    /**
     * 订单总览数据
     */
    // 获取经营总览用户积分等级信息
    getTotalDetail() {
      const api = 'platformReport.getUserLevelInfo';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            for (const key in res?.data) {
              res.data[key] = defaultTo(res?.data[key], '0');
            }
            this.totalBoxDetail = JSON.parse(JSON.stringify(res?.data || {}));
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 获取雷达图数据信息
    getRadarMsg() {
      const api = 'platformReport.getHexagon';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            this.raderPicMsg = JSON.parse(JSON.stringify(res?.data || {}));
          }
        },
        err => {
          console.error(err);
        }
      );
    },

    /**
     * 多板块信息数据
     */
    getCardListData() {
      this.getGoodsData();
      this.getOrdersData();
      this.getMsgNoticeData();
      this.getDisputeData();
      this.getAlertPaddingData();
      // this.getAlertData();
      // this.getHandleData();
      this.getWareData();
      this.getLogisticsData();
    },
    // 商品
    getGoodsData() {
      const api = 'PlatformReport.getGoodsNumber';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            for (const key in res?.data) {
              res.data[key] = defaultTo(res?.data[key], 0);
            }
            this.cardList.goodsData = JSON.parse(
              JSON.stringify(res?.data || {})
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 订单
    getOrdersData() {
      const api = 'PlatformReport.getOrderNumber';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            for (const key in res?.data) {
              res.data[key] = defaultTo(res?.data[key], 0);
            }
            this.cardList.ordersData = JSON.parse(
              JSON.stringify(res?.data || {})
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 消息通知
    getMsgNoticeData() {
      const api = 'PlatformReport.getNoticeNumber';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            for (const key in res?.data) {
              res.data[key] = defaultTo(res?.data[key], 0);
            }
            this.cardList.messageNoticeData = JSON.parse(
              JSON.stringify(res?.data || {})
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 纠纷
    getDisputeData() {
      const api = 'PlatformReport.getDisputeNumber';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            for (const key in res?.data) {
              res.data[key] = defaultTo(res?.data[key], 0);
            }
            this.cardList.disputeData = JSON.parse(
              JSON.stringify(res?.data || {})
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 预警数据&待处理数据
    getAlertPaddingData() {
      const api = 'PlatformReport.getWarningNumber';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            for (const key in res?.data) {
              res.data[key] = defaultTo(res?.data[key], 0);
            }
            this.cardList.alertPaddingData = JSON.parse(
              JSON.stringify(res?.data || {})
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 待处理数据
    // getHandleData() {
    //   const api = 'PlatformReport.getWarningNumber';
    //   xmJson(api, {}, res => {
    //     if (res.errcode == 0) {
    //       for (const key in res.data) {
    //         res.data[key] = defaultTo(res.data[key], 0);
    //       }
    //       this.cardList.handleData = JSON.parse(JSON.stringify(res.data));
    //     } else {
    //       this.$message.warning(res.msg);
    //     }
    //   });
    // },
    // 仓库数据
    getWareData() {
      const api = 'PlatformReport.getWarehouseNumber';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            for (const key in res?.data) {
              res.data[key] = defaultTo(res?.data[key], 0);
            }
            this.cardList.wareData = JSON.parse(
              JSON.stringify(res?.data || {})
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 物流数据
    getLogisticsData() {
      const api = 'platformReport.getLogisticsNumber';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            for (const key in res?.data) {
              res.data[key] = defaultTo(res?.data[key], 0);
            }
            this.cardList.logisticsData = JSON.parse(
              JSON.stringify(res?.data || {})
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    },

    // 实时概况
    getRealTimeOverview() {
      const api = 'platformReport.getRealTime';
      let options = {
        currency: this.pageCurrency,
      };
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            this.realTimeOverviewData = JSON.parse(
              JSON.stringify(res?.data || {})
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    },

    /**
     * echarts折线图板块
     */
    // 支付数据
    getPayEchartsData() {
      const api = 'PlatformReport.getorderstatistics';
      let options = {
        type: this.payDataType,
        currency: this.payDataCurrency,
      };
      if (this.payDataTime && this.payDataTime.length == 2) {
        options.start = this.payDataTime[0];
        options.end = this.payDataTime[1];
      }
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            this.payEchartsData = get(res, 'data', {});
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 选择支付盒子
    chosePayType(type) {
      this.payDataType = type;
      this.getPayEchartsData();
    },
    // 选择支付时间
    chosePayDate(date) {
      if (date) {
        this.payDataTime = date;
      } else {
        this.payDataTime = [];
      }
      this.getPayEchartsData();
    },

    // 订单数据
    getOrdersEchartsData() {
      const api = 'platformReport.getAfterStatistics';
      let options = {
        type: this.orderDataType,
        currency: this.pageCurrency,
      };
      if (this.ordersDataTime && this.ordersDataTime.length == 2) {
        options.start = this.ordersDataTime[0];
        options.end = this.ordersDataTime[1];
      }
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            this.ordersEchartsData = get(res, 'data', {});
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 选择订单盒子
    choseOrderType(type) {
      this.orderDataType = type;
      this.getOrdersEchartsData();
    },
    // 选择订单时间
    choseOrderDate(date) {
      if (date) {
        this.ordersDataTime = date;
      } else {
        this.ordersDataTime = [];
      }
      this.getOrdersEchartsData();
    },

    /**
     * Top榜数据
     */
    // 获取Top榜数据
    getTopDataList() {
      const api = 'PlatformReport.getTopList';
      let options = {
        currency: this.topDataCurrency,
      };
      if (this.topCategory.length >= 1) {
        options.cat_1 = this.topCategory[0];
      }
      if (this.topCategory.length >= 2) {
        options.cat_2 = this.topCategory[1];
      }
      if (this.topCategory.length >= 3) {
        options.cat_id = this.topCategory[2];
      }
      xmJson(
        api,
        options,
        res => {
          if (res?.errcode == 0) {
            this.topDataList = JSON.parse(JSON.stringify(res?.data || {}));
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 搜索条件改变获取Top榜数据
    changeCategory(cate) {
      this.topCategory = cate;
      this.getTopDataList();
    },

    /**
     * 小板块改变币种
     */
    // 支付数据改变币种
    changePayDataCurrency(type) {
      this.payDataCurrency = type;
      this.getPayEchartsData(); // 获取支付echarts图表数据
    },
    // top榜数据改变币种
    changeTopDataCurrency(type) {
      this.topDataCurrency = type;
      this.getTopDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 20px auto 0;
  padding-bottom: 30px;
  width: 1200px;
  background-color: #f7f7f7;

  .supplier-info {
    display: flex;
    justify-content: space-between;
  }

  .quick-entry {
    margin: 20px 0;
  }
}
</style>

<template>
  <div class="card">
    <div class="goods-details-connect">
      <div class="goods-details-connect-title">
        <div>
          预警数据<i class="update-time">(更新于{{ detail.updateTime }})</i>
        </div>
        <!-- <span @click="goUrl">查看全部</span> -->
      </div>
      <div class="goods-details-connect-label">
        <div class="label-list">
          <div>
            退货率
            <el-popover
              placement="bottom-start"
              width="198"
              trigger="hover"
              content="退货订单总数/已完成的订单总数"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.return != 0.0 }]"
            >{{ detail.return }}%</span
          >
        </div>
        <div class="label-list">
          <div>物流异常率</div>
          <span :class="[{ 'have-num': detail.logistics != 0.0 }]"
            >{{ detail.logistics }}%</span
          >
        </div>
        <div class="label-list">
          <div>商品合格率</div>
          <span :class="[{ 'have-num': detail.product != 0.0 }]"
            >{{ detail.product }}%</span
          >
        </div>
        <div class="label-list">
          <div>
            投诉率
            <el-popover
              placement="bottom-start"
              width="234"
              trigger="hover"
              content="已发起投诉订单总数/已完成的订单总数"
              popper-class="platform-level-popover"
            >
              <i slot="reference" class="iconfont">&#xe626;</i>
            </el-popover>
          </div>
          <span :class="[{ 'have-num': detail.after != 0.0 }]"
            >{{ detail.after }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { defaultTo } from 'lodash';
export default {
  name: 'AlertData',
  props: {
    // 预警数据
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // update_time: '',
      // detail: {},
    };
  },
  mounted() {
    // this.update_time = dayjs().format('YYYY-MM-DD');
  },
  methods: {
    goUrl() {},
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/minx.scss';
</style>

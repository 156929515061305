<template>
  <div class="componentPannel">
    <span>{{ title }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'componentPannel',
  props: {
    title: {
      type: String,
      default: function() {
        return '标题';
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.componentPannel {
  /* width: 1060px; */
  height: 56px;
  background: #ffffff;
  padding: 16px 0 16px 23px;
  border-bottom: 1px solid #e6e6e6;
}
.componentPannel span {
  height: 24px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #111111;
}
</style>

const options = {
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['领取张数', '使用张数', '使用总额（￥）', '抵扣总额（Y）']
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value',
    name: '张数'
  },
  series: [
    {
      name: '领取张数',
      type: 'line',
      stack: 'Total',
      data: [120, 132, 101, 134, 90, 230, 210]
    },
    {
      name: '使用张数',
      type: 'line',
      stack: 'Total',
      data: [220, 182, 191, 234, 290, 330, 310]
    },
    {
      name: '使用总额（￥）',
      type: 'line',
      stack: 'Total',
      data: [150, 232, 201, 154, 190, 330, 410]
    },
    {
      name: '抵扣总额（Y）',
      type: 'line',
      stack: 'Total',
      data: [320, 332, 301, 334, 390, 330, 320]
    }
  ]
};


export default {
  setLabels(labels) {
    options.xAxis.data = labels;
  },
  setData(data) {
    options.series.forEach((item, index) => {
      item.data = data[index];
    });
  },
  getOptions() {
    return options;
  }
};
<template>
  <div class="address-selector">
    <el-select
      clearable
      :value="completeAddress"
      :popper-append-to-body="false"
      popper-class="el-select-popper"
      @focus="showDropdown = true"
      ref="selector"
      @clear="clearAddressFunc"
      :placeholder="placeholderKey"
    >
      <template slot="empty">
        <el-tabs v-show="showDropdown" v-model="tabName" type="border-card">
          <el-tab-pane
            :label="langType == 'en' ? 'country' : '国家'"
            name="country"
          >
            <el-input
              :placeholder="langType == 'en' ? 'search country' : '搜索国家'"
              v-model="searchTarget"
              @input="searchCountry"
            >
              <img
                class="search-icon"
                width="16px"
                height="16px"
                slot="prefix"
                :src="searchIcon"
              />
            </el-input>
            <div class="country-list-wrapper">
              <div
                class="country-groups"
                v-for="group in country_list"
                :key="group.initial"
              >
                <div>
                  <span
                    :class="{
                      active:
                        initialActive.toUpperCase() ==
                        group.initial.toUpperCase(),
                    }"
                    >{{ group.initial }}</span
                  >
                  <hr class="country-group-divider" />
                </div>
                <ul>
                  <li
                    v-for="item in group.list"
                    :key="item.id"
                    :class="{
                      active: addressData.country_id == item.id,
                    }"
                    @click="selectOption(0, item)"
                  >
                    {{ langType == 'en' ? item.name : item.cname }}
                  </li>
                </ul>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            name="province"
            :disabled="
              !addressData.country_id || addressData.country_id.length == 0
            "
            :label="langType == 'en' ? 'province' : '省（州）'"
          >
            <ul class="no-search-input">
              <li
                v-for="item in province_list"
                :key="item.id"
                :class="{ active: addressData.province_id == item.id }"
                @click="selectOption(1, item)"
              >
                {{ langType == 'en' ? item.name : item.cname }}
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane
            name="city"
            :disabled="
              !addressData.country_id ||
              addressData.country_id.length == 0 ||
              !addressData.province_id ||
              addressData.province_id.length == 0
            "
            :label="langType == 'en' ? 'city' : '市'"
          >
            <ul class="no-search-input">
              <li
                v-for="item in city_list"
                :key="item.id"
                :class="{ active: addressData.city_id == item.id }"
                @click="selectOption(2, item)"
              >
                {{ langType == 'en' ? item.name : item.cname }}
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs></template
      ></el-select
    >
  </div>
</template>
<script>
export default {
  inject: {
    country: { value: 'country', default: '' },
    country_id: { value: 'country_id', default: '' },
    province: { value: 'province', default: '' },
    province_id: { value: 'province_id', default: '' },
    city: { value: 'city', default: '' },
    city_id: { value: 'city_id', default: '' },
  },
  props: [
    'areaData',
    'continentId',
    'langType',
    'placeholderKey',
    'isEn',
    'en_areaData',
    'isLiveUpdate', // 是否实时更新，每选择一次地址就更新父组件数据
    'isTypeRote',
  ],

  data() {
    return {
      showDropdown: false,
      tabName: '',
      initialActive: '', // 搜索出来的符合条件的首字母
      searchTarget: '', // 用户搜索框的输入
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标

      country_list: [],
      province_list: [],
      city_list: [],
      /**
       * 需要传出的数据：
       */
      addressData: {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
      },
      en_addressData: {
        en_country: '',
        en_province: '',
        en_city: '',
      },
    };
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      const addr = `${this.addressData.country}${
        this.addressData.province && this.addressData.province.length > 0
          ? '/'
          : ''
      }${this.addressData.province}${
        this.addressData.city && this.addressData.city.length > 0 ? '/' : ''
      }${this.addressData.city}`;
      return addr;
    },
  },
  watch: {
    isTypeRote() {
      this.getCountryList();
    },
  },
  methods: {
    //点击x清空选择地址
    clearAddressFunc() {
      Object.keys(this.addressData).forEach(item => {
        this.addressData[item] = '';
      });
      this.en_addressData = this.$options.data().en_addressData;
      this.changeTab('country');
      this.$emit('update', this.addressData, this.en_addressData);
    },
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 0：国家菜单；1：省份菜单；2：城市菜单
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      if (!this.isLiveUpdate) {
        // 如果非实时更新的，则在 selectOption 前清空之前的选项
        this.$emit('reset', index);
      }
      if (index == 0) {
        this.province_list = [];
        this.city_list = [];
        // 选择国家
        this.addressData.country =
          this.langType == 'en' ? item.name : item.cname;
        this.addressData.country_id = item.id;
        // 清空状态
        this.addressData.province = '';
        this.addressData.province_id = '';
        this.addressData.city = '';
        this.addressData.city_id = '';
        if (this.isEn) {
          this.en_addressData.en_country = item.name;
          this.en_addressData.en_province = this.en_addressData.en_city = '';
        }
        if (this.isLiveUpdate) {
          this.$emit('update', this.addressData, this.en_addressData, false);
        }
        if (item.has_state == '1') this.getProvinceList();
        else if (item.has_state == '0') this.getCityList();
      } else if (index == 1) {
        this.city_list = [];
        // 选择省份
        this.addressData.province =
          this.langType == 'en' ? item.name : item.cname;
        this.addressData.province_id = item.id;
        // 清空状态
        this.addressData.city = '';
        this.addressData.city_id = '';
        if (this.isEn) {
          this.en_addressData.en_province = item.name;
          this.en_addressData.en_city = '';
        }
        if (this.isLiveUpdate) {
          this.$emit('update', this.addressData, this.en_addressData, false);
        }
        this.getCityList();
      } else if (index == 2) {
        this.addressData.city = this.langType == 'en' ? item.name : item.cname;
        this.addressData.city_id = item.id;
        if (this.isEn) {
          this.en_addressData.en_city = item.name;
        }
        // 可以传出数据了
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.$emit('update', this.addressData, this.en_addressData, true);
      this.showDropdown = false;
      this.$refs.selector && this.$refs.selector.blur(); // 选择器的尖角符号向下
    },
    // 搜索国家，高亮用户输入数据后的首字母
    searchCountry() {
      const api = 'Supplier.getCountries';
      const options = {
        is_initial_group: 1,
        name: this.searchTarget,
        id: this.continentId ? this.continentId : '',
      };
      if (this.isTypeRote) {
        options.type_rote = this.isTypeRote;
      }
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            that.country_list = res.data;
            that.initialActive = res.data[0].initial;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    getCountryList() {
      const api = 'Supplier.getCountries';
      const options = {
        is_initial_group: 1,
        id: this.continentId ? this.continentId : '',
      };
      if (this.isTypeRote) {
        options.type_rote = this.isTypeRote;
      }
      let that = this;
      xmJson(
        api,
        options,
        res => {
          if (res.errcode == 0) {
            that.country_list = res.data;
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    getProvinceList() {
      return new Promise((resolve, reject) => {
        const api = 'supplier.getStates';
        const options = {
          id: this.addressData.country_id,
        };
        let that = this;
        xmJson(
          api,
          options,
          res => {
            if (res.errcode == 0) {
              that.province_list = res.data;
              that.changeTab('province');
              resolve(res.data);
            }
          },
          err => {
            console.error(err);
            reject(err);
          }
        );
      });
    },
    getCityList() {
      return new Promise((resolve, reject) => {
        const api = 'supplier.getCity';
        // 上级id：没有省份的国家，其城市的上级id就是国家id；否则为省份id
        const upperType =
          this.addressData.province_id && this.addressData.province_id != '0'
            ? ''
            : 'country';
        const upperId =
          upperType != 'country'
            ? this.addressData.province_id
            : this.addressData.country_id;
        const options = {
          id: upperId,
          type: upperType,
        };
        let that = this;
        xmJson(
          api,
          options,
          res => {
            if (res.errcode == 0) {
              that.city_list = res.data;
              if (that.city_list.length > 0) {
                that.changeTab('city');
                resolve(res.data);
              } else {
                that.closeDropdown();
              }
            }
          },
          err => {
            console.error(err);
            reject(err);
          }
        );
      });
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
      };
      this.en_addressData = this.$options.data().en_addressData;
      this.initialActive = '';
      this.searchTarget = '';
      this.tabName = '';
      this.country_list = [];
      this.province_list = [];
      this.city_list = [];
    },
    // 父组件调用地址回显
    echoData(info) {
      this.addressData.country = info ? info.country || '' : '';
      this.addressData.country_id = info ? info.country_id : '';
      this.addressData.province = info ? info.province || '' : '';
      this.addressData.province_id = info ? info.province_id : '';
      this.addressData.city = info ? info.city || '' : '';
      this.addressData.city_id = info ? info.city_id : '';
    },
  },
  async mounted() {
    this.getCountryList();
    this.changeTab('country');
    if (this.country_id) {
      // 使用 provide-inject 传参
      this.addressData.country = this.country || '';
      this.addressData.country_id = this.country_id || '';
      this.addressData.province = this.province || '';
      this.addressData.province_id = this.province_id || '';
      this.addressData.city = this.city || '';
      this.addressData.city_id = this.city_id || '';
    } else {
      // 若父组件传入的地址数据，是在其组件渲染后（比如，在父组件的mounted函数中向后端请求数据）
      // 才获取的，那么就应当使用props传参，参数名为 `areaData`
      // console.log(this.areaData, this.areaData.country_id, 123);
      this.addressData.country = this.areaData
        ? this.areaData.country || ''
        : '';
      this.addressData.country_id = this.areaData
        ? this.areaData.country_id
        : '';
      this.addressData.province = this.areaData
        ? this.areaData.province || ''
        : '';
      this.addressData.province_id = this.areaData
        ? this.areaData.province_id
        : '';
      this.addressData.city = this.areaData ? this.areaData.city || '' : '';
      this.addressData.city_id = this.areaData ? this.areaData.city_id : '';
      if (this.isEnglish) {
        //英文(地区)回显
        this.en_addressData.en_country = this.en_areaData
          ? this.en_areaData.en_country || ''
          : '';
        this.en_addressData.en_province = this.en_areaData
          ? this.en_areaData.en_province || ''
          : '';
        this.en_addressData.en_city = this.en_areaData
          ? this.en_areaData.en_city || ''
          : '';
      }
    }

    if (this.addressData.province_id && this.addressData.province_id != '0') {
      // 有省份的国家，才获取省份
      const res = await this.getProvinceList();
    }
    if (this.addressData.city_id && this.addressData.city_id != '0') {
      const res = await this.getCityList();
    }
  },
  beforeDestroy() {
    this.resetData();
  },
};
</script>
<style lang="scss" scoped>
$tab-width: 100px;
$select-height: 40px;
$highlight-color: #ff7802;

.address-selector {
  position: relative;
  // width: $tab-width * 3;
  width: 200px;

  * {
    border: 0px;
  }
  ul {
    padding-left: 1em; // 选项名称距离左边的距离
  }

  li {
    list-style-type: none;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  li.active,
  span.active {
    color: $highlight-color;
  }
  li:hover {
    color: $highlight-color;
  }
  ::v-deep .el-input.el-input--suffix {
    width: 200px;
  }

  // 解决focus输入框边框变蓝问题
  ::v-deep .el-select .el-input.is-focus .el-input__inner,
  ::v-deep .el-select .el-input__inner:focus {
    border-color: $highlight-color;
  }
  // 隐藏el原本的下拉菜单
  ::v-deep .el-select-dropdown {
    visibility: hidden;
  }
  ::v-deep .popper__arrow {
    visibility: hidden;
  }

  .no-search-input {
    height: 290px;
    overflow: auto;
    padding-left: 5px;

    &::-webkit-scrollbar-track {
      background: none;
    }
  }
  ::v-deep .el-input {
    // width: 280px;
    height: 40px;
    margin-bottom: 10px; // 搜索框离下面的选项文字的距离
  }

  ::v-deep .el-input__prefix {
    display: inline-block;
  }

  ::v-deep .search-icon {
    display: inline-block;
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-tabs .el-input__inner {
    width: 280px;
    margin-left: -5px;
    border-radius: 0px 0px 0px 0px;
    background-color: #f7f7f7;

    font-size: 12px;
    color: #808080;
  }
  // 处理tabs位置问题
  ::v-deep .el-tabs {
    visibility: visible;
    position: absolute;
    top: -8px;
    left: -6px;
    z-index: 2; // 必须比原本的下拉菜单大

    width: $tab-width * 3;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    * {
      font-size: 14px;
      color: #666;
    }
  }

  ::v-deep .el-tabs--border-card > .el-tabs__header {
    border: 0px;
  }
  ::v-deep .el-tabs--border-card > .el-tabs__content {
    padding-bottom: 10px;
  }
  // 解决高亮标签页两侧有1px缝隙
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-left-color: transparent;
    border-right-color: transparent;
  }
  // 调整标签页宽度，宽度相同且文本居中
  ::v-deep .el-tabs__item {
    width: $tab-width;
    text-align: center;
  }
  // 解决“城市”标签页头右侧有1px缝隙
  ::v-deep .el-tabs__item:nth-last-of-type(1) {
    width: $tab-width + 1;
  }
  // 隐藏标签页前后的滑动提示箭头
  ::v-deep .el-tabs__nav-prev,
  .el-tabs__nav-next {
    display: none;
  }
  ::v-deep
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item
    + .el-tabs__item {
    margin-left: 0px;
  }

  ::v-deep .el-tabs__content {
    padding-right: 2px; // 解决滚动条不贴右边问题（有 2px 缝隙）
    padding-top: 10px;
    height: 310px;
  }
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #808080;
  }
  // 解决点击和悬浮标签页，标签页文字蓝色高亮问题
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: #4d4d4d;
  }
  ::v-deep .el-tabs__item.is-top {
    background-color: #f2f2f2;
    color: #808080;
  }
  ::v-deep .el-tabs__item.is-active {
    background-color: #ffffff;
    color: #4d4d4d;
  }

  .country-list-wrapper {
    height: 240px;
    overflow: auto;

    &::-webkit-scrollbar-track {
      background: none;
    }
  }
  .country-groups {
    & > span {
      color: #666;
      padding-right: 5px;
      margin-top: 5px;
    }
    & > span.active {
      color: $highlight-color;
    }
    // 国家按拼音分组，分组的水平分割线
    .country-group-divider {
      border: 1px solid #e6e6e6;
      display: inline-block;
      width: $tab-width * 3 - 50px;

      margin-bottom: 0.33em;
    }
  }
}
</style>

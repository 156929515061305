<template>
  <!-- 细目网站（主要是登录页面）底部的网站信息栏，包含网站备案号等信息 -->
  <div class="ximu-footer" :style="{ height: height + 'px' }">
    <div class="container">
      <!-- 版权 -->
      <div class="policy">
        <div class="about">
          <span>关于我们</span>
          <span>联系客服</span>
          <span>合作招商</span>
          <span>商家帮助</span>
          <span>隐私政策</span>
          <span>语言</span>
          <span>廉政举报</span>
        </div>
        <div class="copyright">
          <span>湘ICP备2023010376号-1</span>
          <span>湘公网备案XXXXXXXX</span>
          <span>增值电信业务经营许可证</span>
          <span>Copyright © 2023-2033 ximu.cn 版权所有</span>
          <span>违法和不良信息举报电话：4006561155</span>
        </div>
        <div class="bottom-imgs">
          <span><img src="@/assets/images/bottom_1.png" alt=""/></span>
          <span><img src="@/assets/images/bottom_2.png" alt=""/></span>
          <span><img src="@/assets/images/bottom_3.png" alt=""/></span>
          <span><img src="@/assets/images/bottom_4.png" alt=""/></span>
          <span><img src="@/assets/images/bottom_5.png" alt=""/></span>
          <span><img src="@/assets/images/bottom_6.png" alt=""/></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'XimuFooter',
  props: {
    height: {
      type: String,
      default: '277',
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.ximu-footer {
  background-color: #ffffff;
  height: 100%;

  .container {
    margin: 0 auto;
    width: 1200px;
    // border-top: 1px dotted #e6e6e6;

    .policy {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      color: #808080;
      padding: 30px 0 50px 0;
    }
    .copyright {
      color: #999;
      padding: 22px 0 20px;
    }

    .about span::after,
    .copyright span::after {
      content: '|';
      width: 1px;
      height: 8px;
      margin: 0 4px;
    }

    .about span:last-child::after,
    .copyright span:last-child::after {
      content: none;
    }

    .bottom-imgs {
      display: flex;
      span {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>

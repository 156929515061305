<template>
  <div class="circular">
    <div class="tab flex">
      <el-tabs v-model="tabActive" @tab-click="onTabClick">
        <el-tab-pane label="最新公告" name="notice"></el-tab-pane>
        <!-- <el-tab-pane label="重要通知" name="notify"></el-tab-pane> -->
      </el-tabs>
      <div class="more" @click="lookMore">更多</div>
    </div>
    <!-- <div class="more">更多</div> -->
    <div class="tab-main">
      <vue-seamless-scroll
        v-if="tabList.length !== 0"
        :data="tabList"
        :class-option="seamlessOptions"
        class="seamless-wrap"
      >
        <ul class="tab_list">
          <li
            class="tab_list_item"
            v-for="(item, index) in tabList"
            :key="index"
            @click="goDetail(item.id)"
          >
            <span class="title">{{ item.title }}</span>
            <span>{{ item.time }}</span>
          </li>
        </ul>
      </vue-seamless-scroll>
      <div v-else class="tab_list--empty">
        <p>{{ compuEmptyText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueSeamlessScroll from 'vue-seamless-scroll';

export default {
  name: 'Circular',
  components: { VueSeamlessScroll },
  data() {
    return {
      tabActive: 'notice',
      tabList: [],
      pageNum: 1,
      pageSize: 12, // 读取最新 12 条

      seamlessOptions: {
        singleHeight: 28 * 4,
        waitTime: 5000, // 停顿 5s
        step: 7, // 滚动时间 500ms
      },
    };
  },
  computed: {
    compuEmptyText() {
      return this.tabActive == 'notice' ? '暂无公告' : '暂无通知';
    },
  },
  created() {
    this.onTabClick();
  },
  methods: {
    onTabClick() {
      if (this.tabActive == 'notice') {
        this.getNoticeList();
      } else {
        this.getNotifyeList();
      }
    },
    // 获取公告列表
    getNoticeList() {
      let options = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        API_MODULE: 'notify',
      };
      xmJson(
        'Notice.noticeDisplay',
        options,
        res => {
          if (res?.errcode == 0) {
            if (res?.data?.list != undefined) {
              let list = res?.data?.list?.map(item => {
                return {
                  id: item.id,
                  title: item.notice_title,
                  time: item.notice_time.slice(5, 11),
                };
              });
              if (list.length % 4 != 0) {
                this.tabList = list.concat(
                  Array.from({ length: 4 - (list.length % 4) }, () => {
                    return { id: '', title: '', time: '' };
                  })
                );
              } else {
                this.tabList = list;
              }
              // this.total = Number(res.data.total);
            }
          }
        },
        err => {
          console.error(err);
        }
      );
    },
    // 获取通知列表
    getNotifyeList() {
      this.tabList = [];
      // const api = 'platformReport.getnotice';

      // xmJson(api, {}, res => {
      //   if (res.errcode == 0) {
      //     const list = get(res, 'data', []);

      //     this.tabList = list.map(item => {
      //       return {
      //         id: item.id,
      //         title: item.call,
      //         time: dayjs.unix(item.created_time).format('YYYY-MM-DD HH:mm:ss'),
      //       };
      //     });
      //   }
      // });
    },
    // 跳转详情
    goDetail(id) {
      if (!id) return;
      if (this.tabActive == 'notice') {
        this.$router.push('/bulletindetail/' + id);
      }
    },
    // 点击更多
    lookMore() {
      this.$router.push('/bulletinlist');
    },
  },
};
</script>

<style lang="scss" scoped>
.circular {
  padding: 20px 20px 0 20px;
  width: 428px;
  height: 185px;
  background-color: #fff;

  .flex {
    display: flex;
    justify-content: space-between;
  }

  ::v-deep .tab {
    .el-tabs__header {
      margin: 0 0 20px;

      .el-tabs__active-bar {
        display: none;
      }

      .el-tabs__nav-wrap::after {
        content: none;
      }

      .el-tabs__item {
        padding: 4px 12px;
        height: 24px;
        line-height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #4d4d4d;
        box-sizing: border-box;
      }

      .el-tabs__item.is-active {
        color: #ff7802;
        border-bottom: 2px solid #ff7802;
      }
    }
  }

  .more {
    height: 24px;
    line-height: 24px;
    color: #3d8df5;
    font-size: 12px;
    cursor: pointer;
  }

  .tab-main {
    height: 112px;
    .seamless-wrap {
      height: 112px;
      overflow: hidden;
    }
    .tab_list {
      .tab_list_item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        color: #666;
        font-size: 14px;

        height: 18px;
        line-height: 18px;
        cursor: pointer;

        &:hover {
          color: #ff7802;
        }

        .title {
          width: 289px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .tab_list--empty p {
      color: #666;
      font-size: 14px;
    }
  }
}
</style>

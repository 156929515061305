import { method } from 'lodash';

let navItem = {
  // 授权书列表
  authBook: [
    // { id: 1, name: '授权书列表', path: '/authBookList', type: 'push' },
    // { id: 2, name: "授权待审核", path: "/authAuditList", type: 'push'},
    // { id: 3, name: "授权已拒绝", path: "/authRejectList", type: 'push'},
    { id: 4, name: '保险列表', path: '/authInsurList', type: 'push' },
    // { id: 5, name: '认证资质', path: '/authCertList', type: 'push' },
  ],
  // 账号及认证
  systemNav: [
    {
      id: 1,
      name: '账户设置',
      path: '/accountSetting',
      type: 'push',
    },
    {
      id: 2,
      name: '管理账号',
      path: '/systemAccount',
      type: 'push',
      isShow: true,
    },
    {
      id: 4,
      name: '操作日志',
      path: '/operLog',
      type: 'push',
      isShow: true,
    },
    {
      id: 5,
      name: '店铺公告',
      path: '/shopnotice',
      type: 'push',
      isShow: true,
    },
    {
      id: 8,
      name: 'EPR管理',
      path: '/eprManageList',
      type: 'push',
      isShow: true,
    },
    {
      id: 9,
      name: '欧盟代理',
      path: '/euPersonLiableList',
      type: 'push',
      isShow: true,
    },
    {
      id: 10,
      name: '地址管理',
      path: '/addressList',
      type: 'push',
      isShow: true,
    },
    {
      id: 11,
      name: '品牌商标',
      path: '/brandConsult',
      type: 'push',
      isShow: true,
    },
    {
      id: 12,
      name: '退店',
      path: '/checkOut',
      type: 'push',
      isshow: true,
      isShow: true,
    },
  ],
  // 投诉&举报
  complaintNav: [
    {
      id: 1,
      name: '投诉&举报',
      path: '/complaint/list',
      type: 'push',
      isshow: true,
    },
    {
      id: 2,
      name: '违规列表',
      path: '/violations/list',
      type: 'push',
      isshow: true,
    },
  ],
  //商品管理
  goodsNav: [
    { id: 1, name: '全部商品', path: '/goodGroup', type: 'push' },
    { id: 2, name: '商品分组', path: '/goodManagement', type: 'push' },
    // { id: 2, name: "品牌资质", path: "/brandConsult", type: "push" },
    // { id: 4, name: "限售模板管理", path: "/tempList", type: "push", isShow:'不显示' },
    // { id: 5, name: "报价单管理", path: "/quotationList", type: "push",isShow:'不显示' },
  ],
  //交易
  orderNav: [
    { id: 0, name: '全部订单', path: '/orderList', type: 'push' },
    // {id:2,name:'待支付',path:'/orderUnpaidList',type:'push'},
    { id: 3, name: '待发货', path: '/shippedList', type: 'push' },
    { id: 4, name: '支付处理中', path: '/paymentProcessing', type: 'push' },
    { id: 5, name: '待收货', path: '/receivedList', type: 'push' },
    { id: 7, name: '评价管理', path: '/evaluations', type: 'push' },
    { id: 6, name: '退款/纠纷', path: '/afterSalesList', type: 'push' },
  ],
  //物流
  freightNav: [
    // { id: 1, name: "运费模板管理", path: "/freightTemplate", type: "push" },
    { id: 4, name: '物流方案查询', path: '/logisPlanInquiry', type: 'push' },
    { id: 1, name: '运费模板管理', path: '/freightTemplate', type: 'push' },
    { id: 5, name: '运费统计', path: '/logisFreight', type: 'push' },
    // {
    //   id: 6,
    //   name: '物流概览',
    //   path: '/logisticsOverview',
    //   type: 'push',
    //   isTip: true,
    // },
  ],

  //仓库
  // wareNav:[
  //     // { id: 1, name: "仓库信息", path: "/wareHouse", type: "push" }
  // ],
  wareNav: [
    {
      id: 1,
      name: '仓库列表',
      children: [
        // { id: 2, name: "仓库信息", path: "/wareHouse", type: "push" },
        { id: 103, name: '商品仓', path: '/goodsStoreHouse', type: 'push' },
        // { id: 104, name: "退回仓", path: "/sendBackStoreHouse", type: "push" },
        // { id: 105, name: "物料仓", path: "/materialStoreHouse", type: "push" },
      ],
    },
    {
      id: 2,
      name: '仓库商品',
      children: [
        {
          id: 201,
          name: '仓库商品列表',
          path: '/wareGoodsManagement',
          type: 'push',
        },
        // { id: 202, name: '平台仓库', path: '/platformWarehouse', type: 'push' },
      ],
    },
    {
      id: 4,
      name: '仓库记录',
      children: [
        { id: 303, name: '出入库记录', path: '/inoutrecord', type: 'push' }, // 仓库下的出入库记录
      ],
    },
  ],

  //资金
  fundsNav: [
    { id: 1, name: '资金中心', path: '/funds', type: 'push' },
    { id: 2, name: '交易记录', path: '/tradeRecord', type: 'push' },
    // { id: 3, name: '历史账单', path: '/historBill', type: 'push', isTip: true },
    { id: 4, name: '发票管理', path: '/invoicemanagement', type: 'push' }, // 发票管理做客制化
    {
      id: 5,
      name: '提现记录',
      path: '/withdrawRecord',
      type: 'push',
    },
    {
      id: 6,
      name: '银行卡管理',
      path: '/bankcardmanage',
      type: 'push',
      isshow: true,
      // isTip: true,
    },
  ],
  // 通知
  noticeNav: [
    {
      id: 1,
      name: '通知设置',
      path: '/notice/setting',
      type: 'push',
      // isShow: true,
      isshow: true,
    },
    {
      id: 2,
      name: '平台公告',
      path: '/bulletinlist',
      type: 'push',
      // isShow: true,
      isshow: true,
    },
    {
      id: 3,
      name: '店铺经营',
      path: '/notice/shop',
      type: 'push',
      isshow: true,
    },
    {
      id: 4,
      name: '交易通知',
      path: '/notice/trade',
      type: 'push',
      isshow: true,
    },
    {
      id: 5,
      name: '系统通知',
      path: '/notice/system',
      type: 'push',
      isshow: true,
    },
  ],
};
class navItemClass {
  constructor(name) {
    if (name) {
      let arr = navItem[name] ? navItem[name] : [];
      //去除空值
      arr = arr.filter(item => {
        if (item) {
          return item;
        }
      });
      return arr;
    } else {
      let arr = navItem;
      return arr;
    }
  }
}
export default navItemClass;

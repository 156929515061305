<template>
  <div class="xm-steps">
    <el-steps :space="realSpace" :active="activeStep" align-center>
      <el-step v-for="(step, index) in steps" :key="index" :title="step.title">
        <template #icon>
          <xm-step-icon
            :status="getCurrentStatus(index)"
            :num="step.id + 1"
          ></xm-step-icon>
        </template>
      </el-step>
    </el-steps>
  </div>
</template>

<script>
import XmStepIcon from './XmStepIcon.vue';

export default {
  name: 'XmSteps',
  components: { XmStepIcon },
  props: {
    space: {
      // 步骤之间的间距（横向分割线的长度）
      //   只传数字，不传单位
      type: [String, Number],
      default: '250',
    },
    currentStep: {
      // 当前高亮的步骤的id
      type: [Number, String],
      default: 1,
    },
    steps: {
      // 定义的步骤条列表
      type: Array,
      default: () => [
        {
          id: 0,
          title: '步骤1',
        },
        {
          id: 1,
          title: '步骤2',
        },
        {
          id: 2,
          title: '步骤3',
        },
      ],
    },
    // icon 的背景色
    iconBgColor: {
      type: String,
      default: '#fff',
    },
  },
  computed: {
    // 要把icon与分割线的间隔算进去
    // 所以要加上 icon 占的 44px
    realSpace() {
      return parseInt(this.space) + 44 + 'px';
    },
    // 当前激活的步骤
    activeStep() {
      // 避免数据出问题，因此多做一步验证
      const active = parseInt(this.currentStep);
      return !isNaN(active) ? active : 0;
    },
  },
  methods: {
    // 当前步骤的状态
    getCurrentStatus(step) {
      if (step < this.currentStep) {
        return 'finish'; // 已过、已完成
      } else if (step == this.currentStep) {
        return 'process'; // 当前的步骤
      } else {
        return 'wait'; // 还未经过的步骤
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$highlight-color: #ff7802;

.xm-steps {
  margin: auto;
  * {
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-size: 14px;
    font-weight: 400;
  }

  ::v-deep .el-steps {
    justify-content: center;
    .is-horizontal {
      height: fit-content !important;
    }
    .el-step__line-inner {
      transition: none;
    }
  }

  ::v-deep .el-step__icon {
    border-color: transparent;

    width: 44px;
    border-radius: 0;

    // icon 与横线的间隔是强行套了一层div做的背景色间隔
    // 不是用 margin 或 padding 做的
    // 所以如果背景色比较特别，就要在下一行调整
    background-color: v-bind(iconBgColor);
  }

  ::v-deep .el-step__head {
    height: 24px;
    &.is-finish {
      border-color: #ffd6b2;
      .el-step__line {
        background-color: #ffd6b2;
        // opacity: 0.5;
      }
    }
    &.is-process {
      .el-step__line {
        background-color: #ffd6b2;
      }
    }
    &.is-wait {
      .el-step__line {
        background-color: #999;
      }
    }
  }

  ::v-deep .el-step__main {
    margin-top: 10px;
    .el-step__title {
      line-height: 22px;
      font-size: 14px;
      font-weight: 400;

      &.is-finish {
        color: #ffd6b2;
        // opacity: 0.5;
      }
      &.is-process {
        color: $highlight-color;
      }
      &.is-wait {
        color: #999;
      }
    }
  }
}
</style>

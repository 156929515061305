<template>
  <div class="xm-empty" v-if="isShow">
    <el-empty :image-size="imageSize" :image="imageUlr" :description="' '"></el-empty>
  </div>
</template>

<script>
export default {
  props: {
    //设置图片的尺寸
    imageSize: {
      type: Number,
      default: 400,
    },
    //控制显示隐藏
    isShow: {
      type: Boolean,
      default: false,
    },
    //设置展示图片的url
    imageUlr: {
      type: String,
      default: require('@/assets/images/defaultEmpty.png'),
    },
  },
};
</script>
<style scoped lang="scss">
.xm-empty {
  ::v-deep .el-empty {
    padding: 0;
    .el-empty__description {
      display: none;
    }
  }
}
</style>

<template>
  <div >
    <el-date-picker
      v-model="stock_timer"
      type="daterange"
      unlink-panels
      range-separator="至"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :picker-options="isShow?pickerOptionsAfter:''"
      @change="changeData"
      value-format="yyyy-MM-dd HH:mm:ss"
      :default-time="['00:00:00','23:59:59']"
      popper-class="date-picker"
      class="data-style">
    </el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    Category:{
      type:Number,
      default:0
    },
    // 把父组件定义的时间变量传进来，初始化作用
    stock_timer:{
      type:Array,
      default:[]
    },
    isShow:{
      type:Boolean,
      default:true
    },
    startPlaceholder:{
      type:String,
      default:'建议补货开始日期'
    },
    endPlaceholder:{
      type:String,
      default:'建议补货结束日期'
    }
  },
  data() {
    return {
      stock_timer:[],
      // 往后推时间
      pickerOptionsAfter: {
        shortcuts: [{
          text: '近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近15天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '近30天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近60天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 60);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      // 往前推时间(等待有缘人来补充)
      pickerOptionsBefore:{}
    };
  },
  methods: {
    // 传出去的值
    changeData(n){
      this.$emit('changeData',n)
    }
  },
};
</script>

<style lang="scss" >
.date-picker{
  .el-picker-panel__shortcut:hover{
    color: #FF7802 !important;
  }
}
.data-style{
  .el-range-separator{
    width: 10% !important;
  }
}

</style>

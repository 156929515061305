<template>
  <!-- 类目 -->
  <el-cascader
    v-model="cascaderValue"
    :options="categoryList"
    :size="size"
    :props="categoryOptions"
    @change="changeFunc($event)"
    @visible-change="valchang"
    :placeholder="placeholder"
    class="cascader_box"
    popper-class="radio_select_category"
    :key="keyindex"
    clearable
    ref="cascader"
    :append-to-body="false"
  ></el-cascader>
</template>

<script>
import { size } from './scripts/props';

export default {
  name: 'SelectCategory',
  props: {
    size,
    value: {},
    checkStrictly: {
      type: Boolean,
      default: true,
    },
    // 默认文字
    placeholder: {
      type: String,
      default: '类目',
    },
  },
  data() {
    return {
      categoryList: this.$store.state.category.categoryData,
      keyindex: 0,
    };
  },
  computed: {
    cascaderValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    categoryOptions() {
      return {
        label: 'name',
        value: 'supplier_class_id',
        children: '_child',
        checkStrictly: this.checkStrictly,
      };
    },
  },
  methods: {
    valchang(val) {
      if (!val) {
        this.keyindex++;
      }
    },
    changeFunc(data) {
      this.$refs.cascader.dropDownVisible = false;
      this.$emit('change', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.cascader_box {
  ::v-deep .el-input__inner {
    width: 255px;
  }
  ::v-deep .el-cascader-node.in-active-path {
    color: #ff7802 !important;
  }
}
</style>
<style>
.radio_select_category .in-active-path {
  font-weight: normal !important;
  background-color: #fff2e6 !important;
}
.radio_select_category .is-active {
  font-weight: normal !important;
  background-color: #fff2e6 !important;
}
.radio_select_category .el-radio__inner {
  top: -18px;
  left: -19px;
  border-radius: 0;
  border: 0;
  width: 170px;
  height: 34px;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
}
.radio_select_category .el-radio__input.is-checked .el-radio__inner {
  background: transparent;
}
.radio_select_category .el-radio__input.is-checked .el-radio__inner::after {
  background-color: transparent;
}
</style>

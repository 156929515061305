<!-- 驳回原因 -->
<template>
  <div class="module-box">
    <div class="content-box">
      <div class="content-lab">
        <span v-if="isRequired" class="required-style">*</span>原因：
      </div>
      <template v-if="isEditor">
        <el-input
          class="textarea-style"
          type="textarea"
          placeholder="请输入"
          v-model="reason"
          maxlength="100"
          show-word-limit
        ></el-input>
      </template>
      <template v-else>
        <div class="content-text">{{ text }}</div>
      </template>
    </div>
    <!-- 按钮组 -->
    <div class="btn-box" v-if="isEditor">
      <div class="btn-style confirm-style" @click="submitForm">确定</div>
      <div class="btn-style cancel-style" @click="cancelBtn">取消</div>
    </div>
  </div>
</template>
<script>
// 例子 src\pages\funds\withdrawRecord.vue
export default {
  data() {
    return {
      reason: '',
    };
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    // 是否编辑
    isEditor: {
      type: Boolean,
      default: false,
    },
    // 是否必填
    isRequired: {
      type: Boolean,
      default: false,
    },
    // 接口
    interface: {
      type: Function,
      default: () => {},
    },
    // 得到的数据
    info: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // 驳回确认按钮
    async submitForm() {
      try {
        if (this.reason == '' && this.isRequired) {
          this.$message.warning('驳回原因不能为空');
          return;
        }
        const options = {
          id: this.info.id,
          reason: this.reason,
        };
        let res = await this.interface(options);
        if (res) {
          if (res.code == 200) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.$emit('submit');
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 驳回取消按钮
    cancelBtn() {
      this.$emit('cancel');
    },
  },
};
</script>
<style lang="scss" scoped>
.module-box {
  padding: 20px;
}
.content-box {
  width: 500px;
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 20px;
  .content-lab {
    flex-shrink: 0;
    width: 50px;
    text-align: right;
  }
}
// 按钮样式
.btn-box {
  display: flex;
  justify-content: center;
  .btn-style {
    padding: 9px 20px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
  }
  .confirm-style {
    background: #026bff;
    color: #ffffff;
    margin-right: 20px;
  }
  .cancel-style {
    border: 1px solid #cccccc;
    box-sizing: border-box;
    color: #808080;
  }
}
.required-style {
  color: #d22316;
  flex-shrink: 0;
}
.textarea-style {
  ::v-deep .el-textarea__inner {
    height: 100px;
  }
}
</style>
